import React, { useEffect, useState} from 'react';
import { Modal, Box, Typography, Button, Link, Card, styled, TableRow, TableContainer, TableBody, Table, TableHead, Paper} from '@mui/material';
import NotifyRaffleWinner from '../Modals/notify_winner'
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import { connect } from 'react-redux';
import { viewDate } from '../../utils';
import { setToast, getRaffleWinners, drawRaffleWinner, deleteRaffleWinner, rewardClaimedData } from '../../services/actions'
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 924,
  bgcolor: 'background.paper',
  border: '0px solid #000',
  boxShadow: 24,
  borderRadius: '8px'
};

const shadowBoxStyle = {
  bgcolor: '#F6F8FB',
  border: '1px solid #CCCEDD',
  boxShadow: '0px 4px 21px rgba(24, 69, 136, 0.2)',
  borderRadius: '6px',
  p: '20px 28px',
  m: '25px 40px',
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column"
}

const StTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    borderColor: "#504D4D"
  },
  [`&.${tableCellClasses.body}`]: {
    borderColor: "#504D4D",
    color: "#8D8B8B"
  }
}));


const DeleteButton =  styled(Button)(
  ({ theme }) => `
    &:hover{
      background:none;
    }
`
);
const DrawWinners = (props) => {
  const {open, handleClose, user, dropsData, raffleWinners, setNotifyWinner, setNotifyRaffleObj} = props;
  
  const [emailIds, setEmailIds] = useState(raffleWinners.data.length > 0 ? raffleWinners.data.filter((a) => a.email_sent == false) : []);
  useEffect(() => {
    props.getRaffleWinners(dropsData.id);
  },[])

  const drawRaffleWinner = () => {
    props.drawRaffleWinner(dropsData.id)
  }

  const claimDataDownload = async() => {
    if (dropsData.drop_total_claim == 0) {return false;}
    const claimData = await rewardClaimedData(dropsData);
  }
    
  const handleDelete = (item_obj=null) => {
    props.deleteRaffleWinner(dropsData.id, item_obj.id)
  }
  const handleNotifyWinnerPopup = (item_obj=null) => {
    handleClose(true);
    setNotifyWinner(true);
    if (item_obj == null) {
      
      item_obj = raffleWinners.data.filter((a) => a.email_sent == false)
    }

    // console.log("item_obj ->",item_obj)
    setNotifyRaffleObj(item_obj);
  }
  return (
    <Modal open={open} aria-labelledby="Draw Winners" className='modals'>
      <Box sx={style} className='modal-card'>
        <Box p={4}>
          <Typography variant="h3" className='heading'>Draw Winners</Typography>

          <Box display="flex" justifyContent="center" my="21px">
            <Box className="draw-winner-label">
              <Typography variant="body1">Registrants</Typography>
              <Typography variant='h3'>{dropsData.drop_total_claim}</Typography>
            </Box>
            <Box className="draw-winner-label" ml={2}>
              <Typography variant="body1">Max Winners</Typography>
              <Typography variant='h3'>{dropsData.drop_type_data[0].max_winners}</Typography>
            </Box>
          </Box>

          {
            raffleWinners.data.length == 0 ?
            <Box textAlign="center">
              <img src="/assets/img/draw-raffle.png" alt="draw raffle" width="64" />
              <Typography variant="body1" className="draw-raffle-label-msg">No winners have been chosen</Typography>
            </Box>
            :
            <TableContainer component={Paper} sx={{borderRadius: "0px", boxShadow: "none"}}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow sx={{ backgroundColor: "#F3F3F3", border: "1px solid #504D4D" }}>
                    <StTableCell className="raffle-th">ETH ADDRESS</StTableCell>
                    <StTableCell className="raffle-th" align="left">EMail</StTableCell>
                    <StTableCell className="raffle-th" align="left">Date Drawn</StTableCell>
                    <StTableCell className="raffle-th" align="left">Date Notified</StTableCell>
                    <StTableCell className="raffle-th" align="left">Actions</StTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    raffleWinners.type == 'success' &&
                    raffleWinners.data.map((item,index) =>{
                      return(
                        <TableRow key={item.id} sx={{ borderColor: "#504D4D" }}>
                          <StTableCell component="th" scope="row">{item.ClaimUser.eth_address}</StTableCell>
                          <StTableCell align="left">
                            {item.claim_info.email}</StTableCell>
                          <StTableCell align="left">{viewDate(item.updatedAt)}</StTableCell>
                          <StTableCell align="left">{item?.email_sent_date == null ? '' : viewDate(item.email_sent_date)}</StTableCell>
                          <StTableCell align="left">
                            <Box sx={{marginLeft:"15px"}}>
                              {
                                item.email_sent ?
                                <DeleteButton sx={{minWidth: "20px"}} onClick={() => handleNotifyWinnerPopup(item)} className='delete-action-icon'>
                                  <img src="/assets/img/refresh-sm.svg" alt="email icon" />
                                </DeleteButton>
                                :
                                <DeleteButton sx={{minWidth: "20px"}} onClick={() => handleNotifyWinnerPopup(item)} className='delete-action-icon'>
                                  <img src="/assets/img/email-sm.svg" alt="email icon" />
                                </DeleteButton>
                              }
                              <DeleteButton sx={{minWidth: "20px"}} onClick={() => handleDelete(item)} className='delete-action-icon'>
                                <img src="/assets/img/delete-sm.svg" alt="email icon" />
                              </DeleteButton>
                            </Box>
                          </StTableCell>
                        </TableRow>
                      )
                    })
                  }
                </TableBody>
              </Table>
            </TableContainer>
          }

          <Box display="flex" justifyContent="center">
            {
              raffleWinners.data.length > 0 &&
              <Typography variant="body1" className="draw-raffle-note-msg-2" sx={{color: "#8D8B8B"}} mt="10px">Note: You are not restricted by the Max Winners amount set in your reward. Draw as few, or as many winners as you like.</Typography>
            }
          </Box>
          <Box display="flex" justifyContent="center" mt={raffleWinners.data.length > 0 ? "21px" : "70px"}>
            {
              (dropsData.drop_total_claim === raffleWinners.data.length) ?
              <Button autoFocus variant="contained" disabled={true} sx={{py:1, px:3, borderRadius:"6px", "&.Mui-disabled": {backgroundColor: "#fff", borderColor: "#8D8B8B", color: "#8D8B8B", border: "1px solid"}}} className='btn-text'>Draw Winner</Button>
              :
              <Button autoFocus variant="contained" onClick={() => drawRaffleWinner()} sx={{py:1, px:3, borderRadius:"6px"}} className='btn-text'>Draw Winner</Button>
            }
            
            {
              raffleWinners.data.length > 0 &&
              <Button autoFocus variant="outlined" disabled={raffleWinners.data.filter((a) => a.email_sent == false).length == 0} onClick={() => handleNotifyWinnerPopup(null)} sx={{py:1,px:3, ml: "21px",borderRadius:"6px", "&.Mui-disabled": {backgroundColor: "#fff", borderColor: "#8D8B8B", color: "#8D8B8B"}}} className='btn-text'>Email New Winners</Button>
            }
          </Box>
        </Box>
        <Box p={4} bgcolor="rgba(34,51,84,0.02)" mt={5}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between'}}>
            <Button onClick={handleClose}  sx={{borderRadius:"6px", py:1,px:3}} className='btn-text'>Close</Button>
            <Button autoFocus variant="outlined" onClick={() => {claimDataDownload();}} sx={{py:1,px:3,borderRadius:"6px"}} className='btn-text'>Download CSV</Button>
          </Box>
        </Box>
      </Box>
      
    </Modal>
  )
}


const mapStateToProps = (state) => ({
  user: state.user,
  raffleWinners: state.raffleWinners
})

const mapDispatchToProps = {
  setToast,
  getRaffleWinners,
  drawRaffleWinner,
  deleteRaffleWinner
}

export default connect(mapStateToProps, mapDispatchToProps)(DrawWinners)