import React from "react";
import { Box, Typography,Button, Modal, Card, Link } from '@mui/material'
import InsertLinkIcon from '@mui/icons-material/InsertLink';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 550,
    bgcolor: 'white',
    boxShadow: 24,
    p: 4,
    borderRadius:'6px'
  };
function GatedContentModal(props){
  const {open, handleClose, rewardDropData} = props;
    return(
        <>
        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="preview-modal"
      >
        <Box sx={style} className='modal-border'>
          <Typography className="preview-title">This is how your reward will appear to users</Typography>
          <Box sx={{maxHeight: "80vh", overflow: "auto"}}>
          <Typography variant="h6"  className="modal-title">You've Claimed</Typography>
          <Typography variant="h1"  className="heading">{rewardDropData?.title}</Typography>
          <Typography variant="h5"  className="description">{rewardDropData.description}</Typography>
          <Card
            sx={{
              bgcolor: "#F6F8FB",
              marginTop: "39px",
              px: 2,
              
              border: '1px solid #CCCEDD',
              boxShadow:'0px 4px 21px rgba(24, 69, 136, 0.2)'
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "center", paddingTop: "10px", }}>
              <Card sx={{display: "flex", bgcolor: "rgba(26, 117, 255, 0.05)", borderRadius: "30px", p: 1, boxShadow: "none"}}>
                <img src="/assets/img/gated-icon.svg" alt="" style={{ width: "22px" }} />
                <Typography variant="h6" className="merch-category">Gated Content</Typography>
              </Card>
            </Box>
            <Box sx={{background: 'rgba(34, 51, 84, 0.02)', py: 0.7, px: 1.2, m: 1, borderRadius: '0.5rem'}}>
            {
              rewardDropData.urls.map((url_data,index) => {
                return(
                  <Card sx={{bgcolor:'white', boxShadow:'none', my:1, borderRadius: '0px'}} key={index}>
                    <Card sx={{display:'flex', justifyContent:'space-between' , px:1, py:1, bgcolor:'white',boxShadow:'none'}}>
                      <Box sx={{display:'flex' , alignItems:'center'}}>
                        <Box>
                          <Card sx={{borderRadius:'100%', p:1, bgcolor:'rgba(34, 51, 84, 0.2)', display: 'flex', justifyContent: 'center', width: '25px' }}>
                            <InsertLinkIcon sx={{ color: "#323232" }} />
                          </Card>
                        </Box>
                        <Typography variant="h5" className="content-name">{url_data.ContentName}</Typography>
                      </Box>
                      <Box>
                      <Link href={url_data.Url} target="_BLANK" underline="none"><Button variant="contained" className="claim-blue-btn">View </Button></Link>
                      </Box>
                    </Card>
                  </Card>
                )
              })
            }
            </Box>
          </Card>
          <Box
            sx={{
              paddingTop: "20px",
              display: "flex",
              justifyContent: "center",
            }}
          >
              <Button variant="outlined" color="secondary" onClick={handleClose}  className='close-btn'>Close</Button>
          </Box>
          </Box>
        </Box>
      </Modal>
        </>
    )
}
export default GatedContentModal;