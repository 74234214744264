import React, {useState} from 'react'
import { Box, Typography, Link as AnchorLink, TextField, Button, Snackbar, Alert } from '@mui/material'
import NotificationsIcon from '@mui/icons-material/Notifications';
import { connect } from 'react-redux';
import {updateAccountDetails, setToast} from 'src/services/actions'
import { validatGA } from 'src/utils';
import ResetMeasurementId from 'src/components/Confirmation/reset_measurement_id';

const AdvancedSettings = (props) => {
  const vertical = 'top';
  const horizontal = 'center';
  const {user} = props
  const supportClick=()=>{
    if(document.getElementsByClassName('_hj-ETLL8__MinimizedWidgetMiddle__label').length > 0){
      document.getElementsByClassName('_hj-ETLL8__MinimizedWidgetMiddle__label')[0].click();
    }
  }

  const [deleteModal, setDeleteModal] = useState(false);
  const [accountFormData, setAccountFormData] = useState({
    fname: user.data.fname,
    lname: user.data.lname,
    email: user.data.email,
    new_email: user.data.new_email,
    measurement_id: user.data.measurement_id
  });
  const [accountUpdateAlert, setAccountUpdateAlert] = useState({type: '', message: ''})

  const updateAccountSettings =  () => {
    const u_data = {...accountFormData};
    if (validatGA(u_data.measurement_id) || u_data.measurement_id == '') {
      const userUpdte = props.updateAccountDetails(u_data, (resp) => {
        if (resp.type == 'success'){
          props.setToast(true, {type: 'success', message: 'Your settings have been updated.'});
        }
      }); 
    } else {
      props.setToast(true, {type: 'end_error', message: 'Please ensure measurement ID starts with G-'});
    }
  }
  const handleData = (type, val) => {
    setAccountUpdateAlert({type: '', message: ''});
    let existing_data = {...accountFormData};
    existing_data[type] = val
    setAccountFormData(existing_data);
  }
  return (
    <Box className='advanced-settings'>
      <Typography variant="h3">Google Analytics (GA4)</Typography>
      <Typography variant='h5'>Add your GA4 measurement ID to your community and membership pages to view and analyze statistics within your own Google Analytics account.</Typography>
      <form noValidate>
        <Box display="flex" alignItems="center" mt="20px">
          <TextField fullWidth margin="normal" label='measurement_id' name="subdomain" variant="outlined" value={accountFormData.measurement_id} InputLabelProps={{shrink: true}} inputProps={{ maxLength: 25, minLength: 3 }} placeholder="Measurement ID begins with “G-”" helperText={accountUpdateAlert.type == 'error' ? accountUpdateAlert.message : ''} error={accountUpdateAlert.type == 'error'} onKeyUp={(e) => {}} onChange={(e) => handleData('measurement_id', e.target.value)} FormHelperTextProps={{ style: { textAlign: 'left', color: '#FF1943' } }}  />
        </Box>

        <Box pt="20px">
          <Typography variant='body1'>Learn how to <AnchorLink target='_blank' href="https://support.google.com/analytics/answer/9304153">setup analytics for a website or app here</AnchorLink>. Reports and explorations can take up to <AnchorLink href="https://support.google.com/analytics/answer/9333790?hl=en#:~:text=Many%20of%20your%20reports%20and,from%20your%20website%20or%20app" target='_blank'>48 hours</AnchorLink> to process data.</Typography>

          <Typography variant='body1' mt="1.25rem">A Measurement ID is an identifier (e.g., G-12345) for a web data stream. Find your <AnchorLink href="https://support.google.com/analytics/answer/9539598" target='_blank'>measurement ID here</AnchorLink>.</Typography>
          <Typography variant='body1' mt="1.25rem">Note: Universal tags a not supported. Learn <AnchorLink href="https://support.google.com/analytics/answer/11583528" target='_blank'>why</AnchorLink>.</Typography>
        </Box>

        <Box display="flex" justifyContent="space-between" sx={{mt: 7, mb: 2}}>
          <Button sx={{borderRadius: '6px'}} onClick={() => user.data.measurement_id != "" ? setDeleteModal(true) : handleData('measurement_id', '')} className='btn-text'>Reset</Button>
        
          <Box display="flex">
            <Button variant="contained" onClick={updateAccountSettings} color="primary" sx={{borderRadius: '6px', ml:1}} className='btn-text' disabled={false}>Update</Button>
          </Box>
        </Box>
      </form>
      {
        accountUpdateAlert.type !== 'error' &&
        <Snackbar sx={{zIndex: '7 !important', width:'auto'}} anchorOrigin={{ vertical, horizontal }} open={accountUpdateAlert.type != ''} autoHideDuration={5000} key={0}>
          <Alert icon={<NotificationsIcon fontSize="inherit" />} severity={accountUpdateAlert.type ? accountUpdateAlert.type : "success" } sx={{ width: '100%' }}>{accountUpdateAlert.message}</Alert>
        </Snackbar>
      }
      {deleteModal &&
        <ResetMeasurementId open={deleteModal} handleClose={() => setDeleteModal(false)} handleData={handleData} />
      }
    </Box>
  )
}

const mapStateToProps = (state) => ({
  user: state.user
})

const mapDispatchToProps = {
  updateAccountDetails,
  setToast
}

export default connect(mapStateToProps, mapDispatchToProps)(AdvancedSettings)