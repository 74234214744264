import React,{useState} from 'react'
import { Modal, Box, Typography, Tabs, Tab } from '@mui/material';
import {updateAdminUsers} from '../../services/actions'
import { connect } from 'react-redux';
import MembershipBasicDetails from './user_membership/basic';
import MembershipNftDetails from './user_membership/nft_details';
import MembershipAdvancedDetails from './user_membership/advanced_settings';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
  border: '0px solid #000',
  boxShadow: 24,
  p: 4,
  height:"650px",
  overflow:"auto"
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3, px:1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const EditUserModal = (props) => {
  const { open, handleClose, user_data } = props;
  

  const [tabValue, setTabValue] = useState(0)

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="Edit Form Field" sx={{outline: 'none'}} onBackdropClick={()=>{return false}}>
      <Box sx={style}>
        <Box>
          <Typography variant="h3" sx={{mb: 1}}>Edit User</Typography>
          <Typography variant="h4" sx={{mb: 1}}>{`${user_data.fname} ${user_data.lname}`} ({user_data.email})</Typography>
        </Box>
        <Box mt={3}>
          <Box sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.2)", p: "15px 50px", bgcolor: "rgba(243, 243, 243, 0.7)"}}>
            <Tabs value={tabValue} onChange={handleTabChange} variant="scrollable" scrollButtons="auto">
              <Tab disableRipple label={`General Details`} {...a11yProps(0)} />
              <Tab disableRipple label={`NFT Details`} {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel key="community-page" value={tabValue} index={0}>
            <Box px="20px">
              <MembershipBasicDetails user_data={user_data} handleClose={handleClose} />
            </Box>
          </TabPanel>
          <TabPanel key="membership-page" value={tabValue} index={1}>
            <Box px="20px">
              <MembershipNftDetails user_data={user_data} handleClose={handleClose} />
            </Box>
          </TabPanel>
        </Box>
      </Box>

    </Modal>
  )
}

const mapStateToProps = (state) => ({
  userUpdate: state.userUpdate,
  adminUsers: state.adminUsers
})

const mapDispatchToProps = {
  updateAdminUsers
}

export default connect(mapStateToProps, mapDispatchToProps)(EditUserModal)