import React, { useEffect, useState } from "react";
import { Modal, Box, Button, Typography, Grid, Avatar, CircularProgress, styled } from "@mui/material";
import { connect } from "react-redux";
import { userLogin, userReset, userCustomLogin, userWalletAuth } from "../../services/actions/ethereum";
import CustodialWallet from "../wallet";

const LoginOptionModal = (props) => {
  const { open, handleClose, user, page, viewType, responseMsg, setResponseMsg } = props;
  const currentTime = new Date().getTime();

  const style = {
    position: "absolute",
    top: page.width >= 900 ? "50%" : "51%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "white",
    boxShadow: "0px 9px 16px rgba(159, 162, 191, 0.18), 0px 2px 2px rgba(159, 162, 191, 0.32)",

    padding: page.width >= 900 ? "85px 100px 61px 100px" : "2rem",
    overflow: "auto",
    height: "auto",
    borderRadius: "6px",
    boder: "1px solid #1A1616",
    maxHeight: "90vh",
    overflowY: "auto",
  };

  const login = (data, cb) => {
    if (user.type !== "request") {
      props.userReset();
      if (data.event) {
        props.userCustomLogin(data.eth_address, data.signature, currentTime, data.profile_img, data.email, "mint", data, (response) => {
          setResponseMsg(response.response_msg);
          cb(response)
        });
      } else {
        props.userWalletAuth(data, (res) => {
          cb(res)
        });
      }
    }
  };

  return (
    <>
      <Modal open={open} onClose={handleClose} aria-labelledby="login-modal-title" className="popup-modal" aria-describedby="login-modal-description">
        <Box sx={style} className="loginModal">
          <CustodialWallet
            signMessage={`Welcome to Step3!\n\nSigning this message will not trigger a blockchain transaction, cost fees, or allow us to transfer contents from your wallet.\nSignature Specific ID: ${currentTime}`}
            handleAuth={(data) => {
              login(data);
            }}
            title="Sign in or register"
            wallets={["Phone", "Email", "Google", "Apple", "Web3 wallets"]}
            colors={{ bg: "#ffffff", pm: "#ED1F23", pm_text: "#ffffff", ac: "#ffffff" }}
          />

          <Box sx={{ paddingTop: "40px", display: "flex", justifyContent: "center" }}>
            <Button variant="contained" onClick={handleClose} className="btn" sx={{ px: 5, py: 1 }}>
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  page: state.page,
});

const mapDispatchToProps = {
  userLogin,
  userCustomLogin,
  userWalletAuth,
  userReset,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginOptionModal);
