import React from "react";
import CWalletCard from "./index";
import Web3 from "web3";
import detectEthereumProvider from "@metamask/detect-provider";
const MetaMaskWallet = (props) => {
  const { walletResponse, setWalletResponse, setWalletType, walletType, signMessage } = props;
  const web3 = new Web3();
  let ethereum;
  if (typeof window !== "undefined") {
    ethereum = window.ethereum;
    window.web3Obj = web3;
  }

  const connect = (callback) => {
    if ((typeof window.ethereum === "undefined" && typeof ethereum?.providers == "undefined") || (ethereum.isCoinbaseWallet && !ethereum.isMetaMask)) {
      let rurl = window.location.href;
      rurl = rurl.replace("http://", "");
      rurl = rurl.replace("https://", "");
      window.open("https://metamask.app.link/dapp/" + rurl);
      callback({ type: "error", message: "Please install Metamask extension." });
      return;
    }
    if (typeof ethereum.providers != "undefined") {
      const walletProvider = ethereum.providers.find(({ isMetaMask }) => isMetaMask);
      if(ethereum.setSelectedProvider){ethereum.setSelectedProvider(walletProvider);}
    }

    ethereum
      .request({ method: "eth_requestAccounts" })
      .then((accounts) => {
        ethereum.on("accountsChanged", function (accounts) {
          window.location.reload();
        });

        if (ethereum.isMetaMask && ethereum.selectedAddress != null) {
          detectEthereumProvider().then(async (provider) => {
            web3.setProvider(provider);
            // web3.eth.net.getNetworkType().then(async(network_type) => {
            let network = {};
            // network['type'] = network_type;
            network["chainId"] = await ethereum.request({ method: "eth_chainId" });
            network["networkVersion"] = await ethereum.request({ method: "net_version" });
            let account = await ethereum.request({ method: "eth_requestAccounts" });
            callback({ type: "success", data: { network: network, eth_address: account[0] } });
            // })
          });
        } else {
          callback({ type: "error", message: "Some error occurred!" });
        }
      })
      .catch((error) => {
        callback({ type: "error", message: error.message });
      });
  };

  const web3signature = async (message, address, callback, type = "personal") => {
    try {
      let signature;
      if (type == "personal") {
        signature = await web3.eth.personal.sign(message, address);
      } else {
        try {
          signature = await web3.eth.sign(message, address);
        } catch (e) {
          signature = await web3.eth.personal.sign(message, address);
        }
      }
      callback({ type: "success", data: { signature } });
    } catch (error) {
      callback({ type: "error", message: error.message });
    }
  };

  const login = async (obj) => {
    setWalletResponse({ type: "request", wallet_type: 'metamask' });
    connect(async (obj) => {
      if (obj.type == "success") {
        const data = obj.data;
        let currentTime = new Date().getTime();
        let message = signMessage;

        web3signature(message, data.eth_address, async (webObj) => {
          if (webObj.type === "success") {
            setWalletResponse({ type: "success", data: { wallet_type: "metamask", eth_address: data.eth_address, time_stamp: currentTime, signature: webObj.data.signature } });
          } else {
            setWalletResponse({...webObj, wallet_type: 'metamask'});
          }
        });
      } else {
        setWalletResponse({...obj, wallet_type: 'metamask'});
      }
    });
  };

  return (
    <>
      <CWalletCard
        theme={props.theme}
        data={{ name: "MetaMask", walletType: "metamask" }}
        onLogin={() => {
          login();
          setWalletType("metamask");
        }}
        walletResponse={walletResponse}
        wType={walletType}
      />
    </>
  );
};

export default MetaMaskWallet;
