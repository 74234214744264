import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Card, Link, Typography, Container, Alert, Button, Checkbox, FormHelperText, TextField, FormControlLabel, useTheme } from "@mui/material";

import { registerUser, setPageThought } from "../../services/actions";
import LoginSuccess from "src/components/LoginAlmostDone";
import { gtag } from "../../utils";

const Registration = (props) => {
  const { user } = props;
  const theme = useTheme();

  const [form, setForm] = React.useState({ fname: { value: user.data.fname, error: "" }, lname: { value: user.data.lname, error: "" }, email: { value: user.data.email, error: "" }, terms: { value: false, error: "" } });

  const setVal = (key, val) => {
    setForm({ ...form, [key]: { value: val, error: "" } });
  };

  const register = () => {
    const fObj = { ...form };
    let flag = true;
    if (fObj.email.value == null || fObj.email.value.trim() == "") {
      fObj.email.error = "Email is required.";
      flag = false;
    } else {
      const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

      if (!fObj.email.value.toLowerCase().match(re)) {
        fObj.email.error = "Please enter a valid email address.";
        flag = false;
      }
      fObj.email.value = fObj.email.value.trim();
    }

    if (!fObj.terms.value) {
      fObj.terms.error = "Please accept the terms and conditions.";
      flag = false;
    }

    if (flag) {
      props.registerUser({ fname: fObj.fname.value, lname: fObj.lname.value, email: fObj.email.value, terms: fObj.terms.value });
    } else {
      setForm(fObj);
    }
  };

  useEffect(() => {
    if (user.type == "email_pending") {
      gtag("account_creation_started", {
        eth_address: ` ${user.data.eth_address} `,
      });
    }
    if (user.type === "success") {
    }
  }, []);

  useEffect(() => {
    user.type == "email_pending" ? props.setPageThought({ heading: "Did you know?", txt: "You can publish your rewards to a brandable  page or embedd them directly on your website with a single line of code." }) : props.setPageThought({ heading: "Did you know?", txt: "You can make your rewards more exclusive by targeting only the NFT collections you want." });
  }, [user.type]);

  return (
    <Box className="registration-page" mt={15}>
      {(user.type == "email_empty" || user.type == "request" || user.type == "error") && (
        <>
          <Typography variant="h2" className="title" align="center" mt={5}>
            Please complete your registration
          </Typography>
          <Container maxWidth="xs">
            <Card
              sx={{
                mt: 5,
                pt: 3,
                pb: 1.75,
              }}
              className="registration-form"
            >
              <form noValidate>
                <Box sx={{ px: 4, pb: 5 }}>
                  <TextField fullWidth margin="normal" label="First Name" autoFocus={user.forceEmail ? false : true} name="fname" variant="outlined" InputLabelProps={{ shrink: true, style: { color: "#8D8B8B" } }} inputProps={{ style: { color: "rgb(34, 51, 84)" } }} placeholder="Enter your first name" value={form.fname.value == null ? "" : form.fname.value} onChange={(e) => setVal("fname", e.target.value)} />

                  <TextField fullWidth margin="normal" label="Last Name" name="lname" variant="outlined" InputLabelProps={{ shrink: true, style: { color: "#8D8B8B" } }} inputProps={{ style: { color: "rgb(34, 51, 84)" } }} placeholder="Enter your last name" value={form.lname.value == null ? "" : form.lname.value} onChange={(e) => setVal("lname", e.target.value)} />
                  <TextField error={form.email.error != ""} helperText={form.email.error} autoFocus={user.forceEmail ? true : false} disabled={["email", "apple", "google"].includes(user.data.wallet_type)} required fullWidth margin="normal" label={"Email"} InputLabelProps={{ shrink: true, style: { color: "#8D8B8B" } }} inputProps={{ style: { color: "rgb(34, 51, 84)" } }} placeholder="Enter your email address" name="email" type="email" value={form.email.value == null ? "" : form.email.value} onChange={(e) => setVal("email", e.target.value)} variant="outlined" />
                </Box>
                <Box display="flex" justifyContent="center" sx={{ borderTop: "1px solid rgba(34, 51, 84,0.1)" }} pt={1.5}>
                  <FormControlLabel
                    control={<Checkbox name="terms" color="primary" checked={form.terms.value} onChange={(e) => setVal("terms", e.target.checked)} icon={<img src="/assets/img/Uncheck.svg" width="100%" />} checkedIcon={<img src="/assets/img/Checkbox.svg" width="100%" />} disableRipple />}
                    label={
                      <>
                        <Typography variant="body2" className="terms-conditions">
                          I accept the{" "}
                          <Link component="a" href="https://step3.io/terms" target={"_blank"}>
                            terms and conditions
                          </Link>
                          .
                        </Typography>
                      </>
                    }
                  />
                </Box>
                {form.terms.error != "" && (
                  <Box display="flex" justifyContent="center">
                    <FormHelperText error>{form.terms.error}</FormHelperText>
                  </Box>
                )}
              </form>
            </Card>

            <Box textAlign={"center"} sx={{ mt: 3 }}>
              <Button
                sx={{ borderRadius: "6px", padding: "0.7rem 2rem" }}
                className="create-btn"
                color="primary"
                type="button"
                size="large"
                variant="contained"
                disabled={user.type == "request"}
                onClick={() => {
                  register();
                }}
              >
                Create Account
              </Button>
            </Box>
          </Container>
        </>
      )}
      {user.type == "error" && user.message != "" && (
        <Container maxWidth="xs" sx={{ mt: 2 }}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  props.userReset();
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            {user.message}
          </Alert>
        </Container>
      )}
      {/* {(user.type=="email_pending") &&
          <LoginSuccess />
      } */}
      {user.type == "" && <Navigate to="/" />}
      {user.type == "success" && <Navigate to="/rewards/community" />}
    </Box>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  registerUser,
  setPageThought,
};

export default connect(mapStateToProps, mapDispatchToProps)(Registration);
