import React,{useEffect,useState} from 'react'
import { converDateToRemainingDays } from 'src/utils';
import moment from "moment";

 const TimerView = (props) => {
  const [showStartTime, setStartTime] = useState(null);
  const {viewDate} = props;

  useEffect(()=>{
    setStartTime(converDateToRemainingDays(viewDate));
    if(moment(viewDate) > moment()){
      let interVal = setInterval(()=>{
        let a = converDateToRemainingDays(viewDate);
        if(a==""){
          props.loadList();
        }
        setStartTime(a);
      },1000);

      return ()=>{
        clearInterval(interVal);
      }
    }
  },[])

  return (
    <>{showStartTime}</>
  )
}


export default TimerView;