import React, {useEffect, useState} from 'react';
import { Modal,Box, Button,Typography, Card, Avatar, TextField } from '@mui/material';
import { connect } from 'react-redux';
import { alphaHex } from 'src/utils';
import { RewardTypePill } from '../RewardsData/reward_type_pill';

  
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    border: '0px solid #000',
    boxShadow: 24,
    p: 3,
    borderRadius:"8px",
    maxHeight: "87.5vh",
    overflowY: "auto"
  };

function PollPreview(props) {
  const { open, handleClose, reward, info, user} = props;
  const [selectedOption, setSelectedOption] = useState('')
  const [basePmColor1, setBasePmColor1] = useState('rgba(26, 117, 255, 0.1)')
  const [basePmColor2, setBasePmColor2] = useState('rgba(26, 117, 255, 0.4)')
  // console.log("user =>",alphaHex(user.data.brand_data.primary))

  useEffect(() => {
    const color1 = alphaHex(`#${user.data.brand_data.primary}`, 0.1)
    const color2 = alphaHex(`#${user.data.brand_data.primary}`, 0.4)
    setBasePmColor1(color1)
    setBasePmColor2(color2)
  },[])

  return (
    <Box className='preview-modal'>
      <Box textAlign="center">
        <RewardTypePill reward={reward} />
      </Box>
        <Typography variant="h6" className="modal-title">You've Claimed</Typography>

        <Card sx={{ border: '1px solid #CCCEDD', bgcolor: "#F6F8FB", marginTop: "19px", px: 4, mx: 2, boxShadow:'0px 4px 21px rgba(24, 69, 136, 0.2)',borderRadius: "6px"}}>
          <Box sx={{ background: 'rgba(34, 51, 84, 0.02)', borderRadius: '8px', p:2, mt:2}}>
            {
              info?.question &&
            <Typography variant='body2' align='center' className='question'>{(info?.question)?.trim().at(-1) == "?" ? (info?.question)?.trim() : `${(info?.question)?.trim()}?`}</Typography>
            }

            <Typography variant='body1' className='question-helper' align='center' my={1}>Cast your vote!</Typography>

            <Box sx={{background:"rgba(34, 51, 84, 0.2)", height:"1px", width:"120px", margin:"auto"}}></Box>

            <Box mt={3}>
                {info?.choices.map((choice,index) => {
                    return(
                        <Box className={`option-area ${selectedOption == choice ? 'selected' : ''}`} sx={{bgcolor: `${selectedOption == choice ? basePmColor2 : basePmColor1} !important`}} onClick={() => setSelectedOption(choice)}>
                            {choice}
                        </Box>
                    )
                })}
            </Box>
              {info?.allow_comment &&
              <Box className='textareArea'>
                <TextField fullWidth multiline rows={3} margin="normal" label={<span>ADD A COMMENT</span>} name="comment" variant="outlined" InputLabelProps={{shrink: true}} placeholder='Your comments will not be made public.'  />
              </Box>
              }

            <Button variant='contained' color='primary' className='confirm-btn'>Submit</Button>

          </Box>
        </Card>
    </Box>
  )
}

const mapStateToProps = (state) => ({
  user: state.user
});

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(PollPreview)