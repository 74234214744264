import React, {useEffect, useState} from 'react'
import { Box,Typography, Snackbar, Alert, Button, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { connect } from 'react-redux';
import { removeToast } from 'src/services/actions';


function ToastBox(props) {
    const vertical =  'top';
    const horizontal = 'center';

    // useEffect(()=>{
    //   window.scrollTo(0, 0);
    // },[])


    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }

      props.removeToast();
    };

    const action = (
      <React.Fragment>
        <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose} disableRipple>
          <CloseIcon fontSize="small"  />
        </IconButton>
      </React.Fragment>
    );
    return (
      <>
      {props.type == "success" &&
      <Snackbar sx={{zIndex: '1500 !important'}} anchorOrigin={{ vertical, horizontal }} open={true}  key={vertical + horizontal}>
        <Alert severity="success" sx={{ width: '100%' }} action={action}>{props.message}</Alert>
      </Snackbar>
      }

      {props.type == "update_success" &&
      <Snackbar sx={{zIndex: '1500 !important'}} anchorOrigin={{ vertical, horizontal }} open={true} autoHideDuration={5000} key={vertical + horizontal}>
        <Alert severity="success" sx={{ width: '100%' }} action={action}>{props.message}</Alert>
      </Snackbar>
      }

      {props.type == "update_error" &&
      <Snackbar sx={{zIndex: '1500 !important'}} anchorOrigin={{ vertical, horizontal }} open={true} autoHideDuration={5000} key={vertical + horizontal}>
        <Alert severity="error" sx={{ width: '100%' }} action={action}>{props.message}</Alert>
      </Snackbar>
      }

      {props.type == "register_success" &&
      <Snackbar sx={{zIndex: '1500 !important'}} anchorOrigin={{ vertical, horizontal }} open={true} autoHideDuration={5000} key={vertical + horizontal}>
        <Alert severity="success" sx={{ width: '100%' }} action={action}>{props.message}</Alert>
      </Snackbar>
      }

      {props.type == "end_error" &&
      <Snackbar sx={{zIndex: '1500 !important'}} anchorOrigin={{ vertical, horizontal }} open={true} autoHideDuration={5000} key={vertical + horizontal}>
        <Alert severity="error" sx={{ width: '100%' }} action={action}>{props.message}</Alert>
      </Snackbar>
      }

      {props.type == "error" &&
      <Snackbar sx={{zIndex: '1500 !important'}} anchorOrigin={{ vertical, horizontal }} open={true} autoHideDuration={5000} key={vertical + horizontal}>
        <Alert severity="error" sx={{ width: '100%' }} action={action}>{props.message}</Alert>
      </Snackbar>
      }

      {props.type == "info" &&
      <Snackbar sx={{zIndex: '1500 !important'}} anchorOrigin={{ vertical, horizontal }} open={true} autoHideDuration={5000} key={vertical + horizontal}>
        <Alert severity="info" sx={{ width: '100%' }} action={action}>{props.message}</Alert>
      </Snackbar>
      }
    </>
  )
}

const mapStateToProps = (state) => ({
})
const mapDispatchToProps = {
  removeToast
}

export default connect(mapStateToProps, mapDispatchToProps)(ToastBox)