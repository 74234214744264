import React from 'react';
import { Breadcrumbs,Typography,Link,Container,Box } from '@mui/material';
import { connect } from 'react-redux';

const DropBreadCrumbs = (props) => {
  
  const goToStepNum = (step, multiStep, index_pos) => {
    if(props.rewardCRD.data.allow_published == true) {return false;}
    props.setStep(step);
    props.setMultiStep(multiStep);
    let br_data = [];
    for (let index = 0; index < props.breadCrumbs.length; index++) {
      let br_obj = props.breadCrumbs[index]
      if (index_pos >= index) {
        br_data.push({
          name: br_obj.name,
          step: br_obj.step,
          multiStep: br_obj.multiStep
        })
      }
    }

    // console.log("br_data =>",br_data)
    // const finalArr = [...new Map(br_data.map(item => [item['name'], item])).values()]
    // console.log("finalArr =>",finalArr)
    props.setBreadcrumbData(br_data);

  }

  return (
    <Container sx={{mt: '-1.2rem'}}>
      <Breadcrumbs sx={{ml:3, backgroundColor:'rgba(208, 211, 218, 0.21)', borderRadius: '6px', p:1, display: 'inline-block'}} aria-label="breadcrumb">
        {[...new Map(props.breadCrumbs.map(item => [item['name'], item])).values()].map((item,index) => {
            return(
              <Box key={index}>
                {index == (props.breadCrumbs.length - 1) 
                ?
                item == "It's live!" ? 
                <Typography sx={{fontWeight: 600}}>{item.name}</Typography>
                :
                <Typography color="text.primary" sx={{color: "#070C27"}}>{item.name}</Typography>
                :
                <Link underline="hover" color="inherit" onClick={()=>{goToStepNum(item.step, item.multiStep, index)}} sx={{cursor: 'pointer',color:"#070C27"}}>
                  {item.name}
                </Link>}
              </Box>
            )
        })}
      </Breadcrumbs>
    </Container>
  )
}

const mapStateToProps = (state) => ({
  rewardCRD: state.rewardCRD,
})

const mapDispatchToProps = {
  
}

export default connect(mapStateToProps, mapDispatchToProps)(DropBreadCrumbs)