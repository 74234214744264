import React, { useState } from 'react'
import { Typography, Button, Box, TextField, InputAdornment, FormControl, FormControlLabel, Radio,styled, OutlinedInput, InputLabel, FormHelperText, Checkbox, Grid, Avatar, Tooltip, Switch } from '@mui/material';
import dayjs from 'dayjs';
import CloseIcon from '@mui/icons-material/Close';
import "react-datepicker/dist/react-datepicker.css";

import {  trimStr, checkImageFileExt, validateImgDimension, handleImageForCompress } from 'src/utils';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { setToast } from 'src/services/actions';


var utc = require('dayjs/plugin/utc');
var timezone = require('dayjs/plugin/timezone')
dayjs.extend(utc);
dayjs.extend(timezone);

const CustomTextField=styled(TextField)(
  ({theme})=> `
  fieldset{
    border-radius: 4px;
    }
  `
)

function AdditionalInfo(props) {
  const { nextTabErrors, setNextTabErrors, page} = props;

  const [focused, setFocused] = useState({
    title: false,
    description: false,
  });

  const handlfocus = (key, is_focus) => {
    const cFocused = {...focused};
    cFocused[key] = is_focus;
    setFocused(cFocused)
  }

  const [qtyDisabled, setQtyDisabled] = useState((!props.dropData.is_limited_quantity || props.dropData.is_limited_quantity === 'true'));
  const [validImg, setValidImg] = useState(true);
  const removeImage =(e)=>{
    props.handleChildData('image', "");
    props.handleChildData('image_remove', true);
    document.getElementById('file_upload').value="";
  }
  const triggerImage = (e) => {
   let preview = document.getElementById("image_preview");
    if(e.target.id !="addImage" && e.target.id !="file_upload" && !preview.contains(e.target)){
      document.getElementById('addImage').click();
    }
  };
 
  const handleAddnValueData = (type, val) => {
    if (type === 'is_limited_quantity' && val === false) {
      setQtyDisabled(true)
      setNextTabErrors({...nextTabErrors,['quantity']:{value: null, error: ""}});
    } else {
      if (type === 'is_limited_quantity'){
        setQtyDisabled(false)
      }
      setNextTabErrors({...nextTabErrors,[type]:{value: typeof val=="string" ?  val.trim() : val,error:""}});
    }
    props.handleChildData(type, val);
  }


  const handleAddnValueDataWithMaxLen = (type, val) => {
    let maxlen = 50;
    if (type == 'description') {
      maxlen = 255;
    }

    setNextTabErrors({...nextTabErrors,[type]:{value: typeof val=="string" ?  val.trim() : val,error:"", txt: `${val.length} / ${maxlen}`}});
    props.handleChildData(type, val);
  }

  const onSelectFile = (e) => {
    const selectedFile = e.target.files[0];
    validateImgDimension(selectedFile, 388, 215, 388, 215).then((res) => {
      if (res.type === 'error') {
        setValidImg(false);
        let message = "Upload an image with minimum size 600x215";
        props.setToast(true, { type: "end_error", message: message });
      } else {
        if (selectedFile) {
          handleImageForCompress(selectedFile, res.fileWidth, res.fileHeight)
            .then((compressedDataURL) => {
              // console.log('Compressed Image Data URL:', compressedDataURL);
              setValidImg(true);
              props.handleChildData('image_remove', false);
              props.handleChildData('image', compressedDataURL);
            })
            .catch((error) => {
              console.error('Error compressing image:', error);
            });
        }
      }
    })
    
      
    document.getElementById('file_upload').value = '';
  };

  return (
    <>
      <Typography variant="body1" textAlign="center" className='subtitle' lineHeight="normal" mb="20px">CREATE REWARD</Typography>
      <Typography variant="h1" textAlign="center" sx={{mb: 1}} className='title'>Add reward details</Typography>
      <Typography variant="body1" textAlign="center" sx={{mb: 3}} className='subtitle'>Describe the offer and choose the reward settings</Typography>
      <Box px={8.5}>
        <Box>
          <TextField sx={{width: '100%', mb: '17px'}} margin="normal" label={<span>Title&nbsp;<span style={{color:'red'}}>*</span></span>} name="title" value={props.dropData.title} variant="outlined" InputLabelProps={{shrink: true}} placeholder='Enter a title.'  onFocus={() => handlfocus("title", true)} onBlur={() => handlfocus("title", false)} onChange={(e) => handleAddnValueDataWithMaxLen('title', e.target.value)} inputProps={{ maxLength: 60 }} error={nextTabErrors.title.error !== ""} helperText=
          {
            (focused.title)?
          <Box display="flex" justifyContent={"space-between"}>
          <Box>
          {nextTabErrors.title.error == '' ? '' : nextTabErrors.title.error}
          </Box>
            <Box component="span" className='charTxt'>
              <Box component="span" display={focused.title ? 'block' : 'none'}>
              {props.dropData.title.length > 0 ? `${props.dropData.title.length} / 60` : false}
              </Box>
            </Box>
          </Box>
          :
          <></>} FormHelperTextProps={{ style: { textAlign: (nextTabErrors.title.error !== "") ? 'left' : 'right', color: (props.dropData.title.length == 60 || nextTabErrors.title.error !== "") ? 'red' : 'rgba(34, 51, 84, 0.7)' } }} />
        </Box>
        <Box>
          <TextField sx={{width: '100%', "& .Mui-disabled": {"-webkit-text-fill-color": "initial !important"}}} className='Add-image' variant="outlined" disabled placeholder={props.dropData.image ? "" :'Add an Image'} onClick={triggerImage} InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Button variant="outlined" size="small" component="label" id="addImage" className="font-weight-600">
                  Browse... 
                  <input type="file" id="file_upload" name="banner" accept="image/png, image/jpeg, image/jpg, image/gif, image/svg+xml" onChange={(e) => { 
                    if (checkImageFileExt(e)){
                      onSelectFile(e)
                    } else {
                      props.setToast(true,{type:"end_error", message: 'Please choose a correct file type'});
                    }
                    
                  }} hidden  />
                </Button>
              </InputAdornment>
            ),
            startAdornment:(
              <InputAdornment position="start">
                <Box sx={{display:"flex"}}>
                  <Box sx={{position: 'relative'}} id="image_preview">
                    {props.dropData.image ?
                    <>
                    <img src={props.dropData.image} height="20" alt="" />
                    <Button id="removeImage" sx={{position: 'absolute', top: '-5px',right:'-5px',padding:'3px',minWidth:'0',display:'flex',justifyContent:'center',alignItems:'center', backgroundColor:'rgba(0,0,0)',padding:0,color: '#fff',':hover': {backgroundColor: '#000'}}} onClick={removeImage}><CloseIcon sx={{fontSize: '1rem !important'}}/></Button>
                    </>
                    :
                    <>
                    <img src="/assets/images/empty-img.svg" height="20" alt=""/>
                    </>
                  }
                  </Box>
                  <Typography variant="body1" ml={1}>{props?.dropData?.image?.name != '' ? trimStr(props?.dropData?.image?.name, (page.width <= 1215) ? 10 : 15) : props?.dropData?.image?.name}</Typography>
                </Box>
              </InputAdornment>
            )
          }} />
        </Box>
        <Box className="textareArea" mt={0.8}>
          <TextField fullWidth multiline rows={3} margin="normal" label={<span>DESCRIPTION</span>} name="short_description" value={props.dropData.description} variant="outlined" InputLabelProps={{shrink: true}} placeholder='Enter a description.' onFocus={() => handlfocus("description", true)} onBlur={() => handlfocus("description", false)} onChange={(e) => handleAddnValueData('description', e.target.value)} helperText={
            <Box component="span" className='charTxt'>
              <Box component="span" display={focused.description ? 'block' : 'none'}>
              {props.dropData.description.length > 0 ? `${props.dropData.description.length} / 1000` : false}
              </Box>
            </Box>
          } inputProps={{ maxLength: 1000 }} FormHelperTextProps={{ style: { textAlign: 'right', color: (props.dropData.description.length == 1000) ? 'red' : 'rgba(34, 51, 84, 0.7)' } }}  />
        </Box>
      </Box>
    </>
  )
}


const mapStateToProps = (state) => ({
  rewardCRD: state.rewardCRD,
  page: state.page
})

const mapDispatchToProps = {
  setToast
}

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalInfo)
