import Scrollbar from 'src/components/Scrollbar';

import { Box, styled, Typography, useTheme } from '@mui/material';

const SidebarWrapper = styled(Box)(
  
  ({ theme }) => `
        width: ${theme.sidebar.width};
        min-width: ${theme.sidebar.width};
        color: ${theme.sidebar.textColor};
        background: ${theme.sidebar.background};
        opacity: 0.95;
        position: absolute;
        top: ${theme.header.height};
        left: 0;
        z-index: 7;
        color: #fff;
        height: calc(100vh - ${theme.header.height});
        @media (min-width: ${theme.breakpoints.values.lg}px) {
          position: absolute;
        }
`
);

const Sidebar = (props) => {
  return (
    <>
      <SidebarWrapper>
        <Scrollbar>
          <Box sx={{textAlign: 'center', height: '100%'}} className='sidebar' display="flex" justifyContent="center" flexDirection="column">
            <Box sx={{padding: '1rem 2.5rem'}}>
              <Typography variant="h2" className='sidebar-text title' mb={2}>Welcome to Step3</Typography>

              <Box textAlign="left">
                <Typography variant="h4" className='sidebar-text sub-head' fontStyle="italic" mb={1}>{props.pageThoughtsHeading}</Typography>
                <Typography variant="body1" className='sidebar-text description' mb={1} sx={{whiteSpace: 'pre-line'}}>{props.pageThoughtsTxt}</Typography>
              </Box>

              <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <Box component="img" src="/assets/img/sidebar-img.svg" width={120} />
              </Box>
            </Box>
          </Box>
        </Scrollbar>
      </SidebarWrapper>
    </>
  );
}
export default Sidebar;