import { useContext, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Box, Card, IconButton, Tooltip, alpha, styled, Link, Popover, List, ListItem, ListItemText, useTheme, Typography } from '@mui/material';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import ReportFilterModal from 'src/components/Modals/report_filter';
import HeaderUserbox from './Userbox';
import ToastBox from '../../../components/ToastBox'
import {downloadRewardInfoData, downloadSiteData, downloadEngagementPointsData, logoutUser} from '../../../services/actions'
import { Link as RouterLink, matchRoutes, useLocation } from 'react-router-dom';

const HeaderWrapper = styled(Card)(
  ({ theme }) => `
    height: ${theme.header.height};
    color: ${theme.header.textColor};
    padding-left: 50px;
    padding-right: 50px;
    right: 0;
    background: ${theme.header.background};
    box-shadow: none !important;
    border-bottom: 1px solid black;
    justify-content: space-between;
    display: flex;
    align-items: center;
    border-radius: 0;
`
);
const Header = (props) => {
  const theme = useTheme();
  const { user, page, showToast } = props;
  const [reportModal, setReportModal] = useState(false);
  const [exportType, setExportType] = useState('');
  const currentPath = window.location.pathname;
  const location = useLocation();
  const path_val = location.pathname.split("/")[1];
  let settings = user.data?.membership_page_info?.other ? user.data?.membership_page_info?.other : null;
  
  const adminUserAddress = process.env.REACT_APP_ADMIN_ETH_ADDRESS.split(",").map((item) => item.toLowerCase())
  const NavbarLink = styled(RouterLink)(
    ({ theme }) => `
    text-decoration: none;
      &:hover{
        text-decoration: none;
        cursor: pointer;
      }
  `
  );
  
  const ref = useRef(null);
  const adminRef = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const [isAdminMenuOpen, setAdminMenuOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleAdminOpen = () => {
    setAdminMenuOpen(true)
  }

  const handleAdminMenuClose = () => {
    setAdminMenuOpen(false)
  }

  const handleClose = () => {
    setOpen(false);
  };
  const CustomPopover = styled(Popover)({
    '& .MuiBox-root.css-7ilvzl': {
      padding: '0%'
    },
    '& .MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper': {
      borderRadius: '0%',
      marginTop: "1.25rem"
    },
    '& .MuiList-root.MuiList-padding': {
      width: '100%'
    },
    '& .MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters:hover': {
      background:  'none'
    }
  });
  const MenuUserBox = styled(Box)(
    ({ theme }) => `
          background: ${theme.colors.alpha.black[5]};
          padding: ${theme.spacing(2)};
  `
  );

  const handleLogout = async () => {
    props.logoutUser();
  };

  const downloadRewardData = () => {
    props.downloadRewardInfoData(user.data.fname)
  }

  const exportSiteData = (export_type) => {
    setExportType(export_type)
    setReportModal(true);
    // props.downloadSiteData(export_type, user.data.fname)
  }

  const downloadEngagementPoints = () => {
    props.downloadEngagementPointsData()
  }
  return (
    <>
      {(showToast.show === true && !showToast.isCustomStyle && (["success", "register_success", "report", "end_error", "update_success", "update_error"].includes(showToast.type))) &&
        <ToastBox type={showToast.type} message={showToast.message} customStyle={showToast.customStyle} isCustomStyle={showToast.isCustomStyle}/>
      }
      {
        user.login && user?.data?.is_paid === false &&
        <Box className='demoBanner'>
          <Typography variant="body1">Heads up! You're currently driving Step3 in <Box component="strong">demo mode</Box>. <Link href="https://www.step3.io/landing-pages/bookacall" target='_blank'>Contact our sales team</Link> to create your own membership program.</Typography>
        </Box>
      }
      <HeaderWrapper>
        <Box className='navbar' display="flex" alignItems="center">
          <Box style={{color: "#fff", marginRight: '3rem', cursor: 'default'}}><img src="/assets/img/step3_logo.svg" width="130" alt="Step3 logo" /></Box>
          {user.login && 
            <Box display="flex" alignItems="center">
              <NavbarLink className={location.pathname.includes('rewards/membership') ? 'nav-link active': 'nav-link'} to="/rewards/membership">Membership Program</NavbarLink>                
              
              {
                user.data.membership_program ?
                user.data.membership_program?.role === 'admin' ?
                <NavbarLink className={location.pathname.includes('partners') ? 'nav-link active': 'nav-link'} to="/partners">Partners</NavbarLink>
                :
                <></>
                :
                <NavbarLink className={location.pathname.includes('rewards/community') ? 'nav-link active': 'nav-link'} to="/rewards/community">Community Rewards</NavbarLink>
               }
              {
                (adminUserAddress.includes(user.data.eth_address)) && user.data.completed_onboarding && 
                <Box ref={adminRef}>
                  <span onClick={handleAdminOpen} className="nav-link">Admin</span>
                  <CustomPopover disableScrollLock anchorEl={adminRef.current} onClose={handleAdminMenuClose} open={isAdminMenuOpen} anchorOrigin={{vertical: 'bottom', horizontal: 'right'}} transformOrigin={{vertical: 'top', horizontal: 'center'}}>
                    <MenuUserBox className='dropdown-box' display="flex">
                      <List component="nav">
                        <ListItem sx={{cursor: 'pointer'}} onClick={() => { handleAdminMenuClose(); }}>
                          <ListItemText primary={<RouterLink to="/admin/collections" style={{color: '#fff', textDecoration: "none"}}>Admin Collections</RouterLink>} />
                        </ListItem>

                        <ListItem sx={{cursor: 'pointer'}} onClick={() => { handleAdminMenuClose(); }}>
                          <ListItemText primary={<RouterLink to="/admin/users" style={{color: '#fff', textDecoration: "none"}}>Admin Users</RouterLink>} />
                        </ListItem>
                      </List>
                    </MenuUserBox>
                  </CustomPopover>
                </Box>
              }
              {
                (adminUserAddress.includes(user.data.eth_address)) && user.data.completed_onboarding &&
                <Box ref={ref}>
                  <span onClick={handleOpen} className="nav-link">Exports</span>
                  <CustomPopover disableScrollLock anchorEl={ref.current} onClose={handleClose} open={isOpen} anchorOrigin={{vertical: 'bottom', horizontal: 'right'}} transformOrigin={{vertical: 'top', horizontal: 'center'}}>
                    <MenuUserBox className='dropdown-box' display="flex">
                      <List component="nav">
                        <ListItem sx={{cursor: 'pointer'}} onClick={() => { handleClose(); exportSiteData('rewards'); }}>
                          <ListItemText primary="Rewards Created" sx={{color: '#fff'}} />
                        </ListItem>
                        <ListItem sx={{cursor: 'pointer'}} onClick={() => { handleClose(); exportSiteData('claims'); }}>
                          <ListItemText primary="Claims" sx={{color: '#fff'}} />
                        </ListItem>
                        <ListItem sx={{cursor: 'pointer'}} onClick={() => { handleClose(); exportSiteData('user_registrations'); }}>
                          <ListItemText primary="User Registrations" sx={{color: '#fff'}} />
                        </ListItem>
                        <ListItem sx={{cursor: 'pointer'}} onClick={() => { handleClose(); exportSiteData('collections'); }}>
                          <ListItemText primary="Collections" sx={{color: '#fff'}} />
                        </ListItem>
                        <ListItem sx={{cursor: 'pointer'}} onClick={() => { handleClose(); downloadEngagementPoints(); }}>
                          <ListItemText primary="Engagement Points" sx={{color: '#fff'}} />
                        </ListItem>
                      </List>
                    </MenuUserBox>
                  </CustomPopover>
                </Box>
              }
              {
                (adminUserAddress.includes(user.data.eth_address)) && user.data.completed_onboarding && 
                <span onClick={() => downloadRewardData()} className="nav-link">Reward Info Data</span>
              }
            </Box>
          }
        </Box>
          {window.location.pathname.includes("/register") &&
            <HeaderUserbox />
          }
        {reportModal &&
          <ReportFilterModal open={reportModal} handleClose={() => setReportModal(false)} export_type={exportType}/>
        }
      </HeaderWrapper>
    </>
  );
}


const mapStateToProps = (state) => ({
  user: state.user,
  showToast: state.showToast
})

const mapDispatchToProps = {
  downloadRewardInfoData,
  downloadSiteData,
  downloadEngagementPointsData,
  logoutUser
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);

