import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import {
    Typography,
    Box, TextField, Link
  } from '@mui/material';
import { formatDateToDbSup, formatMomentObjectToDate, formatMomentObjectToTime } from 'src/utils';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import {RewardDateAndTimePicker} from '../DateAndTimePicker';

const EventPassForm = (props) => {
  const {handleChildData, dropData, nextTabErrors, setNextTabErrors} = props;
  const [eventPassData, setEventPassData] = useState(props?.dropData?.eventPass);
  const [value, setValue] = useState(formatDateToDbSup(props.dropData?.eventPass?.event_date));
  const [focused, setFocused] = useState(false);
  const [eventDateValue, setEventDateValue] = useState(formatMomentObjectToDate(props.dropData?.eventPass?.event_date));
  const [eventTimeValue, setEventTimeValue] = useState(formatMomentObjectToTime(props.dropData?.eventPass?.event_date));
  const [dtPickerOpen, setDtPickerOpen] = useState(false);
  const handleData = (type, val) => {
    let existing_data = {...eventPassData};
    existing_data[type] = val
    setEventPassData(existing_data)
  }
  useEffect(() => {
    handleChildData('eventPass', eventPassData)
  } ,[eventPassData]);
  
  const handleEventValueData = (type, val) => {
    handleData(type, val);
    setNextTabErrors({...nextTabErrors,[type]:{value: typeof val=="string" ?  val.trim() : val,error:""}});
  }
  const handleChange = (newValue) => {
    handleEventValueData('event_date', newValue)
    setValue(newValue);
  };
  return (
    <>
      <Box className="event-pass-form">
        {dropData.status !== 1 &&
        <>
          <Typography variant="body1" textAlign="center" className='subtitle' lineHeight="normal" mb="20px">CREATE REWARD</Typography>
          <Typography variant="h1" textAlign="center" sx={{mb: 1}} className="title">Tell us more about your event</Typography>
          <Typography variant="body2" textAlign="center" className="detail-box">Enter the details and send the event link to the people checking in at the event.<br /><Link href="https://help.step3.io/creating--managing-rewards/ty25EcCpyGNQWuwxhGEyp6/event-pass-rewards/6GCM4WciuVvxrNeP48JnU1" target="_blank">Watch this video to learn more.</Link>.</Typography>
        </>
        }
        
        <Box pt={dropData.status == 1 ? 0 : 4}>
          <Box>
            <Box sx={{mt:2}}>
              <RewardDateAndTimePicker requiredf={true} formtype={dropData.status == 1 ? "edit" : "new"} dropData={dropData} nextTabErrors={nextTabErrors} setNextTabErrors={setNextTabErrors} picker_type={["event_date", "event_time"]} suffix_name="Event" handleChildData={handleChange} startDateValue={eventDateValue} setStartDateValue={setEventDateValue} startTimeValue={eventTimeValue} setStartTimeValue={setEventTimeValue} timeWidth="16.25rem" dateWidth="40rem" />
            </Box>
            <TextField fullWidth type="url" margin="normal" label='URL' name="text" variant="outlined" InputLabelProps={{shrink: true}} placeholder="Link to event website." onChange={(e) => handleData('website', e.target.value)} defaultValue={props.dropData?.eventPass?.website}  />
            <Box className="textareArea">
              <TextField fullWidth multiline rows="3" inputProps={{maxLength: 150}} margin="normal" label='Event Details' name="text" variant="outlined" InputLabelProps={{shrink: true}} placeholder='Is there anything else they need to know about attending your event?' onFocus={() => setFocused(true)} onBlur={() => setFocused(false)} onChange={(e) => handleData('details', e.target.value)} defaultValue={props.dropData?.eventPass?.details} helperText={
                <Box component="span" className='charTxt'>
                  <Box component="span" display={focused ? 'block' : 'none'}>
                  {props?.dropData?.eventPass?.details ? `${props?.dropData?.eventPass?.details?.length} / 150` : ''}
                  </Box>
                </Box>
                } FormHelperTextProps={{ style: { textAlign: 'right',color: (props?.dropData?.eventPass?.details?.length == 150) ? 'red' : 'rgba(34, 51, 84, 0.7)' } }} />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default EventPassForm