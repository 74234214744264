import React, {useState, useEffect} from 'react'
import { Card, Typography } from '@mui/material'
import WalletCard from '../walletCard';
import { gtag } from 'src/utils';

function WalletArea(props) {
    const {setStep, title, wallets, theme, signMessage, walletResponse, setWalletResponse, walletClicked, setWalletClicked, inputVal, setInputVal, setOtpData, openedLoginT, setOpenedLoginT} = props;
    
    useEffect(() => {
        if(!wallets.includes("Phone")){
            setOpenedLoginT("Email")
        }
    }, [])

    const walletData = [
        {
            name: "Phone",
            icon: "phone",
            step: "1",
            allowClick: true
        },
        {
            name: "Email",
            icon: "email",
            step: "2",
            allowClick: true
        },
        {
            name: "Google",
            icon: "google",
            step: "2",
            allowClick: false
        },
        {
            name: "Apple",
            icon: "apple",
            step: "2",
            allowClick: false
        },
        {
            name: "Web3 wallets",
            icon: "wallet-c",
            step: "3",
            allowClick: false
        }
    ];

    useEffect(() => {
        if (walletResponse.type === 'request') {
            gtag("login_initiated", {
              login_method: walletResponse?.wallet_type,
              login_destination: window.location.origin,
            });
          }
        if (walletResponse.type === 'success') {
            let walletype = walletResponse.data.wallet_type;
            props.handleAuth(walletResponse.data,(res) => {
                if (res.type === 'success') {
                    gtag("login_success", { login_method: walletype, login_destination: window.location.origin });
                } else {
                    gtag("login_error", { login_destination: window.location.origin });
                }
            })
        } else if (walletResponse.type === 'error') {
            gtag("login_error", {
              login_destination: window.location.origin,
            });
        }
    },[walletResponse])

  return (
    <>
        <Card sx={{borderRadius: "15px", border: "1px solid #000", background: "#fff", boxShadow:"0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08)", width: "462px", maxWidth: "90vw", padding: {md: "50px 40px", xs: "50px 15px"}, boxSizing: "border-box"}}>
            <Typography variant='h3' sx={{mb: 0.7, fontSize: "1.25rem", fontFamily: "General Sans bold", mb: "1.5rem", color: "#000"}} align='center'>{title}</Typography>
            
            {walletData.filter((item) => wallets.includes(item.name)).sort((a, b) => {
                const nameA = a.name;
                const nameB = b.name;
                
                const indexA = wallets.indexOf(nameA);
                const indexB = wallets.indexOf(nameB);

                return indexA - indexB;

            }).map((item, index) => {
                return (
                    <WalletCard setOtpData={(res) => setOtpData(res)} inputVal={inputVal} setInputVal={setInputVal} signMessage={signMessage} setWalletClicked={setWalletClicked} walletClicked={walletClicked} theme={theme} key={index} data={item} setStep={setStep} openedLoginT={openedLoginT} setOpenedLoginT={setOpenedLoginT} handleAuth={props.handleAuth} setWalletResponse={setWalletResponse} walletResponse={walletResponse} />
                )
            })}
            
        </Card>
    </>
  )
}

export default WalletArea