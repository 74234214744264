import React,{useState} from 'react'
import {connect} from 'react-redux';

import {
    Typography,
    Button,
    Box,FormControlLabel,Switch,styled, Snackbar, Alert
  } from '@mui/material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ClaimCardModal from '../Modals/claim_card';

  const HeightBox = styled(Box)(
    ({theme}) => `
      border-radius: 6px;
      background: #0A2033;
      margin-top: 1.5rem;
      padding: 1rem;
  `
)
const CodeBox = styled(Box)(
    ({theme}) => `
    max-height: 100px;
    overflow-y: auto;
    color: #A1A1A1;
    word-break: break-all;
    `
);
const PreviewButton = styled(Button)(
    ({ theme }) => `
        background: linear-gradient(180deg, rgba(11, 182, 79, 0.1) 0%, rgba(11, 182, 80, 0.1) 8.07%, rgba(11, 182, 80, 0.1) 15.54%, rgba(11, 182, 82, 0.1) 22.5%, rgba(11, 182, 84, 0.1) 29.04%, rgba(11, 182, 86, 0.1) 35.26%, rgba(11, 182, 89, 0.1) 41.25%, rgba(11, 182, 92, 0.1) 47.1%, rgba(11, 182, 95, 0.1) 52.9%, rgba(11, 182, 99, 0.1) 58.75%, rgba(11, 182, 103, 0.1) 64.74%, rgba(11, 182, 108, 0.1) 70.96%, rgba(11, 182, 113, 0.1) 77.5%, rgba(11, 182, 118, 0.1) 84.46%, rgba(11, 182, 123, 0.1) 91.93%, rgba(11, 182, 129, 0.1) 100%);
        border-radius: 6px;
        margin-right: 10px;
        color: #3BB900;
        &:hover{
            background: linear-gradient(180deg, rgba(11, 182, 79, 0.1) 0%, rgba(11, 182, 80, 0.1) 8.07%, rgba(11, 182, 80, 0.1) 15.54%, rgba(11, 182, 82, 0.1) 22.5%, rgba(11, 182, 84, 0.1) 29.04%, rgba(11, 182, 86, 0.1) 35.26%, rgba(11, 182, 89, 0.1) 41.25%, rgba(11, 182, 92, 0.1) 47.1%, rgba(11, 182, 95, 0.1) 52.9%, rgba(11, 182, 99, 0.1) 58.75%, rgba(11, 182, 103, 0.1) 64.74%, rgba(11, 182, 108, 0.1) 70.96%, rgba(11, 182, 113, 0.1) 77.5%, rgba(11, 182, 118, 0.1) 84.46%, rgba(11, 182, 123, 0.1) 91.93%, rgba(11, 182, 129, 0.1) 100%);
        }
  ` 
)
  const LinkButton = styled(Button)(
    ({ theme }) => `
        background: #E8F1FF;
        border-radius: 6px;
        &:hover{
          background: #E8F1FF;
        }
  ` 
)


function PublishingOptions(props) {
  const [open,setOpen] = useState(false)
  const {dropData,user,dropId} = props;
  const [openCopiedBar , setOpenCopiedBar] = useState(false)
  const vertical =  'top';
  const horizontal = 'center';
  const cloned_drop_data = {...dropData}
  const [rewardSwitch, setRewardSwitch] = useState(cloned_drop_data.allow_reward_page)
  const showClaimModal = () => {
    setOpen(true)
  }

  const handleRewardSwitch = (checked_val) => {
    props.handleChildData('allow_reward_page', !checked_val)
  }

  const copyEmbeddedCode = () => {
    navigator.clipboard.writeText(document.getElementById("codebox").innerText)
    setOpenCopiedBar(true)

    setTimeout(() => {
      setOpenCopiedBar(false)
    }, 5000)
  }
  
  
  const allow_disabled = (cloned_drop_data.allow_reward_page && rewardSwitch) ? (cloned_drop_data.drop_total_claim > 0 ? true : false) : false
  return (
    <>
      <Snackbar
        sx={{zIndex: '7 !important'}}
        anchorOrigin={{ vertical, horizontal }}
        open={openCopiedBar}
        autoHideDuration={5000}
        key={vertical + horizontal}
      >
        <Alert severity="success" sx={{ width: '100%' }}>Copied to clipboard!</Alert>
      </Snackbar>
        <Box className="publishing_options" p={dropData.status == 1 ? 0 : 4}>
        {dropData.status !== 1 &&
          <Typography variant="h1" textAlign="center" className='title'>Where will you publish this reward?</Typography>
        }

          <Box mt={dropData.status == 1 ? 0 : 3} className='option_card' p={3}>
            <FormControlLabel sx={{alignItems: "start"}} control={<Switch disableRipple disabled={allow_disabled} data-testid="allow_reward_page_switch" defaultChecked={!props.dropData.allow_reward_page} onChange={(e) => {
                handleRewardSwitch(e.target.checked)
                }} />} label={
                <>
                <Typography variant="h4" className='option_title'>On my community page</Typography>
                <Typography variant="p" className='option_description'>Your community page is home to all your active and past rewards. Add a logo, custom subdomain, and colors to make it your own.</Typography>
                </>
            } />
            </Box>

            <Box mt={3} className='option_card' p={2}>
            <FormControlLabel sx={{alignItems: "start"}} control={<Switch disableRipple defaultChecked={!props.dropData.allow_embed_page} onChange={(e) => {
                props.handleChildData('allow_embed_page', !e.target.checked)
                }} />} label={
                <>
                <Typography variant="h4" className='option_title'>Embed this reward on my website</Typography>
                <Typography variant="p" className='option_description'>Embed this reward on your website. Copy the code below and add it in the &#60;body&#62; section of the page where the reward should appear.</Typography>

                <HeightBox>
                    <CodeBox id="codebox">
                    &lt;iframe src="{user.data.full_brand_domain+"/widgets/"+dropId}" height="650" width="800" style="border:0px"&gt;&lt;/iframe&gt;
                    </CodeBox>
                </HeightBox>
                <Box sx={{mt:2}} display="flex" justifyContent="flex-end">
                  {dropData.status !== 1 &&
                  <PreviewButton onClick={showClaimModal} className="font-weight-600">
                    <img src="/assets/img/eye.svg" alt="" />&nbsp;Preview
                  </PreviewButton>
                  }
                  <LinkButton onClick={() => copyEmbeddedCode()}>
                    <img src="/assets/images/attachment.svg" alt="link"/>
                  </LinkButton>
                </Box>
                </>
            } />
            <ClaimCardModal title="Reward Widget Preview" description="Your reward will have a transparent background to fit in nicely on your site." dropData={dropData} open={open} handleClose={() => setOpen(false)}/>
          </Box>
        </Box>
    </>
  )
}


const mapStateToProps = (state) => ({
  user: state.user,
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(PublishingOptions)
