import React, {useState} from "react";
import { Box, Autocomplete, Typography, Button, Modal, Card, TextField, FormControl } from '@mui/material'
import countries from "../../services/constants/countries.json"

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'white',
    boxShadow: 24,
    py: 4,
    px: 8,
    borderRadius:'6px',
    // height:"90vh",
    // overflow:"auto"
  };  
  const MerchandiseModal = (props) => {
    const {open, handleClose,rewardDropData} = props;

    const sizeData = ['XS',"S","M","L","XL","XXL","XXXL"]
     
    return(
        <>
          <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" className="preview-modal">
            <Box sx={style} className='modal-border'> 
              <Typography className="preview-title">This is how your reward will appear to users</Typography>
              <Box sx={{height:"75vh", overflow:"auto", px:3}}>
              <Typography variant="h6" className="modal-title">Please complete this form to claim reward</Typography>
              <Typography variant="h1" className="heading">{rewardDropData?.title}</Typography>
              {
                rewardDropData?.merchandise?.details &&
                <Typography variant="h5" className="description">{rewardDropData?.merchandise?.details}</Typography>
              }
              {(rewardDropData?.merchandise?.support_contact !== "" &&rewardDropData?.merchandise?.support_contact !== null ) &&
              <Typography variant="h5" align='center' sx={{color:'#343957',fontFamily: "Inter,sans-serif", fontWeight: "400",fontSize: "1rem"}}>If you need help, contact: {rewardDropData?.merchandise?.support_contact}</Typography>
              }
              <Card className="merchandise-form textareArea" sx={{bgcolor:'#F6F8FB', marginTop:'19px', px:2,border: '1px solid #CCCEDD',boxShadow: '0px 4px 21px rgba(24, 69, 136, 0.2)',borderRadius: "6px",py:2 }}>
                {
                  (rewardDropData.merchandise && rewardDropData?.merchandise?.allow_size) &&
                  <>
                    <FormControl className='merch-form' sx={{my:0.9}} fullWidth>
                          <Autocomplete
                          disableClearable={true}
                          disablePortal
                          size='small'
                          id="combo-box-demo"
                          options={sizeData}
                          sx={{".MuiAutocomplete-input": {color: "#8c96a8"}}}
                          renderInput={(params) => <TextField required {...params} placeholder="Choose your size" label="Size" InputLabelProps={{shrink: true}}/>}
                        />
                        </FormControl>

                    <Box sx={{width: "140px", borderTop: "1px solid rgba(34, 51, 84, 0.2)", mt:1,mx:"auto"}}></Box>
                  </>
                }


                <TextField size="small" sx={{mb:0.7}} fullWidth margin="normal" name="full_name" label="Full Name" variant="outlined" InputLabelProps={{shrink: true}} placeholder='Your First and Last Name' required inputProps={{ maxLength: 130 }} />
                <TextField size="small" sx={{mb:0.7}} fullWidth margin="normal" name="address_line_1" label="Address Line 1" variant="outlined" InputLabelProps={{shrink: true}} placeholder='Street address, P.O. box, company name, c/o' required inputProps={{ maxLength: 130 }} />
                <TextField size="small" sx={{mb:0.7}} fullWidth margin="normal" name="address_line_2" label="Address Line 2" variant="outlined" InputLabelProps={{shrink: true}} placeholder='Apartment, suite, unit, floor, etc.' inputProps={{ maxLength: 130 }} />

                <TextField size="small" sx={{mb:0.7}} fullWidth margin="normal" name="city" label="City" variant="outlined" InputLabelProps={{shrink: true}} required inputProps={{ maxLength: 130 }} />
                <TextField size="small" sx={{mb:0.7}} fullWidth margin="normal" name="state" label="State/Province/Region" variant="outlined" InputLabelProps={{shrink: true}} required inputProps={{ maxLength: 130 }} />
                <TextField size="small" sx={{mb:0.7}} fullWidth margin="normal" name="zip" label="ZIP/ Postal Code" variant="outlined" InputLabelProps={{shrink: true}} required inputProps={{ maxLength: 130 }} />

                <FormControl className='merch-form' sx={{my:0.9}} fullWidth>
                  <Autocomplete
                    disableClearable={true}
                    disablePortal
                    size='small'
                    id="combo-box-demo"
                    options={countries}
                    sx={{".MuiAutocomplete-input": {color: "#8c96a8"}}}
                    renderInput={(params) => <TextField required {...params} placeholder="Choose a country" label="Country" InputLabelProps={{shrink: true}}/>}
                  />
                </FormControl>

                <Box sx={{width: "140px", borderTop: "1px solid rgba(34, 51, 84, 0.2)", mt: 1.3, mb:0.5, mx:"auto"}}></Box>

                <TextField size="small" sx={{mb:0.7}} fullWidth margin="normal" name="email" label="Email" variant="outlined" InputLabelProps={{shrink: true}} placeholder="Enter your email address" required inputProps={{ maxLength: 130 }} />
                <TextField size="small" sx={{mb:0.7}} fullWidth margin="normal" name="phone" label="Phone" variant="outlined" InputLabelProps={{shrink: true}} placeholder="Enter your phone number (including country code)" required inputProps={{ maxLength: 130 }} />

                <TextField size="small" sx={{mb:0.7}} multiline rows={3} fullWidth margin="normal" name="phone" variant="outlined" placeholder="Note or Comment" required inputProps={{ maxLength: 130 }} />
                <Box display="flex" justifyContent="center">
                  <Button variant="contained" className="claim-blue-btn" sx={{width:'auto !important', cursor: 'default'}}>Submit</Button>
                </Box>

              </Card>
              <Box sx={{ paddingTop:'20px' , display:'flex' , justifyContent:'center'}}>
                <Button variant="outlined" color="secondary" onClick={handleClose}  sx={{color:"#1C213D" , px:5,borderRadius:"6px"}}>Close</Button>
              </Box>
              </Box>
            </Box>
          </Modal>
        </>
    )
}
export default MerchandiseModal;