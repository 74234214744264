import React from 'react';
import { Dialog,DialogTitle,DialogContent,DialogActions, Button, styled,Typography,Box } from '@mui/material';

import { connect } from 'react-redux';

const DeleteButton =  styled(Button)(
  ({theme}) => `
  background: #FF1A43;
  border-radius: 6px;
  color:#fff;
  font-size: 1rem;
  &:hover{
    background: #FF1A43;
  }
  `
  );
  
  const DeleteCollection = (props) => {
    const { open, handleClose } = props;
    
    const {collection} = props
  return (
      <Dialog
          open={open}
          onClose={()=>{ return collection.delete_collection_status == 'request' ?  false : handleClose() }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className='modals'
        >
          <Box className='modal-card'>
          <DialogTitle id="alert-dialog-title" display="flex" justifyContent="center" sx={{mt:4,mb:4}}>
            <img src="/assets/img/delete-icon.svg" alt="delete" />
          </DialogTitle>
          <DialogContent sx={{pl:4, pr:4}}>
              <Typography variant="h1" className='heading'>Are you sure you want to remove this collection?</Typography>
              <Typography variant="h6" className='helper-text'>Note: Any rewards that currently use this collection will<br/> NOT be affected.</Typography>
          </DialogContent>
          <DialogActions sx={{ mb: 4,display: 'flex', justifyContent: 'center'}}>
            <Button onClick={handleClose} variant="contained" className='btn-text'>Cancel</Button>
            {collection.delete_collection_status == 'request' ?
            <Button sx={{opacity: '0.5', cursor:'not-allowed'}} className='btn-text' autoFocus>
              Deleting...
            </Button>
            :
            <Button onClick={props.delete} autoFocus className='btn-text'>
              Delete Collection
            </Button>
            }
          </DialogActions>
          </Box>
      </Dialog>

  )
}

const mapStateToProps = (state) => ({
  collection : state.collections
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteCollection)