import React,{useEffect} from 'react'
import { connect } from 'react-redux';

import { Box, styled,Typography,Link,Alert,Snackbar } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import { update_email,resendAPI } from '../../services/actions';

import {gaEvent} from "../../utils";


function LoginSuccess(props) {
  const { user } = props;
  const [emailResend, setResendEmail] = React.useState({api:false,message:"",disableButton: false});
  const [confirmEmail , setConfirmEmail] = React.useState(false)

  const vertical =  'top';
  const horizontal = 'center';

  useEffect(()=>{
    gaEvent({
      category: "Email",
      label: `-${user.data.eth_address}-`,
      action: `Confirmation Email Sent`
    });
  },[])


    const NoDropBox = styled(Box)(
        ({theme}) => `
          display: flex;
          justify-content: center;
          margin-top: 2rem  
      `
    )

    const resendEmail =async()=>{
      if(emailResend.api == false){
        setResendEmail({api:true,message:""})
        setConfirmEmail(true)
        let a = await resendAPI()
        setResendEmail({api:false,message:"Email sent successfully.",disableButton:true});
        setTimeout(() => {
          setResendEmail({...emailResend,disableButton:false});
        }, 180000)
        setTimeout(() => {
          setConfirmEmail(false)
        }, 5000)
      }
    }
    
  return (
    <>
          <Snackbar
          sx={{zIndex: '7 !important'}}
            anchorOrigin={{ vertical, horizontal }}
            open={confirmEmail}
            autoHideDuration={5000}
            key={vertical + horizontal}
          >
            <Alert severity="success" sx={{ width: '100%' }}>
            Email sent successfully.
            </Alert>
          </Snackbar>
        <NoDropBox flexDirection="column" display='flex' alignItems="center" justifyContent='center'>
        <Box display="flex" justifyContent="center" sx={{mb:5}}>
       <img src='/assets/img/message-blue.svg'/>
      </Box>
            <Box className='almost-done-page'>
                <Typography variant="h2" className='title' align="center">You're almost done...</Typography>
                <Typography variant="body2" className='para-text' align="center">Check your inbox (or spam folder) to confirm</Typography>
                <Typography variant="body2" className='para-text' align="center">your email({user.data.email}).</Typography>
                  <Box component="hr" width="30%" my={5} borderTop="0.5px solid rgba(34, 51, 84, 0.5)" />
                {emailResend.disableButton ?
                  <Link display="flex" className='resend-btn' justifyContent='center' alignItems="center" sx={{fontSize: '0.813rem',cursor: 'pointer',textAlign:'center', mt:3 , cursor: 'not-allowed', opacity: '0.5'}}>Resend Confirmation Email <HourglassTopIcon sx={{fontSize: '0.813rem', paddingLeft: '4px'}}/></Link>
                  :
                  <Link display="flex" justifyContent='center' className='resend-btn' alignItems="center" sx={{fontSize: '0.813rem',cursor: 'pointer',textAlign:'center', mt:3}} onClick={()=>{resendEmail()}} disabled={emailResend.api}> {emailResend.api ? "Sending..." : "Resend Confirmation Email" }&nbsp;<img src="/assets/img/email.svg" width="16" height="16" />
                  </Link>
                }
                <Link display="flex" justifyContent='center' className='resend-btn' alignItems="center" sx={{fontSize: '0.813rem',cursor: 'pointer',textAlign:'center', mt:3}} onClick={()=>{props.update_email()}}>Update my email address</Link>
            </Box>
        </NoDropBox>
    </>
  )
}




const mapStateToProps = (state) => ({
  user: state.user,
})

const mapDispatchToProps = {
  update_email
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginSuccess)