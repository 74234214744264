import React, { useState, useEffect } from 'react'
import { Box, Card, Typography, Button, Modal,Avatar, Link, CircularProgress } from "@mui/material";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import moment from 'moment-timezone';
import { calculateTimeLeft } from 'src/utils';

function RaffleModal(props) {
  const {open, handleClose,rewardDropData} = props;
  const [countdownTime, setCountdownTime]= useState(
    {
      countdownDays:'',
      countdownHours:'',
      countdownMinutes:'',
      countdownSeconds:''
    }
  );

  let timeIntrval;
  const countdownTimer = () => {
    timeIntrval = setInterval(() => {
      const tm = calculateTimeLeft(rewardDropData.endsIn);
      setCountdownTime(tm)
    }, 1000);
  }

  const checkCountDown = () => {
    return countdownTime.countdownDays != '' && countdownTime.countdownHours != '' && countdownTime.countdownMinutes != '' && countdownTime.countdownSeconds != ''
  }

  useEffect(() => {
    rewardDropData.endsIn && countdownTimer();

    return () => {
      clearInterval(timeIntrval)
    }
  }, [rewardDropData.endsIn]);
  const style = {
    position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", width: 450, bgcolor: "white", boxShadow: 24, py: 4,px:9, borderRadius: "10px",
  };
  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" className="preview-modal">
      <Box sx={style} className='modal-border'>
        <Typography className="preview-title">This is how your reward will appear to users</Typography>
        <Typography variant="h6" className="modal-title">You've Claimed</Typography>
        <Typography variant="h1" className="heading">{rewardDropData?.title}</Typography>
        {/* {
          rewardDropData.eventPass.details &&
          <Typography variant="h5" className="description">{rewardDropData.eventPass.details}</Typography>
        } */}
        <Box sx={{ borderBottom: "1px solid rgba(34, 51, 84, 0.2)", pt: 2, width: "120px", mx: 'auto', }} display="flex" justifyContent="center"></Box>
        <Card sx={{ border: '1px solid #CCCEDD', bgcolor: "#F6F8FB", marginTop: "19px", px: 4, mx: 2, boxShadow:'0px 4px 21px rgba(24, 69, 136, 0.2)',borderRadius: "6px"}}>
          <Box sx={{ display: "flex", justifyContent: "center", paddingTop: "20px",}}>
            <Card sx={{ display: "flex", alignItems: 'center', bgcolor: "rgba(26, 117, 255, 0.05)", borderRadius: "30px", p: 1, boxShadow: "none",}}>
              <img src="/assets/img/raffle.svg" alt="" style={{ width: "19px" }} />
              <Typography variant="h6" className='merch-category'>Raffle</Typography>
            </Card>
          </Box>
          <Box sx={{ background: 'rgba(34, 51, 84, 0.02)', borderRadius: '8px', p:2, mt:2}}>
            <Box textAlign="center">
              <img src="/assets/img/check-circle.svg" alt="check circle" />
              <Typography variant="h3" mt="8px" mb="10px">You’re Registered!</Typography>
              {
                rewardDropData.raffle.registrant_message &&
                <Typography variant="body1" className="raffle-message">{rewardDropData.raffle.registrant_message}</Typography>
              }
              {
                rewardDropData.endsIn && checkCountDown &&
                <>
                  <Box display="flex" justifyContent="center" mt="8px"><Box width="40%" borderTop="1px solid rgba(34, 51, 84, 0.2)" /></Box>

                  <Typography variant="h4" className="raffle-end-title" mt="8px">Check back in</Typography>
                  {countdownTime.countdownDays != '' ? 
                  <Typography variant="h2" className="raffle-end-timer" mt={2} color="#223354">{countdownTime.countdownDays}d : {countdownTime.countdownHours}h : {countdownTime.countdownMinutes}m : {countdownTime.countdownSeconds}s</Typography>
                  :
                  <Box display="flex" justifyContent="center" mt={2}>
                      <CircularProgress sx={{fontSize: "1.5rem"}} />
                  </Box>
                  }
                </>
              }
              
            </Box>
          </Box>
          <Card sx={{bgcolor:'white', boxShadow:'none', my:1 }}></Card>
        </Card>
        <Box sx={{ paddingTop: "20px", display: "flex", justifyContent: "center",}}>
          <Button variant="outlined" color="secondary" onClick={() => {
            props.handleClose()
            clearInterval(timeIntrval)
          }} className='close-btn'>Close</Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default RaffleModal