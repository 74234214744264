import { Box, Button, Typography, Alert, Avatar,Container,useTheme, Link} from '@mui/material';

import React,{useEffect,useState} from 'react'
import { connect } from 'react-redux';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Popper from "@mui/material/Popper";
import PopupState, { bindToggle, bindPopper } from "material-ui-popup-state";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import  { Navigate, useParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import LoginOptionModal from '../../components/Modals/login'

import { userLogin,userReset} from '../../services/actions/ethereum';
import { emailConfirmApi, resendAPI, setPageThought} from '../../services/actions/api';
import {gtag} from "../../utils";


const ConfirmEmail = (props) => {
  const {token} = useParams();
  const [emailConfirm, setEmailConfirm] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [responseMsg, setResponseMsg] = useState("");
  const [confirmMessage, setEmailConfirmMessage] = useState({type:"",message:""});
  const { user } = props;
  const loginWallet =()=>{
    setShowLoginModal(true)
  }
  const login = (type) => {
    if(user.type !='request'){
      props.userReset();
      props.userLogin(type,true);
    }
  }
  const [emailResend, setResendEmail] = useState({api:false,message:"",disableButton: false});
  const [confirmEmail , setConfirmEmail] = useState(false)

  const theme = useTheme()

  const emailApi = async () => {
    let data = await emailConfirmApi(token);
    setEmailConfirmMessage(data);
  }
  
  useEffect(() => {
    if(token!="" && typeof token != "undefined"){
      setEmailConfirm(true);
      emailApi();
    }
  },[]);
  
  useEffect(() => {
    (confirmMessage.type=="error") ?
    props.setPageThought({heading: "", txt: 'Rewards are a great way to add value and engagement to NFTs. And Step3 makes it easy to create unique rewards for any NFT community.'})
    :
    props.setPageThought({heading: "We'd love your feedback!", txt:"We're just getting started ourselves, so your feedback is invaluable to us.\n\nIf you have questions or can't find what you're looking for, please tell us in the feedback widget to the right of the app. We want to hear from you!"})

    if(confirmMessage.type =='success'){
      gtag('account_creation_success',{
        eth_address: ` ${confirmMessage.eth_address} `,
      });
    }

  },[confirmMessage.type])



  return (
   
    <>
     {(confirmMessage.type =='success' || confirmMessage.type =='error')  && 
      <Box display="flex" flexDirection="column" justifyContent="center" className='signup-successfull' sx={{height: `calc(100vh - ${theme.header.height})`}}>
          <>
          {confirmMessage.type =='success' ?
          <Box sx={{mt:4}} display="flex" justifyContent="center">
               <Avatar src="/assets/images/circle-success.svg" alt="success" sx={{height: '100px', width: '100px'}}/>
          </Box>
          :
          <Box sx={{mt:4}} display="flex" justifyContent="center">
            <Avatar src="/assets/img/delete-icon.svg" alt="oops" sx={{height: '100px', width: '100px'}}/>
          </Box>
          }

          <Typography variant="h1" className='title' mt={2}>{confirmMessage.message}</Typography>
          <Typography variant="h4" className='description'>{confirmMessage.email ? (confirmMessage.email + (confirmMessage.type == 'success'?  ' has been verified' : '')) 
            :
          ""}</Typography>
          <br/>
            <hr style={{width:"15%"}}/>
          <br/>
          </>
      
          <>
          <Typography variant="h2" className='connect-title'>Connect</Typography>
          {confirmMessage.type == 'error' &&
            <Typography variant="p" className='login-desc'> to send a new confirmation email</Typography>
          }
          {confirmMessage.type == 'success' &&
            <Typography variant="p" className='login-desc'> to login</Typography>
          }
          
          <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
            <Button variant="contained" disabled={user.type =='request'} className="btn" onClick={() => {loginWallet(true)}} sx={{fontSize: '16px', borderRadius: "6px !important", "&.Mui-disabled": {color: "#fff !important"}}}>
              Connect
              {
                user.type !='request' ?
                <span></span>
                :
                <CircularProgress sx={{ml:1}} color="inherit" className='btn-icon'/>
              }
            </Button>
          </Box>
        </>
     

    {user.message!="" &&  
      <Container maxWidth="xs" sx={{mt:4}} >
        <Alert severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                props.userReset();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          {user.message}
        </Alert>
      </Container>
      }


      {(user.type =='email_empty' || user.type =='email_pending') && <Navigate to="/register" />}
      {(user.type =='success' && user.data.email_verified == true) && <Navigate to="/rewards/community" />}
      {
        showLoginModal &&
        <LoginOptionModal open={showLoginModal} handleClose={() => setShowLoginModal(false)} modalTitle="Connect to sign in or register" viewType="mint" responseMsg={responseMsg} setResponseMsg={setResponseMsg}/>
      }
      </Box>
     }
    </>
  )
}



const mapStateToProps = (state) => ({
  user: state.user,
})

const mapDispatchToProps = {
  userLogin,
  userReset,
  setPageThought
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmEmail)