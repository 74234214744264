import React, { useEffect, useState } from "react";
import { Typography, Box, TextField, FormGroup, FormControlLabel, Checkbox, Avatar } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckIcon from "@mui/icons-material/Check";

const MerchandiseForm = (props) => {
  const { handleChildData, dropData, nextTabErrors, setNextTabErrors } = props;
  const [merchandiseData, setMerchandiseData] = useState(dropData?.merchandise);

  const [focused, setFocused] = useState({
    details: false,
    support_contact: false,
  });

  const handlfocus = (key, is_focus) => {
    const cFocused = { ...focused };
    cFocused[key] = is_focus;
    setFocused(cFocused);
  };

  const handleData = (type, val) => {
    let existingData = { ...merchandiseData };
    existingData[type] = val;

    setMerchandiseData(existingData);

    if (type == "support_contact") {
      setNextTabErrors({ ...nextTabErrors, ["merch_support_contact"]: { value: typeof val == "string" ? String(val).trim() : val, error: "" } });
    }
  };

  useEffect(() => {
    handleChildData("merchandise", merchandiseData);
  }, [merchandiseData]);

  return (
    <>
      <Box className="event-pass-form">
        {dropData.status !== 1 && (
          <>
            <Typography variant="body1" textAlign="center" className='subtitle' lineHeight="normal" mb="20px">CREATE REWARD</Typography>
            <Typography variant="h1" textAlign="center" className="title">
              Add your self-fulfillment details
            </Typography>
            <Typography variant="body2" textAlign="center" className="detail-box">
              Offer physical merchandise like t-shirts, plushies or any items that you can self-fulfill.
            </Typography>
          </>
        )}

        <Box pt={dropData.status == 1 ? 0 : 4}>
          <Box>
            <Box className="textareArea">
              <TextField
                sx={{ mt: 0.5 }}
                fullWidth
                multiline
                inputProps={{ maxLength: 200 }}
                rows="3"
                label="Fulfillment info"
                InputLabelProps={{ shrink: true }}
                margin="normal"
                name="text"
                variant="outlined"
                placeholder="Add additional info (e.g. estimated shipping times, carrier info, etc.)"
                onChange={(e) => handleData("details", e.target.value)}
                onFocus={() => handlfocus("details", true)}
                onBlur={() => handlfocus("details", false)}
                defaultValue={dropData?.merchandise?.details}
                helperText={
                  <Box component="span" className="charTxt">
                    <Box component="span" display={focused.details ? "block" : "none"}>
                      {dropData?.merchandise?.details.length > 0 ? `${dropData?.merchandise?.details.length} / 200` : false}
                    </Box>
                  </Box>
                }
                FormHelperTextProps={{ style: { textAlign: "right", color: dropData?.merchandise?.details.length == 200 ? "red" : "rgba(34, 51, 84, 0.7)" } }}
              />

              <TextField
                size="small"
                fullWidth
                inputProps={{ maxLength: 50 }}
                margin="normal"
                label={
                  <span>
                    Support Email or Phone Number&nbsp;<span style={{ color: "red" }}>*</span>
                  </span>
                }
                placeholder="If the recipient needs help, how should they reach you?"
                name="support_email"
                variant="outlined"
                InputLabelProps={{ shrink: true, style: {} }}
                onChange={(e) => handleData("support_contact", e.target.value)}
                onFocus={() => handlfocus("support_contact", true)}
                onBlur={() => handlfocus("support_contact", false)}
                defaultValue={dropData?.merchandise?.support_contact}
                FormHelperTextProps={{ style: { textAlign: nextTabErrors.merch_support_contact.error !== "" ? "left" : "right", color: dropData?.merchandise?.support_contact?.length == 100 || nextTabErrors.merch_support_contact.error !== "" ? "red" : "rgba(34, 51, 84, 0.7)" } }}
                error={nextTabErrors.merch_support_contact.error !== ""}
                helperText={
                  <Box display="flex" justifyContent={"space-between"}>
                    <Box>{nextTabErrors.merch_support_contact.error == "" ? "" : nextTabErrors.merch_support_contact.error}</Box>
                    <Box component="span" className="charTxt">
                      <Box component="span" display={focused.support_contact ? "block" : "none"}>
                        {dropData?.merchandise?.support_contact?.length > 0 ? `${dropData?.merchandise?.support_contact?.length} / 50` : false}
                      </Box>
                    </Box>
                  </Box>
                }
              />
            </Box>

            <Typography variant="body2" sx={{ mt: 2, fontFamily: "Roboto,sans-serif", fontSize: "0.938rem", fontWeight: "700", color: "#000000" }}>
              Fulfillment Form Options
            </Typography>
            <FormGroup sx={{ mt: 1.5 }}>
              <FormControlLabel
                disabled={true}
                control={
                  <Checkbox
                    sx={{ opacity: 0.455 }}
                    icon={<img alt="Uncheck" src="/assets/img/Uncheck.svg" width="100%" />}
                    checkedIcon={<img alt="Check" src="/assets/img/Checkbox.svg" width="100%" />}
                    defaultChecked={dropData?.merchandise?.allow_shipping_info}
                    onChange={(e) => {
                      handleData("allow_shipping_info", e.target.checked);
                    }}
                  />
                }
                label={
                  <Box>
                    <Typography variant="h4" sx={{ fontSize: "0.875rem", color: "#223354", fontFamily: "Roboto, sans-serif", fontWeight: "700" }}>
                      Standard Shipping Info
                    </Typography>
                    <Typography variant="body2" sx={{ fontSize: "0.875rem", opacity: "0.5", color: "#223354", fontFamily: "Roboto, sans-serif", fontWeight: "400" }}>
                      Includes internal shipping address, email, phone number, and notes
                    </Typography>
                  </Box>
                }
              />
              <FormControlLabel
                sx={{ mt: 1 }}
                control={
                  <Checkbox
                    disableRipple
                    icon={<img alt="Uncheck" src="/assets/img/Uncheck.svg" width="100%" />}
                    checkedIcon={<img alt="Uncheck" src="/assets/img/Checkbox.svg" width="100%" />}
                    defaultChecked={dropData?.merchandise?.allow_size}
                    onChange={(e) => {
                      handleData("allow_size", e.target.checked);
                    }}
                  />
                }
                label={
                  <Box>
                    <Typography variant="h4" sx={{ fontSize: "0.875rem", color: "#223354", fontFamily: "Roboto, sans-serif", fontWeight: "700" }}>
                      Size (t-shirt, clothing, etc.)
                    </Typography>
                    <Typography variant="body2" sx={{ fontSize: "0.875rem", opacity: "0.5", color: "#223354", fontFamily: "Roboto, sans-serif", fontWeight: "400" }}>
                      XS, S, M, L, XL, XXL, XXXL
                    </Typography>
                  </Box>
                }
              />
            </FormGroup>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default MerchandiseForm;
