import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { CardActionArea, CardMedia } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ahex_to_rba, limitText } from "src/utils";

const ContentPostCard = ({title, content,image_url, slug, createdAt, is_featured, admin_contract_name, c_type}) => {
  const navigate = useNavigate();

  return (
    <>
       <Card
          onClick={() => navigate(`/category/${c_type}/${slug}`)}
            variant="outlined"
            sx={{
            marginBottom: "24px",
            marginRight: "21px",
            padding: "0px",
              width: "375px",
              border: "1px solid #2B2011",
              borderRadius: "0px",
              "&:hover": {
                backgroundColor: "white",
                background: "white",
              },
            }}
          >
            {/* {card} */}
            <Box
              sx={{
                display: "flex",
                position: "relative",
                "&:hover": {
                  backgroundColor: "white",
                  background: "white",
                },
              }}
            >
              {image_url ? 
                  <CardMedia
                    component="img"
                    sx={{
                      width: "142px",
                      objectFit: "cover",
                    }}
                    height="200px"
                    image={image_url}
                    alt={title}
                  />
                  :
                <Box sx={{height: "200px",background: "#ffebeb", width: "142px"}}>
                </Box>
              }
              <CardContent
                className=""
                style={{ paddingBottom: "10px" }}
                sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "200px", width: "233px", boxSizing: "border-box", padding: "10px 15px" }}
              >
                <Box>
                {is_featured && (
                  <Typography
                    variant="span"
                    component="span"
                    className="font-gs-md"
                    fontSize="10px"
                    width="48px"
                    height="14px"
                    sx={{
                      padding: "3px",
                      color: "#2B2011",
                      background: "#cee5e8",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",

                      borderRadius: "4px",
                      "&:hover": {
                        background: "#cee5e8",
                      },
                    }}
                  >
                    Featured
                  </Typography>
                )}
                <Typography
                  variant="h5"
                  sx={{
                    color: "#2B2011",
                    fontFamily: "General Sans bold",
                    fontSize: "1.25rem",
                    textAlign: "left",
                    lineHeight: "normal",
                    marginBottom:"0px",
                  }}
                  component="div"
                >
                  {title}
                </Typography>
                {/* <Typography
                  variant="body2"
                  className="font-gs-md"
                  sx={{
                    // color: "#2B2011",
                    // opacity: ".5",
                    color: `rgba(${ahex_to_rba("#2B2011")}, 0.5)`,
                    fontSize: "13px",
                    fontWeight: "500",
                    lineHeight: "130%",
                  }}
                >
                  {content}
                </Typography> */}

                <div className="font-gs-md" style={{fontSize: "13px",color: `rgba(${ahex_to_rba("#2B2011")}, 0.5)`, "display":"-webkit-box","maxWidth":"400px","WebkitLineClamp":"4","WebkitBoxOrient":"vertical","overflow":"hidden","textOverflow":"ellipsis"}} dangerouslySetInnerHTML={{__html: content}}/>

                </Box>

                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    fontSize: "10px",
                  }}
                >
                  <Typography
                    variant="span"
                    className="font-gs-md"
                    sx={{ fontWeight: "500", fontSize: "10px", textTransform: "uppercase" }}
                  >
                    {new Date(createdAt).toLocaleDateString()}
                  </Typography>
                  <Typography
                    size="small"
                    className="font-gs-md"
                    sx={{
                      textTransform: "uppercase",
                      p: "0",
                      fontSize: "10px",
                      fontWeight: "500",
                      color: "#ED8805",
                    }}
                  >
                    {admin_contract_name && `By ${limitText(admin_contract_name, 20)}`}
                  </Typography>
                </Box>
              </CardContent>
            </Box>
          </Card>
    </>
  );
};

export default ContentPostCard;
