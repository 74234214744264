import React from 'react'
import { Box, Card, Typography, Button, Modal,Avatar, Link } from "@mui/material";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import moment from 'moment-timezone';
import { viewDate, viewUTCTime } from 'src/utils';

function EventPassModal(props) {
  const {open, handleClose,rewardDropData} = props;

  const style = {
    position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", width: 450, bgcolor: "white", boxShadow: 24, py: 4,px:9, borderRadius: "10px",
  };
  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" className="preview-modal">
      <Box sx={style} className='modal-border'>
        <Typography className="preview-title">This is how your reward will appear to users</Typography>
        <Typography variant="h6" className="modal-title">You've Claimed</Typography>
        <Typography variant="h1" className="heading">{rewardDropData?.title}</Typography>
        {
          rewardDropData.eventPass.details &&
          <Typography variant="h5" className="description">{rewardDropData.eventPass.details}</Typography>
        }
        <Typography variant="h5" align="center" sx={{ color: "#343957",fontSize: '1rem',fontWeight: '600', paddingTop: "10px",fontFamily: "Inter, sans-serif" }}>Show this QR code to the person checking in at the event <br/> (It changes every 5 minutes).</Typography>
        <Box sx={{ borderBottom: "1px solid rgba(34, 51, 84, 0.2)", pt: 2, width: "120px", mx: 'auto', }} display="flex" justifyContent="center"></Box>
        <Card sx={{ border: '1px solid #CCCEDD', bgcolor: "#F6F8FB", marginTop: "19px", px: 4, mx: 2, boxShadow:'0px 4px 21px rgba(24, 69, 136, 0.2)',borderRadius: "6px"}}>
          <Box sx={{ display: "flex", justifyContent: "center", paddingTop: "20px",}}>
            <Card sx={{ display: "flex", alignItems: 'center', bgcolor: "rgba(26, 117, 255, 0.05)", borderRadius: "30px", p: 1, boxShadow: "none",}}>
              <img src="/assets/img/event-icon.svg" alt="" style={{ width: "19px" }} />
              <Typography variant="h6" className='merch-category'>Event Pass</Typography>
            </Card>
          </Box>
          <Box sx={{ background: 'rgba(34, 51, 84, 0.02)', borderRadius: '8px', p:2, mt:2}}>
            <Card sx={{display:'flex',flexDirection:'column', justifyContent:'space-between' , px:1, py:1, bgcolor:'white',boxShadow:'none', borderRadius: "0%"}}>
              <Box sx={{display:'flex' , justifyContent:"center"}}>
                <Avatar src={`https://api.qrserver.com/v1/create-qr-code/?size=275x275&data=${rewardDropData.eventPass.details}`} alt="qr code" sx={{height: '140px', borderRadius:'0%', width: '140px'}} />
              </Box>
            </Card>
              <Card sx={{mt:1,display:'flex',flexDirection:'column', justifyContent:'space-between' , px:1, py:1, bgcolor:'white',boxShadow:'none',borderRadius: "0%"}}>
              <Box sx={{display:'flex' , alignItems:'center',justifyContent: 'space-between'}}>
                <Box sx={{display:'flex' , alignItems:'center'}}>
                  <Card sx={{borderRadius:'100%',height: '23px',width: '23px',p:1, bgcolor:'rgba(34, 51, 84, 0.2)' }}>
                    <CalendarTodayIcon sx={{ color: "#323232" }} />
                  </Card>
                  <Box>
                    <Typography variant="h5" sx={{pl:1,color:'#223354',fontWeight: '700',fontFamily: "Poppins, sans-serif",fontSize:'0.875rem'}}>Event Date</Typography>
                    <Typography variant="body2" sx={{pl:1,color:'rgba(34, 51, 84, 0.5)',fontWeight: '700',fontSize:'0.625rem',fontFamily: "Inter, sans-serif"}}>{viewDate(rewardDropData.eventPass.event_date)} @ {viewUTCTime(rewardDropData.eventPass.event_date)} ({moment.tz.guess()})</Typography>
                  </Box>
                </Box>
              </Box>
            </Card>
            <Card sx={{mt:1,display:'flex',flexDirection:'column', justifyContent:'space-between' , px:1, py:1, bgcolor:'white',boxShadow:'none'}}>
              <Box sx={{display:'flex' , alignItems:'center',justifyContent: 'space-between'}}>
                <Box sx={{display:'flex' , alignItems:'center'}}>
                  <Card sx={{borderRadius:'100%',height: '23px',width: '23px',p:1, bgcolor:'rgba(34, 51, 84, 0.2)' }}>
                    <InsertLinkIcon sx={{ color: "#323232" }} />
                  </Card>
                  <Box>
                    <Typography variant="h5" sx={{pl:1,color:'#223354',fontWeight: '700',fontFamily: "Poppins, sans-serif",fontSize:'0.875rem'}}>Event Website</Typography>
                    <Typography variant="body2" sx={{pl:1,color:'rgba(34, 51, 84, 0.5)',fontWeight: '700',fontSize:'0.625rem',fontFamily: "Inter, sans-serif"}}>Learn more</Typography>
                  </Box>
                </Box>
                <Box>
                  {
                    rewardDropData.eventPass.website &&
                    <Link href={rewardDropData.eventPass.website} target="_blank"><Button variant="contained" className="claim-blue-btn">View</Button></Link>
                  }
                </Box>
              </Box>
            </Card>

          </Box>
          <Card sx={{bgcolor:'white', boxShadow:'none', my:1 }}></Card>
        </Card>
        <Box sx={{ paddingTop: "20px", display: "flex", justifyContent: "center",}}>
          <Button variant="outlined" color="secondary" onClick={props.handleClose} className='close-btn'>Close</Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default EventPassModal