import React from 'react'
import { Box, Typography } from '@mui/material'
import { ahex_to_rba } from 'src/utils';

function CWalletCard(props) {
    const {name, walletType} = props.data;
    
    const {wType, theme} = props;
  return (
    <Box onClick={() => {
      if(props.walletResponse.type !== 'request'){
        props.onLogin(walletType)
      }
    }} sx={{p: "15px", borderRadius: "7px", border: "1px solid #000", opacity: (props.walletResponse.type === 'request' && wType != walletType) ? "0.6" : "1", cursor: props.walletResponse.type === 'request' ? "default" : "pointer", background: (wType == walletType && props.walletResponse.type === 'request') && `rgba(${ahex_to_rba(theme.data.base_pm_color)}, 0.1)`, "&:hover": {background: `rgba(${ahex_to_rba(theme.data.base_pm_color)}, 0.1)`}}}>
      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        <Box display="flex" alignItems="center">
          <img src={`/icons/${walletType}-icon.svg`} alt={`${walletType} icon`}/>
          <Typography variant='body2' className='font-gs-md' sx={{pl: "10px", color: "#000"}}>{name}</Typography>
        </Box>
        {
          (wType == walletType && props.walletResponse.type === 'request') &&
          <div className='spinner'></div>
        }
      </Box>
    </Box>
  )
}

export default CWalletCard