import { connectMetamask, connectWallet, connectWalletv2, connectCoinbase, web3signature } from "../../utils/ethereum";
import { loginUser, customAuthUser, refreshUser } from "./api";

export function userWalletAuth(data, cb) {
  return async (dispatch) => {
    dispatch({ type: "LOGIN_REQUEST", providerType: data.wallet_type });

    try {
      let currentTime = new Date().getTime();
      // let wallet = data.wallet_type == "wallet" ? "wallet" : data.wallet_type === "mobile" ? "mobile" : "metamask";
      let wallet = data.wallet_type;
      let apiData = await loginUser({ phone: data?.phone, eth_address: data.eth_address, time_stamp: data.currentTime, signature: data.signature, resendEmail: false, wallet_type: wallet });
      if (apiData.type == "success") {
        window.localStorage.setItem("login_data", JSON.stringify({ network: data.network, provider: wallet }));
        dispatch({ type: "LOGIN_SUCCESS", data: { ...apiData.data, time_stamp: currentTime, wallet_type: wallet }, network: data.network, provider: wallet });
      } else {
        dispatch({ type: "LOGIN_ERROR", message: apiData.message });
      }
      cb(apiData)
    } catch (e) {
      dispatch({ type: "LOGIN_ERROR", message: e.message });
    }
  };
}

export function userChkLogin() {
  return (dispatch) => {
    (async (obj) => {
      let apiData = await refreshUser();
      if (apiData.type == "success") {
        let login_data = window.localStorage.getItem("login_data");
        if (login_data) {
          login_data = JSON.parse(login_data);
        }
        dispatch({ type: "LOGIN_SUCCESS", data: { ...apiData.data, wallet_type: login_data.provider }, network: login_data.network, provider: login_data.provider });
      }
    })();
  };
}
export function userLogin(type, resendEmail = false) {
  return (dispatch) => {
    let connect;
    if (type == "wallet") {
      // connect = connectWallet
      connect = connectWalletv2;
    } else if (type == "coinbase") {
      connect = connectCoinbase;
      // connect = connectMetamask
    } else {
      connect = connectMetamask;
    }
    dispatch({ type: "LOGIN_REQUEST", providerType: type });

    connect(async (obj) => {
      if (obj.type == "success") {
        const data = obj.data;
        let currentTime = new Date().getTime();
        let message = `Welcome to Step3!\n\nSigning this message will not trigger a blockchain transaction, cost fees or allow us to transfer contents from your wallet.`;

        web3signature(
          message,
          data.eth_address,
          async (webObj) => {
            if (webObj.type == "success") {
              try {
                let wallet = type == "wallet" ? "wallet" : "metamask";
                let apiData = await loginUser({ eth_address: data.eth_address, time_stamp: currentTime, signature: webObj.data.signature, resendEmail: resendEmail, wallet_type: wallet });
                if (apiData.type == "success") {
                  window.localStorage.setItem("login_data", JSON.stringify({ network: data.network, provider: wallet }));
                  dispatch({ type: "LOGIN_SUCCESS", data: { ...apiData.data, time_stamp: currentTime, wallet_type: wallet }, network: data.network, provider: wallet });
                } else {
                  dispatch({ type: "LOGIN_ERROR", message: apiData.message });
                }
              } catch (e) {
                dispatch({ type: "LOGIN_ERROR", message: e.message });
              }
            } else if (webObj.type == "error") {
              dispatch({ type: "LOGIN_ERROR", message: webObj.message });
            } else {
              dispatch({ type: "LOGIN_ERROR", message: webObj.message });
            }
          },
          type == "wallet" ? "other" : "personal"
        );
      } else {
        dispatch({ type: "LOGIN_ERROR", message: obj.message });
      }
    }, dispatch);
  };
}

export function userReset() {
  return (dispatch) => {
    dispatch({ type: "LOGIN_RESET" });
  };
}

export function userCustomLogin(eth_address, signature, currentTime, profile_img, email_address, mintType, udata, callback) {
  return async (dispatch) => {
    
    let wallet = udata.wallet_type;
    dispatch({ type: "LOGIN_REQUEST", providerType: wallet, mintType: mintType });
    let data = { eth_address, signature };
    email_address = udata.wallet_type === "mobile" ? "" : email_address;
    let phone = udata.wallet_type === "mobile" ? udata.email : "";

    let apiData = await customAuthUser({ eth_address: eth_address, time_stamp: currentTime, signature: signature, wallet_type: wallet, mintType: mintType, email_address, fname: udata.fname, lname: udata.lname, profile_img, custom_wallet_type: udata.wallet_type, phone });
    callback(apiData);
    if (apiData.type == "success") {
      let updated_data = { ...apiData.data };
      updated_data.profile_img = profile_img;
      updated_data.email = email_address;

      window.localStorage.setItem("login_data", JSON.stringify({ network: data.network, provider: wallet }));
      dispatch({ type: "LOGIN_SUCCESS", data: { ...apiData.data, email: email_address, profile_img, time_stamp: currentTime, signature: signature, wallet_type: wallet, mintType: mintType }, network: data.network, provider: wallet, mintType: mintType, response_msg: apiData.response_msg });
    } else {
      dispatch({ type: "LOGIN_ERROR", message: apiData.message });
    }
  };
}
