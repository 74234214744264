import React, { useEffect, useState } from "react";
import { Box, Typography, Link as AnchorLink, TextField, Button, Snackbar, Alert, FormControl, RadioGroup, FormControlLabel, styled, Radio, Card } from "@mui/material";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import { connect } from "react-redux";
import { updateDomains, brand_subdomain_availability_check, setToast } from "src/services/actions";
import { gaEvent, validatGA } from "src/utils";
import DeleteDomainModal from "src/components/Confirmation/delete_domain";

const DomainSettings = (props) => {
  const vertical = "top";
  const horizontal = "center";

  const ALPHA_DASH_REGEX = /^(?!-)[A-Za-z0-9-]{1,63}(?<!-)$/;
  const URL_REGEX = /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\-]*[A-Za-z0-9])$/g;
  const { user } = props;
  const [focused, setFocused] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [rewardPageSettings, setRewardPageSettings] = useState({
    subdomain: user.data?.brand_handle,
    brand_domain: user.data?.brand_domain,
  });
  const [resetDomainModal, setResetDomainModal] = useState(false);
  const [openCopiedBar, setOpenCopiedBar] = useState(false);
  const [isCustomDomain, setIsCustomDomain] = useState(rewardPageSettings.brand_domain !== null && rewardPageSettings.brand_domain !== "");
  const [currentSubDomain, setCurrentSubDomain] = useState("");
  const [currentBrandDomain, setCurrentBrandDomain] = useState("");

  useEffect(() => {
    gaEvent({
      category: "Domain Settings",
      label: `-${user.data.eth_address}-`,
      action: `domain_settings`,
    });
    setCurrentSubDomain(user.data.brand_handle);
    setCurrentBrandDomain(user.data.brand_domain);

    // clear all intervals
    for (let i = 0; i < 9999; i++) {
      window.clearInterval(i);
    }
  }, []);

  const [isBrandSubdomainAvailable, setIsBrandSubdomainAvailable] = useState({ type: "success", message: "" });

  const checkBrandHandleAvailability = (val) => {
    if (val === "") {
      setIsBrandSubdomainAvailable({ type: "error", message: `Subdomain can't be blank` });
      return;
    }
    if (val !== "" && !ALPHA_DASH_REGEX.test(val)) {
      setIsBrandSubdomainAvailable({ type: "error", message: "Invalid Subdomain" });
      return;
    }
    props.brand_subdomain_availability_check(val, (resp) => {
      if (resp.type == "error") {
        setIsBrandSubdomainAvailable({ type: "error", message: "Subdomain is not available" });
      } else {
        setIsBrandSubdomainAvailable({ type: "success", message: "" });
      }
    });
  };

  useEffect(() => {
    if (!isCustomDomain) {
      setIsValidBrandDomain("");
    }
  }, [isCustomDomain]);

  const [isValidBrandDomain, setIsValidBrandDomain] = useState("");
  const validateBrandDomain = (val) => {
    // if (val === "") {
    //   setIsValidBrandDomain("Domain URL can't be blank");
    //   return
    // }
    if (val != "" && !val.match(URL_REGEX)) {
      setIsValidBrandDomain("Invalid Domain URL");
      return;
    }
    setIsValidBrandDomain("");
  };

  const copyToClipboard = (str) => {
    navigator.clipboard.writeText(str);
    props.setToast(true, { type: "success", message: "Copied to clipboard!" });
  };

  const handlePageSubdomain = async (val) => {
    let existing_data = { ...rewardPageSettings };
    existing_data["subdomain"] = val;
    setRewardPageSettings(existing_data);
  };

  const handlePageBrandDomain = (val) => {
    let existing_data = { ...rewardPageSettings };
    existing_data["brand_domain"] = val;
    setRewardPageSettings(existing_data);
  };

  const handleDomainType = (domainType) => {
    setIsCustomDomain(domainType === "customdomain");
  };

  const settoastMsg = (type, message) => {
    props.setToast(true, { type, message });
  };

  useEffect(() => {
    setIsCustomDomain(rewardPageSettings.brand_domain !== null && rewardPageSettings.brand_domain !== "");
  }, [rewardPageSettings.brand_domain]);

  const updateDomainSettings = (is_brand_domain) => {
    const settings_data = { ...rewardPageSettings };

    if (isCustomDomain && (settings_data.brand_domain === "" || settings_data.brand_domain === null)) {
      setIsValidBrandDomain("Domain URL can't be blank");
      return;
    }

    if (!isCustomDomain && (settings_data.subdomain === "" || settings_data.subdomain === null)) {
      return;
    }

    if (is_brand_domain) {
      settings_data["brand_domain"] = "";
    }

    if (!isCustomDomain) {
      settings_data["brand_domain"] = "";
    }
    setIsSubmit(true);
    props.updateDomains(settings_data, (resp) => {
      setIsSubmit(false);
      if (resp.type == "success") {
        settoastMsg("update_success", "Your settings have been updated.");

        gaEvent({
          category: "Custom Domain Settings Updated",
          label: `-${user.data.eth_address}-`,
          action: `custom_domain_settings_updated`,
        });
        setRewardPageSettings(settings_data);
      } else {
        gaEvent({
          category: `Custom Domain Settings error ${resp.message}`,
          label: `-${user.data.eth_address}-`,
          action: `custom_domain_settings_update_error`,
        });
        settoastMsg("update_error", resp.message);
      }
    });
  };

  const resetBrandDomain = () => {
    console.log("Test");
    setResetDomainModal(true);
  };

  return (
    <Box className="domain-settings">
      <Typography variant="h3">Customizing your domain</Typography>
      <Typography variant="h6">Choose between these two options for your community and membership pages.</Typography>
      <form noValidate>
        <FormControl sx={{ mt: 2 }} fullWidth>
          <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
            <FormControlLabel
              sx={{ width: "100%", mb: 2, "& .MuiFormControlLabel-label": { width: "100%" }, alignItems: "start !important", mx: 0 }}
              onChange={(e) => handleDomainType("subdomain")}
              name="is_limited_quantity"
              control={<Radio disableRipple sx={{ ml: "-11px", "&.Mui-checked": { color: "#504D4D" }, marginTop: "inherit" }} checked={!isCustomDomain} value={true} />}
              label={
                <Box>
                  <Typography variant="h5">Use a subdomain (of Step3)</Typography>
                  <Typography variant="body2">Using a custom subdomain is a quick, easy way to launch your community and membership pages. Just enter your brand or community name and your done!</Typography>

                  <Box display="flex" alignItems="center">
                    <TextField
                      fullWidth
                      margin="normal"
                      label="Custom Subdomain"
                      name="subdomain"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ maxLength: 25, minLength: 3 }}
                      required={!isCustomDomain}
                      disabled={isCustomDomain}
                      defaultValue={user.data.brand_handle}
                      placeholder="Enter your domain"
                      onFocus={() => setFocused(true)}
                      onBlur={() => setFocused(false)}
                      helperText={
                        <Box display="flex" justifyContent={"space-between"}>
                          <Box sx={{ color: isBrandSubdomainAvailable.type !== "success" ? "red" : "rgba(34, 51, 84, 0.7)" }}>{isBrandSubdomainAvailable.type == "success" ? "" : isBrandSubdomainAvailable.message}</Box>
                          <Box component="span" className="charTxt">
                            <Box component="span" display={focused ? "block" : "none"}>
                              <Box sx={{ color: rewardPageSettings.subdomain.length == 25 ? "red" : "rgba(34, 51, 84, 0.7)" }}>{rewardPageSettings.subdomain.length > 0 ? `${rewardPageSettings.subdomain.length} / 25` : false}</Box>
                            </Box>
                          </Box>
                        </Box>
                      }
                      error={!(isBrandSubdomainAvailable.type == "success")}
                      onKeyUp={(e) => {
                        checkBrandHandleAvailability(e.target.value);
                      }}
                      onChange={(e) => {
                        handlePageSubdomain(e.target.value);
                      }}
                      FormHelperTextProps={{ style: { textAlign: "right", color: "rgba(34, 51, 84, 0.7)" } }}
                    />
                    <Box mt="-1rem">
                      <Typography variant="body1" className="hostname" ml={2}>
                        {process.env.REACT_APP_BRAND_SUBDOMAIN}
                      </Typography>
                    </Box>
                    <Box mt="-1rem">
                      <AnchorLink onClick={(e) => copyToClipboard(`https://${user.data.brand_handle}${process.env.REACT_APP_BRAND_SUBDOMAIN}`)} sx={{ cursor: "pointer" }} underline="none">
                        <Card sx={{ bgcolor: "#E8F1FF", padding: "10px", marginLeft: "11px", alignItems: "center", display: "flex" }}>
                          <InsertLinkIcon sx={{ color: "#0061F4" }} />
                        </Card>
                      </AnchorLink>
                    </Box>
                  </Box>

                  {user.data.brand_handle != currentSubDomain && currentSubDomain != "" && !isCustomDomain && (
                    <Typography variant="body2" sx={{ color: "#F49200", fontSize: "12px", mb: 1 }}>
                      May take up to 15 minutes to update.
                    </Typography>
                  )}
                </Box>
              }
            />

            <FormControlLabel
              sx={{ width: "100%", "& .MuiFormControlLabel-label": { width: "100%" }, alignItems: "start !important", mx: 0 }}
              onChange={(e) => handleDomainType("customdomain")}
              name="is_limited_quantity"
              control={<Radio disableRipple sx={{ ml: "-11px", "&.Mui-checked": { color: "#504D4D" }, marginTop: "inherit" }} checked={isCustomDomain} value={true} />}
              label={
                <Box>
                  <Typography variant="h5">Use your own domain</Typography>
                  <Typography variant="body2">Connecting your domain points your URL to your community and membership pages on Step3. You will need access to your hosting provider, and knowledge of how modify DNS records to set it up. </Typography>

                  <TextField
                    disabled={!isCustomDomain}
                    fullWidth
                    margin="normal"
                    type="text"
                    value={rewardPageSettings.brand_domain}
                    placeholder="Enter your domain"
                    label="DOMAIN Name"
                    required={isCustomDomain}
                    name="quantity"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    error={isValidBrandDomain !== ""}
                    helperText={isValidBrandDomain}
                    onChange={(e) => {
                      handlePageBrandDomain(e.target.value);
                    }}
                    onKeyUp={(e) => {
                      validateBrandDomain(e.target.value);
                    }}
                  />
                  {user.data.brand_domain && (
                    <Box display="flex" justifyContent="space-between" mt={1.5}>
                      <Box className="domainCname">
                        <Typography variant="body2">
                          <Box component="strong">CNAME Value: {process.env.REACT_APP_BRAND_DNS}</Box>
                        </Typography>
                      </Box>
                      <Box mt="-1rem">
                        <AnchorLink onClick={(e) => copyToClipboard(process.env.REACT_APP_BRAND_DNS)} sx={{ cursor: "pointer" }} underline="none">
                          <Card sx={{ bgcolor: "#E8F1FF", padding: "10px", marginLeft: "11px", alignItems: "center", display: "flex" }}>
                            <InsertLinkIcon sx={{ color: "#0061F4" }} />
                          </Card>
                        </AnchorLink>
                      </Box>
                    </Box>
                  )}

                  {user.data.brand_domain !== currentBrandDomain && currentBrandDomain !== "" && isCustomDomain && (
                    <Typography variant="body2" sx={{ color: "#F49200", fontSize: "12px", mt: 1 }}>
                      After configuring the CNAME Value at your domain server it could take up to 30 mins to update
                    </Typography>
                  )}
                </Box>
              }
            />
          </RadioGroup>
        </FormControl>

        <Box display="flex" justifyContent="space-between" sx={{ mt: 7, mb: 2 }}>
          <Button sx={{ borderRadius: "6px" }} onClick={resetBrandDomain} disabled={rewardPageSettings.brand_domain === ""} className="btn-text">
            Reset
          </Button>

          <Box display="flex">
            <Button variant="contained" onClick={() => updateDomainSettings(false)} color="primary" sx={{ borderRadius: "6px", ml: 1 }} className="btn-text" disabled={isSubmit}>
              {isSubmit ? "Updating..." : "Update"}
            </Button>
          </Box>
        </Box>
      </form>

      <Snackbar sx={{ zIndex: "7 !important" }} anchorOrigin={{ vertical, horizontal }} open={openCopiedBar} autoHideDuration={5000} key={vertical + horizontal}>
        <Alert severity="success" sx={{ width: "100%" }}>
          Copied to clipboard!
        </Alert>
      </Snackbar>

      {resetDomainModal && <DeleteDomainModal open={resetDomainModal} handleClose={() => setResetDomainModal(false)} updateDomainSettings={updateDomainSettings} />}
    </Box>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  updateDomains,
  brand_subdomain_availability_check,
  setToast,
};

export default connect(mapStateToProps, mapDispatchToProps)(DomainSettings);
