import React, { useEffect, useState } from "react";
import { Box, Card, Chip, Typography } from "@mui/material";


import CheckIcon from "@mui/icons-material/Check";
import moment from "moment";
import { connect } from "react-redux";
import { alphaHex, getTextColor } from "src/utils";

function NewClaimRewardCard(props) {
  const { user, theme, webInfo, reward } = props

  const [haveDefaultBlackColor, setHaveDefaultBlackColor] = useState(false)

  useEffect(() => {
    let settings = webInfo?.data?.membership_page_info?.other ? webInfo?.data?.membership_page_info?.other : null;

    if(settings){
      if(["aiea", "bg", "step3"].includes(settings.layout)){
        setHaveDefaultBlackColor(true);
      }
    }
  }, [])
  

  const [daysDiff, setDaysDiff] = useState("")



  useEffect(() => {
    if(reward.end_date){
      let currentDate = moment();
      let diff = moment(reward.end_date).diff(currentDate, "days")

      setDaysDiff(diff)
    }
  }, [])
  return (
    <>

    <Box sx={{ width: "250px"}}>
      <Card sx={{ borderRadius: "8px", border: "1px solid rgba(0, 0, 0, 0.15)", background: "linear-gradient(0deg,  #FFF 0%, #FFF 100%), #FFF", boxShadow: "0px 0px 1.502px 0px rgba(159, 162, 191, 0.32), 0px 4.507px 4.507px 0px rgba(159, 162, 191, 0.18)", height: "250px", width: "250px", opacity: (!reward.schedule_now && moment(reward.published_date) > moment()) ? "0.5" : "1" }}>
        <Box sx={{ height: "165px", overflow: "hidden", position: "relative", background: reward.image ? "" : `${alphaHex(`#${user.data.brand_data.accent}`, 0.25)}` }}>
          {
            reward.redeem_points !== null &&
            <Chip sx={{borderRadius: '8px', ml: '10px', mt: '10px', background: 'rgba(43, 32, 17, 0.75)', fontSize: '13px'}} className="font-gs-sb" label={`${reward.redeem_points} pts`} />
          }
          {reward.image && <img src={reward.image} style={{ objectFit: "cover", width: "100%", height: "100%" }} alt="Reward" />}
        </Box>
        <Box sx={{ height: "75px", position: "relative" }}>
          <Box sx={{padding: "15.5px 13.5px"}}>
            <Typography variant="h1" className="two-line-text">
              {reward.title}
            </Typography>
          </Box>
        </Box>
      </Card>
      <Box sx={{display: "flex",justifyContent: "center", alignItems: "center", mt: "10px"}}>
      {
        (reward.allow_gift) &&
        <Typography variant="body1" align="center" display="flex" alignItems="center" justifyContent="center" sx={{ fontSize: "13px", lineHeight: "normal"}} className="font-gs-md">
          <img src="/assets/img/special-gift.svg" alt="check icon" height={21} width={21}/>
          <Box component="span" ml="5px">Special Gift</Box>
        </Typography>
      }
      {
        (reward.is_limited_quantity && ((JSON.parse(reward.quantity) - reward.drop_total_claim) <= Math.floor(JSON.parse(reward.quantity) * 0.15))) &&
          <Typography variant="body1" align="center" sx={{ fontSize: "13px", lineHeight: "normal", color: haveDefaultBlackColor ? "#000" : getTextColor(user.data.brand_data.accent, '#ffffff') }} className="font-gs-md">
            {JSON.parse(reward.quantity) - reward.drop_total_claim} Remaining
          </Typography>
      }

      {
        (!reward.schedule_now && moment(reward.published_date) > moment()) &&
          <Typography variant="body1" align="center" sx={{ fontSize: "13px", lineHeight: "normal", color: haveDefaultBlackColor ? "#000" : getTextColor(user.data.brand_data.accent, '#ffffff') }} className="font-gs-md">
            Coming Soon - {new Date(reward.published_date).toLocaleDateString()}
          </Typography>
      }
      {
         ((reward.end_date && (daysDiff <= 7)) && ((reward.is_limited_quantity && ((JSON.parse(reward.quantity) - reward.drop_total_claim) <= Math.floor(JSON.parse(reward.quantity) * 0.15))))) &&
         <>&nbsp; - &nbsp;</>
      }

      {(reward.end_date && (daysDiff <= 7) && !(!reward.schedule_now && moment(reward.published_date) > moment())) &&
        <Typography variant="body1" align="center" sx={{ fontSize: "13px", lineHeight: "normal", color: haveDefaultBlackColor ? "#000" : getTextColor(user.data.brand_data.accent, '#ffffff') }} className="font-gs-md">
          Ends in {daysDiff} days
        </Typography>
      }
      </Box>
    </Box>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(NewClaimRewardCard)