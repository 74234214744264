import React from "react";
import { Box, Autocomplete, Typography, Button, Modal, Card, TextField, FormControl } from '@mui/material'
import countries from "../../services/constants/countries.json"
import { networkSym, viewDate, viewUTCTime } from "src/utils";

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'white',
    boxShadow: 24,
    py: 4,
    px: 8,
    borderRadius:'6px',
    // height:"90vh",
    // overflow:"auto"
  };  
  const AllowListModal = (props) => {
    const {open, handleClose,rewardDropData} = props;

    const sizeData = ['XS',"S","M","L","XL","XXL","XXXL"]
    
    return(
        <>
          <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" className="preview-modal">
            <Box sx={style} className='modal-border'> 
              <Typography className="preview-title">This is how your reward will appear to users</Typography>
              <Box sx={{ px:3}}>
              <Typography variant="h6" className="modal-title">You've Claimed</Typography>
              <Typography variant="h1" className="heading" mb={2}>{rewardDropData?.title}</Typography>
              {
                rewardDropData.allowList.description &&
                <Typography variant="h5" className="description">{rewardDropData.allowList.description}</Typography>
              }

              <Card className="allow-list-card" sx={{px:2}}>
                <Box sx={{ display: "flex", justifyContent: "center", paddingTop: "10px", }}>
                    <Card sx={{display: "flex", bgcolor: "rgba(26, 117, 255, 0.05)", borderRadius: "30px", p: 1, boxShadow: "none"}}>
                        <img src="/assets/img/allow-icon.svg" alt="" style={{ width: "22px" }} />
                        <Typography variant="h6" className="merch-category">Allow List</Typography>
                    </Card>
                </Box>

                <Card className="inner-al-card" sx={{p:1, my:1}}>
                    <Card className="detail-card" sx={{p:1, my:0.5}}>
                        <Box>
                            <img src="/assets/img/check-icon2.svg" alt="check icon"/>
                        </Box>
                        <Box ml={1}>
                            <Typography variant="h6" className="helper-title">You’re On The List!</Typography>
                            <Typography variant="body2" className="helper-description">0xA2d377d11CB89F654DdD437123bA168b97b044d0</Typography>
                        </Box>
                    </Card>
                    {
                        (rewardDropData.allowList.project_name != "" || rewardDropData.allowList.project_website != "") &&
                        <Card className="detail-card" sx={{p:1, my:0.5}}>
                            <Box>
                                <Box display="flex">
                                    <img src="/assets/img/link-icon2.svg" alt="link icon"/>
                                    <Box ml={1}>
                                        <Typography variant="h6" className="helper-title">{ rewardDropData.allowList.project_name != "" ? rewardDropData.allowList.project_name : "Project"}</Typography>
                                        <Typography variant="body2" className="helper-description">Learn more</Typography>
                                    </Box>
                                </Box>
                            </Box>
                            {
                                rewardDropData.allowList.project_website != "" &&
                                <Button color="primary" variant="outlined" target="_blank" LinkComponent="a" href={rewardDropData.allowList.project_website} className="btn">Visit</Button>
                            }
                        </Card>
                    }
                    {
                        rewardDropData.allowList.price != "" &&
                        <Card className="detail-card" sx={{p:1, my:0.5}}>
                            <Box>
                            <Box display="flex">
                                <img src="/assets/img/dollar-icon2.svg" alt="link icon"/>
                                <Box ml={1}>
                                    <Typography variant="h6" className="helper-title">Mint Price</Typography>
                                    <Typography variant="body2" className="helper-description">{rewardDropData.allowList.price} {rewardDropData.allowList.network && networkSym(rewardDropData.allowList.network)}</Typography>
                                </Box>
                            </Box>
                            </Box>
                        </Card>
                    }
                    {
                        rewardDropData.allowList.discord_url != "" &&
                        <Card className="detail-card" sx={{p:1, my:0.5}}>
                            <Box>
                                <Box display="flex">
                                    <img src="/assets/img/discord-icon2.svg" width={40} alt="link icon"/>
                                    <Box ml={1}>
                                        <Typography variant="h6" className="helper-title">Discord</Typography>
                                        <Typography variant="body2" className="helper-description">Check out the community</Typography>
                                    </Box>
                                </Box>
                            </Box>
                            <Button color="primary" variant="outlined" target="_blank" LinkComponent="a" href={rewardDropData.allowList.discord_url} className="btn">Visit</Button>
                        </Card>
                    }
                    {
                        rewardDropData.allowList.mint_date != "" &&
                        <Card className="detail-card" sx={{p:1, my:0.5}}>
                            <Box>
                                <Box display="flex">
                                    <img src="/assets/img/calendar-icon2.svg" alt="link icon"/>
                                    <Box ml={1}>
                                        <Typography variant="h6" className="helper-title">Mint Date</Typography>
                                        <Typography variant="body2" className="helper-description">{viewDate(rewardDropData.allowList.mint_date)} @ {viewUTCTime(rewardDropData.allowList.mint_date)}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Card>
                    }
                </Card>
              </Card>
              </Box>
              <Box
                    mt={5}
                    sx={{
                    display: "flex",
                    justifyContent: "center",
                    }}
                >
                    <Button variant="outlined" color="secondary" onClick={handleClose}  className='close-btn'>Close</Button>
                </Box>
            </Box>
          </Modal>
        </>
    )
}
export default AllowListModal;