import React from 'react'
import { Box, Link } from '@mui/material'

const Footer = () => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" height="62px">
      <Box component="span">© Copyright {new Date().getFullYear()} Step3</Box>
      <Box component="span" ml="32px"><Link href="https://www.iubenda.com/privacy-policy/62786231" target="_blank">Privacy Policy</Link></Box>
      <Box component="span" ml="32px"><Link href="https://www.iubenda.com/privacy-policy/62786231/cookie-policy" target="_blank">Cookie Policy</Link></Box>
      <Box component="span" ml="32px"><Link href="https://www.step3.io/terms" target="_blank">Terms and Conditions</Link></Box>
    </Box>
  )
}

export default Footer