import React from 'react'
import { connect, useSelector } from "react-redux";

import { Box, Modal, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import { getVideoThumbnail, validateVideoUrl, youtubeVideoId } from 'src/utils';

function PostDetailModal(props) {
    const {user} = props
  const { page, theme } = useSelector((state) => state)
  const {open, handleClose, data, contentDetail, createdAt, type} = props


  let isMobile = page?.mobileWidth ? page.mobileWidth : false;

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile? "100vw" : "700px",
    bgcolor: "#FDF9F3",
    height: isMobile ? "100vh" : "auto",
    maxHeight: "100vh",
    overflowY: "auto",
    boxShadow: 24,
    padding: isMobile ? "20px" : "20px 50px 40px 50px",
    borderRadius: isMobile ? "0px" :"8px",
    zIndex: "3",
    border: isMobile ? "none" : "1px solid black",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    boxSizing: "border-box"
    // height: props.visited && '80%'
  };
  return (
    <>
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" className='modals'>
      <Box sx={style}>
        <Box display="flex" justifyContent="space-between">
          <Typography className='font-gs-md' sx={{fontSize: "13px", color: "#ED1F23", textTransform: "uppercase"}}>{type}</Typography>
          <CloseIcon sx={{fontSize: "1.25rem", color: "#ED1F23", cursor: "pointer"}} onClick={handleClose}/>
        </Box>

        <Typography className='font-gs-b' sx={{mt: "30px", fontSize: "2rem", lineHeight: "normal"}}>{data.title.value}</Typography>
        {data.type.value === 'image' && data.image.value ?
        <img alt="img" style={{margin: "10px 0", height: "265px", width: "100%", objectFit: "cover", borderRadius: "8px", border: "1px solid #2B2011"}} src={data.image.value}/>
        :
          data.type.value === 'video' && validateVideoUrl(data.video.value) === true !== '' ?
          // <img alt="vid img" style={{margin: "10px 0", height: "265px", width: "100%", objectFit: "cover", borderRadius: "8px", border: "1px solid #2B2011"}} src={getVideoThumbnail(data.video.value)}/>

          <iframe
            title="YouTube video player"
            width="100px"
            height="100px"
            src={`https://www.youtube.com/embed/${youtubeVideoId(data.video.value)}`}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            style={{ margin: "10px 0", height: "265px", width: "100%", objectFit: "cover", borderRadius: "8px", border: "1px solid #000" }}
          ></iframe>
          :
          <Box sx={{margin: "10px 0", height: "265px", width: "99%", background: "#ffebeb", borderRadius: "8px", border: "1px solid #2B2011"}}></Box>
        }

        <Box display="flex" justifyContent="space-between">
          <Box>
            <Typography className='font-gs-md' sx={{fontSize: "13px", lineHeight: "normal", color: "#1A1616"}}>Created by</Typography>
            <Typography className='font-gs-b' sx={{fontSize: "1.25rem", lineHeight: "normal", color: "#1A1616"}}>{user.data.membership_nft_details?.contract_name}</Typography>
          </Box>
          <Box>
            <Typography className='font-gs-md' sx={{fontSize: "13px", lineHeight: "normal", color: "#1A1616"}}>Date</Typography>
            <Typography className='font-gs-b' sx={{fontSize: "1.25rem", lineHeight: "normal", color: "#1A1616"}}>{createdAt ? new Date(createdAt).toLocaleDateString() : new Date().toLocaleDateString()}</Typography>
            {/* {new Date(createdAt).toLocaleDateString()} */}
          </Box>
        </Box>


        <div className="font-gs-md" style={{fontFamily: "Inter", fontWeight: "400",my: "20px", fontSize: "13px", lineHeight: "normal", color: "#000"}} dangerouslySetInnerHTML={{__html: contentDetail.value}}/>

        <Box mt={"20px"} display="flex" justifyContent="center">
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <EmojiEmotionsIcon />
            <Typography className='font-gs-md' sx={{fontSize: "10px", color: "#2B2011"}}>Like it?</Typography>
          </Box>
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" ml={1}>
            <VisibilityOffIcon />
            <Typography className='font-gs-md' sx={{fontSize: "10px", color: "#2B2011"}}>Hide</Typography>
          </Box>
        </Box>
      </Box>
    </Modal>
    </>
  )
}

  
const mapStateToProps = (state) => ({
    user: state.user
  })
  
  const mapDispatchToProps = {
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(PostDetailModal)