import React, { useEffect, useState } from 'react'
import {
    Typography,
    Button,
    Box, TextField, Avatar
  } from '@mui/material';
  import AddIcon from '@mui/icons-material/Add';

function UrlForm(props) {
  const {dropData, nextTabErrors, setNextTabErrors} = props;

  const [focused, setFocused] = useState([]);

  const handlfocus = (key, is_focus) => {
    const cFocused = [...focused];
    cFocused[key].content_name = is_focus;
    setFocused(cFocused)
  }

  const [urls, setUrls] = useState([])
  const [err,setErr] = useState('')
  
    
  const [urlValuesArr, setUrlValuesArr] = useState([]);
  useEffect(() => {
    if (props.dropData.urls.length == 0) {
      setUrls([{Url: '', ContentName: ''}])
    }
  },[])
  useEffect(() => {
    if (props.dropData.urls.length > 0) {
      setUrls(props.dropData.urls);
      let new_arr = [];
      for (let l = 0; l < (dropData.urls.length-1); l++) {
        new_arr.push({content_url: {value: dropData.urls[l].Url, error: ""}, content_name: {value: dropData.urls[l].ContentName, error: ""}});
      }
      setUrlValuesArr(new_arr);
      setFocused(dropData.urls.map((c) => ({content_name: false})))
    } else {
      setUrlValuesArr([{content_url: {value: "", error: ""}, content_name: {value: "", error: ""}}]);
      setFocused([{content_url: {value: "", error: ""}, content_name: {value: "", error: ""}}])
    }
  },[])

  

  useEffect(() => {
    if(urls.length == 10){
        setErr('You have met your limit of 10 items per reward.')
    }else{
        setErr('')
    }
    if (urls.length > 0) {
        props.handleChildData('urls', urls)
    }
    if (urlValuesArr.length <= 10) {
      setUrlValuesArr(prev => [...prev, {content_url: {value: '', error: ''}, content_name: {value: '', error: ''}}])
      // setFocused(prev => [...prev, {content_name: false}])
      setNextTabErrors({...nextTabErrors, ['gatedContent']: urlValuesArr});
    }
  },[urls])

  const addBox = (i) => {
    if(urls.length <= 9){
      setUrls(previous => [...previous, {Url: '', ContentName: ''}])
      setFocused(prev => [...prev, {content_name: false}])
    }
  }

  const removeColumn = (i) => {
    let new_urls = [...urls];
    new_urls = new_urls.filter((item,index) => index !== i)
    setUrls(new_urls)

    let new_url_vals = [...urlValuesArr];
    new_url_vals = new_url_vals.filter((item, indx) => indx !== i);
    setUrlValuesArr(new_url_vals);

    let new_focused_vals = [...focused];
    new_focused_vals = new_focused_vals.filter((item,indx) => indx !== i);
    setFocused(new_focused_vals)
  }

  const addUrlData = (key, val, i) => {
    let url_data = [...urls];
    url_data[i][key] = val;
    setUrls(url_data);
  }


  const handleContentValueData = (type, val, index) => {
    const newContentVals = [...urlValuesArr];
    if (type === 'content_url') {
      addUrlData('Url', val, index)
    }
    if (type === 'content_name') {
      addUrlData('ContentName', val, index)
    }
    
    newContentVals[index][type] = {value: (typeof val == "string") ? val.trim() : val, error:""};

    setUrlValuesArr(newContentVals);
    setNextTabErrors({...nextTabErrors, ['gatedContent']: urlValuesArr});
  }

  return (
    <>
        <Box className='gated-content-form'>
            {dropData.status !== 1 &&
            <>
              <Typography variant="h1" textAlign="center" sx={{mb: 3}} className="title">Add Your Content</Typography>
            </>
            }
            <Box>
                {urls.map((item,index) => {
                    return(
                      <Box key={index} p={1}>
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                          <Typography variant="h5" sx={{fontSize: '1rem',color: '#000', fontFamily: "Roboto, sans-serif", fontWeight: "700"}}>{`Item ${index + 1}`}</Typography>
                          {
                          index !== 0 && 
                          <Box display="flex" justifyContent="flex-end">
                              <Button sx={{color: '#000', "&:hover": {backgroundColor: "transparent !important"}}} onClick={() => removeColumn(index)} disableRipple>
                                  <Avatar src="/assets/images/delete_btn.svg" />
                              </Button>
                          </Box>
                          }
                        </Box>
                        <Box>
                          <TextField fullWidth margin="normal" label={<span>URL&nbsp;<span style={{color:'red'}}>*</span></span>} placeholder='Where is the content you want to share?' name="Url" variant="outlined" InputLabelProps={{shrink: true}} onChange={(e) => handleContentValueData('content_url', e.target.value, index)} value={item.Url} inputProps={{ maxLength: 255 }} error={
                            nextTabErrors.gatedContent[index]?.content_url?.error !== ""
                          } helperText={nextTabErrors.gatedContent[index]?.content_url?.error} />

                          <TextField fullWidth margin="normal" label={<span>Content Name&nbsp;<span style={{color:'red'}}>*</span></span>} name="text" variant="outlined" placeholder='What do you want to call it?' InputLabelProps={{shrink: true}}  onChange={(e) => handleContentValueData('content_name', e.target.value, index)} value={item.ContentName} inputProps={{ maxLength: 40 }} onFocus={() => handlfocus(index, true)} onBlur={() => handlfocus(index, false)} error={nextTabErrors.gatedContent[index]?.content_name?.error !== ""}  helperText={<Box display="flex" justifyContent={"space-between"}>
                          <Box>
                            {nextTabErrors.gatedContent[index]?.content_name?.error == "" ? '' : nextTabErrors.gatedContent[index]?.content_name?.error}
                          </Box>
                            <Box component="span" className='charTxt' display={focused[index].content_name ? 'block' : 'none'}>
                              <Box component="span" >
                              {item.ContentName.length > 0 ? `${item.ContentName.length} / 40` : false}
                              </Box>
                            </Box>
                          </Box>
                          } FormHelperTextProps={{ style: { textAlign: (nextTabErrors.gatedContent[index]?.content_name?.error !== "") ? 'left' : 'right', color: (item.ContentName.length == 40 || nextTabErrors.gatedContent[index]?.content_name?.error !== "") ? 'red' : 'rgba(34, 51, 84, 0.7)' } }} />
                        </Box>
                        <Box sx={{mt:2,mb:2,borderTop: '2px dotted gray'}}>
                          {index == (urls.length - 1) && 
                            <Box display="flex" justifyContent="center" sx={{mt:3}}>
                              {err === ''?
                              <Box textAlign="center">
                                <Typography variant="body1" mb={1}>Click the "+" to add a new item (up to 10)</Typography>
                                <Button sx={{borderRadius: '50%', height:'60px', border: '1px dashed #1A75FF',fontWeight: '400', fontSize: '2.5rem', color: '#1A75FF',p:0}} display="flex" justifycontent="center" alignitems='center' onClick={() => addBox(index)}><AddIcon sx={{fontSize: '100%'}}/></Button>
                              </Box>
                              :
                              <Typography variant="body2" align="center" sx={{color:'#FF1A43'}}>{err}</Typography>
                              }
                            </Box>
                          }
                        </Box>
                      </Box>
                    )
                })}
            </Box>
        </Box>
    
    </>
  )
}

export default UrlForm