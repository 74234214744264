import React, { useEffect, useState } from 'react';
import { Modal,Box, Button,Typography, Card, LinearProgress, TextField, styled } from '@mui/material';
import { connect } from 'react-redux';
import { castVote } from '../../services/actions/api';
import { alphaHex, calculateTimeLeft } from '../../utils';


function PollResultModal(props) {
  const {open, handleClose, reward, info, user, claimNow, themeData} = props;

  const [basePmColor1, setBasePmColor1] = useState('rgba(26, 117, 255, 0.4)')

  useEffect(() => {
    const color1 = alphaHex(`#${user.data.brand_data.primary}`, 0.4)
    setBasePmColor1(color1)
  },[])
  const [apiCalled, setApiCalled] = useState(false)

  const [selectedChoice, setSelectedChoice] = useState('')
  const [voteRequest, setVoteRequest] = useState(false);
  const [comment,setComment] = useState('');

  const [entriesSubmitted, setEntriesSubmitted] = useState(false)

  const [voteResult, setVoteResult] = useState({})

  const [countdownTime, setCountdownTime]= useState(
    {
      countdownDays:'',
      countdownHours:'',
      countdownMinutes:'',
      countdownSeconds:'',
      countdownRemaining: true
    }
  );
  // console.log("reward =>",reward)

  const style = {
    overflowY: "auto",
    position: "absolute",
    top: "50%",
    maxHeight: "75vh",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "500px",
    bgcolor: "white",
    boxShadow: 24,
    px: 6,
    py: 4,
    borderRadius: "6px",
    zIndex: "3",
    border:props.visited && "2px solid rgba(0, 0, 0, 0.25)",
    boxShadow: props.visited && "3px 6px 8px rgba(0, 0, 0, 0.25)",
    height:props.visited && '80%'
  };
    
  useEffect(() => {
    if(Object.keys(reward.result).length > 0){
      setEntriesSubmitted(true);
      setVoteResult(reward.result);
      setSelectedChoice(reward.claim_data.claim_info.option)
    }
    if(reward.status != 1){
      setEntriesSubmitted(true);
      if(Object.keys(reward.result).length > 0){
        setVoteResult(reward.result)
      }else{
        setVoteResult({na: true})
      }
    }
  }, [])

  let timeIntrval;
  const countdownTimer = () => {
    timeIntrval = setInterval(() => {
      const tm = calculateTimeLeft(reward.end_date);
      setCountdownTime(tm)
    }, 1000);
  }

  useEffect(() => {
    if(reward.end_date != null && reward.end_date != ''){
        countdownTimer()
    }

    return () => {
        clearInterval(timeIntrval)
    }
  }, [reward.end_date])
  console.log("info =>",info)
  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="Edit Form Field" sx={{outline: 'none'}} onBackdropClick={()=>{return false}} className='preview-modal'>
      <Box sx={style} className='poll-reward-modal'>
        <Typography variant="h1" className="heading" sx={{fontFamily:"Poppins, sans-serif", fontWeight:"700", textAlign:"center", fontSize:"2rem", color: "#223354", wordBreak: "break-all"}}>{reward.title}</Typography>

        <Card sx={{ border: '1px solid #CCCEDD', bgcolor: "#F6F8FB", marginTop: "19px", px: 4, mx: 2, boxShadow:'0px 4px 21px rgba(24, 69, 136, 0.2)',borderRadius: "6px"}}>
          <Box sx={{ display: "flex", justifyContent: "center", paddingTop: "20px",}}>
            <Card sx={{ display: "flex", alignItems: 'center', bgcolor: "rgba(26, 117, 255, 0.05)", borderRadius: "30px", p: 1, boxShadow: "none",}}>
              <img src="/assets/img/poll-icon.svg" alt="" style={{ width: "19px" }} />
              <Typography variant="h6" className='merch-category'>Poll</Typography>
            </Card>
          </Box>
          <Box sx={{ background: 'rgba(34, 51, 84, 0.02)', borderRadius: '8px', p:2, mt:2}}>
            
            <Typography variant='body2' align='center' sx={{fontFamily:"Poppins, sans-serif", fontWeight:400,fontSize:"1.5rem", color:"#223354"}}>{(info.question)?.trim().at(-1) == "?" ? (info.question)?.trim() : `${(info.question)?.trim()}?`}</Typography>
            <Box sx={{background:"rgba(34, 51, 84, 0.2)", height:"1px", width:"120px", margin:"auto", marginTop: 1.25}}></Box>
            {Object.keys(voteResult).length > 0 &&
              <>
              {
                reward.status !== 1 &&
                <Typography variant='body1' sx={{fontFamily:"Inter, sans-serif", color:"#343957",fontSize:"14px"}} align='center' my={1}>Poll Ended: {new Date(reward.drop_end_time).toLocaleDateString()}</Typography>
              }
              </>
            }

            <Box mt={3}>
              {info.choices.map((c_data, index) => {
                return (
                  <Box key={index} sx={{ width: '100%' }} className={`linear-progress-bar ${Object.keys(voteResult).length > 0 ? 'result-given' : ''} ${selectedChoice == c_data ? 'selected' : ''}`}>
                    <Box>
                      <LinearProgress variant="determinate" value={Object.keys(voteResult).filter((item) => item == c_data).length > 0 ? voteResult[c_data] : '0'} sx={{height:"50px", borderRadius:"100px", background: Object.keys(voteResult).length > 0 ? '#fff': basePmColor1, '& span': {backgroundColor: basePmColor1}}}  >
                      </LinearProgress>
                    </Box>
                    <Box className='choices-result' display="flex" justifyContent={Object.keys(voteResult).length > 0 ? "space-between" : "center"}>
                      <Typography variant='body2' className='choice-text'> {c_data}</Typography>
                      {Object.keys(voteResult).length > 0 &&
                      <Box display="flex" alignItems="center">
                        {(entriesSubmitted && (reward.winner?.includes(c_data)) && reward.status == 2) &&
                          <img src='/assets/img/winner-trophy.svg' alt='trophy icon'/>
                        }
                        <Typography variant='body2' className='choice-text'> {Object.keys(voteResult).filter((item) => item == c_data).length > 0 ? voteResult[c_data]+"%" : '0%'}</Typography>
                      </Box>
                      }
                    </Box>
                  </Box>
                )
              })}
            </Box>

            {entriesSubmitted &&
              <Typography variant='h5' align='center' mt={2} sx={{fontFamily:"General Sans Medium", fontSize:"14px",color:"#000"}}>Total Votes: {reward.total_votes}</Typography>
            }

          </Box>
          <Card sx={{bgcolor:'white', boxShadow:'none', my:1 }}></Card>
        </Card>
        <Box sx={{ paddingTop: "20px", display: "flex", justifyContent: "center",}}>
          <Button color="secondary" onClick={props.handleClose} className='close-btn'>Close</Button>
        </Box>
      </Box>
    </Modal>
  )
}


const mapStateToProps = (state) => ({
  user: state.user
})

const mapDispatchToProps = {
}
  
export default connect(mapStateToProps, mapDispatchToProps)(PollResultModal)