import React from 'react';
import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import {PureLightTheme} from './PureLightTheme';

export const ThemeContext = React.createContext();

const ThemeProviderWrapper = function (props) {
  const theme = PureLightTheme
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        {props.children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default ThemeProviderWrapper;
