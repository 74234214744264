import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { deleteCategoryContent, duplicateCategoryContent } from "src/services/actions";
import DeleteReward from "../Modals/delete_reward";
import ClearIcon from '@mui/icons-material/Clear';
import { youtubeVideoId } from "src/utils";

const ContentPostDetail = (props) => {
  const [action, setAction] = useState("")
  const [detailPageData, setDetailPageData] = useState({type: "", data: {}, message: ""})
  const {slug, c_name} = useParams();
  const [contentDeleteModal, setContentDeleteModal] = useState(false);

  const [reactionsData, setReactionsData] = useState([
    {"reaction":"love","reactionCnt":"0"},
    {"reaction":"happy","reactionCnt":"0"},
    {"reaction":"neutral","reactionCnt":"0"},
    {"reaction":"thinking","reactionCnt":"0"},
    {"reaction":"unhappy","reactionCnt":"0"}
  ]);

  useEffect(() => {
    if (detailPageData.data?.reactions){
      let arr1 = reactionsData
      let arr2 = detailPageData.data.reactions
      
      const result = arr1.map(v => ({ ...v, ...arr2.find(sp => sp.reaction === v.reaction) }));

      setReactionsData(result)
    }
  },[detailPageData.data])

  const navigate = useNavigate()

  const emogList = [
    {
      icons: "/assets/images/emog1.png",
      number: " ",
    },
    {
      icons: "/assets/images/emog2.png",
      number: "123",
    },
    {
      icons: "/assets/images/emog3.png",
      number: "123",
    },
    {
      icons: "/assets/images/emog4.png",
      number: "123",
    },
    {
      icons: "/assets/images/emog5.png",
      number: "123",
    },
  ];


  useEffect(() => {
    if(slug){
      let ourData = props.categories.data.find((item) => item.slug == slug)
      if(ourData){
        setDetailPageData({
          type: "success",
          data: ourData,
          message: ""
        })
      }else{
        setDetailPageData({
          type: "error",
          data: {},
          message: "Something went wrong"
        })
      }
      // setDetailPageData
    }
  }, [slug])

  const deleteContent = () => {
    // setAction("delete")

    // console.log(detailPageData.data)
    // props.deleteCategoryContent(detailPageData.data.id, (res) => {
    //   if(res.type == "success"){
    //     navigate(`/category/${c_name}`, {
    //       replace: true
    //     })
    //   }
    // })
  }

  const duplicateAReward = () => {
    props.duplicateCategoryContent(detailPageData.data.id, (res) => {
      if(res.type == "success"){
        navigate(`/category/${c_name}/${res.data.slug}/edit`, {
          state: {
            postItem: res.data
          }
        })
      }
    })
  }

  return (
    <>
      {detailPageData.type == "success" ?
      <Box 
        className="scrollbar-hide rewards-section"
        width={"calc(100% - 259px)"}
        sx={{ padding: "40px", height: "calc(99vh - 80px)", overflowY: "auto", boxSizing: "border-box" }}
      >
      <Box component="section" sx={{ width: "1150px",  margin: "0 auto 0px" }}>
        <Box sx={{ display: "flex", alignItem: "center", justifyContent: "end", marginBottom: "20px" }} component={NavLink} to={`/category/${c_name}`}>
          <ClearIcon width="32px" />
        </Box>
        <Box sx={{ display: "flex", alignItem: "center", justifyContent: "end", marginBottom: "31px" }}>
          <Button variant="outlined" color="primary" className="font-gs-sb" sx={{ fontSize: "16px", marginRight: "20px", height: "41.81px", padding: "9.91px 22px", "&:hover": { background: "white" }}} onClick={() => {
            setContentDeleteModal(true)
          }}>Delete</Button>
          <Button variant="outlined" color="primary" className="font-gs-sb" sx={{ fontSize: "16px", marginRight: "20px", height: "41.81px", padding: "9.91px 22px", "&:hover": { background: "white" } }} to={`/category/${c_name}/${slug}/edit`} state={{postItem: detailPageData.data}} component={NavLink}>Edit</Button>
          <Button variant="outlined" color="primary" className="font-gs-sb" sx={{ fontSize: "16px",  height: "41.81px", padding: "9.91px 22px", "&:hover": { background: "white" }}} onClick={duplicateAReward}>Duplicate</Button>
        </Box>
        {/* Button List End */}

        {/* Card Start */}
        <Box
          sx={{
            padding: "30px 50px",
            minHeight: "640px",
            borderRadius: "6px",
            border: "1px solid black",
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              variant="h3"
              component="h3"
              sx={{
                fontFamily: "General Sans bold",
                fontWeight: "700",
                fontSize: "32px",
                lineHeight: "43.2px",
                color: "#2B2011",
              }}
            >
              {detailPageData.data.title}
            </Typography>
            {detailPageData.data.is_featured &&
            <Button
              variant="contained"
              className="font-gs-sb"
              sx={{
                color: "#2B2011",
                fontSize: "16px",
                padding: "5px 10px",
                background: "#ceefe8",
                height: "32px",
                "&:hover": {background: "#ceefe8",},
              }}
            >
              Featured
            </Button>
            }
          </Box>
          {
            detailPageData.data.type === 'video' ? 
            <iframe
            title="YouTube video player"
            width="100px"
            height="100px"
            src={`https://www.youtube.com/embed/${youtubeVideoId(detailPageData.data.video_url)}`}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            style={{ margin: "10px 0", height: "265px", width: "100%", maxWidth: '500px', objectFit: "cover", borderRadius: "8px", border: "1px solid #000" }}
          ></iframe>
          :
          detailPageData.data.image_url ?
          <Box sx={{ height: "234px", marginTop: "20px" }}>
            <img
              src={detailPageData.data.image_url ||  "/assets/images/recipes_det_img.svg"}
              alt={detailPageData.data.title}
              style={{  height: "100%" }}
            />
          </Box>
          :
          <Box sx={{ width: "388px", height: "234px", marginTop: "20px", background: "#ffebeb" }}>
          </Box>
          }
          <Box sx={{ marginTop: "20px" }}>
            <Typography
              variant="p"
              className="font-gs-md"
              sx={{ color: "#1A1616" }}
            >
              Date
            </Typography>
            <Typography
              variant="h3"
              className="font-gs-b"
              sx={{ color: "#1A1616" }}
            >
              {new Date(detailPageData.data.createdAt).toLocaleDateString()}
            </Typography>
            <div className="font-gs-md" style={{marginTop: "20px"}} dangerouslySetInnerHTML={{__html: detailPageData.data.content}}/>
            <Box py="10px" mt="20px" display="flex" justifyContent="start">
              
            {
                detailPageData.data?.reactions?.length > 0 &&
                <Box mt="10px">
                  <Grid container spacing="17.5px" justifyContent="center" alignItems="start">
                    {reactionsData.map((item,index) => {
                      return (
                        <Grid item key={index} sx={{display: "flex", flexDirection: 'column', alignItems: "center", justifyContent: 'start'}}>
                          <Box component="span">
                            <img src={`/icons/react-${item.reaction}.svg`} width="20" height="20" alt={item.reaction} />
                          </Box>
                          <Box component="span">{item.reactionCnt !== '0' ? item.reactionCnt : ' '}</Box>
                        </Grid>
                      )
                    })}
                  </Grid>
                </Box>
              }
            </Box>
          </Box>
        </Box>
        {/* Card End */}
      </Box>
      </Box>
      :
      <></>
      }

{contentDeleteModal && <DeleteReward dropId={detailPageData.data.id} c_name={c_name} type="post" open={contentDeleteModal} handleClose={() => setContentDeleteModal(false)}/>}
    </>
  );
};


const mapStateToProps = (state) => ({
  categories: state.categories
})

const mapDispatchToProps = {
  deleteCategoryContent,
  duplicateCategoryContent
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentPostDetail)