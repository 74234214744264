import React,{useState} from 'react'
import {connect} from 'react-redux';
import dayjs from 'dayjs';
import moment from 'moment-timezone';

import { Typography, Box, FormControlLabel, Snackbar, Alert, Checkbox } from '@mui/material';
import ClaimCard from '../ClaimCard/new';


function PublishingOption(props) {
  const {dropData, nextTabErrors, setNextTabErrors} = props;
  const [openCopiedBar , setOpenCopiedBar] = useState(false)
  const vertical =  'top';
  const horizontal = 'center';

  return (
    <>
      <Snackbar
        sx={{zIndex: '7 !important'}}
        anchorOrigin={{ vertical, horizontal }}
        open={openCopiedBar}
        autoHideDuration={5000}
        key={vertical + horizontal}
      >
        <Alert severity="success" sx={{ width: '100%' }}>Copied to clipboard!</Alert>
      </Snackbar>
      <Box className="publishing_options" pt={dropData.status == 1 ? 0 : 4}>
        {dropData.status !== 1 &&
          <Box>
            <Typography variant="body1" textAlign="center" className='subtitle' lineHeight="normal" mb="20px">CREATE REWARD</Typography>
            <Typography variant="h1" textAlign="center" sx={{mb: 3}} className='title'>Publish your reward</Typography>
          </Box>
        }

        {/* claim card preview */}
        <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 3}} className='claim-modal'>
          <ClaimCard reward={dropData} title={props.title} description={props.description} publishedDt={props.dropData.schedule_now ? new Date() : dropData.startAt} />
        </Box>

      </Box>
    </>
  )
}


const mapStateToProps = (state) => ({
  user: state.user,
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(PublishingOption)
