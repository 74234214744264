import React, { useState } from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { Box, Button, ImageList, ImageListItem, Link } from "@mui/material";
import UserBoxModal from "./UserBox";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import CategoryModal from "../Modals/category";
import VideoDropdown from "./Drop_Down";
import BasicMenu from "./Drop_Down";
import { connect } from "react-redux";
import { useEffect } from "react";

const RewardSidebar = (props) => {
  const [categoryModalOpen, setCategoryModalOpen] = useState(false)
  const [categoryActionType, setCategoryActionType] = useState(""); 
  const [allowedToAdd, setAllowedToAdd] = useState(false); 
  const [valueData, setValueData] = useState({name: "", title: "", id: "", slug: ""})

  const navigate = useNavigate()
  // const [showModal, setShowModal] = useState(false);
  const [listDatas, setListDatas] = useState([
    {
      imgs: "/assets/images/rewards.svg",
      name: "Rewards",
      title: "",
      href: "/rewards/membership",
      alt: "rewards",
      haveDropdown: false
    },
  ]);
  

  const returnArrayFromCat = (data) => {
    let array = [];

    for (let i = 0; i < data.length; i++) {
      array.push({
        id: data[i].id,
        imgs: "/assets/images/recipes.svg",
        name: data[i].name,
        title: data[i].title,
        href: `/category/${data[i].slug}`,
        alt: data[i].name,
        contentCnt: data[i].contentCnt,
        haveDropdown: allowedToAdd
      })
    }

    return [...listDatas, ...array]
  }

  const datalistsec = [
    {
      imgs: "/assets/images/membership.svg",
      title: "Membership Page",
      href: props.user.data.full_brand_domain,
      newTab: true,
    },
    {
      imgs: "/assets/images/setting.svg",
      title: "Settings",
      href: "/settings?tab=branding",
      newtab: false
    },
  ];

  const menuClicked = (data) => {
    setCategoryActionType("edit")
    setValueData({
      ...data
    })
    setCategoryModalOpen(true)
    // console.log("Id", data)
  }

  useEffect(() => {
    let flag = false;
    if (props.user.data.membership_program && props.user.data.membership_program?.role === 'admin') {
      flag = true;
    } else if (props.user.data?.other_data?.allow_membership_settings && props.user.data.membership_program && props.user.data.membership_program?.role === 'other') {
      flag = false;
    } else if (!props.user.data?.other_data?.allow_membership_settings)  {
      flag = false;
    } else {
      flag = true
    }
    
    setAllowedToAdd(flag);
  },[props.user.data])


  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection="column"
        height="calc(100vh - 225px)"
        // marginBottom="20px"
      >
        <Box>
          <List
            sx={{
              width: "100%",
              p: 0,
              borderBottom: "1px dashed black",
              paddingBottom: "10px",
            }}
            component="nav"
            aria-labelledby="nested-list-subheader"
          >
            {returnArrayFromCat(props.user.data.categories).map((v, i) => (
              <ListItemButton
                key={i}
                className="sidebar_focused"
                sx={
                  {
                    marginBottom: "10px",
                    padding: "5px 10px",
                    height: "37px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    background: window.location.pathname == v.href ? "#F3F3F3" : "",
                    "&:hover": {
                      color: "#000",
                      backgroundColor: "#F3F3F3",
                      fontWeight: "700",
                      fontSize: "20px",
                      cursor: "pointer"
                    },
                    ".MuiListItemText-root .MuiTypography-root": {
                        fontFamily: window.location.pathname == v.href ? "General Sans bold" : "General Sans Medium",
                      }
                  }}
                onClick={() => {
                  navigate(v.href)
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }} 
                className="sidebar_focused"
                >
                  <Box
                    sx={{
                      width: "20px",
                      height: "20px",
                      marginRight: "10px",
                    }}
                  >
                    <img src={v.imgs} alt={v.alt} loading="lazy" />
                  </Box>

                  <ListItemText sx={
                  {
                    "span": {
                      fontFamily: "General Sans Medium",
                      fontWeight: "500",
                      fontSize: "20px",
                      cursor: "pointer",
                      color: "black",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      width: "130px"
                    }
                  }}>
                      {v.name}
                  </ListItemText>
                </Box>
                {v.haveDropdown &&
                   <Box
                   component="span"
                   id="action-menu"
                   className="basic_manu"
                   sx={[{ position: "relative", padding: "0px" }]}
                   onClick={(event) => {
                    event.stopPropagation()
                   }}
                 >
                   <BasicMenu menuClicked={(id) => {
                    console.log(v.name)
                    menuClicked({
                      id,
                      name: v.name,
                      title: v.title,
                      slug: v.href
                    })
                   }} content_count={v.contentCnt} name={v.name} id={v.id} slug={v.href}/>
                 </Box>
                }
              </ListItemButton>
            ))}
        {
          (allowedToAdd) &&
          <Button onClick={() => { setValueData({name: "", title: "", id: ""}); setCategoryActionType("add"); setCategoryModalOpen(true); }} className="font-gs-md" sx={[ { height: "37px", fontWeight: "500", fontSize: "20px", cursor: "pointer", color: "black", marginBottom: "10px", px:"10px", width: "100%", justifyContent: "flex-start", "&:hover": { backgroundColor: "#F3F3F3", fontSize: "20px", cursor: "pointer", textDecoration: "none" } } ]}>
            <ImageList variant="masonry" sx={{ margin: "0px", marginRight: "10px" }} style={{ columnCount: "0px", columnGap: "0px" }}>
              <ImageListItem sx={{ width: "auto", height: "20.5px" }}>
                <img src="/assets/images/category.svg" alt="category" loading="lazy" />
              </ImageListItem>
            </ImageList>
            Add Category
          </Button>
        }
        {categoryModalOpen &&
          <CategoryModal type={categoryActionType} value={valueData} open={categoryModalOpen} handleClose={() => setCategoryModalOpen(false)}/>
        }
          </List>

          <List sx={{ width: "100%", p: 0, marginTop: "20px" }} component="nav" aria-labelledby="nested-list-subheader">
            {
              (allowedToAdd) &&
            <ListItemButton className="sidebar_focused" sx={{ marginBottom: "10px", padding: "5px 10px", height: "37px", display: "flex", justifyContent: "space-between", alignItems: "center", background: "none" , "&:hover": { color: "#000", backgroundColor: "#F3F3F3", fontWeight: "700", fontSize: "20px", cursor: "pointer" }, ".MuiListItemText-root .MuiTypography-root": { fontFamily: "General Sans Medium" } }} onClick={() => { window.open(`${props.user.data.full_brand_domain}/login`, '_blank') }}>
              <Box sx={{ width: "auto", height: "20.5px", marginRight: "10px" }}>
                <img src={`/assets/images/membership.svg?w=248&fit=crop&auto=format`} alt={'Membership Page'} loading="lazy" />
              </Box>

              <ListItemText sx={ { "span": { fontFamily: "General Sans Medium", fontWeight: "500", fontSize: "20px", cursor: "pointer", color: "black" } }}>
                Membership Page
              </ListItemText>
            </ListItemButton>
            }

            <ListItemButton className="sidebar_focused" sx={{ marginBottom: "10px", padding: "5px 10px", height: "37px", display: "flex", justifyContent: "space-between", alignItems: "center", background: "none" , "&:hover": { color: "#000", backgroundColor: "#F3F3F3", fontWeight: "700", fontSize: "20px", cursor: "pointer" }, ".MuiListItemText-root .MuiTypography-root": { fontFamily: "General Sans Medium" } }} onClick={() => { navigate(allowedToAdd ? '/settings?tab=branding' : '/settings') }}>
                <Box sx={{ width: "auto", height: "20.5px", marginRight: "10px" }}>
                  <img src={`/assets/images/setting.svg?w=248&fit=crop&auto=format`} alt="Settings" loading="lazy" />
                </Box>

                <ListItemText sx={ { "span": { fontFamily: "General Sans Medium", fontWeight: "500", fontSize: "20px", cursor: "pointer", color: "black" } }}>
                  Settings
                </ListItemText>
              </ListItemButton>
          </List>
        </Box>
        <Box>
          <UserBoxModal />
        </Box>
      </Box>
    </>
  );
};


const mapStateToProps = (state) => ({
  user: state.user,
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(RewardSidebar)