import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { ImageList, ImageListItem, TextField } from "@mui/material";
import { connect } from "react-redux";
import { deleteContentCategory } from "src/services/actions";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  borderRadius: "6px",
};

function DeleteCategoryModal(props) {
  const {id, open, handleClose, content_count, name, slug} = props
  const [apiCalled, setApiCalled] = React.useState(false);

  const navigate = useNavigate()

  const deleteCategory = () => {
    setApiCalled(true);
    props.deleteContentCategory(id, (res) => {
      console.log(res)
      setApiCalled(false);
      if(res.type =="success"){
        handleClose(true)
        if(window.location.pathname == slug){
          navigate("/")
        }
      }
    })
  }

  return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style} paddingTop="50px">
            <Typography
              id="transition-modal-title"
              sx={{
                color: "#1A1616",
                fontWeight: "700",
                fontSize: "32px",
                lineHeight: "43.2px",
                fontFamily: "General Sans bold",
              }}
              textAlign="center"
              variant="h3"
              component="h2"
            >
              Delete Category
            </Typography>
            <Box sx={{ margin: "20px 40px",}}>
              <Box>
                <Typography component="p" className="font-gs-md" sx={{fontSize:"25px"}}>
                  Are you sure you want to delete category “{name}”?
                </Typography>
                <Typography component="p" className="font-gs-md" sx={{fontSize:"25px", color:"#ED1F23", }}>
                <b className="font-gs-sb">Warning:</b> There are {content_count} posts in this category. Deleting this cateogry will also delete the posts.
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                background: "#f9f9f9",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "400px",
                  margin: "30px auto",
                  background: "#f9f9f9",
                  height: "41.81px",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    fontFamily: "Roboto, sans-serif",
                    fontWeight: "700",
                    fontSize: "15px",
                    marginRight: "20px",
                  }}
                  onClick={handleClose}
                  disabled={apiCalled}
                >
                  Cancel
                </Button>
                <Button
                  variant="text"
                  color="error"
                  sx={{
                    fontFamily: "Roboto, sans-serif",
                    fontWeight: "700",
                    fontSize: "15px",
                  }}
                  onClick={() => {
                    if(!apiCalled){
                      deleteCategory()
                    }
                  }}
                >
                  {apiCalled ?
                  "Deleting..."  
                  :
                  "Confirm"
                }
                </Button>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
  );
}



const mapStateToProps = (state) => ({
})

const mapDispatchToProps = {
  deleteContentCategory
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteCategoryModal)