// for making helper functions

import moment from "moment";
import ReactGA from "react-ga4";
import { createEvent } from "ics";
import { saveAs } from "file-saver";
import { alpha } from "@mui/material";
import ColorContrastChecker from "color-contrast-checker";
import XIcon from '@mui/icons-material/X';
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/FacebookOutlined";
import YouTubeIcon from "@mui/icons-material/YouTube";
import Compressor from "compressorjs";

export function ValidateEmail(email) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export const validatePhone = (input) => {
  // Basic phone pattern (like (123) 456-7890 or 123-456-7890)
  const phonePattern = /^(\+\d{1,2}\s?)?1?\-?\.?\(?\d{3}\)?[\-.\s]?\d{3}[\-.\s]?\d{4}$/;

  return phonePattern.test(input);
};

export const validateAlphaNumStr = (input) => {
  // Regular expression to match only alphanumeric characters and spaces
  const titleRegex = /^[a-zA-Z0-9 .,' -]+$/;

  // Test the title against the regular expression
  return input && titleRegex.test(input);
}

export const validateVideoUrl = (input) => {
  // Regular expression for YouTube URLs
  const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/;
  
  // Regular expression for Vimeo URLs
  const vimeoRegex = /^(https?:\/\/)?(www\.)?vimeo\.com\/.+$/;
  
  // Test the URL against both regular expressions
  const isYouTube = youtubeRegex.test(input);
  const isVimeo = vimeoRegex.test(input);
  
  // Return true if it's a YouTube or Vimeo URL, otherwise return false
  return isYouTube || isVimeo;
}

export function validatGA(m_id) {
  var regexp = /^(G|UA|YT|MO)-[a-zA-Z0-9-]+$/;
  return regexp.test(m_id);
}
export const getVideoThumbnail = (videoUrl) => {
  const youtubeRegex = /(?:youtube\.com\/watch\?v=|youtu\.be\/)([^&]+)/;
  const vimeoRegex = /vimeo\.com\/(\d+)/;

  // Match the YouTube ID
  const youtubeMatch = videoUrl.match(youtubeRegex);
  if (youtubeMatch && youtubeMatch[1]) {
    const youtubeId = youtubeMatch[1];
    return `https://img.youtube.com/vi/${youtubeId}/maxresdefault.jpg`;
  }

  // Match the Vimeo ID
  const vimeoMatch = videoUrl.match(vimeoRegex);
  if (vimeoMatch && vimeoMatch[1]) {
    const vimeoId = vimeoMatch[1];
    return `https://vumbnail.com/${vimeoId}_large.jpg`;
  }

  // Return null if the URL is invalid
  return null;
}

export const youtubeVideoId = (url) => {
  const urlPatterns = [
    /(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([a-zA-Z0-9_-]{11})/,   // Standard watch URL
    /(?:https?:\/\/)?(?:www\.)?youtu\.be\/([a-zA-Z0-9_-]{11})/,                // Shortened URL
    /(?:https?:\/\/)?(?:www\.)?youtube\.com\/embed\/([a-zA-Z0-9_-]{11})/,      // Embed URL
    /(?:https?:\/\/)?(?:www\.)?youtube\.com\/v\/([a-zA-Z0-9_-]{11})/            // Old embed URL
  ];
  
  for (const pattern of urlPatterns) {
    const match = url.match(pattern);
    if (match) {
      return match[1];
    }
  }
  
  return null; // No valid video ID found
} 

export async function copyText(text) {
  await window.navigator.clipboard.writeText(text);
}

export function showUTCTime(date_time) {
  //   Fri, December 10th 15:00
  return moment.utc(date_time).format("ddd, MMMM Do HH:mm");
}

export function showUTCTime2(date_time) {
  //   December 10th, 2022
  return moment.utc(date_time).format("MMMM Do, YYYY");
}

export function showUTCTime3(date_time) {
  //   Friday 11/10
  return moment.utc(date_time).format("LL");
}

export function addToCalendar(drop) {
  let url = `https://calendar.google.com/calendar/render?action=TEMPLATE&text=PX Drop - ${encodeURI(drop.dropName)}&dates=${encodeURI(drop.drp_date_time_utc.replace(/-/g, "").replace(/:/g, ""))}/${encodeURI(drop.drp_date_time_utc.replace(/-/g, "").replace(/:/g, ""))}&details=${encodeURI(drop.about_sponsor)}`;
  //  let url =`https://calendar.google.com/calendar/render?action=TEMPLATE&text=PX Drop&dates=${encodeURI(drop.drp_date_time_utc)}/${encodeURI(drop.drp_date_time_utc)}&details=With%20clowns%20and%20stuff&location=North%20Pole`
  window.open(url);
}

export const GPageView = (title, path) => {
  if (window.gtag) {
    window.gtag("config", process.env.REACT_APP_GOOGLE_ANALYTICS_ID, { page_title: title, page_path: path });
  }
};

export const GPageEvent = (name, label, category) => {
  if (window.gtag) {
    window.gtag("event", name, {
      event_label: label,
      event_category: category,
      non_interaction: true,
    });
  }
};

export const GScreenView = (screen_name) => {
  if (window.gtag) {
    window.gtag("event", "screen_view", {
      app_name: "Ape Island Season 2",
      screen_name: screen_name,
    });
  }
};

export const strTosnakeCase = (str) => {
  return str.replace(/\s/g, "_").toLowerCase();
};

export const trimStr = (string, length) => {
  if (typeof string == "undefined" || string == null) {
    return "";
  }
  return string.length > length ? string.substring(0, length) + "..." : string;
};

export const snakeCaseToCamels = (str) => {
  return str.replace(/_([a-z])/g, (g) => g[1].toUpperCase());
};

export const humanize = (str) => {
  var i,
    frags = str.split("_");
  for (i = 0; i < frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
  }
  return frags.join(" ");
};

export const claimPgUrl = (user_data, pg_type) => {
  let brand_domain = user_data.brand_domain == null || user_data.brand_domain == "" ? `https://${user_data.brand_handle}${process.env.REACT_APP_BRAND_SUBDOMAIN}` : `${user_data.brand_domain.includes("http") ? "" : "https://"}${user_data.brand_domain}`;
  let pgUrl;
  switch (pg_type) {
    case "community":
      pgUrl = `${brand_domain}`;
      break;
    case "membership":
      pgUrl = `${brand_domain}/member`;
      break;
    default:
      pgUrl = brand_domain;
      break;
  }
  return pgUrl;
};

export const converDateToRemainingDays = (date) => {
  // if (date == null || date == '') {return null}
  let current_dt = new Date(moment());
  let dt = new Date(moment(date));
  let enddt = moment(date);

  let diff = Math.floor((dt - current_dt) / 86400000);
  let start = moment();
  var end = moment().add(diff, "days");

  var years = end.diff(start, "year");
  start.add(years, "years");

  var months = end.diff(start, "months");
  start.add(months, "months");

  var week = end.diff(start, "week");
  start.add(week, "week");

  var duration = moment.duration(enddt.diff(start));
  var hours = duration.asHours();
  var minutes = parseInt(duration.asMinutes()) % 60;
  var seconds = parseInt(duration.asSeconds()) % 60;

  var days = end.diff(start, "days");
  start.add(days, "days");

  if (years > 0) {
    return years == 1 ? years + " Year" : years + " Years";
  } else if (months > 0) {
    return months == 1 ? months + " Month" : months + " Months";
  } else if (week > 0) {
    return week == 1 ? week + " Week" : week + " Weeks";
  } else if (days > 0) {
    return days == 1 ? days + " Day" : days + " Days";
  } else if (days == 0 && parseInt(hours) > 0) {
    return parseInt(hours) == 1 ? parseInt(hours) + " Hour" : parseInt(hours) + " Hours";
  } else if (parseInt(hours) == 0 && minutes > 0) {
    return minutes == 1 ? minutes + " Minute" : minutes + " Minutes";
  } else if (parseInt(minutes) == 0 && seconds > 0) {
    return seconds == 1 ? seconds + " Second" : seconds + " Seconds";
  } else {
    return "";
  }
};

export function viewUTCTime(date_time) {
  return new Date(date_time).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
}

export const viewDate = (date) => {
  let d = new Date(date);
  let month = (d.getMonth() + 1).toString();
  let day = d.getDate().toString();
  let year = d.getFullYear();
  if (month.length < 2) {
    month = "0" + month;
  }
  if (day.length < 2) {
    day = "0" + day;
  }
  // return [year, month, day].join('-');
  return `${month}/${day}/${year}`;
};

export const viewDtTime = (date_time) => {
  return moment(date_time).format("MM/DD/YYYY hh:mma");
};

export const viewDateTime = (date) => {
  try {
    var now = new Date(date);
    /* var dateStr =
      ("00" + (now.getMonth() + 1)).slice(-2) + "/" +
      ("00" + now.getDate()).slice(-2) + "/" +
      now.getFullYear() + " " +
      ("00" + now.getHours()).slice(-2) + ":" +
      ("00" + now.getMinutes()).slice(-2); */
    return now.toLocaleString("en-US", { hour12: true });
  } catch (e) {
    return date;
  }
};

export const formatDateToDbSup = (date) => {
  try {
    var now = new Date(date);
    var offset = now.getTimezoneOffset() * 60000;
    var adjustedDate = new Date(now.getTime() - offset);
    var formattedDate = adjustedDate.toISOString().substring(0, 16); // For minute precision
    return moment(formattedDate);
  } catch (e) {
    return date;
  }
};

export const formatMomentObjectToDate = (date) => {
  try {
    var formattedDate = moment(date);
    return formattedDate;
  } catch (e) {
    return date;
  }
};

export const formatMomentObjectToTime = (date) => {
  try {
    var formattedDate = moment(date);
    return formattedDate;
  } catch (e) {
    return date;
  }
};

export const formatNum = (num) => {
  let nf = new Intl.NumberFormat("en-US");
  return nf.format(num) == "NaN" ? "N/A" : nf.format(num);
};

export const validateEmailOrPhone = (input) => {
  // Basic email pattern
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{1,6}$/;

  // Basic phone pattern (like (123) 456-7890 or 123-456-7890)
  const phonePattern = /^(\+\d{1,2}\s?)?1?\-?\.?\(?\d{3}\)?[\-.\s]?\d{3}[\-.\s]?\d{4}$/;

  return emailPattern.test(input) || phonePattern.test(input);
};

export const isValidHttpUrl = (string) => {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
};

export const networkSym = (network) => {
  let symbol_name;
  if (network == "80001" || network == "137") {
    symbol_name = "MATIC";
  } else if (network == "43113" || network == "43114") {
    symbol_name = "AVAX";
  } else {
    symbol_name = "ETH";
  }
  return symbol_name;
};

export const getSocialIcon = (social_item, size = "medium", color = "#000") => {
  if (social_item === "Twitter") {
    return <XIcon sx={{ color: color }} />;
  } else if (social_item === "Instagram") {
    return <InstagramIcon sx={{ color: color }} />;
  } else if (social_item === "YouTube") {
    return <YouTubeIcon sx={{ color: color }} />;
  } else if (social_item === "Tiktok") {
    return <img src="/assets/img/tiktok.svg" width="20" alt="" />;
  } else if (social_item === "Discord") {
    return <img src="/assets/img/discord.svg" width="20" alt="" />;
  } else {
    return <FacebookIcon sx={{ color: color }} />;
  }
};

export const socialActivities = {
  Twitter: [
    {
      parent: "Twitter",
      key_name: "twitter_follow",
      heading: "Follow",
      label: "Twitter Page",
      placeholder: "Enter the account for users to follow.",
      input_name: "twitter_follow",
      input_count: 2,
    },
    {
      parent: "Twitter",
      key_name: "twitter_like",
      heading: "Like",
      label: "Tweet Link",
      placeholder: "Enter the URL of the Tweet for users to like.",
      input_name: "twitter_like",
      input_count: 1,
      link_text: "Tweet"
    },
    {
      parent: "Twitter",
      key_name: "twitter_retweet",
      heading: "Retweet",
      label: "Tweet Link",
      placeholder: "Enter the URL of the Tweet for users to retweet.",
      input_name: "twitter_retweet",
      input_count: 1,
      link_text: "Tweet"
    },
  ],
  Instagram: [
    {
      parent: "Instagram",
      key_name: "instagram_view",
      heading: "View",
      label: "Profile URL",
      placeholder: "Enter the URL for your Instagram profile page",
      input_name: "instagram_view",
      input_count: 1,
      link_text: "Profile"
    },
  ],
  YouTube: [
    {
      parent: "YouTube",
      key_name: "youtube_view",
      heading: "View",
      label: "Channel URL",
      placeholder: "Enter the URL for your YouTube Channel",
      input_name: "youtube_view",
      input_count: 1,
      link_text: "Channel"
    },
  ],
  Tiktok: [
    {
      parent: "Tiktok",
      key_name: "tiktok_view",
      heading: "View",
      label: "User URL",
      placeholder: "Enter the URL for your TikTok user",
      input_name: "tiktok_view",
      input_count: 1,
      link_text: "User"
    },
  ],
  Discord: [
    {
      parent: "Discord",
      key_name: "discord_join_server",
      heading: "Join server",
      label: "Discord server",
      placeholder: "Enter the Discord server URL  for users to join  ",
      input_name: "discord_join_server",
      info: `To learn where to find your Server ID to set up this task, <a href="https://help.step3.io/" target="_blank">watch the video</a> in our support center.`,
      link_text: "Join",
      inputs: [
        {
          label: "Discord server URL",
          placeholder: "Enter the Discord server URL  for users to join  ",
          input_name: "server_url",
        },
        {
          label: "SERVER ID",
          placeholder: "Enter the Discord ID",
          input_name: "server_id",
        },
      ],
      input_count: 1,
    },
  ],
  Facebook: [
    {
      parent: "Facebook",
      key_name: "facebook_view",
      heading: "View",
      label: "Facebook PAGE URL",
      placeholder: "Enter the URL for your Facebook Page",
      input_name: "facebook_view",
      input_count: 1,
      link_text: "Page"
    },
  ],
};

export const socialActivitiesObjs = () => {
  let obj = {};
  let a = Object.values(socialActivities)
    .map((c) => c.map((s) => s.input_name))
    .join(",")
    .split(",");
  a.map((item) => (obj[item] = ""));
  return obj;
};

export const dropTypesData = [
  {
    icon: "/assets/img/merch-icon.svg",
    img_src: "/assets/img/merch.png",
    title: "Merchandise",
    short_name: "merchandise",
    description: "Offer physical merchandise like t-shirts, plushies or any other item you can self-fulfill.",
  },
  {
    icon: "/assets/img/coupon-icon.svg",
    img_src: "/assets/img/coupon.png",
    title: "Coupon Code",
    short_name: "coupon_code",
    description: "Offer discounts or free products from an e-commerce store.",
  },
  {
    icon: "/assets/img/gated-icon.svg",
    img_src: "/assets/img/gated.png",
    title: "Gated Content",
    short_name: "gated_content",
    description: "Give exclusive access to digital content like music, video or PDFs.",
  },
  {
    icon: "/assets/img/event-icon.svg",
    img_src: "/assets/img/event.png",
    title: "Event Pass",
    short_name: "event_pass",
    description: "Create an event pass for a live event and check in attendees at the door.",
  },
  {
    icon: "/assets/img/allow-icon.svg",
    img_src: "/assets/img/allow.png",
    title: "Allow List",
    short_name: "allow_list",
    description: "Give exclusive access to an upcoming NFT mint.",
  },
  {
    icon: "/assets/img/nft-icon.svg",
    img_src: "/assets/img/nft.png",
    title: "Digital Collectables",
    short_name: "nfts",
    description: "Offer limited edition art, game items, and digital goods.",
  },
  {
    icon: "/assets/img/token-icon.svg",
    img_src: "/assets/img/reward.png",
    title: "Reward Tokens",
    short_name: "reward_tokens",
    description: "Create reward tokens used to redeem benefits in other Web3 applications.",
  },
  {
    icon: "/assets/img/raffle.svg",
    img_src: "/assets/img/raffle.png",
    title: "Raffle",
    short_name: "raffle",
    description: "Create a giveaway for any reward or experience you want to offer.",
  },
  {
    icon: "/assets/img/poll-icon.svg",
    img_src: "/assets/img/poll.png",
    title: "Poll",
    short_name: "poll",
    description: "Collect responses, make informed decisions and let your community’s voices be heard.",
  },
  {
    icon: "/assets/img/custom-reward.svg",
    img_src: "/assets/img/custom-reward.png",
    title: "Custom",
    short_name: "custom",
    description: "Bring your ideas and creativity. We’ll provide the form fields...",
  },
];

export const limitText = (string, length, end = "...") => {
  return string.length < length ? string : string.substring(0, length) + end;
};

export function gaEvent(data) {
  // ReactGA.event({...data});
}

export function gtag(event_name, data) {
  if (data.eth_address) {
    data.eth_address = "-" + data.eth_address + "-";
  }
  ReactGA.gtag("event", event_name, data);
}

export function convertToInternationalCurrency(value) {
  if (value >= 1000000) {
    value = +parseFloat((value / 1000000).toFixed(1)) + "M";
  } else if (value >= 1000) {
    value = parseFloat((value / 1000).toFixed(1)) + "K";
  }
  return value;
}

export function calculateTimeLeft(dt_time) {
  const countdownDateTime = new Date(dt_time).getTime();
  const currentTime = new Date().getTime();
  const remainingDayTime = countdownDateTime - currentTime;
  const totalDays = Math.floor(remainingDayTime / (1000 * 60 * 60 * 24));
  const totalHours = Math.floor((remainingDayTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const totalMinutes = Math.floor((remainingDayTime % (1000 * 60 * 60)) / (1000 * 60));
  const totalSeconds = Math.floor((remainingDayTime % (1000 * 60)) / 1000);

  let runningCountdownTime;

  if (totalDays > 0 || totalHours > 0 || totalMinutes > 0 || totalSeconds > 0) {
    runningCountdownTime = {
      countdownDays: totalDays < 10 ? "0" + totalDays : totalDays,
      countdownHours: totalHours < 10 ? "0" + totalHours : totalHours,
      countdownMinutes: totalMinutes < 10 ? "0" + totalMinutes : totalMinutes,
      countdownSeconds: totalSeconds < 10 ? "0" + totalSeconds : totalSeconds,
    };
  } else {
    runningCountdownTime = {
      countdownDays: "00",
      countdownHours: "00",
      countdownMinutes: "00",
      countdownSeconds: "00",
    };
  }

  return runningCountdownTime;
}

export const createIcsFile = (data, type) => {
  console.log("data, type", data, type);
  // console.log("dropsDatadropsDatadropsData", data.title)
  // console.log("dropsDatadropsDatadropsData", data.details)
  let eventYear, eventMonth, eventDate;
  let file_name = `Step3 reward: ${data.title}`;
  let eventData;

  if (type == "end_date") {
    file_name = `Step3 reward: ${data.title} ends`;
    eventYear = new Date(data.published_date).getFullYear();
    eventMonth = new Date(data.published_date).getMonth() + 1;
    eventDate = new Date(data.published_date).getDate();
    let eventHour = new Date(data.published_date).getHours();
    let eventMin = new Date(data.published_date).getMinutes();

    let eventEndYear = new Date(data.end_date).getFullYear();
    let eventEndMonth = new Date(data.end_date).getMonth() + 1;
    let eventEndDate = new Date(data.end_date).getDate();
    let eventEndHour = new Date(data.end_date).getHours();
    let eventEndMin = new Date(data.end_date).getMinutes();

    eventData = {
      startInputType: "local",
      endInputType: "local",
      startOutputType: "local",
      endOutputType: "local",
      start: [eventYear, eventMonth, eventDate, eventHour, eventMin],
      end: [eventEndYear, eventEndMonth, eventEndDate, eventEndHour, eventEndMin],
      title: `Step3 reward: ${data.title} ends`,
      description: data.details ? `${data.details} ${process.env.REACT_APP_BASE_API_URL}` : `${process.env.REACT_APP_BASE_API_URL}`,
    };
    console.log("eventData =>", eventData);
  } else if (type == "event") {
    eventYear = new Date(data.drop_type_data[0].event_date).getFullYear();
    eventMonth = new Date(data.drop_type_data[0].event_date).getMonth() + 1;
    eventDate = new Date(data.drop_type_data[0].event_date).getDate();

    let eventEndYear = new Date(data.end_date).getFullYear();
    let eventEndMonth = new Date(data.end_date).getMonth() + 1;
    let eventEndDate = new Date(data.end_date).getDate();

    eventData = {
      startInputType: "local",
      endInputType: "local",
      startOutputType: "local",
      endOutputType: "local",
      start: [eventYear, eventMonth, eventDate],
      end: [eventEndYear, eventEndMonth, eventEndDate],
      title: `The event ${data.title} begins`,
      description: data.drop_type_data[0].details ? `${data.drop_type_data[0].details} ${process.env.REACT_APP_BASE_API_URL}` : `${process.env.REACT_APP_BASE_API_URL}`,
    };
    file_name = `The event ${data.title} begins`;
  } else if (type == "start_date") {
    eventYear = new Date(data.published_date).getFullYear();
    eventMonth = new Date(data.published_date).getMonth() + 1;
    eventDate = new Date(data.published_date).getDate();
    let eventHour = new Date(data.published_date).getHours();
    let eventMin = new Date(data.published_date).getMinutes();

    let eventEndYear = new Date(data.end_date).getFullYear();
    let eventEndMonth = new Date(data.end_date).getMonth() + 1;
    let eventEndDate = new Date(data.end_date).getDate();
    let eventEndHour = new Date(data.end_date).getHours();
    let eventEndMin = new Date(data.end_date).getMinutes();

    console.log("eventEndDate-start =>", [eventYear, eventMonth, eventDate]);
    console.log("eventEndDate-end =>", [eventEndYear, eventEndMonth, eventEndDate]);

    eventData = {
      startInputType: "local",
      endInputType: "local",
      startOutputType: "local",
      endOutputType: "local",
      start: [eventYear, eventMonth, eventDate, eventHour, eventMin],
      title: data.end_date ? `Step3 reward: ${data.title} ends` : `Step3 reward: ${data.title} begins`,
      description: data.details ? `${data.details} ${process.env.REACT_APP_BASE_API_URL}` : `${process.env.REACT_APP_BASE_API_URL}`,
    };
    if (data.end_date) {
      file_name = `Step3 reward: ${data.title} ends`;
      eventData["end"] = [eventEndYear, eventEndMonth, eventEndDate, eventEndHour, eventEndMin];
    }
  }
  createEvent(eventData, (error, value) => {
    console.log("value =>", value);
    const blob = new Blob([value], { type: "text/plain;charset=utf-8" });
    saveAs(blob, `${file_name}.ics`);
  });
};

export const allowedCustomWallet = (wallet_type) => {
  return ["custom_wallet", "apple", "google", "email"].includes(wallet_type);
};

export const alphaHex = (bg, value) => {
  let c = alpha(bg, value);
  let color = rgba2hex(c);
  return color;
};

function rgba2hex(orig) {
  var a,
    isPercent,
    rgb = orig.replace(/\s/g, "").match(/^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i),
    alpha = ((rgb && rgb[4]) || "").trim(),
    hex = rgb ? (rgb[1] | (1 << 8)).toString(16).slice(1) + (rgb[2] | (1 << 8)).toString(16).slice(1) + (rgb[3] | (1 << 8)).toString(16).slice(1) : orig;

  if (alpha !== "") {
    a = alpha;
  } else {
    a = "01";
  }
  // multiply before convert to HEX
  a = ((a * 255) | (1 << 8)).toString(16).slice(1);
  hex = hex + a;

  return "#" + hex;
}

export const getTextColor = (background, text_color) => {
  var ccc = new ColorContrastChecker();
  if (ccc.isLevelAA(background.substr(0, 7), text_color.substr(0, 7), 18) == false) {
    if (text_color == "#ffffff") {
      return "#000000";
    } else {
      return "#ffffff";
    }
  } else {
    return text_color;
  }
};

export const ahex_to_rba = (ahex) => {
  //clean #
  if (ahex == "" || ahex == null || ahex == undefined) {
    return;
  }
  ahex = ahex.substring(1, ahex.length);
  ahex = ahex.split("");

  var r = ahex[0] + ahex[0],
    g = ahex[1] + ahex[1],
    b = ahex[2] + ahex[2],
    a = ahex[3] + ahex[3];

  if (ahex.length >= 6) {
    r = ahex[0] + ahex[1];
    g = ahex[2] + ahex[3];
    b = ahex[4] + ahex[5];
    a = ahex[6] + (ahex[7] ? ahex[7] : ahex[6]);
  }

  var int_r = parseInt(r, 16),
    int_g = parseInt(g, 16),
    int_b = parseInt(b, 16),
    int_a = parseInt(a, 16);

  int_a = int_a / 255;

  if (int_a < 1 && int_a > 0) int_a = int_a.toFixed(2);

  if (int_a || int_a === 0) return "rgba(" + int_r + ", " + int_g + ", " + int_b + ", " + int_a + ")";
  return int_r + ", " + int_g + ", " + int_b;
};

export const checkImageFileExt = (e) => {
  let allowed_extentions = ["png", "svg", "jpg", "webp", "jpeg", "gif", "svg+xml"];
  let ext;
  if (e.target.files) {
    ext = e.target.files[0].name.split(".").pop();
  }

  return allowed_extentions.includes(ext) ? true : false;
};

export const validateImgDimension = (file, width, height, minWidth, minHeight) => {
  return new Promise((resolve, reject) => {

    var reader = new FileReader();

    reader.onload = function() {
        var image = new Image();
        var fWidth = minWidth;
        var fHeight = minHeight;

        image.src = reader.result;

        image.onload = function() {
          if (image.width >= width && image.height >= height) {
            if (image.width >= (fWidth*2) || image.height >= (fHeight*2)) {
              fWidth = fWidth*2
              fHeight = fHeight*2
            }
            resolve({type: 'success', fileWidth: fWidth, fileHeight: fHeight})
          } else {
            resolve({type: 'error'})
          }
        };

    };

    reader.readAsDataURL(file);

  })
}
export const handleImageForCompress = (file, maxWidth = 1000, maxHeight = 1000) => {
  return new Promise((resolve, reject) => {
    // Create a FileReader instance
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64String = reader.result;
    };
  
    reader.readAsDataURL(file);    
    // Use the "compressorjs" library to compress the image
    new Compressor(file, {
      quality: 1,
      maxWidth,
      // maxHeight,
      success(result) {
        reader.onloadend = () => {
          const base64String = reader.result;
          // console.log("base64String ===>",base64String);
          resolve(base64String)
          // setCompressedImage(base64String);
        };
        reader.readAsDataURL(result)
        // Resolve the Promise with the compressed image data URL
        
      },
      error(err) {
        // Reject the Promise with the error
        reject(err);
      },
    });

  });
}



export const handleImageForCompress1 = (e) => {
  let resultData;
  const file = e.target.files[0];

  const reader = new FileReader();

  reader.onloadend = () => {
    const base64String = reader.result;
    // console.log(base64String);
    // setImage(base64String);
  };

  reader.readAsDataURL(file);

  // console.log("file", file);

  if (file) {
    new Compressor(file, {
      quality: 1,
      maxWidth: 1000,
      maxHeight: 1000,
      // mimeType: "image/webp",
      // The compression process is asynchronous,
      // which means you have to access the `result` in the `success` hook function.
      success(result) {
        // console.log("result", result);
        reader.onloadend = () => {
          const base64String = reader.result;
          // console.log("base64String ===>",base64String);
          return base64String
          // setCompressedImage(base64String);
        };
        
        resultData= reader.readAsDataURL(result);
        console.log("result.name", resultData);
        // Send the compressed image file to server with XMLHttpRequest.
        // axios.post('/path/to/upload', formData).then(() => {
        //   console.log('Upload success');
        // });
      },
      error(err) {
        console.log(err.message);
      },
    });
  }
  console.log("resultData =>",resultData)
  return resultData
};