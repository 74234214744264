import React, {useState, useEffect, useRef} from 'react'
import { connect } from 'react-redux'
import { Box, Button, Typography, styled, Link } from '@mui/material';
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useNavigate } from 'react-router-dom';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { updateAccountDetails } from 'src/services/actions';
import ContactUsCta from 'src/components/ContactUsCta';

const OnBoarding = (props) => {
  const { user } = props;
  const navigate = useNavigate();
  const sliderRef = useRef(null);
  const [accountFormData, setAccountFormData] = useState({
    fname: user.data.fname,
    lname: user.data.lname,
    email: user.data.email,
    new_email: user.data.new_email
  });
  

  const completeOnboarding = async(redir = null) => {
    const u_data = {...accountFormData};
    u_data['onboarding'] = true;
    console.log("u_data =>",u_data)
    await props.updateAccountDetails(u_data, (resp) => {
      if (resp.type == 'success') {
        if (redir == null) {
          navigate('/rewards/membership')
        } else {
          navigate(`/rewards/community/new`, {state: {reward_type: 'community'}})
        }
      }
    })
    
  }

  const OnBoardingBox = styled(Box)(
    ({ theme }) => `
      background-size: cover;
      height: calc(98vh - ${theme.header.height});
      background-repeat: no-repeat;
      background-position: top;
    `
  );
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: false
  };
  return (
    <Box className="onboarding" sx={{borderTop: '1px solid'}}>
      <Slider {...settings} ref={sliderRef}>
        <OnBoardingBox sx={{backgroundImage: "url(/assets/img/bg-1.png)"}}>
          <Box display="flex" justifyContent="center" alignItems="end" height="100%">
            <Box textAlign="center" pb={1}>
              <Typography variant="h1">Engage Your Community</Typography>
              <Box width="60%" mx="auto"><Typography variant="body1" mb={2.5}>Create deeper relationships with Web3 communities through exclusive rewards and experiences.</Typography></Box>
              <Box><Button variant="contained" onClick={() => sliderRef.current.slickGoTo(1)}>Next up, learn about rewards</Button></Box>
              <Typography variant="body1" sx={{mt: 1, color: '#8D8B8B !important', fontSize: '21.2987px !important'}}>1/3</Typography>
            </Box>
          </Box>
        </OnBoardingBox>
        <OnBoardingBox sx={{backgroundImage: "url(/assets/img/bg-2.png)"}}>
          <Box display="flex" justifyContent="center" alignItems="end" height="100%">
            <Box textAlign="center" pb={1}>
              <Typography variant="h1">Offer Enticing Rewards & Experiences</Typography>
              <Box width="75%" mx="auto"><Typography variant="body1" mb={2.5}>Deliver merchandise claims, digital content, event access, community feedback and more...with just a few clicks.</Typography></Box>
              <Box><Button variant="contained" onClick={() => sliderRef.current.slickGoTo(2)}>Next up, brand the experience</Button></Box>
              <Typography variant="body1" sx={{mt: 1, color: '#8D8B8B !important', fontSize: '21.2987px !important'}}>2/3</Typography>
            </Box>
          </Box>
        </OnBoardingBox>
        <OnBoardingBox sx={{backgroundImage: "url(/assets/img/bg-3.png)", backgroundPosition: "top"}}>
          <Box display="flex" justifyContent="center" alignItems="end" height="100%">
            <Box textAlign="center" pb={1}>
              <Typography variant="h1">Tailored To Your Brand</Typography>
              <Box width="70%" mx="auto"><Typography variant="body1" mb={2.5}>Deliver rewards on a custom-branded page with an easy claim experience.</Typography></Box>
              <Box><Button variant="contained" onClick={() => completeOnboarding('new-reward')}>Create Your First Reward <img src="/assets/img/magicwand.svg" width={25} alt="" style={{marginLeft: '10px'}} /></Button></Box>
              <Link onClick={() => completeOnboarding()}><Typography variant="body1" sx={{mt: 1, color: '#1A1616 !important', fontSize: '21.2987px !important', textDecoration: "underline", cursor: "pointer"}}>Visit Your Dashboard</Typography></Link>
            </Box>
          </Box>
        </OnBoardingBox>
      </Slider>
    </Box>
  )
}


const mapStateToProps = (state) => ({
  user: state.user
})

const mapDispatchToProps = {
  updateAccountDetails
}

export default connect(mapStateToProps, mapDispatchToProps)(OnBoarding)