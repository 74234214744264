import React from 'react'
import { Skeleton,TableCell } from "@mui/material";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';


function SkeletonRowData() {
  
  return (
    <>
        <TableCell sx={{display:'flex' , alignItems:'center'}}>
            <Skeleton variant="rectangular" width={70} height={70} />
            <Skeleton variant="rectangular" width={93} height={20} sx={{marginLeft:'14px'}} />
        </TableCell>
        <TableCell>
            <Skeleton variant="rectangular" width={50} height={20}  />
        </TableCell>
        <TableCell>
                <Skeleton variant="rectangular" width={245} height={19} />
                <Skeleton variant="rectangular" width={232} height={19} sx={{marginTop:'4px'}}/>
                <Skeleton variant="rectangular" width={218} height={19} sx={{marginTop:'4px'}} />
        </TableCell>
        <TableCell>
                <Skeleton variant="circular" width={40} height={40} />
        </TableCell>
        <TableCell component="th" scope="row">
            <DeleteOutlineIcon sx={{color:'rgba(34, 51, 84, 0.5);', cursor: 'not-allowed'}}/>
        </TableCell>
    </>
  )
}

export default SkeletonRowData