import React, { useEffect, useState } from "react";
import { Box, Typography, Button, Link } from "@mui/material";
import { NavLink, useLocation } from "react-router-dom";
import ContentPostCard from "src/components/ContentPostCard";
import PostsSorting from "src/components/DropItem/postsSorting";
import { connect } from "react-redux";
import { getCategoryData } from "src/services/actions";
import { useParams } from 'react-router-dom';


const ContentPostRewards = (props) => {
  const [sortOrder, setSortOrder] = useState(1);
  const [name, setName] = useState("")
  const { c_type } = useParams();
  const location = useLocation();
  const statusType = location?.state?.postItem;
  const {categories, user} = props


  const handleSelectChange = (event) => {
    setSortOrder(event.target.value);
  };

  useEffect(() => {
    if(c_type){
      props.getCategoryData(c_type, sortOrder)
    }
  }, [c_type, sortOrder])  

  useEffect(() => {
    if(c_type && user.login){
      setName(user.data.categories.filter((item) => item.slug == c_type)[0]?.name)
    }
  }, [c_type, user.data.categories])

  useEffect(() => {
    if (location.state?.postItem) {
      setSortOrder(location.state?.postItem?.status)
    }
  },[location])


  return (
    <>
          {/* add box and useing width and height or paddign */}
          <Box
            className="scrollbar-hide"
            width={"calc(100% - 259px)"}
            sx={{ padding: "40px", height: "calc(99vh - 80px)", overflowY: "auto", boxSizing: "border-box" }}>
            <Box
              sx={{ pb: "15px" }}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box>
                <Typography variant="h1" className="title">
                  {name}
                </Typography>
                <Typography variant="body1" className="subtitle">
                  Create and manage content posts
                </Typography>
              </Box>
              <Box>
                <Button
                  variant="contained"
                  to={`/category/${c_type}/new`}
                  component={NavLink}
                  className="font-gs-md"
                  sx={{padding:"14px 20px", fontSize:"16px", height:"50px" }}
                >
                  Create Post
                </Button>
              </Box>
            </Box>
            <Box sx={{ borderBottom: 0, borderColor: "" }}>
              {
                <PostsSorting
                  sortOrder={sortOrder}
                  handleSelectChange={handleSelectChange}
                  selectedTab="active"
                />
              }
            </Box>

            <Box
            >
              {categories.type == "request" ?
                <Typography align="center" className="font-gs-md" sx={{fontSize: "1.5rem"}}>Loading...</Typography>
                :
                categories.type == "success"  ?
                categories.data.length == 0
                ?
                <Typography align="center" className="font-gs-md" sx={{color: "#2B2011", fontSize: "1.563rem"}}>There are no “{sortOrder == 0 ? "draft" : "active"}” posts to display.</Typography>
                :
                <Box display="flex" flexWrap="wrap">
                {categories.data.map((c_data, index) => {
                  return(
                      // JSON.stringify(c_data)
                      <ContentPostCard {...c_data} c_type={c_type}/>
                    )
                  })}
                </Box>
                // JSON.stringify(categories)
                :
                <></>
              }
              {/* <RecipesCard />
              {/*  */}
            </Box>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  categories: state.categories,
  user: state.user
})

const mapDispatchToProps = {
  getCategoryData
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentPostRewards)