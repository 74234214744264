import React, {useEffect, useState} from 'react';
import { Box, Typography, Button, Container, Card,  CardContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow ,Paper,Alert,Snackbar } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import {UsersTableRow} from 'src/components/DropItem/tablerow';
// import  EditCollection from 'src/components/Modals/edit_collection';
import EditAdminUser from 'src/components/Modals/edit_user';

import NoActiveCollection from 'src/components/NoActiveDrop/noActiveCollection';

import { getAdminUsers, updateMembershipSettingsToggle } from 'src/services/actions';

import { connect } from 'react-redux';

import SkeletonUserData from 'src/components/DropItem/skeletonUserData';


const AdminUsers = (props) => {
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editUserId, setEditUserId] = useState('')
  const [deleteBox, setDeleteBox] = useState(false)
  const [deleteId, setDeleteId] = useState('')
  const [showSnackbar , setShowSnackbar] = React.useState(false)
  const { adminUsers } =props


  const handleOpen = () => {
    setOpen(true)
  };
  const handleEdit = () => setEdit(true);

  const deleteTable = (id) => {
    setDeleteBox(true)
    setDeleteId(id)
  }

  const deleteCollection = async() => {
    props.deleteCollections(deleteId)
  }
  

  useEffect(() => {
    props.getAdminUsers()
  }, [])

  const vertical =  'top';
  const horizontal = 'center';


  const showEditModal = (i) => {
    setEdit(true)
    setEditUserId(i)
  }

  const handleMembershipToggle = (userid, toggle) => {
    const usr = adminUsers.data.find((u) => u.id === userid);
    const isUsrPaid = usr.is_paid || false;
    props.updateMembershipSettingsToggle({allow_membership_settings: toggle, is_paid: isUsrPaid}, userid, (res) => {
      console.log()
    })
  }
  const handlePaidAccToggle = (userid, toggle) => {
    const usr = adminUsers.data.find((u) => u.id === userid);
    const allowMemSettings = usr.other_data?.allow_membership_settings || false;
    props.updateMembershipSettingsToggle({allow_membership_settings: allowMemSettings, is_paid: toggle}, userid, (res) => {
      console.log()
    })
  }

  return (
    <>
        <Snackbar sx={{zIndex: '7 !important'}} anchorOrigin={{ vertical, horizontal }} open={showSnackbar} autoHideDuration={5000} key={vertical + horizontal}>
          <Alert severity="info" sx={{ width: '100%' }}>
            It may take a while
          </Alert>
        </Snackbar>
    <Container maxWidth="xl" fixed>
      <Box sx={{py: 3, mt:3,mb:1}} display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <Typography variant='h1' sx={{pb: 1,fontFamily: 'Roboto, sans-serif',fontWeight: "700", fontSize: '1.875rem' ,color: '#223354'}}>Admin Users</Typography>
        </Box>
      </Box>
      {adminUsers.data &&
        adminUsers.data.length === 0 ?
          adminUsers.type =='request' ? 
          <Card sx={{borderRadius: 0}}>
            <CardContent>
              <TableContainer component={Paper} sx={{borderRadius: 0, boxShadow: 0, py:5}}>
                <Table sx={{ minWidth: 650, border: 0, boxShadow: 0, borderRadius: 0, p: 5 }} aria-label="simple table">
                  <TableHead>
                  <TableRow>
                      <TableCell width={100} sx={{fontFamily: "Roboto, sans-serif",fontWeight: "400", fontSize: "0.875rem",color: "#223354", opacity: "0.5"}}>Name</TableCell>
                      <TableCell width={100} sx={{fontFamily: "Roboto, sans-serif",fontWeight: "400", fontSize: "0.875rem",color: "#223354", opacity: "0.5"}}>Email</TableCell>
                      <TableCell width={100} sx={{fontFamily: "Roboto, sans-serif",fontWeight: "400", fontSize: "0.875rem",color: "#223354", opacity: "0.5"}}>Eth Address</TableCell>
                      <TableCell width={150} sx={{fontFamily: "Roboto, sans-serif",fontWeight: "400", fontSize: "0.875rem",color: "#223354", opacity: "0.5"}}>Partner</TableCell>
                      <TableCell width={150} sx={{fontFamily: "Roboto, sans-serif",fontWeight: "400", fontSize: "0.875rem",color: "#223354", opacity: "0.5"}}>Created At</TableCell>
                      <TableCell width={150} sx={{fontFamily: "Roboto, sans-serif",fontWeight: "400", fontSize: "0.875rem",color: "#223354", opacity: "0.5"}}>Membership Settings</TableCell>
                      <TableCell width={150} sx={{fontFamily: "Roboto, sans-serif",fontWeight: "400", fontSize: "0.875rem",color: "#223354", opacity: "0.5"}}>Paid</TableCell>
                      <TableCell width={300} sx={{fontFamily: "Roboto, sans-serif",fontWeight: "400", fontSize: "0.875rem",color: "#223354", opacity: "0.5"}}>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <SkeletonUserData />
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer> 
            </CardContent>
          </Card>
          :
          <NoActiveCollection description="Hurrah!🎉 All users are up to date." type="admin" addCollection={handleOpen}/>
        :
        adminUsers.type !=='request' &&
        <Card sx={{borderRadius: 0}}>
          <CardContent>
            <TableContainer component={Paper} sx={{borderRadius: 0, boxShadow: 0, py:5}}>
                <Table sx={{ minWidth: 650, border: 0, boxShadow: 0, borderRadius: 0, p: 5 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell width={100} sx={{fontFamily: "Roboto, sans-serif",fontWeight: "400", fontSize: "0.875rem",color: "#223354", opacity: "0.5"}}>Name</TableCell>
                      <TableCell width={100} sx={{fontFamily: "Roboto, sans-serif",fontWeight: "400", fontSize: "0.875rem",color: "#223354", opacity: "0.5"}}>Email</TableCell>
                      <TableCell width={100} sx={{fontFamily: "Roboto, sans-serif",fontWeight: "400", fontSize: "0.875rem",color: "#223354", opacity: "0.5"}}>Eth Address</TableCell>
                      <TableCell width={150} sx={{fontFamily: "Roboto, sans-serif",fontWeight: "400", fontSize: "0.875rem",color: "#223354", opacity: "0.5"}}>Partner</TableCell>
                      <TableCell width={150} sx={{fontFamily: "Roboto, sans-serif",fontWeight: "400", fontSize: "0.875rem",color: "#223354", opacity: "0.5"}}>Created At</TableCell>
                      <TableCell width={150} sx={{fontFamily: "Roboto, sans-serif",fontWeight: "400", fontSize: "0.875rem",color: "#223354", opacity: "0.5"}}>Membership Settings</TableCell>
                      <TableCell width={150} sx={{fontFamily: "Roboto, sans-serif",fontWeight: "400", fontSize: "0.875rem",color: "#223354", opacity: "0.5"}}>Paid</TableCell>
                      <TableCell width={300} sx={{fontFamily: "Roboto, sans-serif",fontWeight: "400", fontSize: "0.875rem",color: "#223354", opacity: "0.5"}}>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody >
                    {adminUsers.data.map((item,index)=> {
                      return(
                        <UsersTableRow handleMembershipToggle={handleMembershipToggle} handlePaidAccToggle={handlePaidAccToggle} ShowEditModal={showEditModal} collectionType="admin" key={index} rowData={item} handleOpen={handleEdit} onClick={deleteTable}/>    
                      )
                    })}
                  </TableBody>
                </Table>
            </TableContainer> 
          </CardContent>
        </Card>
        
        }
      
      {edit &&
      <EditAdminUser user_data={adminUsers.data.filter(item => item.id == editUserId)[0]} open={edit} handleClose={() => setEdit(false)} loadCollections={() => {
        setEdit(false)
      }} />
      }
    </Container>
    </>
  )
}



const mapStateToProps = (state) => ({
  adminUsers: state.adminUsers
})

const mapDispatchToProps = {
  getAdminUsers,
  updateMembershipSettingsToggle
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminUsers)