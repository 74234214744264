import React, { useState } from 'react';
import { Dialog,DialogTitle,DialogContent,DialogActions, Button,Typography,Box } from '@mui/material';

import { connect } from 'react-redux';

import { deleteCategoryContent, deleteReward,resetRewardCRD } from 'src/services/actions';
import {gtag} from "../../utils";
import { useNavigate } from 'react-router-dom';

const DeleteReward = (props) => {
  const navigate = useNavigate()
  const [apiCalled, setApiCalled] = useState(false)
  const { open, handleClose, type = "reward", c_name="" } = props;

  const {rewardCRD, rewardList,user,dropsData} = props

  React.useEffect(()=>{
    props.resetRewardCRD();
  },[])

  const handleDelete = () => {
    if(type == "reward"){
      props.deleteReward(props.dropId)
      navigate(user.data?.other_data?.allow_membership_settings ? "/rewards/membership" : "/rewards/community", { replace: true });
    }else{

    setApiCalled(true)

    props.deleteCategoryContent(props.dropId, (res) => {
      setApiCalled(false)
      if(res.type == "success"){
        navigate(`/category/${c_name}`, {
          replace: true
        })
      }
    })
    }
  }

  React.useEffect(()=>{
    if(rewardCRD.type == 'success'){
      gtag('reward_deleted',{
        reward_type: dropsData.drop_type,
        eth_address: ` ${user.data.eth_address} `
      });
      props.resetRewardCRD();
    }

  },[rewardCRD.type])
  
  return (
    <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className='modals'
      >
        <Box className='modal-card'>
        <DialogTitle id="alert-dialog-title" display="flex" justifyContent="center" sx={{mt:4,mb:4}}>
          <img src="/assets/img/delete-icon.svg" alt="delete" />
        </DialogTitle>
        <DialogContent sx={{pl:4, pr:4}}>
            <Typography variant="h1" className='heading'>Are you sure you want to delete this {type == "post" ? "post" : "reward"}?</Typography>
            <Typography variant="h6" className='helper-text'>Heads up: Deleting a {type == "post" ? "post" : "reward"} cannot be undone.</Typography>
        </DialogContent>
        {rewardCRD.type == "request" ? 
        <DialogActions sx={{ mb: 4,display: 'flex', justifyContent: 'center'}}>
          <Button sx={{opacity:'0.5'}}>Cancel</Button>
          <Button autoFocus sx={{opacity:'0.5', cursor: 'not-allowed'}}>
            Deleting...
          </Button>
        </DialogActions>
        :
        <DialogActions sx={{ mb: 4,display: 'flex', justifyContent: 'center'}}>
          <Button onClick={handleClose} variant="contained" sx={{borderRadius:"6px", py:1,px:3}} className='btn-text'>Cancel</Button>
          <Button autoFocus onClick={handleDelete} sx={{py:1,px:3,borderRadius:"6px"}} className='btn-text'>
            Delete {type == "post" ? "Post" : "Reward"}
          </Button>
        </DialogActions>
        }
        </Box>
      </Dialog>
  )
}

const mapStateToProps = (state) => ({
  rewardCRD: state.rewardCRD,
  rewardList: state.rewardList,
  user: state.user,
})

const mapDispatchToProps = {
  deleteReward,
  resetRewardCRD,
  deleteCategoryContent
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteReward)