import React,{useEffect, useState} from 'react';
import { Box, Card, CardContent, Grid, Typography, Link, Tabs, Tab } from '@mui/material';
import {connect} from 'react-redux';

import {gaEvent} from "../../utils";
import CommunityPageSettings from './community_settings/community';
import MarketingPageSettings from './community_settings/marketing';
import ClaimPageSettings from './claim';
import AdvancedSettings from './advanced';
import { useSearchParams } from 'react-router-dom';


function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: "20px", px:1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const CommunitySettings = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [tabValue, setTabValue] = useState(0);
  
  const {page, user} = props


  useEffect(() => {
    gaEvent({
      category: "Community Page Settings",
      label:`-${user.data.eth_address}-`,
      action: `community_settings`
    });
  }, []);

  useEffect(() => {
    if (searchParams.get('tab') && searchParams.get('tab') === 'branding') {
      setTabValue(1)
    }
  },[searchParams])
  

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (
    <Box fixed mx={11} pt={4} className='settings'>
      <Typography variant="h1" sx={{mb:1}} className='title'>Settings</Typography>
      <Typography variant="h4"sx={{mb:"40px"}} className='subhead'>Need help with your account, or want to learn how to get the most out of Step3? <Link sx={{cursor: 'pointer'}} target='_blank' href='https://www.step3.io/bookacall'>Talk to an expert</Link>.</Typography>
      <Box className='claim-settings'>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={(page.width > 1100 && page.width < 1500) ? 7.2 : 6}>
            <Card sx={{borderRadius: 0, maxWidth: '694px', pt:3}} className='claim-card'>
              <CardContent sx={{p:0}}>
                <Typography variant='h3' px="50px" pb="20px" className='claim-heading' sx={{fontSize: "32px !important"}}>Customize your claim page</Typography>
                <Box sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.2)", p: "15px 50px", bgcolor: "rgba(243, 243, 243, 0.7)"}}>
                  <Tabs value={tabValue} onChange={handleTabChange} variant="standard" scrollButtons="auto">
                    <Tab disableRipple label={`Community Page`} {...a11yProps(0)} />
                    <Tab disableRipple label={`Branding`} {...a11yProps(1)} />
                    <Tab disableRipple label={`Advanced`} {...a11yProps(2)} />
                  </Tabs>
                </Box>
                <TabPanel key="community-page" value={tabValue} index={0}>
                  <Box px="91px">
                    <CommunityPageSettings />
                  </Box>
                </TabPanel>
                <TabPanel key="branding" value={tabValue} index={1}>
                  <Box px="91px">
                    <ClaimPageSettings />
                  </Box>
                </TabPanel>
                <TabPanel key="advanced" value={tabValue} index={2}>
                  <Box px="40px">
                    <AdvancedSettings />
                  </Box>
                </TabPanel>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

const mapStateToProps = (state) => ({
  user: state.user,
  page: state.page
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(CommunitySettings)