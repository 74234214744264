import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { ImageList, ImageListItem, TextField } from "@mui/material";
import { connect } from "react-redux";
import { createNewContentCategory, setToast, updateContentCategory } from "src/services/actions";
import { useNavigate } from "react-router-dom";
import { validateAlphaNumStr } from "src/utils";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  borderRadius: "6px",
};

function CategoryModal(props) {
  const { categoryItem, id, handleClose, open, type = "add", value = {name: "", title: "", id: "", slug: ""} } = props;
  const navigate = useNavigate()
  // const [open, setOpen] = useState(false);
  

  const [isSubmit, setIsSubmit] = useState(false);
  const [formData, setformData] = useState({
    name: {value: '', error: ''},
    title: {value: '', error: ''},
  });

  useEffect(() => {
    if(type == "edit"){
      let existingData = {...formData}
      existingData['name']['value'] = value.name
      existingData['title']['value'] = value.title

      setformData(existingData)
      // setformData({
      //   ...formData,
      //   name: {
      //     ...formData
      //   }
      // })
    }
  }, [type == "edit"])

  // const handleClose = () => {
  //   setOpen(false);
  //   setformData({
  //     name: {value: '', error: ''},
  //     title: {value: '', error: ''},
  //   })
  // };

  const handleChange = (type, val) => {
    const formvals = {...formData};
    formvals[type] = {value: val, error: ''}
    setformData(formvals)
  }

  const submitCategory = async() => {
    const fObj = {...formData};
    setIsSubmit(true)
    let flag = true;

    if (fObj.name.value == null || fObj.name.value.trim() == "") {
      fObj.name = {value: fObj.name.value, error: 'Category name is required.'}
      flag = false;
    }

    if (fObj.name.value !== '' && validateAlphaNumStr(fObj.name.value) === false) {
      fObj.name = {value: fObj.name.value, error: 'Category name is invalid.'}
      flag = false;
    }
    

    if (flag) {
      let dataKeys = Object.keys(fObj)
      let newData = {}
      for (let i = 0; i < dataKeys.length; i++) {
        const keyName = dataKeys[i];
        
        newData[keyName] = formData[keyName].value.trim()
      }

      if (type == "edit"){
        props.updateContentCategory(value.id, newData, (resp) => {
          setIsSubmit(false)
          if (resp.type == 'success') {
            navigate(`/category/${resp.data.slug}`, {
              replace: true
            })
            props.setToast(true, { type:"success", message:"Category has been saved successfully." });
            handleClose()
          } else {
            fObj.name = {value: fObj.name.value, error: 'Category name is already taken.'}
            setformData(fObj)
          }
        })
      } else {
        props.createNewContentCategory(newData, (resp) => {
          setIsSubmit(false)
          if (resp.type == 'success') {
            props.setToast(true, { type:"success", message:"Category has been saved successfully." });
            handleClose()
            navigate(`/category/${resp.data.slug}`)
          } else {
            fObj.name = {value: fObj.name.value, error: 'Category name is already taken.'}
            setformData(fObj)
          }
        })
      }
    } else {
      setIsSubmit(false)
      setformData(fObj)
    }
  }

  return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style} paddingTop="50px">
            <Typography id="transition-modal-title" sx={{ color: "#1A1616", fontWeight: "700", fontSize: "32px", lineHeight: "43.2px", fontFamily: "General Sans bold", }} textAlign="center" variant="h3" component="h2">{ type == "edit" ? "Edit category" : "Add a new category"}</Typography>
            <Box sx={{ margin: "20px 50px", p: "20px" }}>
              <Box>
                <TextField sx={{ width: "100%", mb: "20px", "&::placeholder": { color: "#223354b3", }, }} required id="outlined-required" label="Category Name" onChange={(e)=>handleChange("name", e.target.value)} value={formData.name.value}  InputLabelProps={{shrink: true}} placeholder='Enter a name' error={formData.name.error !== ""} helperText={formData.name.error == '' ? '' : formData.name.error} FormHelperTextProps={{ style: {color: (formData.name.error !== "") ? 'red' : 'rgba(34, 51, 84, 0.7)' } }} inputProps={{ maxLength: 25 }} />
              </Box>
              <Box>
                <TextField sx={{ width: "100%", mb: "20px", "&::placeholder": { color: "#223354b3", } }}  id="outlined-required" label="Title" value={formData.title.value} InputLabelProps={{shrink: true}} placeholder="Enter a title for the category page" onChange={(e)=>handleChange("title", e.target.value)}/>
              </Box>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", background: "#f9f9f9", }} >
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "400px", margin: "30px auto", background: "#f9f9f9", height:"41.81px" }}>
                <Button variant="text" color="primary" sx={{ fontFamily: "Roboto, sans-serif", fontWeight: "700", fontSize: "15px", marginRight: "20px", }} onClick={handleClose}>Cancel</Button>
                <Button variant="contained" color="error" sx={{ fontFamily: "Roboto, sans-serif", fontWeight: "700", fontSize: "15px", background:"#ED1F23" }} onClick={submitCategory} disabled={isSubmit}>{isSubmit ? 'Working..' : type == "edit" ? `Save` : `Add New Category`}</Button>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  categoryForm: state.categoryForm
})

const mapDispatchToProps = {
  setToast,
  createNewContentCategory,
  updateContentCategory
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryModal)