import React, { useState} from 'react';
import { connect } from 'react-redux';
import PartnerForm from './form';


const EditPartnerForm = (props) => {
  const { open, handleClose, partnerUser } = props;
  const [formData, setformData] = useState({
    fname: {value: partnerUser.fname, error: ''},
    lname: {value: partnerUser.lname, error: ''},
    membership_title: {value: partnerUser.membership_page_info.membership_title, error: ''},
    email: {value: partnerUser.email, error: ''},
    image: {value: partnerUser.brand_logo, error: ''},
    nav_image: {value: partnerUser.membership_page_info?.nav_logo, error: ''},
    stripe_product_id: {value: partnerUser.stripe_connect_data?.stripe_product_id, error: ''},
    brand_data: {value: partnerUser.brand_data, error: ''},
    other: {value: partnerUser.membership_page_info.other, error: ''},
    contract_name: {value: partnerUser.membership_nft_details?.contract_name, error: ''},
    nft_metadata: {value: partnerUser.membership_nft_details?.nft_metadata, error: ''},
    brand_domain: {value: partnerUser.brand_domain, error: ''},
  })

  return (
    <PartnerForm formTitle={'Edit Partner'} partnerUser={partnerUser} open={open} handleClose={handleClose} formData={formData} setformData={setformData} />
  )
}


const mapStateToProps = (state) => ({
  page: state.page,
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(EditPartnerForm)