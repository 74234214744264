import React from 'react';
import { Modal,Box, Button,Typography } from '@mui/material';

import { connect } from 'react-redux';

    
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 450,
  bgcolor: 'background.paper',
  border: '0px solid #000',
  boxShadow: 24,
  p: 3,
  borderRadius:"8px"
};

const ResetMembershipSettings = (props) => {
  const { open, handleClose, user, updateDomainSettings} = props;

  const DeleteDomain = () => {
    handleClose()
    updateDomainSettings(true)
  }

  return (
      <Modal className="deleteResetModal" open={open} onClose={handleClose} aria-labelledby="Edit Form Field" sx={{outline: 'none'}} onBackdropClick={()=>{return false}}>
      <Box sx={style}>
        <Box display="flex" justifyContent="center" sx={{mt:4,mb:4}}>
          <img src="/assets/images/circle-error.svg" alt="delete" />
        </Box>
        <Typography variant="h3" align="center">Are you sure you want to reset Settings?</Typography>
        <Box display={'flex'} justifyContent={'center'} mt={5}>
        <Button variant="contained" color="primary" onClick={handleClose} sx={{borderRadius:' 6px', padding:'0.5rem 1.4rem', px:2,fontSize: "0.938rem"}}>Cancel</Button>
        {user.type == "update_request" ?
         <Button variant="outlined" color="error" sx={{borderRadius:' 6px', padding:'0.5rem 1.4rem',fontFamily: "Roboto,sans-serif", fontWeight: "700", fontSize: "0.938rem",ml:2, px:4, cursor:'not-allowed', opacity:"0.5"}}>Deleting...</Button>
         :
        <Button variant="outlined" color="error" sx={{borderRadius:' 6px', padding:'0.5rem 1.4rem',fontFamily: "Roboto,sans-serif", fontWeight: "700", fontSize: "0.938rem", px:4,ml:2}} onClick={DeleteDomain}>Reset</Button>
      }
        </Box>
      </Box>
    </Modal>
  )
}

const mapStateToProps = (state) => ({
  user: state.user
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ResetMembershipSettings)