import React, { useEffect, useState} from 'react';
import { Modal, Box, Typography, Button, Link, Card} from '@mui/material';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import { connect } from 'react-redux';
import { setToast } from '../../services/actions'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '0px solid #000',
  boxShadow: 24,
  borderRadius: '8px',
  p: 4
};

const shadowBoxStyle = {
  bgcolor: '#F6F8FB',
  border: '1px solid #CCCEDD',
  boxShadow: '0px 4px 21px rgba(24, 69, 136, 0.2)',
  borderRadius: '6px',
  p: '20px 28px',
  m: '25px 40px',
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column"
}

const CheckInAttendees = (props) => {
  const {open, handleClose, user, dropsData} = props;

  const copyEventPassUrl = () => {
    navigator.clipboard.writeText(`${user.data.full_brand_domain}/passchecker/${dropsData.drop_type_data[0].unique_key}`)
    props.setToast(true,{type:"success",message:"Copied to clipboard"});
  }

  return (
    <Modal open={open} aria-labelledby="Reward Coming Soon" className='modals'>
      <Box sx={style} className='modal-card'>
        <Typography variant="h3" className='heading'>Check in Attendees</Typography>
        <Typography variant="body1"  className='helper-text'>Copy the link below and send it to the person responsibile for checking in people at the event. They can use it to scan the QR Code for each reward claimer and verify their eligibility.</Typography>
        <Typography variant="body1" align="center" my={2}><Link href="https://help.step3.io/creating--managing-rewards/ty25EcCpyGNQWuwxhGEyp6/event-pass-rewards/6GCM4WciuVvxrNeP48JnU1" target="_blank" sx={{textDecoration: "underline"}}>Learn more about Event Pass rewards</Link></Typography>
        
        <Box sx={shadowBoxStyle}>
          <Box display="flex" justifyContent="space-between" alignItems="center" bgcolor="rgba(34, 51, 84, 0.02)" p={0.5} borderRadius={1}>
            <Typography variant="h4" sx={{fontFamily: "Roboto, sans-serif", fontWeight: "500",fontSize: "20px", color: "#223354"}}>"Check in" site for mobile devices</Typography>
            <Card sx={{bgcolor:'#E8F1FF', padding:'10px' , marginLeft:'11px', alignItems: 'center', display: 'flex', cursor: 'pointer'}} onClick={copyEventPassUrl}>
              <InsertLinkIcon sx={{color:'#0061F4' }} />
            </Card>
          </Box>

          <Button variant="contained" onClick={copyEventPassUrl} sx={{borderRadius: '6px', padding: '0.6rem 2rem', marginTop: "1.5rem", width: "100%"}}>Copy Link</Button>
        </Box>
        <Box display={'flex'} justifyContent={'center'} mt={3}>
          <Button variant="outlined" color="secondary" onClick={handleClose} sx={{borderRadius:' 6px', padding:'0.5rem 1.4rem', border: "1px solid #6E759F", color: "#6E759F", px:4,fontSize: "0.938rem"}}>Close</Button>
        </Box>
      </Box>
    </Modal>
  )
}


const mapStateToProps = (state) => ({
  user: state.user
})

const mapDispatchToProps = {
  setToast
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckInAttendees)