import React, {useState} from "react";
import { Box, Typography,Button, Modal, Card, Link, Snackbar, Alert } from '@mui/material'
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import { setToast } from "src/services/actions";
import { connect } from "react-redux";

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'white',
    boxShadow: 24,
    py: 4,
    px: 10,
    borderRadius:'6px'
  };  
  const CouponCodeModal = (props) => {
    const {open, handleClose,rewardDropData} = props;
    const vertical =  'top';
    const horizontal = 'center';
    const [openCopiedBar , setOpenCopiedBar] = useState(false)

    const copyToClipboard = (str) => {
      navigator.clipboard.writeText(str)
      props.setToast(true,{type: 'success', message: 'Copied to clipboard!'});
    }
     
    return(
        <>
          <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" className="preview-modal">
            <Box sx={style} className='modal-border'>
              <Box className="modal-card">  
              <Typography className="preview-title">This is how your reward will appear to users</Typography>
              <Typography variant="h6" className="modal-title">You've Claimed</Typography>
              <Typography variant="h1" className="heading">{rewardDropData?.title}</Typography>
              <Typography variant="h5"  className="description">{rewardDropData?.couponCodes?.details}</Typography>

              <Card sx={{bgcolor:'#F6F8FB', marginTop:'19px', px:4,border: '1px solid #CCCEDD',boxShadow: '0px 4px 21px rgba(24, 69, 136, 0.2)',borderRadius: "6px" }}>
                <Box sx={{display:'flex',justifyContent:'center', paddingTop:'10px'}}>
                  <Card sx={{display:'flex', bgcolor:'rgba(26, 117, 255, 0.05)',borderRadius:'30px',p:1, boxShadow:'none' }}>
                    <img src="/assets/img/coupon-icon.svg" alt="" style={{width:'17px'}}/>
                    <Typography variant="h6" className="merch-category">Coupon Code</Typography>
                  </Card>
                </Box>
                <Card sx={{bgcolor:'rgba(34, 51, 84, 0.02)', mt:"10px", mb:1 , p:2 , boxShadow:'none'}}>
                  <Box sx={{display:"flex" , justifyContent:'center' , alignItems:'center'}}>
                    {/* fontSize: info[0].coupon_code.length <= 11 ?"2.3rem": info[0].coupon_code.length <= 30 ?"1.8rem" :info[0].coupon_code.length <= 60 ? "1.4rem": "1rem" */}
                    <Typography  variant="h1"  align='center' className="coupon-code" sx={{wordBreak: "break-all",fontSize: rewardDropData?.couponCodes?.code.length <= 11 ?"2.3rem": rewardDropData?.couponCodes?.code.length <= 30 ?"1.8rem" :rewardDropData?.couponCodes?.code.length <= 60 ? "1.4rem": "1rem"}}>{rewardDropData?.couponCodes?.code}</Typography>
                    {rewardDropData.couponCodes.code !== "" &&
                    <Link onClick={(e) => copyToClipboard(rewardDropData?.couponCodes?.code)} sx={{cursor: 'pointer'}} underline="none">
                      <Card sx={{bgcolor:'#E8F1FF', padding:'10px' , marginLeft:'11px', alignItems: 'center', display: 'flex'}} >
                        <InsertLinkIcon sx={{color:'#0061F4' }} />
                      </Card>
                    </Link>
                    }
                  </Box>
                </Card>
                {rewardDropData.couponCodes.website !== "" &&
                <Box sx={{display:'flex', justifyContent:'center'}} mb={2}>
                  <Link href={rewardDropData.couponCodes.website} target="_BLANK" underline="none" sx={{width: '100%'}}>
                  <Button className="go-to-site-btn">Go to site</Button>
                  </Link>
                </Box>
                }
              </Card>
              <Box sx={{ paddingTop:'20px' , display:'flex' , justifyContent:'center'}}>
                <Button variant="outlined" color="secondary" onClick={handleClose}  className='close-btn'>Close</Button>
              </Box>
              <Snackbar
                sx={{zIndex: '7 !important'}}
                anchorOrigin={{ vertical, horizontal }}
                open={openCopiedBar}
                autoHideDuration={5000}
                key={vertical + horizontal}
              >
                <Alert severity="success" sx={{ width: '100%' }}>Copied to clipboard!</Alert>
              </Snackbar>
              </Box>
            </Box>
          </Modal>
        </>
    )
}
const mapStateToProps = (state) => ({
})
const mapDispatchToProps = {
  setToast
}

export default connect(mapStateToProps, mapDispatchToProps)(CouponCodeModal)