import React from 'react'
import { Box, Link, Typography, Button } from '@mui/material'

const ContactUsCta = () => {
  return (
    <Box className='contactus_cta' textAlign="center">
      <Typography variant="body1" sx={{color: '#1A1616 !important', fontSize: '16px !important'}}>Need Help Getting Started?</Typography>
      <Link href="https://www.step3.io/landing-pages/bookacall" target="_blank"><Button variant="outlined" color="secondary">Contact Us</Button></Link>
    </Box>
  )
}

export default ContactUsCta