import React, { useState, useEffect } from 'react'
import { Box, Card, Typography, Button, Modal,Avatar, Link, CircularProgress } from "@mui/material";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import moment from 'moment-timezone';
import { calculateTimeLeft } from 'src/utils';

function RafflePreview(props) {
  const {info} = props;
  const [countdownTime, setCountdownTime]= useState({countdownDays:'', countdownHours:'', countdownMinutes:'', countdownSeconds:''});

  let timeIntrval;
  const countdownTimer = () => {
    timeIntrval = setInterval(() => {
      const tm = calculateTimeLeft(info?.endsIn);
      setCountdownTime(tm)
    }, 1000);
  }

  const checkCountDown = () => {
    return countdownTime.countdownDays != '' && countdownTime.countdownHours != '' && countdownTime.countdownMinutes != '' && countdownTime.countdownSeconds != ''
  }

  useEffect(() => {
    info?.endsIn && countdownTimer();

    return () => {
      clearInterval(timeIntrval)
    }
  }, [info?.endsIn]);
  
  return (
    <Box className='preview-modal'>
      <Typography variant="h6" className="modal-title">You've Claimed</Typography>
      <Box sx={{ borderBottom: "1px solid rgba(34, 51, 84, 0.2)", pt: 2, width: "120px", mx: 'auto', }} display="flex" justifyContent="center"></Box>
      <Card sx={{ border: '1px solid #CCCEDD', bgcolor: "#F6F8FB", marginTop: "19px", px: 4, mx: 2, boxShadow:'0px 4px 21px rgba(24, 69, 136, 0.2)',borderRadius: "6px"}}>
        <Box sx={{ display: "flex", justifyContent: "center", paddingTop: "20px",}}>
          <Card sx={{ display: "flex", alignItems: 'center', bgcolor: "rgba(26, 117, 255, 0.05)", borderRadius: "30px", p: 1, boxShadow: "none",}}>
            <img src="/assets/img/raffle.svg" alt="" style={{ width: "19px" }} />
            <Typography variant="h6" className='merch-category'>Raffle</Typography>
          </Card>
        </Box>
        <Box sx={{ background: 'rgba(34, 51, 84, 0.02)', borderRadius: '8px', p:2, mt:2}}>
          <Box textAlign="center">
            <img src="/assets/img/check-circle.svg" alt="check circle" />
            <Typography variant="h3" mt="8px" mb="10px">You’re Registered!</Typography>
            {
              info?.registrant_message &&
              <Typography variant="body1" className="raffle-message">{info.registrant_message}</Typography>
            }
            {
              info?.endsIn && checkCountDown &&
              <>
                <Box display="flex" justifyContent="center" mt="8px"><Box width="40%" borderTop="1px solid rgba(34, 51, 84, 0.2)" /></Box>

                <Typography variant="h4" className="raffle-end-title" mt="8px">Check back in</Typography>
                {countdownTime.countdownDays != '' ? 
                <Typography variant="h2" className="raffle-end-timer" mt={2} color="#223354">{countdownTime.countdownDays}d : {countdownTime.countdownHours}h : {countdownTime.countdownMinutes}m : {countdownTime.countdownSeconds}s</Typography>
                :
                <Box display="flex" justifyContent="center" mt={2}>
                    <CircularProgress sx={{fontSize: "1.5rem"}} />
                </Box>
                }
              </>
            }
            
          </Box>
        </Box>
      </Card>
    </Box>
  )
}

export default RafflePreview