import React, {useState, useEffect} from 'react'
import { connect } from 'react-redux'
import { Box,  MenuItem, Typography, Accordion, AccordionSummary, AccordionDetails, styled, FormControl, Select, InputLabel, Chip, FormControlLabel, Checkbox, Link, Avatar, Grid } from '@mui/material';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { gaEvent, limitText } from 'src/utils';
import { findCollectionTraits } from 'src/services/actions';
import {TraitPill, TraitCheckbox} from './TraitPill';

const MAccordion = styled((props) => (
  <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid #000`,
  boxShadow: '0px 9px 16px rgba(159, 162, 191, 0.18), 0px 2px 2px rgba(159, 162, 191, 0.32)',
  borderBottom: '0px',
  '&:first-child': {
    borderRadius: '6px 6px 0 0',
    borderTop: '1px solid !important',
    '& .MuiAccordionSummary-root': {
      borderRadius: '6px 6px 0 0'
    }
  },
  '&:last-of-type': {
    borderRadius: '0 0 6px 6px',
    borderBottom: '1px solid !important',
    borderTop: '0px solid !important'
  },
  '&:last-child': {
    '& .MuiAccordionSummary-root': {
      borderRadius: '0 0 6px 6px'
    }
  },
  '&:not(:last-child)': {
    borderTop: '0px solid',
    
  },
  '&:before': {
    display: 'none',
  },
}));

const MAccordionSummary = styled((props) => (
  <AccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
  backgroundColor: 'rgba(243, 243, 243, 0.6)',
  borderRadius: '0px',
  borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  }
}));

const MAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '0px solid rgba(0, 0, 0, .125)',
  
}));

const CollectionTargeting = (props) => {
  const {user, dropData, handleChildData, handleAudienceClick, nextTabErrors, setNextTabErrors, collectionTraits} = props;
  
  const sortOptions = dropData.sponsors.map((s) => {return {label: s.title, value: s.id}});
  const [loadingTakingMoreTime, setLoadingTakingMoreTime] = useState(false)
  
  const [targetCollection, setTargetCollection] = useState(dropData.sponsors[0].id);

  const findtraitsData = (collectionid) => {
    const sp = props.collections.collections.find((c) => c.id === collectionid)
      if (sp){
        props.findCollectionTraits({collection_address: sp.contract_address, chain_id: sp.chain_id})
      }
  }

  useEffect(() => {
    if (targetCollection){
      findtraitsData(targetCollection)
    }
  },[targetCollection])

  const [modCollectionTraits, setModCollectionTraits] = useState([]);
  
  
  const sponsorLogoImg = (id) => {
    const sp = props.collections.collections.find((c) => c.id === id)
    let colImg = sp.logo_image;
    if (colImg === null) {
      colImg = "/assets/images/default_collection.svg"
    }
    return colImg
  }
  
  const handleTargetCollection = (e) => {
    setTargetCollection(e.target.value)
  }

  const [expanded, setExpanded] = useState('panel0');
  const [timerValue, setTimerValue] = useState(0);
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleTargetSelection = (trait_name, trait_value, checked) => {
    // console.log("trait_name, trait_value, checked =>",trait_name, trait_value, checked)

    let collectionsData = [...dropData.sponsors];

    collectionsData.forEach((obj, index) => {
      if (obj.id === targetCollection){
        let traitsData = {...obj.traits};
        
        if (obj.traits) {
          if (obj.traits[trait_name]){
            let newArr = obj.traits[trait_name]
            if (!checked && newArr.includes(trait_value)) {
              newArr = newArr.filter(a => a !== trait_value)
            } else {
              newArr.push(trait_value)
            }
            traitsData[trait_name] = newArr
            obj.traits = traitsData
          } else {
            traitsData[trait_name] = [trait_value]
            obj.traits = traitsData
          }
          
        } else {
          traitsData[trait_name] = [trait_value]
          obj.traits = traitsData
        }
        for (var s in traitsData) {
          if (traitsData[s].length === 0) {
            delete traitsData[s]
          }
        }
        obj.traits = traitsData
      }
    });
    
    handleChildData('sponsors', collectionsData)
  }

  // console.log("dropData.sponsors =>",dropData.sponsors.find((s) => s.id === targetCollection)?.traits)

  /* const selectedSocialActivity = (activity_arr) => {
    let a = Object.values(socialActivities).map((c,i)=> 
      c.map((s)=>
        activity_arr.includes(s.key_name) ? {type: Object.keys(socialActivities)[i], val: s.heading} : null
      )
    )

    // console.log("selected_socials =>",a.flat(1).filter((vals) => vals !== null))
    return a.flat(1).filter((vals) => vals !== null)
  } */

  useEffect(() => {
    const traitsData = dropData.sponsors;
    let modTraits = traitsData.map((t) => {
      const trt = dropData.sponsors.find((s) => s.id === t.id)?.traits
      if (trt !== undefined && trt !== null){
        const trKeys = Object.keys(trt)
        const trVals = Object.values(trt)

        return trVals.map((s,i) => {
          return s.map((n_s) => {
            return {
              logo_image: t.logo_image,
              trait: trKeys[i],
              value: n_s
            }
          })
        }).flat(1)
      }
    }).flat(1)
    modTraits = modTraits.filter((s) => (s !== undefined && s !== null))
    setModCollectionTraits(modTraits)
  },[dropData.sponsors])
  // console.log("collectionTraits.status =>",collectionTraits.status)
  
  useEffect(() => {
    if (collectionTraits.type !== 'success' && collectionTraits.status === 'COLLECTING'){
      // Set up the interval to call the API every 5 seconds
      const intervalId = setInterval(() => {
        findtraitsData(targetCollection)
      }, 5000);

      // Clear interval on component unmount
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [collectionTraits]);

  useEffect(() => {
    let loadingTimout = setTimeout(() => {
      setLoadingTakingMoreTime(true)
    }, 60000)

    return () => {
      clearTimeout(loadingTimout)
    }
  }, []);

  
  return (
    <Box className="collection_targeting">
      <Box px={8.5}>
        <Typography variant="body1" textAlign="center" className='subtitle' lineHeight="normal" mb="20px">CREATE REWARD</Typography>
        <Typography variant="h1" textAlign="center" sx={{mb: 1.5}} className='title'>Do you want to target a specific property? (optional)</Typography>
        <Box mt={3}>
          <FormControl fullWidth className='targetCollectionSelection'>
            <InputLabel>Target Collection</InputLabel>
            <Select label="Target Collection" labelId='target-collection' className='targetCollection' value={targetCollection} onChange={handleTargetCollection} displayEmpty fullWidth sx={{"& .MuiSelect-icon": {color: "#1A1616"}}} renderValue={(value) => {
                return (
                  <Box sx={{ display: "flex", gap: 1, alignItems: 'center' }}>
                    <img src={sponsorLogoImg(value)} className='collection-image' alt="" width={40}/>
                    {sortOptions.find((s) => s.value === value).label}
                  </Box>
                );
              }}
              
            >
              {sortOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Box mt={3}>
            {
              collectionTraits.type === 'success' ? 
              <>
              <Box>
                {
                  Object.entries(collectionTraits.data).map(([traitName, traitValue],i) => {
                    return (
                    <MAccordion expanded={expanded === `panel${i}`} onChange={handleChange(`panel${i}`)}>
                      <MAccordionSummary aria-controls={`panel${i}d-content`} id={`panel${i}d-header`}>
                        <Typography display="flex" alignItems="center">
                          <Typography variant="body1" className='accordian_title' ml="5px">{traitName}</Typography>
                        </Typography>
                      </MAccordionSummary>
                      <MAccordionDetails>
                        <Box px={3}>
                          <Grid container>
                          {
                            traitValue.map((vals) => {
                              return (
                                <Grid item lg={3}>
                                  <TraitCheckbox traitVal={vals} traitName={traitName} traitChecked={dropData.sponsors.find((s) => s.id === targetCollection)?.traits ? (dropData.sponsors.find((s) => s.id === targetCollection)?.traits[traitName]?.includes(vals)) : false} handleTargetSelection={handleTargetSelection} />
                                </Grid>
                              )
                            })
                          }
                          </Grid>
                        </Box>
                      </MAccordionDetails>
                    </MAccordion>
                    )
                  })
                }
              </Box>
              { modCollectionTraits.length > 0 &&
                <Box sx={{mt:'1.25rem'}} className='selectedTraits'>
                  <Typography textAlign="center" variant="h2">Selected</Typography>
                  <Box mt={1}>
                  {
                    modCollectionTraits.map((ct) => {
                      return (
                        ct &&
                        <TraitPill collectionTrait={ct} />
                      )
                    })
                  }
                  </Box>
                </Box>
              }
              </>
              :
                collectionTraits.type === 'request' ?
                <Box display="flex" justifyContent="center">
                  {
                    !loadingTakingMoreTime ?
                    <Box bgcolor="#F3F3F3" border="1px solid rgba(0, 0, 0, 0.30)" textAlign="center" width="250px" p="10px 20px">Loading the collection properties...</Box>
                    :
                    <Box bgcolor="#F3F3F3" border="1px solid rgba(0, 0, 0, 0.30)" textAlign="center" width="100%" p="10px 20px" display="flex" justifyContent="center" flexDirection="column">
                      <Box component="span">Loading the properties is taking longer than expected.</Box>
                      <Box component="span">Please wait a bit longer or continue without selecting properties for this collection.</Box>
                    </Box>
                  }
                </Box>
                :
                  collectionTraits.status === 'COLLECTING' ?
                  <Box display="flex" justifyContent="center">
                    {
                      !loadingTakingMoreTime ?
                      <Box bgcolor="#F3F3F3" border="1px solid rgba(0, 0, 0, 0.30)" textAlign="center" width="250px" p="10px 20px">Loading the collection properties...</Box>
                      :
                      <Box bgcolor="#F3F3F3" border="1px solid rgba(0, 0, 0, 0.30)" textAlign="center" width="100%" p="10px 20px" display="flex" justifyContent="center" flexDirection="column">
                        <Box component="span">Loading the properties is taking longer than expected.</Box>
                        <Box component="span">Please wait a bit longer or continue without selecting properties for this collection.</Box>
                      </Box>
                    }
                  </Box>
                  :
                  <Box display="flex" justifyContent="center">
                    <Box bgcolor="#F3F3F3" border="1px solid rgba(0, 0, 0, 0.30)" textAlign="center" p="10px 20px" color="#F62F23" fontSize="16px">
                      <Box>Cannot load properties for this collection.<br/>Continue without <Box component="span">Advanced Targeting</Box>, or <Link sx={{textDecoration: 'underline'}} href="https://help.step3.io/" target="_blank">contact support</Link>.</Box>
                    </Box>
                  </Box>
            }
            
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

const mapStateToProps = (state) => ({
  rewardCRD: state.rewardCRD,
  user: state.user,
  collections: state.collections,
  collectionTraits: state.collectionTraits
})

const mapDispatchToProps = {
  findCollectionTraits
}

export default connect(mapStateToProps, mapDispatchToProps)(CollectionTargeting)