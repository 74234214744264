import React, { useState, useEffect } from "react";
import "react-image-crop/dist/ReactCrop.css";
import ReactCrop, { centerCrop, makeAspectCrop, Crop, PixelCrop } from "react-image-crop";
import { Modal, Box, Typography, Button } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 24,
  p: 4,
};

const CropperModal = (props) => {
  const { open, handleClose, imgSrc, crop, setCrop, setCompletedCrop, imgRef, onImageLoaded } = props;

  const [aspect, setAspect] = useState(7.9069);
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);

  const [currCompleteCrop, setCurrCompleteCrop] = useState("");
  useEffect(() => {
    setCurrCompleteCrop(crop);
  }, []);

  const setCropAreaCompleted = (c) => {
    setCurrCompleteCrop(c);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="Crop Logo"
      sx={{ outline: "none" }}
      onBackdropClick={() => {
        return false;
      }}
    >
      <Box sx={style}>
        <Typography variant="h3" sx={{ mb: 1 }}>
          Crop {props.modal_title}
        </Typography>

        <Box mt={3} display="flex" justifyContent="center">
          <ReactCrop
            crop={crop}
            onChange={(_, percentCrop) => setCrop(percentCrop)}
            onComplete={(c) => setCropAreaCompleted(c)}
            onImageLoaded={(img) => {
              imgRef.current = img;
              // onLoad(img);
              return false; // Return false when you manually handle the loading state to prevent double handling
            }}
            aspect={aspect}
          >
            <img ref={imgRef} alt="Crop me" src={imgSrc} style={{ transform: `scale(${scale}) rotate(${rotate}deg)`, maxWidth: "100%", maxHeight: "501px" }} />
          </ReactCrop>
        </Box>

        <Box display={"flex"} justifyContent={"space-between"} mt={3}>
          <Button variant="outlined" color="primary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setCompletedCrop(currCompleteCrop);
              handleClose();
            }}
          >
            Crop
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default CropperModal;
