import React, {useEffect, useState} from 'react';
import { Box, Typography, Button, Container, Card,  CardContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow ,Paper,Alert,Snackbar } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import {DropTableRow} from 'src/components/DropItem/tablerow';
import  NewCollection from 'src/components/Modals/new_collection';
import  EditCollection from 'src/components/Modals/edit_collection';
import DeleteCollection from 'src/components/Modals/delete_collection';

import NoActiveCollection from 'src/components/NoActiveDrop/noActiveCollection';

import { getAdminCollections,deleteCollections } from 'src/services/actions';

import { connect } from 'react-redux';

import SkeletonRowData from 'src/components/DropItem/skeletonRowData';

import {gaEvent} from "../../utils";


const AdminCollections = (props) => {
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editCollectionId, setEditCollectionId] = useState('')
  const [deleteBox, setDeleteBox] = useState(false)
  const [deleteId, setDeleteId] = useState('')
  const [showSnackbar , setShowSnackbar] = React.useState(false)
  const {adminCollections,user} =props
  const currentTab = window.location.pathname.split('/')[2];


  const handleOpen = () => {
    gaEvent({
      category: "Open Collection Model",
      label: `-${user.data.eth_address} -`,
      action: 'collection_created_popup'
    });

    setOpen(true)
  };
  const handleEdit = () => setEdit(true);

  const deleteTable = (id) => {
    setDeleteBox(true)
    setDeleteId(id)

    gaEvent({
      category: "Delete Collection",
      value: id,
      label: `-${user.data.eth_address} -`,
      action: 'collection_delete_popup'
    });
  }

  const deleteCollection = async() => {
    props.deleteCollections(deleteId)
  }
  useEffect(() => {
    if(adminCollections.delete_collection_status =="success"){
      gaEvent({
        category: "Collection Delete Success",
        value: deleteId,
        label: `-${user.data.eth_address} -`,
        action: 'collection_delete_success'
      });
      setDeleteBox(false)
    }
  }, [adminCollections.delete_collection_status])

  useEffect(() => {
    props.getAdminCollections()
  }, [])

  const vertical =  'top';
  const horizontal = 'center';

  useEffect(() => {
    if(adminCollections.collections.length == 0 && adminCollections.new_collection_status == 'request'){
      setShowSnackbar(true)
      setTimeout(() => {
        setShowSnackbar(false)
      },6000)
    }
    if(adminCollections.new_collection_status == 'success'){
      setOpen(false)
    }
  },[adminCollections.collections])

  const showEditModal = (i) => {
    setEdit(true)
    setEditCollectionId(i)
  }

  useEffect(() => {
    if(adminCollections.type  == 'success'){
      
    }
  }, [adminCollections.type])

  return (
    <>
        <Snackbar
          sx={{zIndex: '7 !important'}}
            anchorOrigin={{ vertical, horizontal }}
            open={showSnackbar}
            autoHideDuration={5000}
            key={vertical + horizontal}
          >
          <Alert severity="info" sx={{ width: '100%' }}>
            It may take a while
          </Alert>
        </Snackbar>
    <Container fixed>
      <Box sx={{py: 3, mt:3,mb:1}} display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <Typography variant='h1' sx={{pb: 1,fontFamily: 'Roboto, sans-serif',fontWeight: "700", fontSize: '1.875rem' ,color: '#223354'}}>Admin Collections</Typography>
        </Box>
      </Box>     
      {/* <h1>{typeof(collection.collections)}</h1>  */}
      {adminCollections.collections &&
        adminCollections.collections.length === 0 ?
        adminCollections.type =='request' ? 
        <Card sx={{borderRadius: 0}}>
        <CardContent>
          <TableContainer component={Paper} sx={{borderRadius: 0, boxShadow: 0, py:5}}>
            <Container>
            <Table sx={{ minWidth: 650, border: 0, boxShadow: 0, borderRadius: 0, p: 5 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell width={300} sx={{fontFamily: "Roboto, sans-serif",fontWeight: "400", fontSize: "0.875rem",color: "#223354", opacity: "0.5"}}>Collection</TableCell>
                  <TableCell width={150} sx={{fontFamily: "Roboto, sans-serif",fontWeight: "400", fontSize: "0.875rem",color: "#223354", opacity: "0.5"}}>Size</TableCell>
                  <TableCell width={450} sx={{fontFamily: "Roboto, sans-serif",fontWeight: "400", fontSize: "0.875rem",color: "#223354", opacity: "0.5"}}>Description</TableCell>
                  <TableCell width={300} sx={{fontFamily: "Roboto, sans-serif",fontWeight: "400", fontSize: "0.875rem",color: "#223354", opacity: "0.5"}}>Network</TableCell>
                  <TableCell width={300} sx={{fontFamily: "Roboto, sans-serif",fontWeight: "400", fontSize: "0.875rem",color: "#223354", opacity: "0.5"}}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                <SkeletonRowData/>
                </TableRow>
              </TableBody>
            </Table>
            </Container>
          </TableContainer> 
        </CardContent>
        </Card>
        :
        <NoActiveCollection description="Hurrah!🎉 All collections are up to date." type="admin" addCollection={handleOpen}/>
        :
        adminCollections.type !=='request' &&
        <Card sx={{borderRadius: 0}}>
          <CardContent>
            <TableContainer component={Paper} sx={{borderRadius: 0, boxShadow: 0, py:5}}>
              <Container>
                <Table sx={{ minWidth: 650, border: 0, boxShadow: 0, borderRadius: 0, p: 5 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell width={300} sx={{fontFamily: "Roboto, sans-serif",fontWeight: "400", fontSize: "0.875rem",color: "#223354", opacity: "0.5"}}>Collection</TableCell>
                      <TableCell width={150} sx={{fontFamily: "Roboto, sans-serif",fontWeight: "400", fontSize: "0.875rem",color: "#223354", opacity: "0.5"}}>Size</TableCell>
                      <TableCell width={450} sx={{fontFamily: "Roboto, sans-serif",fontWeight: "400", fontSize: "0.875rem",color: "#223354", opacity: "0.5"}}>Description</TableCell>
                      <TableCell width={300} sx={{fontFamily: "Roboto, sans-serif",fontWeight: "400", fontSize: "0.875rem",color: "#223354", opacity: "0.5"}}>Network</TableCell>
                      <TableCell width={300} sx={{fontFamily: "Roboto, sans-serif",fontWeight: "400", fontSize: "0.875rem",color: "#223354", opacity: "0.5"}}>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody >
                    {adminCollections.collections.sort((a,b) => a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1).map((item,index)=> {
                      return(
                        <DropTableRow ShowEditModal={showEditModal} collectionType="admin" key={index} rowData={item} handleOpen={handleEdit} onClick={deleteTable}/>    
                      )
                    })}
                  </TableBody>
                </Table>
              </Container>
            </TableContainer> 
          </CardContent>
        </Card>
        
        }
      { open &&   
      <NewCollection open={open} handleClose={() => setOpen(false)} />
      }
      {edit &&
      <EditCollection data={adminCollections.collections.filter(item => item.id == editCollectionId)[0]} open={edit} handleClose={() => setEdit(false)} loadCollections={() => {
        setEdit(false)
      }} />
      }
      <DeleteCollection open={deleteBox} handleClose={() => setDeleteBox(false)} delete={deleteCollection} />
    </Container>
    </>
  )
}



const mapStateToProps = (state) => ({
  adminCollections : state.adminCollections,
  user: state.user,
})

const mapDispatchToProps = {
    getAdminCollections,
    deleteCollections
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminCollections)