import React, { useEffect } from 'react';
import { Dialog,DialogTitle,DialogContent,DialogActions, Button,Typography,Box } from '@mui/material';

import { connect } from 'react-redux';
import {updateRewardDrop, resetRewardCRD, setToast} from 'src/services/actions'
import {gtag} from "../../utils";

    
const EndReward = (props) => {
  const { open, handleClose,rewardCRD,user,dropsData } = props;
  const endDrop = () => {
    props.updateRewardDrop({drop_end:true},props.dropId);

    let publish_destination;
    publish_destination = dropsData.allow_embed_page ? "widget" : "";
    publish_destination = dropsData.allow_reward_page ? "branded_page" : "";
    publish_destination = dropsData.allow_embed_page && dropsData.allow_reward_page ? "branded_page_and_widget" : publish_destination;

    gtag('reward_completed',{
      reward_type: dropsData.drop_type,
      publish_destination: publish_destination,
      claims: dropsData.drop_total_claim,
      eth_address: ` ${user.data.eth_address} `
    });

    // Reward has been closed and has been moved to your Completed Rewards.
    props.setToast(true,{type:"success",message:"Reward has been closed and has been moved to your Completed Rewards."});
  }

  useEffect(()=>{
    props.resetRewardCRD();
  },[]);
      
    

  return (
    <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className='modals'
        sx={{borderRadius: '6px !important'}}
      >
        <Box className='modal-card'>
        <DialogTitle id="alert-dialog-title" display="flex" justifyContent="center" sx={{mt:4,mb:1}}>
          <img src="/assets/img/exclaimation.svg" alt="End now" />
        </DialogTitle>
        <DialogContent sx={{pl:4, pr:4}}>
            <Typography variant="h1" className='heading'>Are you sure you want to end this reward?</Typography>
            <Typography variant="h6" className='helper-text'>Heads up: Ending a reward cannot be undone.</Typography>
        </DialogContent>
        <DialogActions sx={{ mb: 4,display: 'flex', justifyContent: 'center'}}>
          <Button variant="contained" className='btn-text' onClick={handleClose} sx={{borderRadius:'6px', padding:'0.5rem 1.4rem'}}>Cancel</Button>
          {
            (rewardCRD.type == "request") ? 
            <Button className='btn-text' sx={{borderRadius:'6px', padding:'0.5rem 1.4rem', fontWeight: '500',opacity: '0.5', cursor:'not-allowed'}} autoFocus>
             Ending...
            </Button>
            :
            <Button className='btn-text' sx={{borderRadius:'6px', padding:'0.5rem 1.4rem', fontWeight: '500'}} autoFocus onClick={endDrop}>
              End Reward Now
            </Button>
          }
          
          
        </DialogActions>
        </Box>
      </Dialog>
  )
}

const mapStateToProps = (state) => ({
  rewardCRD: state.rewardCRD,
  user: state.user,
  showToast: state.showToast
})

const mapDispatchToProps = {
  updateRewardDrop,
  resetRewardCRD,
  setToast
}

export default connect(mapStateToProps, mapDispatchToProps)(EndReward)