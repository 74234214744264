import React, {useEffect, useState} from 'react'
import { Box, Typography, Button, Snackbar, Alert } from '@mui/material';

import DropItem from 'src/components/DropItem';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import NoActiveDrop from 'src/components/NoActiveDrop';
import { connect } from 'react-redux';
import { setToast } from 'src/services/actions';

import moment from "moment";
import { gtag } from "../../utils";
import ContactUsCta from 'src/components/ContactUsCta';
import Sorting from 'src/components/DropItem/sorting';



const RewardsData = (props) => {
  const vertical =  'top';
  const horizontal = 'center';
  const location = useLocation();
  const d_rewards = location?.state?.drop_rewards;
  const navigate = useNavigate();
  
  const [open, setOpen] = useState(false);
  const [openCopiedBar , setOpenCopiedBar] = useState(false)
  const [showSentAlert, setShowSentAlert] = useState(false);
  const [updatedRewardAlert, setUpdatedRewardAlert] = useState(false)

  const [activeDrops,setActiveDrops] = useState([])
  const [sortedActiveDrops,setSortedActiveDrops] = useState([])
  
  const [scheduledDrops,setScheduledDrops] = useState([])
  
  const [draftDrops,setDraftDrops] = useState([])
  
  const [completedDrops, setCompletedDrops] = useState([])

  
  const {rewardList, user, community_type} = props
  
  // console.log("rewardList =>",rewardList)
  
  useEffect(() => {
    if(location.state){
      if(location.state.published){
        let publish_destination;
         publish_destination = location.state.data.allow_embed_page ? "widget" : "";
         publish_destination = location.state.data.allow_reward_page ? "branded_page" : "";
         publish_destination = location.state.data.allow_embed_page && location.state.data.allow_reward_page ? "branded_page_and_widget" : publish_destination;

        gtag('reward_published',{
          reward_type: location.state.data.category, 
          publish_destination: publish_destination,
          eth_address: ` ${user.data.eth_address} `
        });
      }
    }
  },[location.state])

  const setRewardList = ()=>{
    if(rewardList.data){
      let draftDrps = rewardList.data.filter(item => (item.status == 0)).sort((a,b)=>{
        return new Date(a.updatedAt).getTime() <  new Date(b.updatedAt).getTime() ? 1 : -1;
      })
      setDraftDrops(draftDrps)
      sortOrder === 'drafts' && setSortedActiveDrops(draftDrps)

      let scheduledDrps = rewardList.data.filter(item => (item.status == 1 && (moment().diff(item.published_date, 'seconds') < 0))).sort((a,b)=>{
        return new Date(a.updatedAt).getTime() <  new Date(b.updatedAt).getTime() ? 1 : -1;
      })

      setScheduledDrops(scheduledDrps)

      sortOrder === 'scheduled' && setSortedActiveDrops(scheduledDrps)

      let activeRewardWithEndDate = rewardList.data.filter(item => item.status == 1 && (moment().diff(item.published_date, 'seconds') >= 0) && item.end_date!=null ).sort((a,b)=>{
        return new Date(a.end_date) - new Date(b.end_date);
      })
      let activeRewardWithOutEndDate = rewardList.data.filter(item => item.status == 1 && (moment().diff(item.published_date, 'seconds') >= 0) && item.end_date ==null ).sort((a,b)=>{
        return new Date(b.createdAt) - new Date(a.createdAt);
      })

      setActiveDrops([...activeRewardWithEndDate,...activeRewardWithOutEndDate]);
      
      sortOrder === 'active' && setSortedActiveDrops([...activeRewardWithEndDate,...activeRewardWithOutEndDate]);
      
      let completedDrps = rewardList.data.filter(item => item.status == 2).sort((a,b)=>{
        return new Date(a.drop_end_time).getTime() <  new Date(b.drop_end_time).getTime() ? 1 : -1;
      })
      setCompletedDrops(completedDrps)
    }
  }
  useEffect(() => {
    setRewardList();
  },[rewardList])

  
  useEffect(() => {
    (location?.state?.is_scheduled) && setSortOrder('scheduled')
  },[])
  useEffect(() => {
    (location?.state?.drop_rewards) && setSortOrder('drafts')
  },[])

  const [sortOrder, setSortOrder] = useState('active');

  const handleSelectChange = (event) => {
    setSortOrder(event.target.value);
  };

  const loadList = ()=>{
    setRewardList();
  }

  useEffect(() => {
    let filteredActiveDrops = [...activeDrops];
    let filteredScheduledDrops = [...scheduledDrops];
    let filteredDraftDrops = [...draftDrops];
    let filteredCompletedDrops = [...completedDrops];
    if (sortOrder !== "") {
      if (sortOrder === 'active') {
        setSortedActiveDrops(filteredActiveDrops);
      } else if (sortOrder === 'scheduled') {
        setSortedActiveDrops(filteredScheduledDrops);
      } else if (sortOrder === 'drafts') {
        setSortedActiveDrops(filteredDraftDrops)
      } else if (sortOrder === 'completed') {
        setSortedActiveDrops(filteredCompletedDrops)
      } 
    }
  }, [sortOrder])

  

  return (
    <Box>
      <Box sx={{ borderBottom: 0, borderColor: '' }}>
        {
        <Sorting sortOrder={sortOrder} handleSelectChange={handleSelectChange} selectedTab="active" />
        }
      </Box>
      {rewardList.type == 'success'?
      <>
        {sortedActiveDrops.length > 0 ?
        <>
          <Box container sx={{width:"100%",flexWrap:"wrap"}} display="flex">
            {sortedActiveDrops.map((item,index) =>{
              return(
                <Box sx={{width:"328px", mr:3}} key={item.id}>
                <DropItem keyData={index} dropsData={item} onClick={() => setOpen(true)} setOpenCopiedBar={setOpenCopiedBar} setUpdatedRewardAlert={setUpdatedRewardAlert} setShowSentAlert={setShowSentAlert} loadList={loadList} />
                </Box>
              )
            })}
          </Box>
        </>
        :
        <Box sx={{width:"100%"}} display="flex" justifyContent="space-between" flexDirection="column" alignItems="center">
          <Box></Box>
          <NoActiveDrop description={`You don't have any ${sortOrder === 'drafts' ? 'draft' : sortOrder} rewards`} new_drop={true} community_type={community_type} type="active"/>
          <Box textAlign="center">
            <ContactUsCta />
          </Box>
        </Box>
        }
      </>
      :
      <Box sx={{height: '40vh'}} display="flex" justifyContent="center" alignItems="center">
        <Typography variant="h1">LOADING...</Typography>
      </Box>
      }
      {
        openCopiedBar &&
        <Snackbar
          sx={{zIndex: '7 !important'}}
          anchorOrigin={{ vertical, horizontal }}
          open={openCopiedBar}
          autoHideDuration={5000}
          key={vertical + horizontal}
        >
          <Alert severity="success" sx={{ width: '100%' }}>Copied to clipboard!</Alert>
        </Snackbar>
      }
      {updatedRewardAlert &&
        <Snackbar sx={{zIndex: '7 !important'}} anchorOrigin={{ vertical, horizontal }} open={updatedRewardAlert} autoHideDuration={5000} key={vertical + horizontal}>
          <Alert severity="success" sx={{ width: '100%' }}>Your reward has been updated.</Alert>
        </Snackbar>
      }

      {showSentAlert &&
        <Snackbar sx={{zIndex: '7 !important'}} anchorOrigin={{ vertical, horizontal }} open={showSentAlert} autoHideDuration={5000} key={vertical + horizontal}>
          <Alert severity="success" sx={{ width: '100%' }}>Your email(s) have been sent.</Alert>
        </Snackbar>
      }
    </Box>
  )
}

const mapStateToProps = (state) => ({
  rewardList: state.rewardList,
  user: state.user,
})

const mapDispatchToProps = {
  setToast
}

export default connect(mapStateToProps, mapDispatchToProps)(RewardsData)