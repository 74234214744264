import React, { useEffect, useState } from 'react'
import { Box, Button, Card, TextField, Typography, InputAdornment } from '@mui/material'
import { ValidateEmail, ahex_to_rba, gtag, validatePhone } from 'src/utils';
import { walletCreateOtp } from '..';

function VerifyArea(props) {
  const {theme, inputVal, setInputVal, walletClicked, setWalletClicked, setOtpData, walletResponse, setWalletResponse} = props
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const {type, icon, setStep} = props;
  const [isBtnClick, setIsBtnClick] = useState(false)
  
  useEffect(() => {
    walletResponse.type === 'error' && setError(true)
  },[walletResponse])

  const formatPhoneNumber = (val) => {
    let inputValue = val;
    inputValue = inputValue.replace(/\D/g, '');

    if (inputValue.length > 3 && inputValue.length <= 6) {
      inputValue = `(${inputValue.slice(0, 3)}) ${inputValue.slice(3)}`;
    } else if (inputValue.length > 6) {
      inputValue = `(${inputValue.slice(0, 3)}) ${inputValue.slice(3, 6)}-${inputValue.slice(6)}`;
    }

    setInputVal(inputValue.replaceAll('[()\\s-]+', ''))
  };

  const handleWalletVerification = () => {
    gtag("login_initiated", {
      login_method: type === "Email" ? 'email_custodial' : 'sms_custodial',
      login_destination: `${window.location.origin}`,
    });
    setIsBtnClick(true)
    setError(false)
    setErrorMsg('');
    if ((type === 'Email' && ValidateEmail(inputVal)) || (type === 'Phone' && validatePhone(inputVal))) {
      const data = {themeInfo: {
        name: `step3`,
        logo: `${process.env.REACT_APP_BASE_API_URL}/assets/images/combomark.png`,
        bg_color: "#ED1F23",
        btn_color: '#ED1F23',
        btn_text_color: '#ffffff',
        txt_color: "#000000",
        body_bg_color: "#f3f3f3",
        center_bg_color: "#ffffff",
      }};
      if (type === "Email") {
        data['email'] = inputVal
      } else {
        data['mobile'] = `+1 ${inputVal}`
      }
      walletCreateOtp((type === 'Email' ? 'email' : 'mobile'), data, (res) => {
        setIsBtnClick(false)
        if (res.type === 'success'){
          setOtpData(res)
          setWalletClicked(type)
          setStep("2")
        } else {
          setErrorMsg(res.message)
          setError(true)
        }
      })
    } else {
      setIsBtnClick(false)
      if (type === 'Email') {
        setErrorMsg('Enter a valid email address.')
      } else {
        setErrorMsg('Please enter a valid US phone number.')
      }
      setError(true)
    }
  }

    
  return (
    <Box sx={{background: `rgba(${ahex_to_rba(theme.data.base_pm_color)}, 0.10)`, padding: "10px"}}>
        <TextField inputProps={{ maxLength: type == "Phone" && 14 }} value={inputVal} onChange={(e) => {
          setError(false); setWalletResponse({type: '', data: {}, message: ''});
          type == "Phone" ?
            formatPhoneNumber(e.target.value)
            :
            setInputVal(e.target.value)
        }} fullWidth placeholder={type == "Email" ? "Enter your email address." : '(XXX)-XXX-XXXX'} sx={{"& .MuiOutlinedInput-input": {pl:'0px !important'}, "& .MuiInputBase-root": {background: "#ffffff","& .MuiOutlinedInput-notchedOutline": {borderColor: error ? "#ED1F23" : "#000"}, "&:hover": {background: `rgba(${ahex_to_rba(theme.data.base_pm_color)}, 0.10)`, "& .MuiOutlinedInput-notchedOutline": {borderColor: "#000000"}}, "&:focus-within": {background: "#ffffff", "& .MuiOutlinedInput-notchedOutline": {borderWidth: "3px",borderColor: `${theme.data.base_pm_color} !important`}},"&:active": {background: `rgba(${ahex_to_rba(theme.data.base_pm_color)}, 0.10)`, "& .MuiOutlinedInput-notchedOutline": {borderWidth: "3px",borderColor: `${theme.data.base_pm_color} !important`}}}}}
        InputProps={{
          startAdornment:(
            <InputAdornment position="start">
              <Box sx={{display:"flex"}}>
                <Box sx={{position: 'relative', display: "flex"}} id="image_preview">
                  <img src={`/icons/${icon}-icon.svg`} alt={`${icon} icon`}/>
                </Box>
                {
                  type === 'Phone' &&
                <Box sx={{position: 'relative', display: "flex"}} id="image_preview">+1</Box>
                }
              </Box>
            </InputAdornment>
          )
        }}/>

        {
          walletResponse.type === 'error' &&
          <Typography variant='body2' sx={{mt: 0.5, fontSize: "11px", color: "#000"}} className="font-gs-md"><span style={{color: "#ED1F23"}}>{walletResponse.message}</span></Typography>
        }
        {error ? 
          <Typography variant='body2' sx={{mt: 0.5, fontSize: "11px", color: "#000"}} className="font-gs-md">
            {
              (errorMsg.startsWith(`Invalid 'To' Phone Number`)) ? 
              <span style={{color: '#1A1616'}}><span style={{color: "#ED1F23"}}>Not a valid number.</span> International user? Use another method.</span>
              :
              <span style={{ color: "#ED1F23" }}>{errorMsg}</span>
            }
          </Typography>
          :
          type == "Email" ? 
            <Typography variant='body2' sx={{mt: 0.5, fontSize: "11px", color: "#000"}} className="font-gs-md">We'll send you a one-time code to verify your address.</Typography>
            :
            <Typography variant='body2' sx={{mt: 0.5, fontSize: "11px", color: "#000"}} className="font-gs-md">We’ll text you to verify your number. By clicking continue, you agree to receive SMS messages for authentication purposes only.</Typography>
        }

        <Button variant='contained' disabled={isBtnClick} sx={{"&.Mui-disabled": {background: `rgba(${ahex_to_rba(theme.data.base_pm_color)}, 0.1)`, color: "#8D8B8B", border: `1px solid ${theme.data.base_pm_color}`}, lineHeight: "normal", p: "8px 20px",fontFamily: "General Sans Semibold", fontSize: "1rem", mt:1, background: false && "linear-gradient(0deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.15) 100%), var(--fills-primary-primary-fill-100, #DEFB02)", background: isBtnClick ? `rgba(${ahex_to_rba(theme.data.base_pm_color)}, 0.1)` : theme.data.base_pm_color, color: theme.data.base_pm_text_color, "&:hover": {background: `linear-gradient(0deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.15) 100%), var(--fills-primary-primary-fill-100, ${theme.data.base_pm_color})`}}} fullWidth className="font-gs-sb" onClick={handleWalletVerification}>Continue {isBtnClick && <div className='spinner'/>}</Button>
    </Box>
  )
}

export default VerifyArea