import React,{useState} from 'react'
import { Modal, Box, Typography, TextField, Button } from '@mui/material';
import {updateAdminCollections} from '../../services/actions'
import { connect } from 'react-redux';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '0px solid #000',
    boxShadow: 24,
    p: 4,
  };

const EditCollection = (props) => {
  const { open, handleClose,data } = props;
  const [ title, setTitle] = useState(data.title) 
  const [ details, setDetails] = useState(data.details) 
  const [collectionImage, setCollectionImage] = useState(data.logo_image)
  const [totalSupply, setTotalSupply] = useState(parseInt(data.total_supply))
  const {adminCollections} = props

  const updateCollection = () => {
    let updatedData = {
      id: data.id,
      title,
      details,
      total_supply: totalSupply,
      logo_image:collectionImage
    }
    props.updateAdminCollections(updatedData, (res) => {
      if(res.type == 'success'){
        props.loadCollections()
      }
    })
  }

  const handleFileSelect = (e) => {
    setCollectionImage(e.target.files[0])
  }

    return (
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="Edit Form Field"
        sx={{outline: 'none'}}
        onBackdropClick={()=>{return false}}
      >
        <Box sx={style}>
          <Typography variant="h3" sx={{mb: 1}}>Edit Collection</Typography>
          <Typography variant="p">Locate the NFT collections' address below and we will fill in the  details for you. If you don't know where to get the ETH address, watch this Loom video.</Typography>
          <Box mt={3}>
  
            <Box display="flex" justifyContent="start" alignItems="center" mb={1}>
              <img src={collectionImage == null ? "/assets/images/dummy_collection.png" : data.logo_image ? data.logo_image: URL.createObjectURL(collectionImage) } style={{height:"100px", width:"100px", borderRadius:"20px"}} alt="" />
              <Box ml={2} display="flex" flexDirection="column">
                <Button
                    variant="contained"
                    component="label"
                    size="small"
                    color="secondary"
                  >
                    Upload File
                    <input
                      type="file"
                      hidden
                      id="logo_image"
                      name="banner"
                      accept="image/png, image/jpeg, image/jpg, image/gif, image/svg+xml"
                      onChange={(e) => handleFileSelect(e)}
                    />
                  </Button>
                <Button size="small" sx={{mt: 1}}>Restore Original</Button>
              </Box>
            </Box>
  
  
            <TextField fullWidth label='Name' name="name" variant="outlined" InputLabelProps={{shrink: true}} placeholder="Creature World" value={title} onChange={(e) => setTitle(e.target.value) } margin="normal" />
  
            <TextField fullWidth multiline rows={3} margin="normal" label='Description' name="description" value={details} onChange={(e) => setDetails(e.target.value)}/>
            <TextField fullWidth type="number" margin="normal" label='Total Supply' name="total_supply" value={totalSupply} onChange={(e) => setTotalSupply(e.target.value)}  />
          </Box>
  
          <Box display={'flex'} justifyContent={'space-between'} mt={3}>
            <Button variant="outlined" color="primary" onClick={handleClose}>Cancel</Button>
            <Button variant="contained" color="primary" sx={{cursor: adminCollections.editType == "request" ? "not-allowed":"pointer", opacity:adminCollections.editType == "request" ? '0.5':"1"}} onClick={adminCollections.editType !== "request" ?updateCollection: undefined}>{adminCollections.editType == "request"? "Updating..." : "Update"}</Button>
          </Box>
        </Box>
      </Modal>
    )
  }

const mapStateToProps = (state) => ({
  adminCollections:state.adminCollections 
})

const mapDispatchToProps = {
  updateAdminCollections
}

export default connect(mapStateToProps, mapDispatchToProps)(EditCollection)