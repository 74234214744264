import React, { useEffect, useState } from 'react'
import { Autocomplete, Box, FormControl, Grid, TextField, Typography, InputLabel } from '@mui/material';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';
import moment from 'moment-timezone';
import { formatDateToDbSup, formatMomentObjectToDate, formatMomentObjectToTime} from 'src/utils';
import NetworkSelectionOptions from '../NetworkSelectionOptns';
import {RewardDateAndTimePicker} from '../DateAndTimePicker';

function AllowList(props) {
  const {handleChildData, dropData, nextTabErrors, setNextTabErrors} = props;
  const [allowList, setAllowList] = useState(dropData?.allowList)
  const [dtPickerOpen, setDtPickerOpen] = useState(false);

  const [focused, setFocused] = useState({
    project_name: false,
    description: false,
  });

  const handlfocus = (key, is_focus) => {
    const cFocused = {...focused};
    cFocused[key] = is_focus;
    setFocused(cFocused)
  }

  const [mintDateValue, setMintDateValue] = useState(formatMomentObjectToDate(dropData?.allowList?.mint_date));
  const [mintTimeValue, setMintTimeValue] = useState(formatMomentObjectToTime(dropData?.allowList?.mint_date));
  const [value, setValue] = React.useState(formatDateToDbSup(dropData?.allowList?.mint_date));

  const handleData = (type, val) => {
    let existing_data = {...allowList};
    existing_data[type] = val
    setAllowList(existing_data)
  }

  const handleChange = (newValue) => {
    if (newValue !== "Invalid date"){
      handleData('mint_date', newValue)
      setValue(newValue);
    }
    // setNextTabErrors({...nextTabErrors,['mint_date_and_time']:{value: null, error: ""}});
  };

  const handleNetworkChange = (elem) => {
    handleCoupValueData('network', elem.target.value)
  }

  useEffect(() => {
    handleChildData('allowList', allowList)
  } ,[allowList]);
  
  const handleCoupValueData = (type, val) => {
    if (type === 'project_website') {
      props.setNextTabErrors({...props.nextTabErrors, ['allow_list_website']: {value: val, error: ""}})
    } else if (type === 'discord_url') {
      props.setNextTabErrors({...props.nextTabErrors, ['allow_list_disord_url']: {value: val, error: ""}})
    } else if (type === 'price') {
      props.setNextTabErrors({...props.nextTabErrors, ['allow_list_price']: {value: val, error: ""}})
    } else {

      setNextTabErrors({...nextTabErrors,[type]:{value: typeof val=="string" ?  String(val).trim() : val,error:""}});
    }
    handleData(type, val);
  }

  return (
    <Box className="event-pass-form">
      <>
      {dropData.status !== 1 &&
        <>
          <Typography variant="body1" textAlign="center" className='subtitle' lineHeight="normal" mb="20px">CREATE REWARD</Typography>
          <Typography variant="h1" textAlign="center" className="title" mb={2}>Add your project & allow list details</Typography>
          <Typography variant="body2" textAlign="center" sx={{maxWidth: "600px", margin:"auto"}} className="detail-box">The user’s ETH address will automatically be captured when they claim. You’ll be able to download this information as a CSV file.</Typography>
        </>
        }
        <Box pt={dropData.status == 1 ? 0 : 4}>
          <Box>
            <TextField fullWidth margin="normal" label='Project Name' name="project_name" variant="outlined" InputLabelProps={{shrink: true}} placeholder="What is the name of the NFT collection?" onFocus={() => handlfocus("project_name", true)} onBlur={() => handlfocus("project_name", false)} onChange={(e) => handleCoupValueData('project_name', e.target.value)} defaultValue={dropData?.allowList?.project_name} inputProps={{maxLength: 50}} helperText={
              <Box component="span" className='charTxt'>
                <Box component="span" display={focused.project_name ? 'block' : 'none'}>
                {dropData?.allowList?.project_name.length > 0 ? `${dropData?.allowList?.project_name.length} / 50` : false}
                </Box>
              </Box>
            } FormHelperTextProps={{ style: { textAlign: 'right', color: (dropData?.allowList?.project_name.length == 50) ? 'red' : 'rgba(34, 51, 84, 0.7)' } }} />
            
            <TextField fullWidth margin="normal" label='Project Website' name="project_website" variant="outlined" InputLabelProps={{shrink: true}} placeholder="What is the URL of the mint page?" onChange={(e) => handleCoupValueData('project_website', e.target.value)} defaultValue={dropData?.allowList?.project_website} error={nextTabErrors.allow_list_website.error !== ""} helperText={nextTabErrors.allow_list_website.error} />

            <Box display="flex">
              <TextField type="number" InputProps={{ inputProps: {step:"any"} }} step="any" onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() } fullWidth margin="normal" label='Price' name="price" variant="outlined" InputLabelProps={{shrink: true}} placeholder="How much does it cost?" onChange={(e) => handleCoupValueData('price', e.target.value)} defaultValue={dropData?.allowList?.price} error={nextTabErrors.allow_list_price.error !== ""} helperText={nextTabErrors.allow_list_price.error} />
              <>
                <FormControl className='merch-form' sx={{marginTop:"1rem", ml:1}} fullWidth>
                <InputLabel id="demo-simple-select-label" sx={{mt: (dropData?.allowList?.network == "") ? "-7px" : ""}}>Crypto</InputLabel>
                    <NetworkSelectionOptions handleChange={handleNetworkChange} errorStatus={false} network={dropData?.allowList?.network}/>
                </FormControl>
              </>
            </Box>

            <TextField fullWidth margin="normal" label='Discord URL' name="discord_url" variant="outlined" InputLabelProps={{shrink: true}} placeholder="What is the URL of your Discord site?" onChange={(e) => handleCoupValueData('discord_url', e.target.value)} defaultValue={dropData?.allowList?.discord_url} error={nextTabErrors.allow_list_disord_url.error !== ""} helperText={nextTabErrors.allow_list_disord_url.error}/>
            <Box my={1.5}>
              {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  label={`Mint Date & time`}
                  mask="L hh:mm A"
                  minDateTime={dayjs()}
                  value={value}
                  onChange={handleChange}
                  open={dtPickerOpen}
                  onOpen={() => setDtPickerOpen(true)}
                  onClose={() => setDtPickerOpen(false)}
                  renderInput={(params) => <TextField {...params} required={false} error={false} placeholder="Hi" name="Mint Date" fullWidth variant="outlined" InputLabelProps={{shrink: true }} onClick={(e) => setDtPickerOpen(true)} inputProps={{ ...params.inputProps, placeholder: "What’s the date & time of the mint?"}}/>}
                  showToolbar={true}
                  ToolbarComponent={() => <Box px={2} pt={2}>Select Date & Time</Box>}
                  sx={{
                    '& .MuiPickersToolbar-penIconButton': {display: 'none'}
                  }}
                  />
              </LocalizationProvider> */}
              <RewardDateAndTimePicker formtype="new" dropData={dropData} nextTabErrors={nextTabErrors} setNextTabErrors={setNextTabErrors} picker_type={["mint_date", "mint_time"]} suffix_name="Mint" handleChildData={handleChange} startDateValue={mintDateValue} setStartDateValue={setMintDateValue} startTimeValue={mintTimeValue} setStartTimeValue={setMintTimeValue} timeWidth="16.25rem" dateWidth="50rem" />
            </Box>
            <Box className="textareArea">
              <TextField fullWidth multiline rows={5} margin="normal" label='Description' name="description" variant="outlined" InputLabelProps={{shrink: true}} placeholder="Is there anything else they should know?" onFocus={() => handlfocus("description", true)} onBlur={() => handlfocus("description", false)} onChange={(e) => handleCoupValueData('description', e.target.value)} defaultValue={dropData?.allowList?.description} helperText={
              <Box component="span" className='charTxt'>
                <Box component="span" display={focused.description ? 'block' : 'none'}>
                {dropData?.allowList?.description.length > 0 ? `${dropData?.allowList?.description.length} / 130` : false}
                </Box>
              </Box>
            } inputProps={{ maxLength: 130 }} FormHelperTextProps={{ style: { textAlign: 'right', color: (dropData?.allowList?.description.length == 130) ? 'red' : 'rgba(34, 51, 84, 0.7)' } }} />
            </Box>
          </Box>
        </Box>
      </>
    </Box>
  )
}

export default AllowList