import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { Box, Typography } from '@mui/material'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import VerifyArea from '../verifyArea';
import AppleAuth from './apple';
import GoogleAuth from './google';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ahex_to_rba } from 'src/utils';


export async function kmsCreateAccount(data, callback) {
  try {
    let user_obj = await axios.post(
      `${process.env.REACT_APP_AWS_KMS_URL}/api/create_account`,
      { wallet: data.wallet, wallet_type: data.wallet_type }
    );
    callback(user_obj.data);
  } catch (e) {
  }
}

export async function kmsSignAccount(data, callback) {
  try {
    let user_obj = await axios.post(
      `${process.env.REACT_APP_AWS_KMS_URL}/api/signInAccount`,
      { ...data }
    );
    
    callback(user_obj.data);
  } catch (e) {
    console.log("e.message =>", e.message);
  }
}

export async function updateUser(data, callback) {
  let user_obj = await axios.post(
    `${process.env.REACT_APP_BASE_APPLE_URL}/api/v1/account_update`,
    { ...data }
  );
  callback(user_obj.data);
}

function WalletCard(props) {
  const {setStep, walletResponse, setWalletResponse, walletClicked, setWalletClicked, signMessage, inputVal, setInputVal, setOtpData} = props
  const {name, icon, step, allowClick} = props.data;

  const {openedLoginT, setOpenedLoginT, theme} = props;


  return (
    <>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        {((name == openedLoginT) && walletClicked == "") ?
          <VerifyArea setOtpData={setOtpData} inputVal={inputVal} setInputVal={setInputVal} signMessage={signMessage} setWalletClicked={setWalletClicked} walletClicked={walletClicked} type={openedLoginT} icon={icon} setStep={setStep} theme={theme} walletResponse={walletResponse} setWalletResponse={setWalletResponse} />
          :
          (name === 'Apple')?
          <Box>
            <AppleAuth signMessage={signMessage} theme={theme} setWalletClicked={setWalletClicked} walletClicked={walletClicked} walletResponse={walletResponse} setWalletResponse={setWalletResponse} />
          </Box>
          :
          (name === "Google") ?
          <Box>
            <GoogleAuth signMessage={signMessage} theme={theme} setWalletClicked={setWalletClicked} walletClicked={walletClicked} walletResponse={walletResponse} setWalletResponse={setWalletResponse} />
          </Box>
          :
          <Box sx={{p: "15px", borderRadius: "7px", border: "1px solid #000", "&:hover": {background:  `rgba(${ahex_to_rba(theme.data.base_pm_color)}, 0.1)`}, my:"20px", cursor: walletClicked != "" ? "default" : "pointer", opacity: walletClicked != "" ? "0.6" : "1"}} onClick={() => {
            setInputVal('');
            setWalletResponse({type: '', data: {}, message: ''});
            if(walletClicked == ""){
              if(allowClick){
                setOpenedLoginT(name)
              }
              if(step == "3"){
                setStep(step)
              }
            }
          }}>
            <Box sx={{display: "flex", justifyContent: "space-between"}}>
              <Box display="flex" alignItems="center">
                <img src={`/icons/${icon}-icon.svg`} alt={`${icon} icon`}/>
                <Typography variant='h5' className="font-gs-md" sx={{ml: "10px", fontSize: "1rem", lineHeight: "normal", color: "#000"}}>{name}</Typography>
              </Box>
                {step == "3" &&
                  <KeyboardArrowRightIcon sx={{color: "#000"}}/>
                }
            </Box>
          </Box>
        }
      </GoogleOAuthProvider>
    </>
  )
}

export default WalletCard