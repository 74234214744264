import { Avatar, Box, Checkbox, FormControlLabel, Typography, Tooltip } from '@mui/material'
import React from 'react'
import { limitText, trimStr } from 'src/utils'

export const TraitPill = ({collectionTrait}) => {
  return (
    <Box sx={{border: "1px solid #000", mr: '20px', mb: 1, borderRadius: "1000px", display: "inline-flex", alignItems: 'center', padding: "7px", fontFamily: "Poppins, sans-serif", fontWeight: "400", fontSize: "14px"}}>
      <Avatar src={(collectionTrait.logo_image !== null && collectionTrait.logo_image !== '') ? collectionTrait.logo_image : '/assets/images/default_collection.svg'} alt={collectionTrait.title} className='sponsorcol_image' sx={{width: '25px', height: '25px'}} />
      
      <Tooltip title={collectionTrait.trait.length > 12 ? collectionTrait.trait : ''}><Box component="span" style={{fontWeight: "700"}}>{trimStr(collectionTrait.trait, 14)}:</Box></Tooltip>
      <Tooltip title={collectionTrait.value.length > 12 ? collectionTrait.value : ''}><Box component="span" style={{marginLeft: '0.25rem'}}>{trimStr(collectionTrait.value, 12)}</Box></Tooltip>
    </Box>
  )
}

export const TraitCheckbox = (props) => {
  const {traitVal, traitName, traitChecked, handleTargetSelection} = props
  return (
    
    <FormControlLabel key={traitVal} control={<Checkbox icon={<img src="/assets/img/Uncheck.svg" width="100%" alt="Uncheck" />} checkedIcon={<img src="/assets/img/Checkbox.svg" width="100%" alt="Checked" />} checked={traitChecked} onChange={(e) => {handleTargetSelection(traitName, traitVal, e.target.checked)}} disableRipple />} label={
      <Box>
          {
            traitVal !== null &&
            <Tooltip title={traitVal.length > 9 ? traitVal : ''}>
            <Typography variant="h4" sx={{fontSize:"0.875rem", color:"#000", fontFamily:"Roboto, sans-serif", fontWeight:"600"}}>{trimStr(traitVal, 9)}</Typography>
            </Tooltip>
          }
      </Box>
      }
    />
    
  )
}

// export default TraitPill