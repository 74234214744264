import React from 'react';
import { Dialog,DialogTitle,DialogContent,DialogActions, Button, Typography, Box } from '@mui/material';

import { connect } from 'react-redux';

  
  const DeletePollChoice = (props) => {
    const { open, handleClose, choices, setChoices, choiceItem } = props;

    const removeBox = () => {
      let newChoices = [...choices];
      newChoices = newChoices.filter((c, ind) => ind != choiceItem);
      setChoices(newChoices);
      handleClose(true)
    }
    
    return (
      <Dialog open={open} onClose={()=>{ handleClose() }} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className='modals'>
          <Box className='modal-card'>
            <DialogTitle id="alert-dialog-title" display="flex" justifyContent="center" sx={{mt:4,mb:4}}>
              <img src="/assets/img/delete-icon.svg" alt="delete" />
            </DialogTitle>
            <DialogContent sx={{pl:4, pr:4}}>
              <Typography variant="h1" className='heading'>Are you sure you want to remove this option?</Typography>
            </DialogContent>
            <DialogActions sx={{ mb: 4,display: 'flex', justifyContent: 'center'}}>
              <Button onClick={handleClose} variant="contained" className='btn-text'>Cancel</Button>
              <Button onClick={removeBox} autoFocus className='btn-text'>
                Delete Choice
              </Button>
            </DialogActions>
          </Box>
      </Dialog>

  )
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(DeletePollChoice)