import React, { useEffect, useState} from 'react';
import { Modal, Box, Typography, TextField, Grid, FormControlLabel, Checkbox, Button, Snackbar, Alert} from '@mui/material';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { downloadSiteData } from 'src/services/actions';
import { humanize } from 'src/utils';
import moment from 'moment-timezone';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '0px solid #000',
  boxShadow: 24,
  borderRadius: '8px',
  p: 4
};

const ReportFilterModal = (props) => {
  const {open, export_type, handleClose} = props;
  const [fromValue, setFromValue] = useState(null);
  const [toValue, setToValue] = useState(null);
  const [dtError, setDtError] = useState(false);

  const exportSiteData = () => {
    props.downloadSiteData(export_type, fromValue, toValue)
  }
  
  useEffect(() => {
    if (fromValue != null && toValue != null) {
      if (toValue < fromValue) {
        setDtError(true)
      } else {
        setDtError(false)
      }
    } else {
      setDtError(false)
    }
  },[toValue, fromValue])

  const handleDtPicker = (dt_type, val) => {
    
    if (dt_type == 'from'){
      setFromValue(val)
    } else {
      setToValue(val)
    }

    
  }

  return (
    <Modal open={open} aria-labelledby="Reward Coming Soon">
      <Box sx={style}>
        <Typography variant="h3" sx={{mb: 1, fontFamily: "Roboto, sans-serif", fontWeight: "700",fontSize: "1.5rem"}}>{humanize(export_type)}</Typography>
        <Typography variant="p" sx={{lineHeight: "140%",fontFamily: "Roboto, sans-serif",fontWeight: "400", fontSize: "1rem",color: "#223354"}}>Select a date range to export report.</Typography>
        <Box display="flex" justifyContent="center">
        </Box>
        
        <Box display="flex" justifyContent="start" alignItems="center" mt={3}>
          {/* <LocalizationProvider dateAdapter={AdapterDayjs} >
            <DatePicker
              label="From"
              value={fromValue}
              onChange={(newValue) => {
                handleDtPicker('from',newValue)
              }}
              renderInput={(params) => <TextField {...params} error={false} InputLabelProps={{shrink: true }} />}
            />
          </LocalizationProvider> */}
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DateTimePicker']}>
            <DatePicker
              label={`From`}
              value={dayjs(fromValue)}
              onChange={(newValue) => {
                handleDtPicker('from',newValue)
              }}
              slotProps={{textField: {error: false, InputLabelProps: {shrink: true}, inputProps: {placeholder: "From Date"}}}}
            />
            </DemoContainer>
          </LocalizationProvider>

          {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="To"
              value={toValue}
              onChange={(newValue) => {
                handleDtPicker('to',newValue)
              }}
              renderInput={(params) => <TextField sx={{ml: 2}} {...params} error={dtError} InputLabelProps={{shrink: true }} />}
            />
          </LocalizationProvider> */}

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DateTimePicker']}>
            <DatePicker
              label={`To`}
              value={dayjs(toValue)}
              onChange={(newValue) => {
                handleDtPicker('to',newValue)
              }}
              sx={{ml: 2}}
              slotProps={{textField: {error: false, InputLabelProps: {shrink: true}, inputProps: {placeholder: "To Date"}}}}
            />
            </DemoContainer>
          </LocalizationProvider>
        </Box>
        <Box display={'flex'} justifyContent={'space-between'} mt={5}>
          <Button variant="outlined" color="secondary" onClick={handleClose} sx={{borderRadius:' 6px', padding:'0.5rem 1.4rem', border: "1px solid #6E759F", color: "#6E759F", px:4,fontSize: "0.938rem"}}>Cancel</Button>
          
          <Button variant="contained" color="primary" sx={{borderRadius:' 6px', padding:'0.5rem 1.4rem',fontFamily: "Roboto,sans-serif", fontWeight: "700", fontSize: "0.938rem", px:3}} onClick={() => {handleClose(true);exportSiteData();}} disabled={dtError}>Export</Button>
        </Box>
      </Box>      
    </Modal>
  )
}


const mapStateToProps = (state) => ({
})

const mapDispatchToProps = {
  downloadSiteData
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportFilterModal)