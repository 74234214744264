import React, { useState} from 'react';
import { Modal, Box, Typography, TextField, Grid, FormControlLabel, Checkbox, Button, Snackbar, Alert} from '@mui/material';
import { connect } from 'react-redux';
import { reward_drop_inquiry, setToast } from 'src/services/actions';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '0px solid #000',
  boxShadow: 24,
  borderRadius: '8px',
  pt:5
};

const DropComingSoon = (props) => {
  const vertical =  'top'
  const horizontal = 'center'
  const [isSubmit, setIsSubmit] = useState(false);
  const [responseAlert, setResponseAlert] = useState('');
  const { open, handleClose, rewardCRD, setShowInquiryAlert } = props;

  const [inquiryFormData, setInquiryFormData] = useState({
    drop_type: rewardCRD.data.category,
    message: "",
    notify: true
  })
  const handleChange = (type, val) => {
    const formvals = {...inquiryFormData};
    formvals['drop_type'] = rewardCRD.data.category
    formvals[type] = val
    setInquiryFormData(formvals)
  }

  const sendDropInquiry = async() => {
    setIsSubmit(true)
    props.reward_drop_inquiry(inquiryFormData, (resp) => {
      if (resp.type == 'success') {
        handleClose(true)
        props.setToast(true, {type: 'info', message: 'Inquiry submitted successfully.'});
      } else {
        setResponseAlert(resp.message)
        setTimeout(() => {
          setResponseAlert('');
        },5000);
      }

      setIsSubmit(false)
    })
  }

  return (
    <Modal open={open} aria-labelledby="Reward Coming Soon" className='modals'>
      <Box sx={style} className='modal-card'>
        <Typography variant="body2" sx={{fontFamily: "Roboto, sans-serif", fontWeight: "600", fontSize: "1.875rem", mb: 0}} align="center">This reward is coming soon...</Typography>
        <Box display="flex" justifyContent="center">
          <Box width="200px" sx={{borderBottom: "0.5px solid rgba(34, 51, 84, 0.5)"}}>&nbsp;</Box>
        </Box>
        <Typography variant="body1" align="center" sx={{mt:2,fontFamily: "Roboto,sans-serif", fontWeight: "600", fontSize: "1rem"}}>Tell us more to get early access</Typography>
        
        <Box display='flex' justifyContent="center" sx={{mt:1}} className='textareArea'>
          <Grid container justifyContent="center">
            <Grid item lg={7}>
              <TextField fullWidth multiline rows={3} margin="normal" name="short_description"variant="outlined" InputLabelProps={{shrink: true}} placeholder='How will you use this reward? How many would you like to offer? Who is it for?' inputProps={{ maxLength: 255 }} onChange={(e)=>handleChange("message",e.target.value)} />
            </Grid>
          </Grid>
        </Box>
        <Box display="flex" justifyContent="center">
          <FormControlLabel sx={{mt:2}} control={<Checkbox name="terms" color="primary" onChange={(e)=>handleChange("notify",e.target.checked)} checked={inquiryFormData.notify} /> } label={<><Typography variant="body2" sx={{color: '#000000',fontFamily:"Roboto, sans-serif", fontSize: "15px", fontWeight: "400"}}>Notify me when this reward is ready</Typography></>} />
        </Box>
        <Box sx={{mt:4,py:3,px:5,background:"rgba(34, 51, 84,0.02)"}}>
          <Box display="flex" justifyContent="space-between">
            <Button variant="outlined" color="primary" sx={{fontFamily: "Roboto, sans-serif", fontWeight: "700" ,fontSize: "15px"}} onClick={handleClose}>Cancel</Button>
            <Button variant="contained" color="primary" sx={{fontFamily: "Roboto, sans-serif", fontWeight: "700" ,fontSize: "15px"}} onClick={sendDropInquiry} disabled={isSubmit}>{isSubmit ? 'Submitting' : 'Submit'}</Button>
          </Box>
        </Box>
        {
          (responseAlert != "") &&
          <Snackbar sx={{zIndex: '7 !important'}} anchorOrigin={{ vertical, horizontal }} open={true} autoHideDuration={5000}><Alert severity="info" sx={{ width: '100%' }}>{responseAlert}</Alert></Snackbar>
        }
      </Box>


      
    </Modal>
  )
}


const mapStateToProps = (state) => ({
  rewardCRD: state.rewardCRD
})

const mapDispatchToProps = {
  reward_drop_inquiry,
  setToast
}

export default connect(mapStateToProps, mapDispatchToProps)(DropComingSoon)