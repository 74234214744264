import React, { Suspense, StrictMode } from "react";
import { Provider } from "react-redux";
import { stopReportingRuntimeErrors } from "react-error-overlay";
import "./style/style.css";

import "./App.css";

import configureStore from "./services/configureStore";
import Routes from "./routes";
import ThemeProvider from "./theme/ThemeProvider";

import ReactGA from "react-ga4";
const options = {
  testMode: false,
};
ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS, options);
ReactGA.send("pageview");

const store = configureStore();

if (process.env.REACT_APP_NODE_ENV == "production") {
  // stopReportingRuntimeErrors(); // disables error overlays
}

// for redirecting them at new server at https
if (
  process.env.REACT_APP_NODE_ENV == "production" &&
  (window.location.host == "app.polyientx.com" ||
    window.location.protocol != "https:")
) {
  window.location.href = "https://app.step3.io/";
}

function App() {
  return (
    <StrictMode>
      <Provider store={store}>
        <Suspense fallback={null}>
          <ThemeProvider>
            <Routes />
          </ThemeProvider>
        </Suspense>
      </Provider>
    </StrictMode>
  );
}

export default App;
