import React, { useState} from 'react';
import { connect } from 'react-redux';
import PartnerForm from './form';


const NewPartnerForm = (props) => {
  const { user, open, handleClose } = props;
  const [formData, setformData] = useState({
    fname: {value: '', error: ''},
    lname: {value: '', error: ''},
    membership_title: {value: '', error: ''},
    email: {value: '', error: ''},
    image: {value: '', error: ''},
    nav_image: {value: '', error: ''},
    stripe_product_id: {value: '', error: ''},
    brand_data: {value: user.data.brand_data, error: ''},
    other: {value: user.data.membership_page_info.other, error: ''},
    contract_name: {value: user.data.membership_nft_details?.contract_name, error: ''},
    nft_metadata: {value: user.data.membership_nft_details?.nft_metadata, error: ''},
  })

  return (
    <PartnerForm formTitle={'Add New Partner'} open={open} handleClose={handleClose} formData={formData} setformData={setformData} />
  )
}


const mapStateToProps = (state) => ({
  page: state.page,
  user: state.user,
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(NewPartnerForm)