import collections from "src/pages/collections";

const initalState = {
    type: '',
    collections : [],
    message: '',
    new_collection_status: '',
    delete_collection_status: '',
    contract_type: 'ERC721',
    record_type: '',
    error_data: []
}


export function collectionReducer(state = initalState, action){
  let obj = state;
    switch (action.type) {
      case "GET_COLLECTION_SUCCESS":
        return{type: 'success', collections: action.data};
      case "DELETE_COLLECTION_SUCCESS":
        return{delete_collection_status: 'success', collections: state.collections.filter(collection => collection.id !== action.id)};
      case "DELETE_COLLECTION_REQUEST":
        return{...state, delete_collection_status: 'request'};
      case "GET_COLLECTION_ERROR":
        return{type: 'error', message: action.message};
      case "REQUEST_COLLECTION":
        return{ type: 'request',collections: []};
      case "RESET_COLLECTION_CD_STATE":
        return{ ...state, new_collection_status: '',contract_type: 'ERC721',message: "",delete_collection_status: ''};
      case "NEW_COLLECTION_ERROR":
        return{ ...state, new_collection_status: 'error',message: action.message, error_data: action.error_data};
      case "NEW_COLLECTION_REQUEST":
        return{ ...state, new_collection_status: 'request'};
      case "NEW_COLLECTION_SUCCESS":
        if (action.contract_type == 'ERC1155'){
          if (action.record_type == 'new'){

            return{...state, message: '', error_data: [], new_collection_status:'success', contract_type: action.contract_type,collections: [...state.collections, ...action.newCollection]};
          } else {
            return{...state, message: '', error_data: [], new_collection_status:'success', contract_type: action.contract_type,collections: [...state.collections]};
          }
        } else {
          return{...state, message: '', error_data: [], new_collection_status:'success', contract_type: 'ERC721',collections: [...state.collections, action.newCollection]};
        }
      default:
        return state;
    }
    return state;
  }

export function collectionTraitsReducer(state = {type:"",data:{}, status: '',message:[]}, action){
  let obj;
  switch (action.type) {
    case "COLLECTION_TRAITS_REQUEST":
      return {type:"request",data: {}, message:"", status: ''}
    case "COLLECTION_TRAITS_SUCCESS":
      return{type: 'success', data: action.data, status: action.status};
    case "COLLECTION_TRAITS_ERROR":
      return{ ...state, type: 'error', message: action.message,status: action.status};
    default:
      return state;
  }  
}