import React, {useEffect, useState} from 'react';
import { Modal,Box, Button,Typography, Card, Avatar, TextField } from '@mui/material';
import { connect } from 'react-redux';
import { alphaHex } from 'src/utils';

  
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    border: '0px solid #000',
    boxShadow: 24,
    p: 3,
    borderRadius:"8px",
    maxHeight: "87.5vh",
    overflowY: "auto"
  };

function CustomRewardPreview(props) {
  const { open, handleClose, info, user} = props;
  const [selectedOption, setSelectedOption] = useState('')
  const [basePmColor1, setBasePmColor1] = useState('rgba(26, 117, 255, 0.1)')
  const [basePmColor2, setBasePmColor2] = useState('rgba(26, 117, 255, 0.4)')
  // console.log("user =>",alphaHex(user.data.brand_data.primary))

  useEffect(() => {
    const color1 = alphaHex(`#${user.data.brand_data.primary}`, 0.1)
    const color2 = alphaHex(`#${user.data.brand_data.primary}`, 0.4)
    setBasePmColor1(color1)
    setBasePmColor2(color2)
  },[])

  return (
    <Box className='preview-modal'>
        <Typography variant="h6" className="modal-title">You've Claimed</Typography>
        <Box sx={{ display: "flex", justifyContent: "center", paddingTop: "20px",}}>
          <Card sx={{ display: "flex", alignItems: 'center', bgcolor: "rgba(26, 117, 255, 0.05)", borderRadius: "30px", p: 1, boxShadow: "none",}}>
            <img src="/assets/img/event-icon.svg" alt="" style={{ width: "19px" }} />
            <Typography variant="h6" className='merch-category'>Custom</Typography>
          </Card>
        </Box>
        
        <Box sx={{ borderRadius: '8px', p:2, mt:2}}>
          <Typography variant='body2' display="flex" justifyContent="center" alignItems="center" textAlign="center">
            <div dangerouslySetInnerHTML={{ __html: info.details }}></div>
          </Typography>
        </Box>
        
    </Box>
  )
}

const mapStateToProps = (state) => ({
  user: state.user
});

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomRewardPreview)