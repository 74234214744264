import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux';
import { Box, Typography, Link as AnchorLink, Snackbar, Alert, Card, TextField, InputAdornment, Button, styled } from '@mui/material';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import { gaEvent, isValidHttpUrl, claimPgUrl, checkImageFileExt, validateImgDimension, handleImageForCompress } from 'src/utils';
import CloseIcon from '@mui/icons-material/Close';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { updateCommunitySettings, saveSettingsPreviewData, setToast, connectUserStripeAccount, createStripeProducts } from 'src/services/actions';
import CropperModal from 'src/components/Modals/img_cropper';
import ResetMembershipSettings from 'src/components/Confirmation/reset_membership_settings';

const PreviewButton = styled(Button)(
  ({ theme }) => `
    display: flex;
    justify-content: center;
    background: #ECFBE6;
    border-radius: 6px;
    color: #3BB900;
    &:hover{
        background: #ECFBE6;
    }
  `
)

const MarketingPageSettings = (props) => {
  const vertical =  'top';
  const horizontal = 'center';
  const { user } = props;
  const [stripeConnected, setStripeConnected] = useState(user.data.stripe_connect_data !== null);
  const [focused, setFocused] = useState({
    page_title: false,
    page_subtitle: false,
    membership_title: false,
    membership_subtitle: false,
    membership_details: false
  });

  const handlfocus = (key, is_focus) => {
    const cFocused = {...focused};
    cFocused[key] = is_focus;
    setFocused(cFocused)
  }
  const [isSubmit, setIsSubmit] = useState(false);
  const [settingsUpdateAlert, setSettingsUpdateAlert] = useState({type: '', message: ''})
  const [allowPreviewDisable, setAllowPreviewDisable] = useState(false)
  const [openCopiedBar , setOpenCopiedBar] = useState(false);
  const [resetDomainModal, setResetDomainModal] = useState(false);
  const [pageInfoData, setPageInfoData] = useState({
    banner_url: user.data?.membership_page_info?.banner_url,
    page_title: {value:  (user.data?.membership_page_info?.page_title === undefined) ? null : user.data?.membership_page_info?.page_title, error: ""},
    page_subtitle: {value:  (user.data?.membership_page_info?.page_subtitle === undefined) ? null : user.data?.membership_page_info?.page_subtitle, error: ""},
    membership_title: {value:  (user.data?.membership_page_info?.membership_title === undefined) ? null : user.data?.membership_page_info?.membership_title, error: ""},
    membership_subtitle: {value:  (user.data?.membership_page_info?.membership_subtitle === undefined) ? null : user.data?.membership_page_info?.membership_subtitle, error: ""},
    membership_details: {value:  (user.data?.membership_page_info?.membership_details === undefined) ? null : user.data?.membership_page_info?.membership_details, error: ""},
  });

  const copyToClipboard = (str) => {
    navigator.clipboard.writeText(str)
    props.setToast(true,{type: 'success', message: 'Copied to clipboard!'});
  }

  const triggerImage = (e) => {
    let preview = document.getElementById("image_preview");
    if(e.target.id !="addImage" && e.target.id !="file_upload" && !preview.contains(e.target)){
      document.getElementById('addImage').click();
    }
  };
  /* const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>{
        handleFileSelect('banner_url', reader.result.toString())
      });

      reader.readAsDataURL(e.target.files[0]);
    }
  } */
  const handleFileSelect =(type,fileobj)=>{
    let existing_data = {...pageInfoData}
    existing_data[type] = fileobj
    setPageInfoData(existing_data)
  }
  const removeImage =(e)=>{
    let existing_data = {...pageInfoData}
    existing_data['banner_url'] = ""
    setPageInfoData(existing_data)
  }
  const previewBtnClick = () => {
    setAllowPreviewDisable(true);
    let infoData = {...pageInfoData};
    let membership_page_settings = {};
    let dataKeys = Object.keys(infoData).filter((d) => d !== "banner_url")
    let newData = {}
    for (let i = 0; i < dataKeys.length; i++) {
      const keyName = dataKeys[i];
      newData[keyName] = pageInfoData[keyName].value
    }
    newData['banner_url'] = infoData.banner_url
    membership_page_settings['membership_page_info'] = newData
    props.saveSettingsPreviewData('membership', membership_page_settings, (resp) => {

      setAllowPreviewDisable(false)
      window.open(`${user.data.full_brand_domain}/preview/member-rewards?eth_address=${user.data.eth_address}`, '_blank')
    })
    
  }
  const resetRewardPageSettings = () => {
    setPageInfoData({
      banner_url: "",
      page_title: {value: "", error: ""},
      page_subtitle: {value: "", error: ""},
      membership_title: {value: "", error: ""},
      membership_subtitle: {value: "", error: ""},
      membership_details: {value: "", error: ""}
    })
  }

  const handlePageSettings =(type, val) => {
    let existing_data = {...pageInfoData};
    existing_data[type] = {value: val, error: ""}
    
    setPageInfoData(existing_data)
  }

  const updateRewardPageSettings = () => {
    let infoData = {...pageInfoData};
    

    setIsSubmit(true)
    let membership_page_settings = {};

    
    let dataKeys = Object.keys(infoData).filter((d) => d !== "banner_url")
    let newData = {}
    for (let i = 0; i < dataKeys.length; i++) {
      const keyName = dataKeys[i];
      newData[keyName] = pageInfoData[keyName].value
    }
    newData['banner_url'] = infoData.banner_url
    membership_page_settings['membership_page_info'] = newData
    
    props.updateCommunitySettings('membership', membership_page_settings, (resp) => {
      if (resp.type == 'success') {
        props.setToast(true,{type: 'success', message: 'Your settings have been updated.'});

        gaEvent({
          category: "Membership Page Settings Updated",
          label:`-${user.data.eth_address}-`,
          action: `membership_page_settings_updated`
        });

      } else {
        gaEvent({
          category: `Membership Page Settings error ${resp.message}`,
          label:`-${user.data.eth_address}-`,
          action: `membership_page_settings_update_error`
        });
        props.setToast(true, {type: 'error', message: resp.message});
      }
      setIsSubmit(false)
    })
  }

  const [open, setOpen] = useState(false);
  const imgRef = useRef(null);
  const [imgSrc, setImgSrc] = useState('')
  const [crop, setCrop] = useState({
    unit: "%",
    aspect: 7.9069,
    x: 0,
    y: 30,
    width: "100",
    height: 37.81,
  });

  const onSelectFile = (e) => {
    const selectedFile = e.target.files[0];
    validateImgDimension(selectedFile, 600, 215, 1700, 215).then((res) => {
      if (res.type === 'error') {
        let message = "Upload an image with minimum size 600px";
        props.setToast(true, { type: "end_error", message: message });
      } else {
        if (selectedFile) {
          handleImageForCompress(selectedFile, res.fileWidth, res.fileHeight)
            .then((compressedDataURL) => {
              // console.log('Compressed Image Data URL:', compressedDataURL);
              handleFileSelect("banner_url", compressedDataURL);
              // You can use the compressedDataURL as needed
            })
            .catch((error) => {
              console.error('Error compressing image:', error);
            });
        }
      }
    })
    
      
    document.getElementById('file_upload').value = '';
  };

  const onSelectFileOld = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      // setCrop(undefined); // Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener('load', () =>{
        const img1 = new Image();
        img1.onload = () => {
          if (img1.width >= 600 && img1.height >= 215) {
            setImgSrc(reader.result.toString() || "");
            setOpen(true);
          } else {
            let message = "Please make sure image height and width should be minimum 600px";
            props.setToast(true, { type: "end_error", message: message });
          }
        };
        img1.src = reader.result.toString();
      });
      reader.readAsDataURL(e.target.files[0]);
      document.getElementById('file_upload').value = '';
    }
  }
  const onImageLoaded = (image) => {
    imgRef.current = image
  };

  const onCropComplete = (crop) => {
    makeClientCrop(crop);
  };

  const makeClientCrop = async(crop) => {
    if (imgRef.current && crop.width && crop.height) {
      const croppedImageUrl_val = await getCroppedImg(imgRef.current, crop, 'newFile.png');
      handleFileSelect('banner_url', croppedImageUrl_val)
    }
  }

  const getCroppedImg = (image, crop, fileName) => {
    const canvas = document.createElement('canvas');
    const pixelRatio = window.devicePixelRatio;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';
    
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    return new Promise((resolve, reject) => {
      resolve(canvas.toDataURL('image/png'))
    });
  }

  const resetSettings = () => {
    console.log("Test")
    setResetDomainModal(true)
  }
  
  const createProducts = () => {
    props.createStripeProducts({"name": user.data.membership_page_info?.other?.stripe_product_name, "price": user.data.membership_page_info?.other?.stripe_product_price}, (res) => {
      if (res) {
        
      }
    })
  }

  useEffect(() => {
    window.addEventListener("message", (event) => {
      if (event.data?.event === 'stripe_event') {
        setStripeConnected(true)
      }
    });
  },[])

  const connectStripe = () => {
    props.connectUserStripeAccount((res) => {
      if (res && res?.type !== 'error'){
        // window.location = res.url
        window.open(res.url, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600')
      } else {
        props.setToast(true,{type:"end_error", message: res.message});
      }
    })
  }
  
  return (
    <Box className="design-settings textareArea">
      <Box>
        <Typography variant="h3">Membership Rewards Page</Typography>
        <Typography variant='h6' mb="29px">Your membership page is where people join your brand’s community and claim rewards through journeys and quests. <a href='https://help.step3.io/' target='_blank' style={{color: "inherit"}}>Learn more about memberships.</a></Typography>

        <Box>
          <Typography variant='h4'>Membership Page URL</Typography>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant='h5'>
              <a href={user.data.membership_page_info?.other ? user.data.membership_page_info?.other?.is_home_page !== true ? `${user.data.full_brand_domain}/member` : user.data.full_brand_domain : `${user.data.full_brand_domain}/member`} style={{color: "inherit"}} target='_blank' rel="noreferrer">{user.data.membership_page_info?.other && user.data.membership_page_info?.other?.is_home_page !== true ? `${user.data.full_brand_domain}/member` : user.data.full_brand_domain}</a>
            </Typography>
            <AnchorLink onClick={(e) => copyToClipboard(claimPgUrl(user.data,'membership'))} sx={{cursor: 'pointer'}} underline="none">
              <Card sx={{bgcolor:'#E8F1FF', padding:'10px' , marginLeft:'11px', alignItems: 'center', display: 'flex'}} >
                <InsertLinkIcon sx={{color:'#0061F4' }} />
              </Card>
            </AnchorLink>
          </Box>
        </Box>

        <Box mt="36px">
          <Typography variant='h4'>Content Options</Typography>
          <form noValidate>
            <TextField sx={{mt:2}} fullWidth className='Add-image' variant="outlined" disabled placeholder={'Add a banner (optional)'} onClick={triggerImage} InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button variant="outlined" component="label" id="addImage" className="font-weight-600">
                    Browse... 
                    <input type="file" id="file_upload" accept="image/png, image/jpeg, image/jpg, image/gif, image/svg+xml" onChange={(e) => {
                      if (checkImageFileExt(e)){
                        onSelectFile(e)
                      } else {
                        props.setToast(true,{type:"end_error", message: 'Please choose a correct file type'});
                      }
                    }} hidden  />
                  </Button>
                </InputAdornment>
              ),
              startAdornment:(
                <InputAdornment position="start">
                  <Box sx={{display:"flex"}}>
                    <Box sx={{position: 'relative'}} id="image_preview">
                      {pageInfoData.banner_url ?
                      <>
                      <img src={isValidHttpUrl(pageInfoData.banner_url) ? pageInfoData.banner_url : (pageInfoData.banner_url)} height="20" alt="" />
                      <Button id="removeImage" sx={{position: 'absolute', top: '-5px',right:'-5px',padding:'3px',minWidth:'0',display:'flex',justifyContent:'center',alignItems:'center', backgroundColor:'rgba(0,0,0)',padding:0,color: '#fff',':hover': {backgroundColor: '#000'}}} onClick={removeImage}><CloseIcon sx={{fontSize: '1rem !important'}}/></Button>
                      </>
                      :
                      <>
                      <img src="/assets/images/empty-img.svg" height="20" alt=""/>
                      </>
                    }
                    </Box>
                    <Typography variant="body1" ml={1}>{pageInfoData.banner_url?.name}</Typography>
                  </Box>
                </InputAdornment>
              )
            }} />
            <Typography variant="body2" sx={{color: "#223354", opacity: "0.5", fontSize: "12px", mt: 0.5, mb: 1}}>Recommended dimensions: 1700px x 215px</Typography>

            <TextField fullWidth margin="normal" label='Page TITLE' name="page_title" variant="outlined" InputLabelProps={{shrink: true}} inputProps={{ maxLength: 60, minLength: 3 }} value={pageInfoData.page_title.value} placeholder="Enter a Page Title" error={pageInfoData.page_title.error !== ""} onFocus={() => handlfocus("page_title", true)} onBlur={() => handlfocus("page_title", false)} onChange={(e) => handlePageSettings("page_title", e.target.value)} FormHelperTextProps={{ style: { textAlign: 'right', color: (pageInfoData.page_title.value?.length == 60 || pageInfoData.page_title.error !== "") ? 'red' : 'rgba(34, 51, 84, 0.7)' } }} helperText={
              <Box display="flex" justifyContent={"space-between"}>
                <Box component="span">{pageInfoData.page_title.error == '' ? '' : pageInfoData.page_title.error}</Box>
                <Box component="span" className='charTxt'>
                  <Box component="span" display={focused.page_title ? 'block' : 'none'}>
                    {pageInfoData.page_title.value?.length > 0 ? `${pageInfoData.page_title.value?.length} / 60` : false}
                  </Box>
                </Box>
              </Box>
              }
            />
            
            <TextField fullWidth margin="normal" label='Page SubTitle' name="page_subtitle" variant="outlined" InputLabelProps={{shrink: true}} inputProps={{ maxLength: 84, minLength: 3 }} value={pageInfoData.page_subtitle.value} placeholder="Enter a Page Subtitle" error={pageInfoData.page_subtitle.error !== ""} onFocus={() => handlfocus("page_subtitle", true)} onBlur={() => handlfocus("page_subtitle", false)} onChange={(e) => handlePageSettings("page_subtitle", e.target.value)} FormHelperTextProps={{ style: { textAlign: 'right', color: (pageInfoData.page_subtitle.value?.length == 84 || pageInfoData.page_subtitle.error !== "") ? 'red' : 'rgba(34, 51, 84, 0.7)' } }} helperText={<Box display="flex" justifyContent={"space-between"}>
              <Box>
                {pageInfoData.page_subtitle.error == '' ? '' : pageInfoData.page_subtitle.error}
              </Box>
                <Box component="span" className='charTxt'>
                  <Box component="span" display={focused.page_subtitle ? 'block' : 'none'}>
                    {pageInfoData.page_subtitle.value?.length > 0 ? `${pageInfoData.page_subtitle.value?.length} / 84` : false}
                  </Box>
                </Box>
              </Box>
              } />

            <TextField fullWidth margin="normal" label='Membership Title' name="membership_title" variant="outlined" InputLabelProps={{shrink: true}} inputProps={{ maxLength: 60, minLength: 3 }} value={pageInfoData.membership_title.value} placeholder="Enter a Membership Title" error={pageInfoData.membership_title.error !== ""} onFocus={() => handlfocus("membership_title", true)} onBlur={() => handlfocus("membership_title", false)} onChange={(e) => handlePageSettings("membership_title", e.target.value)} FormHelperTextProps={{ style: { textAlign: 'right', color: (pageInfoData.membership_title.value?.length == 60 || pageInfoData.membership_title.error !== "") ? 'red' : 'rgba(34, 51, 84, 0.7)' } }} helperText={<Box display="flex" justifyContent={"space-between"}>
              <Box>
                {pageInfoData.membership_title.error == '' ? '' : pageInfoData.membership_title.error}
              </Box>
                <Box component="span" className='charTxt'>
                  <Box component="span" display={focused.membership_title ? 'block' : 'none'}>
                    {pageInfoData.membership_title.value?.length > 0 ? `${pageInfoData.membership_title.value?.length} / 84` : false}
                  </Box>
                </Box>
              </Box>
              } />

            <TextField fullWidth multiline inputProps={{maxLength: 150}} rows="3" margin="normal" label='Membership SubTitle' name="text" variant="outlined" InputLabelProps={{shrink: true}} placeholder='Enter a Membership Subtitle' onFocus={() => handlfocus("membership_subtitle", true)} onBlur={() => handlfocus("membership_subtitle", false)} onChange={(e) => handlePageSettings("membership_subtitle", e.target.value)} value={pageInfoData.membership_subtitle.value} FormHelperTextProps={{ style: { textAlign: 'right',color: (pageInfoData.membership_subtitle.value?.length == 150 || pageInfoData.membership_subtitle.error !== "") ? 'red' : 'rgba(34, 51, 84, 0.7)' } }} error={pageInfoData.membership_subtitle.error !== ""} helperText={<Box display="flex" justifyContent={"space-between"}>
              <Box>
                {pageInfoData.membership_subtitle.error == '' ? '' : pageInfoData.membership_subtitle.error}
              </Box>
                <Box component="span" className='charTxt'>
                  <Box component="span" display={focused.membership_subtitle ? 'block' : 'none'}>
                    {pageInfoData.membership_subtitle.value?.length > 0 ? `${pageInfoData.membership_subtitle.value?.length} / 150` : false}
                  </Box>
                </Box>
              </Box>
              } />

            <TextField fullWidth multiline inputProps={{maxLength: 330}} rows="3" margin="normal" label='Membership Details' name="text" variant="outlined" InputLabelProps={{shrink: true}} placeholder='Enter the details of your membership program.' onFocus={() => handlfocus("membership_details", true)} onBlur={() => handlfocus("membership_details", false)} onChange={(e) => handlePageSettings("membership_details", e.target.value)} value={pageInfoData.membership_details.value} FormHelperTextProps={{ style: { textAlign: 'right',color: (pageInfoData.membership_details.value?.length == 330 || pageInfoData.membership_details.error !== "") ? 'red' : 'rgba(34, 51, 84, 0.7)' } }} error={pageInfoData.page_subtitle.value?.length == 300 || pageInfoData.membership_details.error !== ""} helperText={<Box display="flex" justifyContent={"space-between"}>
              <Box>
                {pageInfoData.membership_details.error == '' ? '' : pageInfoData.membership_details.error}
              </Box>
                <Box component="span" className='charTxt'>
                  <Box component="span" display={focused.membership_details ? 'block' : 'none'}>
                    {pageInfoData.membership_details.value?.length > 0 ? `${pageInfoData.membership_details.value?.length} / 330` : false}
                  </Box>
                </Box>
              </Box>
              } />
            { user.data.membership_page_info?.other?.layout === "aiea" && 
              <Box>
                {
                !stripeConnected ?
                <Box>
                  <Button variant="contained" onClick={connectStripe}>Connect Stripe</Button>
                </Box>
                :
                <Box>
                  <Button variant="contained" disabled={true} disableRipple sx={{cursor: 'default', "&.Mui-disabled": {background: "rgb(237 31 35 / 45%)", color: "#fff"}}}>Stripe Connected</Button>
                </Box>
                }
              </Box>
            }

            <Box display="flex" justifyContent="space-between" sx={{mt: 7, mb: 2}}>
              <Button sx={{borderRadius: '6px'}} onClick={resetSettings} className='btn-text'>Reset</Button>
            
              <Box display="flex">
                <PreviewButton onClick={previewBtnClick} disabled={allowPreviewDisable} className="font-weight-600">
                  {
                    allowPreviewDisable ? <Box component="span">Wait...</Box> : <Box display="flex"><RemoveRedEyeIcon/>&nbsp;Preview</Box>
                  }
                </PreviewButton>
                <Button variant="contained" onClick={updateRewardPageSettings} color="primary" sx={{borderRadius: '6px', ml:1}} className='btn-text' disabled={isSubmit}>{isSubmit ? 'Updating...' : 'Update'}</Button>
              </Box>
            </Box>
          </form>
        </Box>
      </Box>
      <Snackbar sx={{zIndex: '7 !important'}} anchorOrigin={{ vertical, horizontal }} open={openCopiedBar} autoHideDuration={5000} key={vertical + horizontal}>
        <Alert severity="success" sx={{ width: '100%' }}>Copied to clipboard!</Alert>
      </Snackbar>

      {settingsUpdateAlert.type != "" && 
      <Snackbar sx={{zIndex: '7 !important', width:'300px'}} anchorOrigin={{ vertical, horizontal }} open={settingsUpdateAlert.type != ''} autoHideDuration={5000} key={1}>
        <Alert icon={<NotificationsIcon fontSize="inherit" />} severity={settingsUpdateAlert.type != "" ? settingsUpdateAlert.type : "" } sx={{ width: '100%' }}>{settingsUpdateAlert.message}</Alert>
      </Snackbar>
      }

      {resetDomainModal &&
        <ResetMembershipSettings open={resetDomainModal} handleClose={() => setResetDomainModal(false)} updateDomainSettings={resetRewardPageSettings} />
      }

      <CropperModal open={open} handleClose={() => setOpen(false)} imgSrc={imgSrc} setCompletedCrop={onCropComplete} setCrop={setCrop} crop={crop} imgRef={imgRef} onImageLoaded={onImageLoaded} modal_title={"Banner"} />
    </Box>
  )
}

const mapStateToProps = (state) => ({
  user: state.user
})
const mapDispatchToProps = {
  saveSettingsPreviewData,
  updateCommunitySettings,
  setToast,
  connectUserStripeAccount,
  createStripeProducts
}

export default connect(mapStateToProps, mapDispatchToProps)(MarketingPageSettings)