import React, { useEffect, useState } from 'react'
import {
    Typography,
    Box, TextField
  } from '@mui/material';


const CouponCode = (props) => {
  const {handleChildData, dropData, nextTabErrors, setNextTabErrors} = props;

  const [focused, setFocused] = useState({
    code: false,
    description: false,
  });

  const handlfocus = (key, is_focus) => {
    const cFocused = {...focused};
    cFocused[key] = is_focus;
    setFocused(cFocused)
  }

  const [couponCode, setCouponCode] = useState(props?.dropData?.couponCodes);
  const handleData = (type, val) => {
    let existing_data = {...couponCode};
    existing_data[type] = val
    setCouponCode(existing_data)
  }
  
  useEffect(() => {
    handleChildData('couponCodes', couponCode)
  } ,[couponCode]);
  const handleCoupValueData = (type, val) => {
    if (type === 'coupon_code_website') {
      handleData('website', val);  
    } else {
      handleData(type, val);
    }
      setNextTabErrors({...nextTabErrors,[type]:{value: typeof val=="string" ?  String(val).trim() : val,error:""}});
    
  }

  return (
    <>
      <Box className="event-pass-form">
        {dropData.status !== 1 &&
        <>
          <Typography variant="body1" textAlign="center" className='subtitle' lineHeight="normal" mb="20px">CREATE REWARD</Typography>
          <Typography variant="h1" textAlign="center" className="title">Add the coupon code details</Typography>
          <Typography variant="body2" textAlign="center" className="detail-box">Add details about how this reward can be redeemed.</Typography>
        </>
        }
        
        <Box pt={dropData.status == 1 ? 0 : 4}>
          <Box>
            <TextField fullWidth margin="normal" label={<span>Coupon Code&nbsp;<span style={{color:'red'}}>*</span></span>} placeholder="Enter the coupon code." name="url" variant="outlined" InputLabelProps={{shrink: true}} onFocus={() => handlfocus("code", true)} onBlur={() => handlfocus("code", false)} onChange={(e) => handleCoupValueData('code', e.target.value)} defaultValue={props.dropData?.couponCodes?.code} error={nextTabErrors.code.error !== ""}  inputProps={{maxLength: 100}} helperText={<Box display="flex" justifyContent={"space-between"}>
            <Box>
              {nextTabErrors.code.error == "" ? '' : nextTabErrors.code.error}
            </Box>
              <Box component="span" className='charTxt'>
                <Box component="span" display={focused.code ? 'block' : 'none'}>
                {props.dropData.couponCodes.code.length > 0 ? `${props.dropData.couponCodes.code.length} / 100` : false}
                </Box>
              </Box>
            </Box>
            } FormHelperTextProps={{ style: { textAlign: (nextTabErrors.code.error !== "") ? 'left' : 'right',color: (props.dropData.couponCodes.code.length == 100 || nextTabErrors.code.error !== "") ? 'red' : 'rgba(34, 51, 84, 0.7)' } }} />
  
            <TextField fullWidth margin="normal" label='Website' name="text" variant="outlined" InputLabelProps={{shrink: true}} placeholder="Enter the website URL where this code can be redeemed." onChange={(e) => handleCoupValueData('coupon_code_website', e.target.value)} defaultValue={props.dropData?.couponCodes?.website} error={nextTabErrors.coupon_code_website.error !== ""} helperText={nextTabErrors.coupon_code_website.error} />
            <Box className="textareArea">
              <TextField fullWidth multiline inputProps={{maxLength: 150}} rows="3" margin="normal" label='Description' name="text" variant="outlined" InputLabelProps={{shrink: true}} placeholder='Add extra details (expiration date, restrictions, etc.)' onFocus={() => handlfocus("description", true)} onBlur={() => handlfocus("description", false)} onChange={(e) => handleData('details', e.target.value)} defaultValue={props.dropData?.couponCodes?.details} helperText={
                <Box component="span" className='charTxt'>
                <Box component="span" display={focused.description ? 'block' : 'none'}>
                {(props?.dropData?.couponCodes?.details) ? `${props.dropData.couponCodes.details.length} / 150` : ''}
                </Box>
              </Box>
              } FormHelperTextProps={{ style: { textAlign: 'right',color: (props?.dropData?.couponCodes?.details?.length == 150) ? 'red' : 'rgba(34, 51, 84, 0.7)' } }} />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default CouponCode