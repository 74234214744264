import React, { useEffect, useState } from 'react'
import {
    Typography,
    Box,
    styled,
    Button,
    Container,
    Chip,
    Card,
    Grid,
    Avatar,
    Menu,
    Tooltip
  } from '@mui/material';
  import { dropTypesData, converDateToRemainingDays, convertToInternationalCurrency, viewDtTime, calculateTimeLeft, createIcsFile, limitText, alphaHex, getTextColor } from 'src/utils';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

function ClaimCard(props) {
  const {user} = props
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [alphaColor, setalphaColor] = useState("")
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
  };
  const {dropData} = props
  let nf = new Intl.NumberFormat('en-US');

  const [countdownTime, setCountdownTime]= useState(
    {
      countdownDays:'',
      countdownHours:'',
      countdownMinutes:'',
      countdownSeconds:''
    }
  );

  let timeIntrval;
  const countdownTimer = () => {
    timeIntrval = setInterval(() => {
      const tm = calculateTimeLeft(dropData.published_date);
      setCountdownTime(tm)
    }, 1000);
  }

  useEffect(() => {
    const failsInToday = moment(dropData.published_date).isSame(moment(),'day')
    failsInToday && countdownTimer();

    return () => {
      clearInterval(timeIntrval)
    }
  },[])

  useEffect(() => {
    const color1 = alphaHex(`#${user.data.brand_data.primary}`, 0.75)
    setalphaColor(color1)
  },[])


  return (
    <>
      <Box width="450px" height="278.073px">
        <Grid container spacing={0} sx={{ display: "flex", justifyContent: "end" }}>
          <Grid item lg={12} xs={12} sm={12} xl={12} sx={{mx:1, boxShadow: "0px 0px 2px #6a7199", borderRadius: "6px"}}>
            <Card  className='reward-card'>
              <Grid container>
                <Grid item lg={5} sm={5} md={5} xs={5}  className='left-side'>
                  {dropData.image ? 
                  <Box style={{backgroundImage: `url(${dropData.image})`, backgroundRepeat: 'no-repeat', backgroundSize: "cover",backgroundPosition:'center', color: "#f5f5f5", width: "100%", height: '100%'}}>
                    
                    <Box sx={{height:'100%',background:'linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 61.08%, rgba(0, 0, 0, 0.3) 65.66%, rgba(0, 0, 0, 0.9) 100%)'}} display="flex" justifyContent="space-between" flexDirection="column" alignItems="center">
                    <Box width="100%">
                      {
                        dropData.is_coming_soon && !dropData.schedule_now &&
                        <Box sx={{bgcolor: `#${user.data.brand_data.accent}`, borderRadius: "6px 0px 0px 0px", width: "100%", textAlign: "center", height: "46px", display: "flex", alignItems: "center", justifyContent: "center"}}><Typography className="coming-soon-txt" sx={{color: `${getTextColor(user.data.brand_data.accent, '#ffffff')} !important`}} variant="body1">
                          {
                            moment(dropData.published_date).isSame(moment(),'day') ?
                            countdownTime.countdownHours != '' &&
                            <span>{countdownTime.countdownHours}h : {countdownTime.countdownMinutes}m : {countdownTime.countdownSeconds}s</span>
                            :
                            <span>Coming Soon</span>
                          }
                          </Typography></Box>
                      }
                    </Box>
                    <Box pl={1.2} sx={{height:'100%',pb:5}} display="flex" alignItems="center" justifyContent="flex-end" flexDirection="column">
                      <Typography variant="h1" align="center" className='total-claim'>{nf.format(dropData.drop_total_claim)}</Typography>
                      {dropData.is_limited_quantity &&
                        <Typography variant="h3" align="center" className='quantity'>of {nf.format(dropData.quantity)} </Typography>
                      }
                      <Typography variant="h3" align="center" className='claimed-text' sx={{pb:1}}>claimed </Typography>
                    </Box>
                    </Box>
                  </Box>
                  :
                  <Box display="flex" justifyContent="space-between" flexDirection="column" alignItems="center" style={{background: `radial-gradient(190% 100.95% at 50% 100.95%, rgba(0, 0, 0, 0.55) 7.9%, rgba(255, 255, 255, 0) 70.01%), #${user.data.brand_data.accent}`, width: "100%", height: '100%'}}>
                    <Box width="100%">
                      {
                        dropData.is_coming_soon && !dropData.schedule_now &&
                        <Box sx={{bgcolor: user.data.brand_data.accent, borderRadius: "6px 0px 0px 0px", width: "100%", textAlign: "center", height: "46px", display: "flex", alignItems: "center", justifyContent: "center"}}><Typography className="coming-soon-txt" variant="body1" sx={{color: `${getTextColor(user.data.brand_data.accent, '#ffffff')} !important`}}>
                          {
                            moment(dropData.published_date).isSame(moment(),'day') ?
                            countdownTime.countdownHours != '' &&
                            <span>{countdownTime.countdownHours}h : {countdownTime.countdownMinutes}m : {countdownTime.countdownSeconds}s</span>
                            :
                            <span>Coming Soon</span>
                          }
                          </Typography></Box>
                      }
                    </Box>
                    <Box pl={1.2} sx={{height:'100%',pb:5}} display="flex" alignItems="center" justifyContent="flex-end" flexDirection="column">
                    <Typography variant="h1" align="center" className='total-claim'>{dropData.drop_total_claim}</Typography>
                      {dropData.is_limited_quantity &&
                        <Typography variant="h3" align="center" className='quantity'>of {dropData.quantity} </Typography>
                      }
                      <Typography variant="h3" align="center" className='claimed-text' sx={{pb:1}}>claimed </Typography>
                    
                    </Box>
                  </Box>
                  }
                </Grid>
                <Grid item lg={7} sm={7} md={7} xs={7} sx={{ p: "7.512px" }}>
                  <Box display="flex" flexDirection="column" justifyContent="space-between" height="278.07px">
                    <Box>
                    <Box sx={{display: "flex", alignItems: "center", paddingTop: "9px"}}>
                        <Box width="100%">
                          <Box display="flex" alignItems="center" justifyContent="space-between">
                            <Chip style={{backgroundColor: "#EBF6FF !important"}} label={
                              <Box display="flex">
                                <img src={dropTypesData.find((d) => d.short_name == dropData.category).icon} alt="" width={15} />
                                <Typography variant="body1" className='merch-category'>{dropTypesData.find((d) => d.short_name == dropData.category).title}</Typography>
                              </Box>
                            } sx={{bgcolor: '#EBF6FF', color: '#1A1616'}} />
                            {
                              dropData.category == 'raffle' &&
                              <Chip style={{backgroundColor: "#EBFFF9 !important"}} sx={{bgcolor: '#EBFFF9', color: '#1A1616', fontSize: "9.696px"}} className="raffle-chip-label" label={`${convertToInternationalCurrency(parseInt(dropData.raffle.max_winners))} ${(parseInt(dropData.raffle.max_winners)) > 1 ? "Winners" : "Winner"}`}/>
                            }
                          </Box>
                          <Typography className='reward-title' variant="h3" sx={{wordBreak: 'break-all'}}>
                            {limitText(dropData.title, 30)}
                          </Typography>
                        </Box>
                    </Box>
                    <Typography variant="h6" className='reward-description' sx={{wordBreak: 'break-all', mb: 1.5}}>{limitText(dropData.description, 130)}</Typography>
                    </Box>
                    <Box>
                    <Card className='detail-card' sx={{p: '12.019px 11.268px'}}>
                        <Box sx={{display: "flex", justifyContent: "space-between"}}>
                        <Box>
                            <Typography variant="h6" className='started-text'>{(dropData.schedule_now) ? "Started" : moment(dropData.published_date).isSame(moment(),'day') ? "Starts on" : "Starts in"}</Typography>
                            <Typography variant="h4" className='started-time'>{props.publishedDt ? (dropData.schedule_now) ? new Date(props.publishedDt).toLocaleDateString() : <Box>
                      <Tooltip placement='bottom-start' componentsProps={{
                        tooltip:{
                          sx:{
                            backgroundColor:"rgba(34, 51, 84, 1)",
                            width:"130px",
                            "& :before": {
                              color:"rgba(34, 51, 84, 1)"
                            },
                          }
                        }
                        }} title={
                          <Typography variant='body2' align="center" sx={{fontSize:"15px",fontFamily:"Roboto, sans-serif",fontWeight:"400",color:"rgba(255,255,255,0.7)",zIndex:100,position:"relative"}}>Add to calendar</Typography>
                        } arrow>
                          <Box display="flex" onClick={() => createIcsFile(dropData, 'start_date')}>
                            <label>
                            <Typography variant="span" sx={{color: "#0BB681", cursor: 'pointer'}}>{converDateToRemainingDays(props.publishedDt)}</Typography>
                            </label>
                          </Box>
                      </Tooltip>
                    </Box> :  (dropData.schedule_now) ? new Date().toLocaleDateString() : "-"}</Typography>
                        </Box>
                        <Box>
                            <Typography variant="h6" className='started-text'>Ends</Typography>
                            <Typography variant="h4" className='started-time'>{dropData.endsIn ? converDateToRemainingDays(dropData.endsIn) : "-"}</Typography>
                        </Box>
                        </Box>
                        <Box sx={{paddingTop: "18px"}}>
                          <Box display="flex" justifyContent="space-between">
                            <Box display="flex" alignItems="center">
                              <Typography variant="h6" className='started-text' mr={1}>Exclusively for:</Typography>
                              {(dropData.sponsors.length == 1) ?
                                <>
                                  <Avatar src={dropData.sponsors[0].logo_image ? dropData.sponsors[0].logo_image : "/assets/images/default_collection.svg"} alt={dropData.sponsors[0].title} style={{height:'24.613px',width:'24.613px'}}/>
                                  <Typography variant="h4" className='sponsor-name' ml={0.5}>{dropData.sponsors[0].title}</Typography>
                                </>
                              :
                                <Tooltip componentsProps={{
                                  tooltip:{
                                    sx:{
                                      backgroundColor:"#1A1616",
                                      width:"200px"
                                    }
                                  }
                                }} title={
                                  <Box id="basic-menu" sx={{padding: '0px !important'}}>
                                    {dropData.sponsors.map((item,index) => {
                                      return(
                                        <Box sx={{py: '0.5rem', opacity: '0.9'}} key={index}>
                                          <Box display="flex" alignItems="center">
                                            <Avatar src={item.logo_image ?item.logo_image: '/assets/images/default_collection.svg'} alt="avatar 4" height="40" width="40" style={{borderRadius: '50%',border: '2px solid #fff'}} />
                                            <Typography variant="body1" sx={{ fontSize: '0.75rem', ml: 1,color:'#fff', fontWeight:'bold' }}>{item.title}</Typography>
                                          </Box>
                                        </Box>
                                      )
                                    })}
                                  </Box>
                                } arrow>
                                  <Box display="flex">
                                    {dropData.sponsors.map((item,index) => {
                                      return(
                                        index <= 2 &&
                                        <Avatar src={item.logo_image ?item.logo_image: '/assets/images/default_collection.svg' } key={index} alt={item.title} style={{marginLeft:
                                          index == 0 ? '0px' : '-1rem', borderRadius: '50%',zIndex:index,cursor:"pointer"}} height="40" width="40"/>
                                      )
                                    })}
                                    
                                    {dropData.sponsors.length > 3 &&
                                      <Box sx={{cursor:"pointer",height:'24.613px', width: '24.613px', borderRadius:'50%', marginLeft:'-1rem', background: '#fff !important',zIndex:10,color:'#1A75FF', boxShadow: '0 3px 3px #ccc'}} display="flex" justifyContent="center" alignItems="center" aria-controls={open ? 'basic-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined} onClick={handleClick}>+{parseInt(dropData.sponsors.length - 3)}</Box>
                                    }
                                  </Box>
                                </Tooltip>
                              }
                            </Box>
                          </Box>
                        </Box>
                        <Box sx={{ paddingTop: "18px" }}>
                          
                          {
                            !(!dropData.is_coming_soon || (dropData.published_date === null )) ?
                            
                            dropData.claim_condition.length > 0 ? 
                              <Button variant="contained" className="btn" sx={{cursor: 'default',background: `linear-gradient(90deg, #${user.data.brand_data.primary} 0%, ${alphaColor} 0px, ${alphaColor} 100%) !important`}}>Complete {dropData.claim_condition.length === 1 ? '1 task' : `${dropData.claim_condition.length} tasks`} to Claim</Button>
                              :
                              <Button variant="contained" className='btn' sx={{color: user.data.membership_page_info?.other?.layout === "aiea" && "#000",cursor: 'default',background:`#${user.data.brand_data.primary}`, '&:hover': {background: `#${user.data.brand_data.primary}`}}}>
                                { dropData.category == 'raffle' ? 'Enter now' : 'Claim Now' }
                              </Button>
                            :
                            ''
                          }
                        </Box>
                    </Card>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>

  )
}

const mapStateToProps = (state) => ({
  user: state.user
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(ClaimCard)