import { Box, Button, Typography } from '@mui/material'
import React from 'react'

import InsertLinkIcon from "@mui/icons-material/InsertLink";
import { RewardTypePill } from '../RewardsData/reward_type_pill';

function CouponCodeClaimed(props) {
  const {reward, info, setOpenCopiedBar} = props

  const copyToClipboard = (str) => {
    navigator.clipboard.writeText(str)
    setOpenCopiedBar(true)

    setTimeout(() => {
      setOpenCopiedBar(false)
    }, 5000)
  }
  return (
    <>
      <Box display="flex" justifyContent="center" alignItems='center' flexDirection="column">
        <RewardTypePill reward={reward} />
        <Box sx={{background: "rgba(34, 51, 84, 0.02);",fontFamily: "Space Mono", fontSize: "2.5rem", display: "flex", alignItems: "center", fontStyle: "italic", py:1, px:2,borderRadius: "8px"}}>
          {info.coupon_code}
          <Box sx={{background:"#E8F1FF", px:1, py:0.5, ml:2,display: "flex", cursor:"pointer",borderRadius: "6px"}} onClick={(e) => copyToClipboard(info.coupon_code)}>
            <InsertLinkIcon sx={{ color: "#0061F4" }} />
          </Box>
        </Box>
      </Box>

      {info.website &&
        <Button target='_blank' variant="contained" sx={{py: 1.3, my:0.8,mt:3, fontSize:"0.864rem",borderRadius:"8px" , color: "#fff"}} href={info.website} style={{ width: "100%"}}>{"Go to site"}</Button>
      }
    </>
  )
}

export default CouponCodeClaimed