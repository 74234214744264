import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Box, Card, CardContent, CardMedia, Grid, Link, Typography, Button } from "@mui/material";
import { gaEvent } from "src/utils";

const Audience = (props) => {
  const { user, dropData, handleChildData, handleAudienceClick, nextTabErrors, setNextTabErrors } = props;
  const [audienceType, setAudienceType] = useState('')
  const handleAudienceType = (atype) => {
    if (atype === "membership" && user.data.membership_page_info === null) {
      return false;
    }
    if (props.rewardCRD.type !== "request"){
      handleChildData("community_type", atype);
      setAudienceType(atype)
    }
    handleAudienceClick();
    gaEvent({
      category: `Audience - ${atype}`,
      action: "audience_type_select",
      label: `-${user.data.eth_address} -`,
    });
  };

  useEffect(() => {
    audienceType === 'membership' && handleChildData('sponsors',[user.data?.membership_sponsordetail])
  },[audienceType])

  return (
    <Box className="audience">
      <Grid container spacing={5} justifyContent="center" alignItems="center">
        <Grid item lg={user.data.membership_page_info !== null && user.data.membership_page_info !== "" ? 4.2 : 4.8}>
          {user.data.membership_page_info !== null && user.data.membership_page_info !== "" ? (
            <Card
              className={dropData.community_type === "membership" ? "selected" : ""}
              sx={{ opacity: user.data.membership_page_info !== null ? 1 : 0.5, cursor: user.data.membership_page_info !== null ? "pointer !important" : "default !important" }}
              onClick={() => {
                handleAudienceType("membership");
              }}
            >
              <CardContent>
                <Box textAlign="center" p="5px 28px 19px 28px" display="flex" justifyContent="center">
                  {user.data.membership_nft_details.collection_image != "" && user.data.membership_nft_details.collection_image != null ? (
                    <CardMedia
                      component="img"
                      sx={{ objectFit: "contain", width: "93px" }}
                      image={user.data.membership_nft_details.collection_image}
                      alt="membership"
                      onClick={() => {
                        handleAudienceType("membership");
                      }}
                    />
                  ) : (
                    <CardMedia
                      component="img"
                      sx={{ objectFit: "contain", width: "114px" }}
                      image="/assets/img/audience-membership.svg"
                      alt="membership"
                      onClick={() => {
                        handleAudienceType("membership");
                      }}
                    />
                  )}
                </Box>
                <Typography gutterBottom variant="h2" component="div">
                  Your Membership Pass Holders
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ minHeight: "90px" }}>
                  Increase engagement and retention by offering unique rewards and experiences to your members.
                </Typography>
              </CardContent>
            </Card>
          ) : (
            <Card className="no-membership" sx={{ cursor: "default !important" }}>
              <CardContent sx={{ textAlign: "center" }}>
                <Box px="28px" pt="5px" pb="17px" display="flex" justifyContent="center">
                  <CardMedia
                    component="img"
                    sx={{ objectFit: "contain", width: "97px" }}
                    image="/assets/img/onboarding-membership-overview.svg"
                    alt="membership"
                    onClick={() => {
                      handleAudienceType("membership");
                    }}
                  />
                </Box>
                <Typography gutterBottom variant="h2" component="div">
                  Supercharge loyalty with a membership program
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Learn how membership programs foster customer loyalty and build better customer relationships.
                </Typography>
                <Link href="https://www.step3.io/landing-pages/bookacall" target="_blank">
                  <Button variant="contained" color="info">
                    Learn More
                  </Button>
                </Link>
              </CardContent>
            </Card>
          )}
        </Grid>

        <Grid item lg={4.2}>
          <Card
            className={dropData.community_type === "other" ? "selected" : ""}
            onClick={() => {
              handleAudienceType("other");
            }}
          >
            <CardContent>
              <Box textAlign="center" p="5px 28px 19px 28px" display="flex" justifyContent="center">
                <CardMedia
                  component="img"
                  sx={{ objectFit: "contain", width: "93px" }}
                  image="/assets/img/audience-other.png"
                  alt="other"
                  onClick={() => {
                    handleAudienceType("other");
                  }}
                />
              </Box>
              <Typography gutterBottom variant="h2" component="div">
                Other Digital Communities
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ minHeight: "90px" }}>
                Acquire new customers by targeting rewards to any NFT based project or community.{" "}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  rewardCRD: state.rewardCRD,
  user: state.user,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Audience);
