import React from "react";
import CWalletCard from "./index";
import Web3 from "web3";
import { EthereumProvider } from "@walletconnect/ethereum-provider";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import { Box } from "@mui/material";

const Coinbase = (props) => {
  const { walletResponse, setWalletResponse, setWalletType, walletType, signMessage } = props;
  const web3 = new Web3();
  let ethereum;
  if (typeof window !== "undefined") {
    ethereum = window.ethereum;
    window.web3Obj = web3;
  }
  const connect = async (callback) => {
    const coinbaseWallet = new CoinbaseWalletSDK({
      appName: "Step3",
      appLogoUrl: "https://app.step3.io/assets/img/step3_logo.svg",
      darkMode: false,
    });

    const ethereumObj = coinbaseWallet.makeWeb3Provider("https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161", 1);
    web3.setProvider(ethereumObj);
    ethereumObj
      .request({ method: "eth_requestAccounts" })
      .then(async (accounts) => {
        ethereumObj.on("accountsChanged", function (accounts) {
          // window.location.reload();
        });
        if (accounts.length > 0) {
          web3.eth.defaultAccount = accounts[0];
          let network = {};
          network["chainId"] = await ethereumObj.request({ method: "eth_chainId" });
          network["networkVersion"] = await ethereumObj.request({ method: "net_version" });
          let account = await ethereumObj.request({ method: "eth_requestAccounts" });
          callback({ type: "success", data: { network: network, eth_address: account[0] } });
        } else {
          callback({ type: "error", message: "Some error occurred!" });
        }
      })
      .catch((error) => {
        callback({ type: "error", message: error.message });
      });
  };

  const web3signature = async (message, address, callback, type = "personal") => {
    try {
      let signature;
      if (type == "personal") {
        signature = await web3.eth.personal.sign(message, address);
      } else {
        try {
          signature = await web3.eth.sign(message, address);
        } catch (e) {
          signature = await web3.eth.personal.sign(message, address);
        }
      }
      callback({ type: "success", data: { signature } });
    } catch (error) {
      callback({ type: "error", message: error.message });
    }
  };

  const login = async (obj) => {
    setWalletResponse({ type: "request", wallet_type: 'coinbase' });
    connect(async (obj) => {
      if (obj.type == "success") {
        const data = obj.data;
        let currentTime = new Date().getTime();
        let message = signMessage;

        web3signature(message, data.eth_address, async (webObj) => {
          if (webObj.type === "success") {
            setWalletResponse({ type: "success", data: { wallet_type: "coinbase", login_type: "member", eth_address: data.eth_address, time_stamp: currentTime, signature: webObj.data.signature } });
          } else {
            setWalletResponse({...webObj, wallet_type: 'coinbase'});
          }
        });
      } else {
        setWalletType("");
        setWalletResponse({ type: "error", data: {}, message: obj.message, wallet_type: 'coinbase' });
      }
    });
  };

  return (
    <Box my={"20px"}>
      <CWalletCard
        theme={props.theme}
        data={{ name: "Coinbase", walletType: "coinbase" }}
        onLogin={() => {
          login();
          setWalletType("coinbase");
        }}
        walletResponse={walletResponse}
        wType={walletType}
      />
    </Box>
  );
};

export default Coinbase;
