import React,{ useEffect, useState,useRef } from 'react'
import {Box, Card, CardHeader, CardMedia, CardContent, Typography, Divider, CircularProgress,styled,List,
  ListItem,
  ListItemText,
  Popover,
  Avatar,Menu, Skeleton,Tooltip } from '@mui/material';
import EditReward from '../Modals/edit_reward';
import DeleteReward from '../Modals/delete_reward';
import EndReward from '../Modals/end_reward';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import { NavLink, useNavigate } from 'react-router-dom'

import { connect } from 'react-redux';
import { converDateToRemainingDays, dropTypesData, viewDate,convertToInternationalCurrency, createIcsFile, limitText } from 'src/utils';
import { duplicateRewardDrop, rewardClaimedData, rewardCompleted, setToast } from 'src/services/actions';

import {gaEvent} from "../../utils";
import { MouseOutlined } from '@mui/icons-material';
import CheckInAttendees from '../Modals/checkin_attendees';
import DrawWinners from '../Modals/draw_winners';
import PollResultModal from '../Modals/pollResultData';
import NotifyRaffleWinner from '../Modals/notify_winner';
import moment from "moment";

import TimerView from "../TimerView";
import { AllMembersPill } from '../RewardsData/reward_type_pill';

export function CircularProgressWithBorder(props) {
  return (
      <Box sx={{ position: 'absolute', bottom: '1.4rem', display: 'inline-flex' }}>
        <CircularProgress size="10" variant="determinate" sx={{height: '60px !important',width: '60px !important',ml:1,p:1,backgroundColor: '#111633',color: '#fff', opacity: '0.8', borderRadius: '50%'}} thickness={6} value={props.claim} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="caption" display="flex" justifyContent="center" alignItems="center" component="div" sx={{ml:1,color: '#fff', backgroundColor:'rgba(255,255,255,0.05)',borderRadius: '50%',fontSize: '0.6rem', textAlign:'center', height: '38px', width: '38px'}}>
            {props.value ? props.value : 0}
          </Typography>
        </Box>
      </Box>
  );
}

const DropItem = (props) => {
  const navigate = useNavigate();

  const {user} = props

  const [open, setOpen] = useState(false);
  const [updateSuccessAlert, setUpdateSuccessAlert] = useState(false);
  const [showEndTime, setShowEndTime] = useState(null);
  const {dropsData, setOpenCopiedBar, setUpdatedRewardAlert, setShowSentAlert} = props


  useEffect(()=>{
    if(dropsData.status !== 2  && dropsData.end_date){ 
      let value = converDateToRemainingDays(dropsData.end_date);
      setShowEndTime(value);
      let interVal = setInterval(()=>{
        let value = converDateToRemainingDays(dropsData.end_date);
        setShowEndTime(value);
        if(value ==''){
          props.rewardCompleted(dropsData.id);
          clearInterval(interVal);
        }
        
      },1000);

      return ()=>{
        clearInterval(interVal);
      }
    }
  },[dropsData.end_date])



  const [deleteModal, setDeleteModal] = useState(false);
  const [endReward, setEndReward] = useState(false);
  const [checkInEvent, setCheckInEvent] = useState(false);
  const [drawRaffleWinner, setDrawRaffleWinner] = useState(false);
  const [pollResult, setPollResult] = useState(false);
  const [notifyWinner, setNotifyWinner] = useState(false);
  const [notifyRaffleObj, setNotifyRaffleObj] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  
  const handleOpen = () => setOpen(true);
  const ref = useRef(null);

  const [openPopover, setOpenPopover] = useState(false);

  const handleOpenPopover = () => {
    setOpenPopover(true);
  };

  const handleClose = () => {
    setOpenPopover(false);
  };


  const CustomPopover = styled(Popover)({
    '& .MuiBox-root.css-7ilvzl': {
      padding: '0%'
    },
    '& .MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper': {
      borderRadius: '8px'
    },
    '& .MuiList-root.MuiList-padding': {
      width: '100%'
    },
    '& .MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters:hover': {
      background:  'none'
    }
  });
  const MenuUserBox = styled(Box)(
    ({ theme }) => `
          background: ${theme.colors.alpha.black[5]};
    ,`,{
      '& .MuiBox-root.css-7ilvzl': {
        padding: '0%'
      },
    }
  );


  
  const {rewardCRD} = props

  const allowDuplicateRewardDrop = async() => {
    const new_drop = await props.duplicateRewardDrop(dropsData.id);
    if(new_drop.type == 'success'){

      gaEvent({
        category: `Reward duplicated - ${dropsData.id}`,
        label: `-${user.data.eth_address} -`,
        value: dropsData.id,
        action: 'admin_reward_duplicate'
      });

      navigate(`/rewards/${new_drop.data.community_type === 'membership' ? 'membership' : 'community'}/edit`, {state: {reward_drop_data: new_drop.data, is_duplicate: true}});
    }
  }

  const claimDataDownload = async() => {
    if (dropsData.drop_total_claim == 0) {return false;}
    const claimData = await rewardClaimedData(dropsData);
  }

  const copyEventPassUrl = () => {
 
    navigator.clipboard.writeText(`${user.data.full_brand_domain}/passchecker/${dropsData.drop_type_data[0].unique_key}`)
    setOpenCopiedBar(true)

    setTimeout(() => {
      setOpenCopiedBar(false)
    }, 5000)
  }
  useEffect(() => {
    if( rewardCRD.type == "success"){
      setOpen(false);
      setDeleteModal(false);
      setEndReward(false);
    } else if (rewardCRD.type == "error") {
      if (endReward){
        props.setToast(true,{type:"end_error", message: rewardCRD.message});
        setEndReward(false);
      }
    }
  },[rewardCRD.type]);

  useEffect(() => {
    if(open == false && rewardCRD.type == 'success' && updateSuccessAlert == true) {
      setUpdatedRewardAlert(true)
      setTimeout(() => {
        
        setUpdatedRewardAlert(false)
      }, 5000)
    }
  },[open])


  const MutedText = styled(Typography)({
    color: 'rgba(34, 51, 84, 0.5);',
    fontSize: '0.8rem',
    opacity: 0.5,
    marginBottom: 0,
    paddingBottom: 0,
  });
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };


  const copyEmbeddedCode = () => {
    let embeddedCode = `<iframe src="${user.data.full_brand_domain+"/widgets/"+dropsData.id}" height="650" width="800" style="border:0px"></iframe>`
    navigator.clipboard.writeText(embeddedCode)
    handleClose()
    props.setToast(true,{type:"success",message:"Copied to clipboard"});
  }

  return (
    <NavLink to={`/rewards/${dropsData.community_type === 'membership' ? 'membership' : 'community'}/detail/${dropsData.id}`} style={{textDecoration: 'none'}} data-testid="rewardDropItem">
      <Card key={props.keyData} className='dropbox' mb={2}>
      {/* // <Card key={props.keyData} sx={{mr: 2, mb: 2, backgroundColor: '#fff', height: '32rem',position:'relative',width:"328px"}}onMouseOver={(e)=>mouseOutHandle(e)}> */}
        <CardHeader
        className='card-header'
        display="flex"
          title={
            <Typography variant="h5" component="h2" alignItems={'center'} justifyContent="space-between" display="flex">
              <Box alignItems={'center'} display="flex">
                <img src={dropTypesData.find((d) => d.short_name == dropsData.drop_type).icon} width={20} alt="" />&nbsp;
                <span className='drop-category'>{dropTypesData.find((d) => d.short_name == dropsData.drop_type)?.title}</span>
              </Box>
            </Typography>
          }
        />
        <Divider />
        
        <CardContent sx={{position: 'relative', p:0}}>
          {dropsData.image == null ?
          <Skeleton className='no-image' animation={false} variant="rectangular" width='100%' height={238} />
          :
          <CardMedia
            className='image-com'
            component="img"
            height="238"
            image={dropsData.image}
            alt="Paella dish"
          />
          }
        </CardContent>
          
        <Divider />
        <CardContent className='card-content'>
            <Box>
            { dropsData.title ?
            <Typography variant='h4' className='drop-title' sx={{textOverflow: "ellipsis", wordBreak: "break-all", overflow: 'hidden', whiteSpace: "nowrap"}}>
              {dropsData.title}
            </Typography>
            :
            <Skeleton variant='text' width={250} height={30} animation={false} />
            }
          
          <Box>
            <Typography variant="body2" className='drop-description'>
              {
                dropsData.details ?
                limitText(dropsData.details, 130) : 
                <>
                {(dropsData.status == 2 || dropsData.status ==  1) ?
                <Box sx={{height:"20px"}}></Box>
                :
                <>
                <Skeleton variant='text' height={20} animation={false} />
                <Skeleton variant='text' height={20} animation={false} />
                </>
                }
                </>
              }
            </Typography>
          </Box>
          </Box>

          <Box>

            <Box display='flex' justifyContent='space-between' sx={{mt: 1.5}}>
          
              <label>
                <Typography variant="body2" className='started-text'>{(dropsData.status !== 0 || !dropsData.is_schedule_now) ? (moment() > moment(dropsData.published_date)) ? "Started" : "Starts In" : "" }</Typography>
                {dropsData.status !== 0 || !dropsData.is_schedule_now ?
                  <Typography variant="h6" className='started-time' >
                    {
                    (dropsData.status !== 0 || !dropsData.is_schedule_now)
                    ?
                      (moment() > moment(dropsData.published_date)) ?
                      viewDate(dropsData.published_date)
                      :

                      <Box>
                        <Tooltip placement='bottom-start' componentsProps={{
                          tooltip:{
                            sx:{
                              backgroundColor:"rgba(34, 51, 84, 1)",
                              width:"130px",
                              "& :before": {
                                color:"rgba(34, 51, 84, 1)"
                              },
                            }
                          }
                          }} title={
                            <Typography variant='body2' align="center" sx={{fontSize:"15px",fontFamily:"Roboto, sans-serif",fontWeight:"400",color:"rgba(255,255,255,0.7)",zIndex:100,position:"relative"}}>Add to calendar</Typography>
                          } arrow>
                            <Box display="flex" onClick={() => createIcsFile(dropsData, 'start_date')}>
                              <label>
                              <Typography variant="span" sx={{color: "#0BB681", cursor: "pointer"}} onClick={() => createIcsFile(dropsData, 'start_date')}><TimerView viewDate={dropsData.published_date} loadList={props.loadList}/></Typography>
                              </label>
                            </Box>
                        </Tooltip>
                      </Box>
                    :
                    ""
                    }
                  </Typography>
                  :
                  dropsData.status !== 2 && 
                  <Skeleton variant='text' height={20} width={60} animation={false}/>
                }
              </label>

              {
                (dropsData.status !== 2 && dropsData.end_date && showEndTime!='') ?
                dropsData.status == 1 ? 
                <Box>
                  <Tooltip placement='bottom-start' componentsProps={{
                      tooltip:{
                          sx:{
                          backgroundColor:"rgba(34, 51, 84, 1)",
                          width:"130px",
                          "& :before": {
                              color:"rgba(34, 51, 84, 1)"
                          },
                          }
                      }
                      }} title={
                          <Typography variant='body2' align="center" sx={{fontSize:"15px",fontFamily:"Roboto, sans-serif",fontWeight:"400",color:"rgba(255,255,255,0.7)",zIndex:100,position:"relative"}}>Add to calendar</Typography>
                      } arrow>
                          <Box display="flex" onClick={() => createIcsFile(dropsData, 'end_date')}>
                          <label>
                              <Box sx={{cursor:"pointer"}}>           
                                <Typography variant="body2" className='ends-text'>Ends</Typography>
                                <Typography variant="h6" className='ends-time'>{showEndTime}</Typography>
                              </Box>
                          </label>
                          </Box>
                  </Tooltip>
                </Box>
                :
                <label>
                  <Typography variant="body2" className='ends-text'>Ends</Typography>
                  <Typography variant="h6" className='ends-time'>{showEndTime}</Typography>
                </label>
                :
                  dropsData.status == 0 &&
                  <label>
                    <MutedText sx={{textAlign: 'right'}}></MutedText>
                    <Skeleton variant='text' height={20} width={60} animation={false}/>
                  </label>
              }
              {
                (dropsData.status == 2) &&
                <label>
                  <Typography variant="body2" className='ends-text'>Ended</Typography>
                  <Typography variant="h6" className='ends-time'>{viewDate(dropsData.drop_end_time)}</Typography>
                </label>
              }
            </Box>
          </Box>
        </CardContent>
        <Divider />
        <CardContent sx={{position:'absolute', bottom: '-1.75rem', left: '0',p:0,width:'100%'}}>
          <Box className='sponsors-area' display='flex' alignItems="center" justifyContent='space-between' px={1} py={1}>
            <Box>
              <AllMembersPill dropsData={dropsData} />
            </Box>
            <Box className='claimed-count'>
              {
                dropsData.community_type !== 'membership' &&
                <label>
                  <Typography className='claimed'>Claimed</Typography>
                  <Typography variant="body1" sx={{fontWeight: 'bold',pb:0.3}}>
                    <Typography variant="h5" className='claimed-quantity'>{dropsData.drop_total_claim}</Typography>
                    {dropsData.is_limited_quantity && 
                      <Typography variant="h5" className='claimed-limit'>
                        {dropsData.quantity ? `of ${convertToInternationalCurrency(dropsData.quantity)}` : ''}
                      </Typography>
                    }
                  </Typography>
                </label>
              }
            </Box>
          </Box>
        </CardContent>
        {deleteModal &&
          <DeleteReward open={deleteModal} handleClose={() => setDeleteModal(false)} dropId={dropsData.id} dropsData={dropsData}/>
        }
        {open&&
        <EditReward editData={dropsData} open={open} handleClose={() => setOpen(false)} setUpdateSuccessAlert={setUpdateSuccessAlert} loadList={props.loadList} />
        }
        {endReward &&
        <EndReward open={endReward} handleClose={() => setEndReward(false)}  dropId={dropsData.id} dropsData={dropsData}/>
        }
        {checkInEvent &&
        <CheckInAttendees open={checkInEvent} handleClose={() => setCheckInEvent(false)} dropsData={dropsData} setOpenCopiedBar={setOpenCopiedBar} />
        }
        {drawRaffleWinner &&
        <DrawWinners open={drawRaffleWinner} handleClose={() => setDrawRaffleWinner(false)} dropsData={dropsData} setNotifyWinner={setNotifyWinner} setNotifyRaffleObj={setNotifyRaffleObj} />
        }
        
        {pollResult &&
        <PollResultModal open={pollResult} handleClose={() => setPollResult(false)} reward={dropsData} info={dropsData.drop_type_data[0]} />
        }
        {notifyWinner &&
        <NotifyRaffleWinner open={notifyWinner} handleClose={() => setNotifyWinner(false)} notifyRaffleObj={notifyRaffleObj} dropsData={dropsData} setShowSentAlert={setShowSentAlert} />
        }
      </Card>
    </NavLink>
  )
}

const mapStateToProps = (state) => ({
  rewardCRD: state.rewardCRD,
  user: state.user,
  showToast: state.showToast
})

const mapDispatchToProps = {
  duplicateRewardDrop,
  rewardCompleted,
  setToast
}

export default connect(mapStateToProps, mapDispatchToProps)(DropItem)