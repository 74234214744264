import React, {useEffect, useState} from 'react';
import { Modal,Box, Button,Typography, Card, Avatar, TextField } from '@mui/material';
import { connect } from 'react-redux';
import { alphaHex } from 'src/utils';

  
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    border: '0px solid #000',
    boxShadow: 24,
    p: 3,
    borderRadius:"8px",
    maxHeight: "87.5vh",
    overflowY: "auto"
  };

function PollModal(props) {
  const { open, handleClose, rewardDropData, user} = props;
  const [selectedOption, setSelectedOption] = useState('')
  const [basePmColor1, setBasePmColor1] = useState('rgba(26, 117, 255, 0.1)')
  const [basePmColor2, setBasePmColor2] = useState('rgba(26, 117, 255, 0.4)')
  // console.log("user =>",alphaHex(user.data.brand_data.primary))

  useEffect(() => {
    const color1 = alphaHex(`#${user.data.brand_data.primary}`, 0.1)
    const color2 = alphaHex(`#${user.data.brand_data.primary}`, 0.4)
    setBasePmColor1(color1)
    setBasePmColor2(color2)
  },[])

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="Edit Form Field"
      sx={{outline: 'none'}}
      onBackdropClick={()=>{return false}}
      className='preview-modal'
    >
      <Box sx={style} className='modal-border'>
        <Typography variant="h6" className="modal-title">You've Claimed</Typography>
        <Typography variant="h1" className="heading">{rewardDropData?.title}</Typography>

        <Card sx={{ border: '1px solid #CCCEDD', bgcolor: "#F6F8FB", marginTop: "19px", px: 4, mx: 2, boxShadow:'0px 4px 21px rgba(24, 69, 136, 0.2)',borderRadius: "6px"}}>
          <Box sx={{ display: "flex", justifyContent: "center", paddingTop: "20px",}}>
            <Card sx={{ display: "flex", alignItems: 'center', bgcolor: "rgba(26, 117, 255, 0.05)", borderRadius: "30px", p: 1, boxShadow: "none",}}>
              <img src="/assets/img/poll-icon.svg" alt="" style={{ width: "19px" }} />
              <Typography variant="h6" className='merch-category'>Poll</Typography>
            </Card>
          </Box>
          <Box sx={{ background: 'rgba(34, 51, 84, 0.02)', borderRadius: '8px', p:2, mt:2}}>
            
            <Typography variant='body2' align='center' className='question'>{(rewardDropData?.poll?.question)?.trim().at(-1) == "?" ? (rewardDropData?.poll?.question)?.trim() : `${(rewardDropData?.poll?.question)?.trim()}?`}</Typography>

            <Typography variant='body1' className='question-helper' align='center' my={1}>Cast your vote!</Typography>

            <Box sx={{background:"rgba(34, 51, 84, 0.2)", height:"1px", width:"120px", margin:"auto"}}></Box>

            <Box mt={3}>
                {rewardDropData?.poll?.choices.map((choice,index) => {
                    return(
                        <Box className={`option-area ${selectedOption == choice ? 'selected' : ''}`} sx={{bgcolor: `${selectedOption == choice ? basePmColor2 : basePmColor1} !important`}} onClick={() => setSelectedOption(choice)}>
                            {choice}
                        </Box>
                    )
                })}
            </Box>
                {rewardDropData?.poll?.allow_comment &&
              <Box className='textareArea'>
              <TextField fullWidth multiline rows={3} margin="normal" label={<span>ADD A COMMENT</span>} name="comment" variant="outlined" InputLabelProps={{shrink: true}} placeholder='Your comments will not be made public.'  />
              </Box>
            }

            <Button variant='contained' color='primary' className='confirm-btn'>Submit</Button>

          </Box>
          <Card sx={{bgcolor:'white', boxShadow:'none', my:1 }}></Card>
        </Card>
        <Box sx={{ paddingTop: "20px", display: "flex", justifyContent: "center",}}>
          <Button variant="outlined" color="secondary" onClick={props.handleClose} className='close-btn'>Close</Button>
        </Box>
      </Box>
    </Modal>
  )
}

const mapStateToProps = (state) => ({
  user: state.user
});

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(PollModal)