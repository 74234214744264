import React, {useState,useEffect} from 'react';
import { Modal, Box, Typography, TextField, Button,Link,styled, Alert, AlertTitle, FormLabel, FormHelperText } from '@mui/material';
// import Select from '@mui/material/Select';
// import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { connect } from 'react-redux';

import {useNavigate} from 'react-router-dom'

import {addCollection, resetCollectionCDState, addErc1155Collection} from 'src/services/actions';

import {gtag} from "../../utils";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '0px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius: "6px"
};

const NewCollectionStep2 = (props) => {
  const { open, handleClose, user, step2Addr, step2Network } = props;
  const [network, setNetwork] = useState(step2Network);
  const [contractAddress, setContractAddress] = useState(step2Addr);
  const [tokenIds, setTokenIds] = useState('');

  const [error,setError] = useState([])
  const [errorStatus,setErrorStatus] = useState(false)

  const {collection} = props

  const handleChange = (event) => {
    setNetwork(event.target.value)
  };
  // console.log("error =>",collection)
  const addCollectionHandler = async() => {
    if(!network == ''){
      setError([])
      setErrorStatus(false)
      const data = {
        chain_id: network,
        collection_address: contractAddress,
        token_id: tokenIds.split(",")
      }
      // console.log('data =>',data)
      props.addErc1155Collection(data, (resp) => {
        if (resp.type == 'success') {
          handleClose();
          props.resetCollectionCDState();
        }
      });
      
      if(collection.new_collection_status === 'success'){
        setNetwork('')
        setContractAddress('')
        setTokenIds('')
      }
    }else{
      setError(['Please select a network'])
      setErrorStatus(true)
    }
  }

  useEffect(()=>{
    props.resetCollectionCDState();
  },[]);

  let close = ()=>{
    props.resetCollectionCDState();
    handleClose();
  }
  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState([]);
  
  const createOption = (label) => ({
    label,
    value: label,
  });
  const handleKeyDown = (event) => {
    if (!inputValue) return;
    // console.log("check =>",isNaN(+event.nativeEvent.key))
    if (isNaN(+inputValue)) {
      return;
    }
    // eslint-disable-next-line default-case
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        setValue((prev) => [...prev, createOption(inputValue)]);
        setInputValue('');
        event.preventDefault();
    }
  };

  useEffect(() => {
    value.length > 0 && setTokenIds(value.map((k) => k.label).join(","))
  },[value])

  return (
    <Modal open={open} onClose={()=>{ return collection.new_collection_status == 'request' ?  false : close() }} aria-labelledby="New Form Field" className='preview-modal'>
      <Box sx={style} className='modal-border'>
        <Box>
          <Typography variant="h3" className="heading" align='start !important'>Please enter a Token ID</Typography>
          <Typography variant="body1" mt={2} sx={{lineHeight: "140%",fontFamily: "Roboto, sans-serif",fontWeight: "400", fontSize: "1rem",color: "#223354"}}>We’ve detected that this collection is an 1155 NFT contract.  To learn more about 1155 NFTs and how to find the Token ID, please visit our <Link href="https://help.step3.io/managing-nft-collections/4KSRw7GELtfqFffE4SqkZ6/how-to-use-nft-collections-for-rewards/hqz6YYQ7EwWxXLJBVKYnkx" sx={{fontFamily:"Roboto, sans-serif", fontWeight: "700", fontSize: "1rem"}} target="_blank"> help center</Link>.</Typography>
          {
            collection.new_collection_status == 'error' && collection.error_data.length > 0 &&
            <Box mt={3}>
              <Alert severity="error">
                {
                  collection.error_data.filter((e) => e.message == 'Already Exists').length > 0 && 
                  <Box>The Token ID {collection.error_data.filter((e) => e.message == 'Already Exists').map((t) => `#${t.token_id}`).join(', ')} already exists
                    
                  </Box>
                }

                {
                  collection.error_data.filter((e) => e.message == 'Invalid Token').length > 0 && 
                  <Box>The Token ID {collection.error_data.filter((e) => e.message == 'Invalid Token').map((t) => `#${t.token_id}`).join(', ')} was not found. Please enter a valid Token ID</Box>
                }

{
                  collection.error_data.filter((e) => e.message == 'Empty Token').length > 0 && 
                  <Box>Please enter Token ID(s)
                    {/* {collection.error_data.filter((e) => e.message == 'Already Exists').map((t) => t.token_id).join(', ')} */}
                  </Box>
                }
              </Alert>
            </Box>
          }
          <Box mt={3}>
            <FormLabel>Token ID</FormLabel>
            {/* <TextField sx={{mt:2}}  required fullWidth label='Token Id' name="token_id" variant="outlined" InputLabelProps={{shrink: true}} placeholder="Enter the token ID number for this NFT collection" error={collection.new_collection_status == 'error'} value={tokenIds} onChange={(e) => { setTokenIds(e.target.value) }}/> */}
            <CreatableSelect
              components={{DropdownIndicator: null}}
              inputValue={inputValue}
              isClearable
              isMulti
              menuIsOpen={false}
              onChange={(newValue) => setValue(newValue)}
              onInputChange={(newValue) => setInputValue(newValue)}
              onKeyDown={handleKeyDown}
              placeholder="Enter the token ID number for this NFT collection"
              value={value}
            />
            <FormHelperText>Hint: You can add multiple Token IDs by hitting the “enter” key after each ID number.</FormHelperText>
          </Box>
        </Box>
        <Box display={'flex'} justifyContent={'space-between'} mt={5}>
          <Button color="primary" onClick={handleClose} className='btn-text'>Cancel</Button>
          {collection.new_collection_status == 'request' ?
            <Button variant="contained" color="primary" sx={{cursor: 'disabled', opacity: '0.5',borderRadius:' 6px', padding:'0.5rem 1.4rem',fontFamily: "Roboto,sans-serif", fontWeight: "700", fontSize: "0.938rem"}}>Adding...</Button>
            :
            <Button variant="contained" sx={{borderRadius:' 6px', padding:'0.5rem 1.4rem',fontFamily: "Roboto,sans-serif", fontWeight: "700", fontSize: "0.938rem", px:3}} onClick={addCollectionHandler}>Add</Button>
          }
        </Box>
      </Box>
    </Modal>
  )
}


const mapStateToProps = (state) => ({
  collection : state.collections,
  user: state.user,
})

const mapDispatchToProps = {
  addCollection,
  addErc1155Collection,
  resetCollectionCDState
}

export default connect(mapStateToProps, mapDispatchToProps)(NewCollectionStep2)