import axios from "axios";
axios.defaults.baseURL = process.env.REACT_APP_BASE_API_URL;

// let default_sign = {"time_stamp":1659962738805,"signature":"0xbfb020073b5840cd1bddafb73becfb8d66e582715b98e8dc6564875082ef43680ce4b25767525c830c204baf508a171076181691744f63f48e6424c109d70ef61c"};
// axios.defaults.headers={...axios.defaults.headers,...default_sign};

axios.interceptors.response.use(undefined, (err) => {
  if (err.response && [400, 401, 403, 404, 500].includes(err.response.status)) {
    if (err.response.status === 401 && err.response.data?.jwt) {
      clearLocalStorage();
    }
    return err.response;
  }
});

export async function signUpUser(data, callback) {
  try {
    let user_obj = await axios.post(`${process.env.REACT_APP_BASE_APPLE_URL}/api/client/signup`, { ...data, credential: data.credential });
    callback(user_obj.data);
  } catch (e) {}
}
export async function clearLocalStorage() {
  window.localStorage.clear();
  window.location.href = "/"; // redirect to home page
}
export async function refreshUser() {
  let jwt_token = window.localStorage.getItem("jwt_token");
  axios.defaults.headers = { ...axios.defaults.headers, jwt_token: jwt_token };
  let user_obj = await axios.get("/api/admin/account_info");
  axios.defaults.headers = { ...axios.defaults.headers, jwt_token: jwt_token };
  window.localStorage.setItem("jwt_token", user_obj.data.jwt_token);
  return user_obj.data;
}

export async function loginUser(data) {
  let user_obj = await axios.post("/api/admin/login", data);
  // axios.defaults.headers = { ...axios.defaults.headers, time_stamp: data.time_stamp, signature: data.signature };
  axios.defaults.headers = { ...axios.defaults.headers, jwt_token: user_obj.data.jwt_token };
  window.localStorage.setItem("jwt_token", user_obj.data.jwt_token);
  return user_obj.data;
}

export async function customAuthUser(data) {
  let user_obj = await axios.post("/api/admin/custom_login", data);
  // axios.defaults.headers = { ...axios.defaults.headers, time_stamp: data.time_stamp, signature: data.signature };
  axios.defaults.headers = { ...axios.defaults.headers, jwt_token: user_obj.data.jwt_token };
  window.localStorage.setItem("jwt_token", user_obj.data.jwt_token);
  return user_obj.data;
}

export function registerUser(data) {
  return async (dispatch) => {
    dispatch({ type: "LOGIN_REQUEST" });
    let user_obj = await axios.post("/api/admin/register", data);
    if (user_obj.data.type == "success") {
      dispatch({ type: "LOGIN_SUCCESS", data: user_obj.data.data });
    } else if (user_obj.data.type == "error") {
      dispatch({ type: "LOGIN_ERROR", message: user_obj.data.message });
    }
    return;
  };
}

export function update_email(data) {
  return async (dispatch) => {
    dispatch({ type: "UPDATE_EMAIL_REQUEST" });
  };
}

export async function resendAPI(data) {
  let user_obj = await axios.post("/api/admin/resend_email_verification", {});
  return user_obj.data;
}

export async function emailConfirmApi(apiKey) {
  let user_obj = await axios.get("/api/admin/verify_email/" + apiKey, {});
  return user_obj.data;
}

export function logoutUser() {
  return async (dispatch) => {
    clearLocalStorage();
  };
}

export function createOtp(wallet_type, data, callback) {
  return async dispatch => {
    try {
      let verification = await axios.post(`/api/user_otp/create/${wallet_type}`, data);
      callback(verification.data);
    } catch (e) {
      callback({type: 'error'})
    }
  }
}

export function verifyOtp(wallet_type, data, callback) {
  return async dispatch => {
    try {
      let verification = await axios.post(`/api/user_otp/verify/${wallet_type}`, {...data});
      callback(verification.data);
    } catch (e) {
      callback({type: 'error'})
    }
  }
}

export function updateAccountDetails(data, callback) {
  return async (dispatch) => {
    try {
      dispatch({
        type: "UPDATE_USER_REQUEST",
      });
      let updateUsr = await axios.post(`/api/admin/account_update`, { ...data });
      callback(updateUsr.data);
      if (updateUsr.data.type == "error") {
        dispatch({
          type: "UPDATE_USER_ERROR",
          message: updateUsr.data.message,
        });
      } else if (updateUsr.data.type == "success") {
        dispatch({
          type: "UPDATE_USER_SUCCESS",
          data: updateUsr.data.data,
        });
      }
    } catch (e) {}
  };
}

export function updateDomains(data, cb) {
  return async (dispatch) => {
    try {
      dispatch({ type: "UPDATE_PAGE_SETTINGS_REQUEST" });
      let response = await axios.post(`/api/admin/update_domains`, { ...data });
      // console.log("response.data.message =>",response.data)
      if (response.data.type == "success") {
        dispatch({
          type: "UPDATE_PAGE_SETTINGS_SUCCESS",
          data: response.data.data,
        });
      } else if (response.data.type == "error") {
        dispatch({
          type: "UPDATE_PAGE_SETTINGS_ERROR",
          message: response.data.message,
        });
      }
      cb(response.data);
    } catch (e) {}
  };
}
export function updateCommunitySettings(setting_type, data, cb) {
  return async (dispatch) => {
    try {
      dispatch({ type: "UPDATE_PAGE_SETTINGS_REQUEST" });

      let response = await axios.post(`/api/admin/settings_update/${setting_type}`, { ...data });
      if (response.data.type == "success") {
        dispatch({
          type: "UPDATE_PAGE_SETTINGS_SUCCESS",
          data: response.data.data,
        });
      } else {
        dispatch({
          type: "UPDATE_PAGE_SETTINGS_ERROR",
          message: response.data.message,
        });
      }
      cb(response.data);
    } catch (e) {}
  };
}

export function saveSettingsPreviewData(setting_type, data, cb) {
  return async (dispatch) => {
    try {
      let response = await axios.post(`/api/admin/save_settings_for_preview`, { ...data });
      cb(response.data);
    } catch (e) {}
  };
}

export async function updateEmailResendVerificationAPI(data) {
  let user_obj = await axios.post("/api/admin/account_update_resend_email", {});
  return user_obj.data;
}

export async function newEmailConfirmApi(apiKey) {
  let user_obj = await axios.get("/api/admin/verify_new_email/" + apiKey, {});
  return user_obj.data;
}

export async function claimEmailConfirmApi(token) {
  let user_obj = await axios.get(`/api/claim/verify_email/${token}`, {});
  return user_obj.data;
}

export function setPageArea() {
  return async (dispatch) => {
    dispatch({
      type: "PAGE_SET_AREA",
      data: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
    });
  };
}

export function setPageThought(txt) {
  return async (dispatch) => {
    dispatch({
      type: "PAGE_RESET_THOUGHTS",
    });
    dispatch({
      type: "PAGE_SET_THOUGHTS",
      data: txt,
    });
  };
}

export function getCollections(q) {
  return async (dispatch) => {
    try {
      dispatch({
        type: "REQUEST_COLLECTION",
      });
      const spo_collections = await axios.get("/api/admin/sponsor_collections");
      if (spo_collections.data.type == "success") {
        dispatch({
          type: "GET_COLLECTION_SUCCESS",
          data: spo_collections.data.data,
        });
      } else if (spo_collections.data.type == "error") {
        dispatch({
          type: "GET_COLLECTION_ERROR",
          message: spo_collections.data.message,
        });
      }
    } catch (e) {}
  };
}

export function getAdminUsers() {
  return async (dispatch) => {
    dispatch({ type: "ADMIN_USERS_REQUEST" });
    let res = await axios.get("/api/admin/users_list");
    if (res.data.type == "success") {
      dispatch({
        type: "ADMIN_USERS_SUCCESS",
        data: res.data.data,
      });
    } else if (res.data.type == "error") {
      dispatch({
        type: "ADMIN_USERS_ERROR",
        message: res.data.message,
      });
    }
  };
}

export function resetUpdateAdminUser() {
  return async (dispatch) => {
    dispatch({ type: "ADMIN_USERS_UPDATE_RESET" });
  };
}
export function updateAdminUserAdvancedSettings(data, admin_user_id, cb) {
  return async (dispatch) => {
    dispatch({ type: "ADMIN_USERS_UPDATE_REQUEST" });
    let res = await axios.put(`/api/admin/advanced_settings_update/${admin_user_id}`, data);
    if (res.data.type === "success") {
      dispatch({ type: "ADMIN_USERS_UPDATE_SUCCESS", data: res.data.data });
    } else {
      dispatch({ type: "ADMIN_USERS_UPDATE_ERROR", message: res.data.message });
    }
    cb(res.data);
  };
}

export function updateAdminUsers(data, admin_user_id, cb) {
  return async (dispatch) => {
    dispatch({ type: "ADMIN_USERS_UPDATE_REQUEST" });
    let res = await axios.put(`/api/admin/user_update/${admin_user_id}`, data);
    if (res.data.type === "success") {
      dispatch({ type: "ADMIN_USERS_UPDATE_SUCCESS", data: res.data.data });
    } else {
      dispatch({ type: "ADMIN_USERS_UPDATE_ERROR", message: res.data.message });
    }
    cb(res.data);
  };
}
export function updateMembershipSettingsToggle(data, admin_user_id, cb) {
  return async (dispatch) => {
    dispatch({ type: "ADMIN_USERS_UPDATE_REQUEST" });
    let res = await axios.put(`/api/admin/allow_membership_settings/${admin_user_id}`, data);
    if (res.data.type === "success") {
      dispatch({ type: "ADMIN_USERS_UPDATE_SUCCESS", data: res.data.data });
    } else {
      dispatch({ type: "ADMIN_USERS_UPDATE_ERROR", message: res.data.message });
    }
    cb(res.data);
  };
}

export function getAdminCollections() {
  return async (dispatch) => {
    dispatch({
      type: "REQUEST_ADMIN_COLLECTION",
    });
    let admin_spo_collections = await axios.get("/api/admin/admin_sponsor_collections");
    if (admin_spo_collections.data.type == "success") {
      dispatch({
        type: "GET_ADMIN_COLLECTION_SUCCESS",
        data: admin_spo_collections.data.data,
      });
    } else if (admin_spo_collections.data.type == "error") {
      dispatch({
        type: "GET_ADMIN_COLLECTION_ERROR",
        message: admin_spo_collections.data.message,
      });
    }
  };
}

export function updateAdminCollections(data, cb) {
  return async (dispatch) => {
    dispatch({
      type: "UPDATE_ADMIN_COLLECTION_REQUEST",
    });
    let updatedData;
    if (data.logo_image && typeof data.logo_image !== "string") {
      let formData = new FormData();
      for (const key in data) {
        formData.append(key, data[key]);
      }
      updatedData = await axios.put(`/api/admin/sponsor_collections/${parseInt(data.id)}`, formData);
    } else {
      updatedData = await axios.put(`/api/admin/sponsor_collections/${parseInt(data.id)}`, { ...data });
    }
    if (updatedData.data.type == "success") {
      cb({ type: "success" });
      dispatch({
        type: "UPDATE_ADMIN_COLLECTION_SUCCESS",
        data: updatedData.data.data,
      });
    } else {
    }
  };
}

export function deleteCollections(id) {
  return async (dispatch) => {
    try {
      dispatch({
        type: "DELETE_COLLECTION_REQUEST",
      });
      const deleteCollection = await axios.delete(`/api/admin/sponsor_collections/${id}`);
      getCollections();
      if (deleteCollection) {
        dispatch({
          type: "DELETE_COLLECTION_SUCCESS",
          id: id,
        });
      }
    } catch (e) {}
  };
}

export function checkCountryAllowed() {
  return async (dispatch) => {
    dispatch({
      type: "ALLOWED_COUNTRY_REQUEST",
    });
    const checkIpAddr = await axios.get(`/api/admin/allowed_ip`);

    dispatch({
      type: "ALLOWED_COUNTRY_SUCCESS",
      data: checkIpAddr.data,
    });
  };
}

export function resetCollectionCDState() {
  return (dispatch) => {
    dispatch({
      type: "RESET_COLLECTION_CD_STATE",
    });
  };
}
export function addCollection(data) {
  return async (dispatch) => {
    try {
      dispatch({
        type: "NEW_COLLECTION_REQUEST",
      });
      const newCollection = await axios.post(`/api/admin/sponsor_collections`, { chain_id: data.chain_id, collection_address: data.collection_address });
      // console.log("newCollection.data =>",newCollection.data)
      if (newCollection.data.type == "error") {
        dispatch({
          type: "NEW_COLLECTION_ERROR",
          message: newCollection.data.message,
        });
      } else if (newCollection.data.type == "success") {
        dispatch({
          type: "NEW_COLLECTION_SUCCESS",
          newCollection: newCollection.data.data,
          contract_type: newCollection.data.contract_type,
        });
      }
    } catch (e) {}
  };
}

export function addErc1155Collection(data, callback) {
  return async (dispatch) => {
    try {
      dispatch({
        type: "NEW_COLLECTION_REQUEST",
      });
      const newCollection = await axios.post(`/api/admin/sponsor_collections/create_erc1155`, { chain_id: data.chain_id, collection_address: data.collection_address, token_id: data.token_id });
      callback(newCollection.data);
      // console.log("newCollection.data =>",newCollection.data)
      if (newCollection.data.type == "error") {
        dispatch({
          type: "NEW_COLLECTION_ERROR",
          message: newCollection.data.message,
          error_data: newCollection.data.data,
        });
      } else if (newCollection.data.type == "success") {
        dispatch({
          type: "NEW_COLLECTION_SUCCESS",
          newCollection: newCollection.data.data,
          contract_type: newCollection.data.contract_type,
          record_type: newCollection.data.record_type,
        });
      }
    } catch (e) {}
  };
}

export function getDrops(community_type = 'community') {
  return async (dispatch) => {
    dispatch({
      type: "REWARD_LIST_REQUEST",
    });
    let drops = await axios.get(`/api/admin/rewards/${community_type}`);
    if (drops.data.type == "success") {
      dispatch({
        type: "REWARD_LIST_SUCCESS",
        data: drops.data.data,
      });
    } else {
      dispatch({
        type: "REWARD_LIST_ERROR",
        message: drops.data.message,
      });
    }
  };
}
export function createRewardDrop(data) {
  return async (dispatch) => {
    try {
      dispatch({
        type: "RESET_REWARD_CRD_ERROR",
      });
      const createRewardDrop = await axios.post(`/api/admin/reward_drops`, { ...data });
      if (createRewardDrop.data.type == "success") {
        dispatch({
          type: "REWARD_ADD_SUCCESS",
          data: createRewardDrop.data.data,
        });

        dispatch({
          type: "REWARD_CRD_DB_SET",
          data: createRewardDrop.data.data,
        });
      } else {
        dispatch({
          type: "REWARD_CRD_ERROR",
          message: createRewardDrop.data.message,
        });
      }
      return createRewardDrop.data;
    } catch (e) {
      return e.message;
    }
  };
}

export function setRewardCRD_DB(data) {
  return (dispatch) => {
    dispatch({
      type: "REWARD_CRD_DB_SET",
      data: data,
    });
  };
}

export function setRewardCRD(data) {
  return (dispatch) => {
    dispatch({
      type: "REWARD_CRD_SET",
      data: data,
    });
  };
}

export function resetRewardCRD() {
  return (dispatch) => {
    dispatch({
      type: "REWARD_CRD_RESET",
    });
  };
}

export function userAccountInfo(data, callback) {
  return async (dispatch) => {
    try {
      let accountInfo = await axios.get(`/api/admin/account_info`);
      callback(accountInfo.data);
      if (accountInfo.data.type == "success") {
        dispatch({
          type: "UPDATE_USER_SUCCESS",
          data: accountInfo.data.data,
        });
      }
    } catch (e) {}
  };
}

export async function brandLogoUpload(imgObj, img_type, callback) {
  try {
    let brandSettings = await axios.put(`/api/admin/brand_logo_upload`, { img: imgObj, img_type });
    callback(brandSettings.data);
    return brandSettings.data;
  } catch (e) {
    return { type: "error", message: e.message };
  }
}

export async function reward_drop_inquiry(data, callback) {
  try {
    let drop_inquiry = await axios.post(`/api/admin/reward_drops/inquiry`, { ...data });
    callback(drop_inquiry.data);
    return drop_inquiry.data;
  } catch (e) {
    return { type: "error", message: e.message };
  }
}

export function brand_subdomain_availability_check(brand_domain, callback) {
  return async (dispatch) => {
    try {
      let subdomain_availability = await axios.post(`/api/admin/check_brand_handle_availability`, { brand_handle: brand_domain });
      callback(subdomain_availability.data);
      return subdomain_availability.data;
    } catch (e) {
      return { type: "error", message: e.message };
    }
  };
}

export async function duplicate_reward_drop(id) {
  try {
    let duplicateReward = await axios.post(`/api/admin/reward_drops/${id}/duplicate`);
    return duplicateReward.data;
  } catch (e) {
    return { type: "error", message: e.message };
  }
}

export function duplicateRewardDrop(id) {
  return async (dispatch) => {
    dispatch({
      type: "REWARD_CRD_REQUEST",
    });
    dispatch({
      type: "RESET_REWARD_CRD_ERROR",
    });
    // return;
    const duplicateReward = await axios.post(`/api/admin/reward_drops/${id}/duplicate`);
    if (duplicateReward.data.type == "success") {
      dispatch({
        type: "REWARD_DUPLICATE_SUCCESS",
        data: duplicateReward.data.data,
      });

      dispatch({
        type: "REWARD_CRD_DB_SET",
        data: duplicateReward.data.data,
      });
      return duplicateReward.data;
    } else if (duplicateReward.data.type == "error") {
      dispatch({
        type: "REWARD_CRD_ERROR",
        message: duplicateReward.data.message,
      });
      return { type: "error", message: duplicateReward.data.message };
    }
  };
}

export function updateRewardDrop(data, id) {
  return async (dispatch) => {
    try {
      dispatch({
        type: "REWARD_CRD_REQUEST",
      });
      dispatch({
        type: "RESET_REWARD_CRD_ERROR",
      });
      let updateRewardDrop;
      // if (data.image && typeof data.image != "string") {
      //   const formData = new FormData();
      //   for (const key in data) {
      //     // for handling drop data while updating it
      //     if (typeof data[key] == "object" && key != "image") {
      //       if (key == "end_date") {
      //         data[key] = data[key];
      //       } else {
      //         data[key] = JSON.stringify(data[key]);
      //       }
      //     }
      //     formData.append(key, data[key]);
      //   }
      //   updateRewardDrop = await axios.put(`/api/admin/reward_drops/${id}`, formData);
      // } else {
        updateRewardDrop = await axios.put(`/api/admin/reward_drops/${id}`, { ...data });
      // }

      if (updateRewardDrop.data.type == "error") {
        dispatch({
          type: "REWARD_CRD_ERROR",
          message: updateRewardDrop.data.message,
        });
      } else if (updateRewardDrop.data.type == "success") {
        dispatch({
          type: "REWARD_UPDATE_SUCCESS",
          data: updateRewardDrop.data.data,
        });

        dispatch({
          type: "REWARD_CRD_DB_SET",
          data: updateRewardDrop.data.data,
        });
      }
    } catch (e) {}
  };
}

export function deleteReward(id) {
  return async (dispatch) => {
    dispatch({
      type: "REWARD_CRD_REQUEST",
    });
    // return;
    const deletedReward = await axios.delete(`/api/admin/reward_drops/${id}`);
    if (deletedReward.data.type == "success") {
      dispatch({
        type: "REWARD_DELETE_SUCCESS",
        data: { id },
      });
    } else if (deletedReward.data.type == "error") {
      dispatch({
        type: "REWARD_CRD_ERROR",
        message: deletedReward.data.message,
      });
    }
  };
}

export async function rewardClaimedData(dropsData) {
  axios({
    url: `/api/admin/reward_drops/${dropsData.id}/claims_data`, //your url
    method: "GET",
    responseType: "blob", // important
  }).then((response) => {
    const href = URL.createObjectURL(response.data);
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", `reward_${dropsData.title}_${new Date().getTime()}.csv`); //or any other extension
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  });
  return;
}

export async function downloadRewardInfoData(fName) {
  axios({
    url: `/api/admin/reward_drops/inquiry_data`, //your url
    method: "GET",
    responseType: "blob",
  }).then((response) => {
    const href = URL.createObjectURL(response.data);
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", `reward_${new Date().getTime()}.csv`); //or any other extension
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  });
  return;
}

export async function downloadSiteData(export_type, fromDate, toDate) {
  axios({
    url: `/api/admin/download_site_data/${export_type}`, //your url
    method: "POST",
    data: { fromDate, toDate },
    responseType: "blob",
  }).then((response) => {
    const href = URL.createObjectURL(response.data);
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", `${export_type}_${new Date().getTime()}.csv`); //or any other extension
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  });
  return;
}

export async function downloadEngagementPointsData() {
  axios({
    url: `/api/admin/download_engagement_points`, //your url
    method: "GET",
    responseType: "blob",
  }).then((response) => {
    const href = URL.createObjectURL(response.data);
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", `engagement_points_${new Date().getTime()}.csv`); //or any other extension
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  });
  return;
}

export async function eventAttendeesData(dropsData) {
  axios({
    url: `/api/admin/reward_drops/${dropsData.id}/event_attendees_data`, //your url
    method: "GET",
    responseType: "blob", // important
  }).then((response) => {
    const href = URL.createObjectURL(response.data);
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", `reward_${dropsData.title}_${new Date().getTime()}.csv`); //or any other extension
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  });
  return;
}

export function updateRewardSettings(data, callback) {
  return async (dispatch) => {
    try {
      dispatch({
        type: "UPDATE_PAGE_SETTINGS_REQUEST",
      });
      let updateRewardSettings;
      updateRewardSettings = await axios.put(`/api/admin/reward_settings_update`, { ...data });
      callback(updateRewardSettings.data);
      if (updateRewardSettings.data.type == "error") {
        dispatch({
          type: "UPDATE_PAGE_SETTINGS_ERROR",
          message: updateRewardSettings.data.message,
        });
      } else if (updateRewardSettings.data.type == "success") {
        dispatch({
          type: "UPDATE_PAGE_SETTINGS_SUCCESS",
          data: updateRewardSettings.data.data,
        });
      }
    } catch (e) {}
  };
}

export function updateBrandDomain(data) {
  return async (dispatch) => {
    dispatch({
      type: "UPDATE_USER_REQUEST",
    });
    const updatedDomain = await axios.post("/api/admin/update_brand_domain", { brand_domain: data });
    if (updatedDomain.data.type == "success") {
      dispatch({
        type: "UPDATE_USER_SUCCESS",
        data: updatedDomain.data.data,
      });
    } else {
      dispatch({
        type: "UPDATE_USER_ERROR",
        message: updatedDomain.data.message,
      });
    }
  };
}

export function rewardCompleted(reward_id) {
  return async (dispatch) => {
    dispatch({
      type: "REWARD_COMPLETED",
      data: { id: reward_id },
    });
  };
}

let toastTimeout;

export function setToast(showToast, data) {
  return async (dispatch) => {
    // if(showToast ==true){
    //   dispatch({
    //     type: "SHOW_TOAST",
    //     data:data
    //   })
    // }else{
    //   dispatch({
    //     type: "HIDE_TOAST",
    //     data:data
    //   })
    // }
    dispatch({
      type: "SHOW_TOAST",
      data: data,
    });
    toastTimeout = setTimeout(() => {
      dispatch({
        type: "HIDE_TOAST",
        data: data,
      });
    }, 6000);
  };
}

export function removeToast() {
  return async (dispatch) => {
    clearTimeout(toastTimeout);
    dispatch({
      type: "HIDE_TOAST",
      data: { type: "", message: "" },
    });
  };
}

export function getRaffleWinners(reward_drop_id) {
  return async (dispatch) => {
    dispatch({
      type: "RAFFLE_WINNERS_REQUEST",
    });
    const raffle_winner = await axios.get(`/api/admin/reward_drops/${reward_drop_id}/raffle_winners`);
    if (raffle_winner.data.type == "success") {
      dispatch({
        type: "RAFFLE_WINNERS_SUCCESS",
        data: raffle_winner.data.data,
      });
    } else {
      dispatch({
        type: "RAFFLE_WINNERS_ERROR",
        message: raffle_winner.data.message,
      });
    }
  };
}

export function drawRaffleWinner(reward_drop_id) {
  return async (dispatch) => {
    const draw_winner = await axios.post(`/api/admin/reward_drops/${reward_drop_id}/draw_winner`);
    if (draw_winner.data.type == "success") {
      dispatch({
        type: "DRAW_WINNER_SUCCESS",
        data: draw_winner.data.data,
      });
    } else {
      dispatch({
        type: "RAFFLE_WINNERS_ERROR",
        message: draw_winner.data.message,
      });
    }
  };
}

export function deleteRaffleWinner(reward_drop_id, id) {
  return async (dispatch) => {
    let object = await axios.delete(`/api/admin/reward_drops/${reward_drop_id}/delete_winner/${id}`);
    dispatch({
      type: "RAFFLE_WINNERS_DELETE_SUCCESS",
      data: object.data,
    });
  };
}

export async function notifyRaffleRewardWinner(reward_drop_id, data, callback) {
  try {
    let notify_winner = await axios.post(`/api/admin/reward_drops/${reward_drop_id}/notify_winners`, { ...data });
    callback(notify_winner.data);
    return notify_winner.data;
  } catch (e) {
    return { type: "error", message: e.message };
  }
}

export function twitterUsernameCheck(username, cb) {
  return async (dispatch) => {
    try {
      let res = await axios.post(`/api/admin/twitter_username_availability`, { username });
      cb(res.data);
      return res.data;
    } catch (e) {
      return { type: "error", message: e.message };
    }
  };
}

export function socialValidationCheck(provider, social_action, social_value, cb) {
  return async (dispatch) => {
    try {
      let res = await axios.post(`/api/admin/${provider}/${social_action}`, { social_value });
      cb(res.data);
      return res.data;
    } catch (e) {
      return { type: "error", message: e.message };
    }
  };
}

export function findCollectionTraits(data) {
  return async (dispatch) => {
    dispatch({ type: "COLLECTION_TRAITS_REQUEST" });
    try {
      let res = await axios.post(`/api/admin/sponsor_collections/findTraits`, { ...data });
      if (res.data.type === "success") {
        if (res.data.data.retrieved && res.data.data.metadata) {
          if (JSON.stringify(res.data.data.metadata) === "{}") {
            dispatch({ type: "COLLECTION_TRAITS_ERROR", data: {}, status: res.data.data.status });
          } else {
            dispatch({ type: "COLLECTION_TRAITS_SUCCESS", data: res.data.data.metadata, status: res.data?.data?.status });
          }
        } else {
          dispatch({ type: "COLLECTION_TRAITS_ERROR", data: res.data.message, status: res.data.data.status });
        }
      } else {
        dispatch({ type: "COLLECTION_TRAITS_ERROR", data: res.data.message, status: "" });
      }
    } catch (e) {
      dispatch({ type: "COLLECTION_TRAITS_ERROR", message: e.message, status: "" });
    }
  };
}

export function connectUserStripeAccount(cb) {
  return async (dispatch) => {
    try {
      let res = await axios.get(`/api/payment/connect-account`);
      cb(res.data);
      return res.data;
    } catch (e) {
      return { type: "error", message: e.message };
    }
  };
}

export function createStripeProducts(data, cb) {
  return async (dispatch) => {
    try {
      let res = await axios.post(``, {});
      cb(res.data);
      return res.data;
    } catch (e) {
      return { type: "error", message: e.message };
    }
  };
}

export function memberPartnersList(data, cb) {
  return async (dispatch) => {
    try {
      dispatch({
        type: "PARTNERS_REQUEST",
      });
      const partners = await axios.get("/api/admin/partners");
      if (partners.data.type == "success") {
        dispatch({
          type: "PARTNERS_SUCCESS",
          data: partners.data.data,
        });
      } else if (partners.data.type == "error") {
        dispatch({
          type: "PARTNERS_ERROR",
          message: partners.data.message,
        });
      }
    } catch (e) {
      
    }
  };

}
export function createNewPartner(data, cb) {
  return async (dispatch) => {
    dispatch({ type: "PARTNER_FORM_REQUEST" });
    try {
      let res = await axios.post(`/api/admin/partner/create`, {...data});
      setTimeout(() => {
        if (res.data.type === 'success') {
          dispatch({ type: "PARTNER_CREATE_SUCCESS", data: res.data.data });
        } else {
          dispatch({ type: "PARTNER_CREATE_ERROR", message: res.data.message });
        }
      },100)
      cb(res.data);
    } catch (e) {
      dispatch({ type: "PARTNER_CREATE_ERROR", message: e.message });
      return { type: "error", message: e.message };
    }
  };
}

export function updatePartner(id, data, cb) {
  return async (dispatch) => {
    dispatch({ type: "PARTNER_FORM_REQUEST" });
    try {
      let res = await axios.post(`/api/admin/partner/update/${id}`, {...data});
      setTimeout(() => {
        if (res.data.type === 'success') {
          dispatch({ type: "PARTNERS_UPDATE_SUCCESS", data: res.data.data });
        } else {
          dispatch({ type: "PARTNER_CREATE_ERROR", message: res.data.message });
        }
      },100)
      cb(res.data);
    } catch (e) {
      dispatch({ type: "PARTNER_CREATE_ERROR", message: e.message });
      return { type: "error", message: e.message };
    }
  };
}

export function adminContentCategories() {
  return async (dispatch) => {
    try {
      dispatch({
        type: "CONTENT_CATEGORIES_REQUEST",
      });
      const partners = await axios.get("/api/admin/category");
      if (partners.data.type === "success") {
        dispatch({
          type: "CONTENT_CATEGORIES_SUCCESS",
          data: partners.data.data,
        });
      } else if (partners.data.type == "error") {
        dispatch({
          type: "CONTENT_CATEGORIES_ERROR",
          message: partners.data.message,
        });
      }
    } catch (e) {
      dispatch({
        type: "CONTENT_CATEGORIES_ERROR",
        message: e.message,
      });
    }
  };
}

export function createNewContentCategory(data, cb) {
  return async (dispatch) => {
    dispatch({ type: "CONTENT_CATEGORY_FORM_REQUEST" });
    try {
      let res = await axios.post(`/api/admin/category`, {...data});
      if (res.data.type === 'success') {
        dispatch({type: "ADD_CATEGORIES_SUCCESS", data: res.data.data})
        dispatch({ type: "CONTENT_CATEGORY_CREATE_SUCCESS", data: res.data.data });
      } else {
        dispatch({ type: "CONTENT_CATEGORY_CREATE_ERROR", message: res.data.message });
      }
      cb(res.data);
    } catch (e) {
      dispatch({ type: "CONTENT_CATEGORY_CREATE_ERROR", message: e.message });
      return { type: "error", message: e.message };
    }
  };
}

export function deleteContentCategory(id, cb) {
  return async (dispatch) => {
    dispatch({ type: "CONTENT_CATEGORY_FORM_REQUEST" });
    try {
      let res = await axios.delete(`/api/admin/category/${id}`);
        if (res.data.type === 'success') {
          dispatch({type: "DELETE_CATEGORIES_SUCCESS", data: id})
          // dispatch({ type: "CONTENT_CATEGORY_CREATE_SUCCESS", data: res.data.data });
        } else {
          // dispatch({ type: "CONTENT_CATEGORY_CREATE_ERROR", message: res.data.message });
        }
      cb({type: res.data.type});
    } catch (e) {
      // dispatch({ type: "CONTENT_CATEGORY_CREATE_ERROR", message: e.message });
      return { type: "error", message: e.message };
    }
  };
}

export function updateContentCategory(id, data, cb) {
  return async (dispatch) => {
    dispatch({ type: "CONTENT_CATEGORY_FORM_REQUEST" });
    try {
      let res = await axios.put(`/api/admin/category/${id}`, {...data});
      console.log("Category". res)
      setTimeout(() => {
        if (res.data.type === 'success') {
          dispatch({ type: "CONTENT_CATEGORIES_UPDATE_SUCCESS", data: res.data.data });
          dispatch({ type: "UPDATE_CATEGORIES_SUCCESS", data: {...res.data.data} });
        } else {
          dispatch({ type: "CONTENT_CATEGORY_CREATE_ERROR", message: res.data.message });
        }
      },100)
      cb(res.data);
    } catch (e) {
      dispatch({ type: "CONTENT_CATEGORY_CREATE_ERROR", message: e.message });
      return { type: "error", message: e.message };
    }
  };
}

export function updateContentPost(id, data, cb) {
  return async (dispatch) => {
    dispatch({ type: "CONTENT_POST_FORM_REQUEST" });
    try {
      let res = await axios.put(`/api/admin/content/${id}`, {...data});
      if (res.data.type === 'success') {
        // dispatch({type: "ADD_CATEGORIES_SUCCESS", data: res.data.data})
        dispatch({ type: "CONTENT_POST_UPDATE_SUCCESS", data: res.data.data });
      } else {
        dispatch({ type: "CONTENT_POST_CREATE_ERROR", message: res.data.message });
      }
      cb(res.data);
    } catch (e) {
      dispatch({ type: "CONTENT_POST_CREATE_ERROR", message: e.message });
      return { type: "error", message: e.message };
    }
  };
}
export function createNewContentPost(data, cb) {
  return async (dispatch) => {
    dispatch({ type: "CONTENT_POST_FORM_REQUEST" });
    try {
      let res = await axios.post(`/api/admin/content`, {...data});
      if (res.data.type === 'success') {
        // dispatch({type: "ADD_CATEGORIES_SUCCESS", data: res.data.data})
        dispatch({ type: "CONTENT_POST_CREATE_SUCCESS", data: res.data.data });
      } else {
        dispatch({ type: "CONTENT_POST_CREATE_ERROR", message: res.data.message });
      }
      cb(res.data);
    } catch (e) {
      dispatch({ type: "CONTENT_POST_CREATE_ERROR", message: e.message });
      return { type: "error", message: e.message };
    }
  };
}
export function getCategoryData(slug, status){
  return async (dispatch) => {
    dispatch({type: "CONTENT_CATEGORIES_REQUEST"})
    let getData = await axios.get(`/api/admin/category/${slug}?status=${status}`)

    if(getData.data.type == "success"){
      dispatch({
        type: "CONTENT_CATEGORIES_SUCCESS",
        data: getData.data.data
      })
    }else{
      dispatch({
        type: "CONTENT_CATEGORIES_ERROR",
        message: getData.data.message
      })
    }
  }
}

export function deleteCategoryContent (id, cb) {
  return async (dispatch) => {
    let deletedData = await axios.delete(`api/admin/content/${id}`)

    if(deletedData.data.type == "success"){
      dispatch({
        type: "CONTENT_CATEGORY_DELETE_SUCCESS",
        id: id
      })
    }else{
      dispatch({
        type: "CONTENT_CATEGORIES_ERROR",
        message: deletedData.data.message
      })
    }
    cb({type: deletedData.data.type})
  }
}

export function duplicateCategoryContent(id, cb) {
  return async (dispatch) => {
    dispatch({
      type: "CONTENT_CATEGORIES_REQUEST"
    })

    let duplicateContent = await axios.get(`api/admin/content/${id}/duplicate`)

    if(duplicateContent.data.type == "success"){
      dispatch({ type: "CONTENT_POST_CREATE_SUCCESS", data: duplicateContent.data.data });
    }else{
      dispatch({
        type: "CONTENT_CATEGORIES_ERROR",
        message: duplicateContent.data.message
      })
    }

    cb({
      type: duplicateContent.data.type,
      data: duplicateContent.data.data
    })
  }
}