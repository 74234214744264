import React from 'react'
import { Box, styled,Typography, Avatar } from '@mui/material';
import {Link as RouterLink} from 'react-router-dom'

function NoActiveDrop(props) {
    const { community_type } = props;
    return (
        <>
            <Box className='no-active-drops'>
            
                <Box component="img" src={"/assets/img/sidebar-img.svg"} width={120} sx={{ opacity: (props.type !== 'active') ? '0.5' : '1', mb: 1}} alt="oops" />
                    <Box>
                        <Typography variant="body1" className='helper-text'>{props.description}</Typography>
                        {props.new_drop && 
                            <RouterLink to={`/rewards/${community_type}/new`} state={{reward_type: community_type}}>Create a new reward</RouterLink>
                        }
                    </Box>
                </Box>
        </>
    )
}

export default NoActiveDrop