import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { Label } from "@mui/icons-material";
import { Box, Button, FormControl, InputLabel, MenuItem, Select, styled, TextField, Typography, InputAdornment, FormControlLabel, Switch} from "@mui/material";
import { checkImageFileExt, handleImageForCompress, isValidHttpUrl, validateAlphaNumStr, validateImgDimension, validateVideoUrl } from "src/utils";
import CloseIcon from '@mui/icons-material/Close';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { createNewContentPost, setToast, updateContentPost } from "src/services/actions";
import { NavLink, useNavigate, useParams, useLocation, Link } from "react-router-dom";
import PostDetailModal from "../Modals/postDetailModal";
import ConfirmFeaturedPostModal from '../Modals/confirm_featured_post_modal';


const CreatePost = (props) => {
  const location = useLocation();
  const {c_name, slug} = useParams();
  const navigate = useNavigate();
  const { user, categories } = props;
  const [postItem, setPostItem] = useState({});
  const [confirmFeature, setConfirmFeature] = useState(false);
  // const [allowFeatured, setAllowFeatured] = useState(false);
  useEffect(() => {
    if (location.state?.postItem) {
      setPostItem(location.state.postItem)
    }
  },[location])

  const [isSubmit, setIsSubmit] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [allowedToAdd, setAllowedToAdd] = useState(false);
  const [formContentDetail, setFormContentDetail] = useState({value: '', error: ''});
  const [formData, setformData] = useState({
    content_category_id: {value: '', error: ''},
    title: {value: '', error: ''},
    type: {value: 'image', error: ''},
    image: {value: '', error: ''},
    video: {value: '', error: ''},
    is_featured: {value: false, error: ''},
  });

  useEffect(() => {
    if(c_name && user.login){
      handleChange(`content_category_id`, user.data.categories.filter((item) => item.slug == c_name)[0].id)
    }
  }, [c_name, user]);

  useEffect(() => {
    let flag = false;
    if (props.user.data.membership_program && props.user.data.membership_program?.role === 'admin') {
      flag = true;
    } else if (props.user.data?.other_data?.allow_membership_settings && props.user.data.membership_program && props.user.data.membership_program?.role === 'other') {
      flag = false;
    } else if (!props.user.data?.other_data?.allow_membership_settings)  {
      flag = false;
    } else {
      flag = true
    }
    
    setAllowedToAdd(flag);
  },[props.user.data])


  useEffect(() => {
    if(Object.keys(postItem).length > 0){
      const fdata = {...formData};
      fdata.content_category_id = {value: postItem.content_category_id, error: ''};
      fdata.title = {value: postItem.title, error: ''};
      fdata.type = {value: postItem.type, error: ''};
      fdata.image = {value: postItem.image_url, error: ''};
      fdata.video = {value: postItem.video_url, error: ''};
      fdata.is_featured = {value: postItem.is_featured, error: ''};
      setformData(fdata);

      handleContentChange(postItem.content)
    }
  }, [postItem]);

  
  const handleChange = (type, val) => {
    const formvals = {...formData};
    formvals[type] = {value: val, error: ''}
    setformData(formvals)
  }

  const handleContentChange = (val) => {
    setFormContentDetail({value: val, error: ''});
  }

  const submitPost = async(status) => {
    const fObj = {...formData};
    setIsSubmit(true)
    let flag = true;

    // const featuredPost = categories.data.find((ct) => ct.is_featured);
    // if (featuredPost && fObj.is_featured.value === true && allowFeatured === false) {
    //   setConfirmFeature(true);
    //   flag = false;
    // }

    if (fObj.title.value == null || fObj.title.value.trim() === "") {
      fObj.title = {value: fObj.title.value, error: 'Title is required.'}
      flag = false;
    }

    if (fObj.title.value !== '' && validateAlphaNumStr(fObj.title.value) === false) {
      fObj.title = {value: fObj.title.value, error: 'Title is invalid.'}
      flag = false;
    }


    if (fObj.type.value === 'video' && fObj.video.value === '') {
      fObj.video = { value: fObj.video.value, error: "Video URL can't be blank" };
      flag = false;
    }
    if (fObj.type.value === 'video' && fObj.video.value !== '') {
      if (validateVideoUrl(fObj.video.value) === false) {
        fObj.video = { value: fObj.video.value, error: "Invalid URL" };
        flag = false;
      }
    }


    if (flag) {
      let dataKeys = Object.keys(fObj)
      let newData = {}
      for (let i = 0; i < dataKeys.length; i++) {
        const keyName = dataKeys[i];
        
        newData[keyName] = formData[keyName].value
      }

      newData['content'] = formContentDetail.value;
      newData['status'] = status;

      if (Object.keys(postItem).length > 0){
        props.updateContentPost(postItem.id, newData, (resp) => {
          setIsSubmit(false)
          if (resp.type === 'success') {
            props.setToast(true, { type:"success", message:"Post has been saved successfully." });
            navigate(`/category/${c_name}`, { state: { postItem: resp.data } })
          } else {
            fObj.name = {value: fObj.name.value, error: 'Post name is already taken.'}
            setformData(fObj)
          }
        })
      } else {
        
        props.createNewContentPost(newData, (resp) => {
          setIsSubmit(false)
          if (resp.type === 'success') {
            props.setToast(true, { type:"success", message:"Post has been saved successfully." });
            navigate(`/category/${c_name}`, { state: { postItem: resp.data } })
          } else {
            fObj.name = {value: fObj.name.value, error: 'Post name is already taken.'}
            setformData(fObj)
          }
        })
      }
    } else {
      setIsSubmit(false)
      setformData(fObj)
    }
  }

  const handleFileSelect =(type, fileobj)=>{
    let existing_data = {...formData}
    existing_data[type] = {value: fileobj, error: ''}
    setformData(existing_data)
  }

  const removeImage =(e)=>{
    let existing_data = {...formData}
    existing_data['image'] = {value: '', error: ''}
    setformData(existing_data)
  }

  const onSelectFile = (e) => {
    const selectedFile = e.target.files[0];
    validateImgDimension(selectedFile, 400, 250, 400, 250).then((res) => {
      if (res.type === 'error') {
        let message = "Upload an image with minimum size 400x250";
        props.setToast(true, { type: "end_error", message: message });
      } else {
        if (selectedFile) {
          handleImageForCompress(selectedFile, res.fileWidth, res.fileHeight)
            .then((compressedDataURL) => {
              handleFileSelect('image', compressedDataURL)
            })
            .catch((error) => {
              console.error('Error compressing image:', error);
            });
        }
      }
    })
      
    document.getElementById('file_upload').value = '';
  };

  const triggerImage = (e) => {
    let preview = document.getElementById("image_preview");
    if(e.target.id !="addImage" && e.target.id !="file_upload" && !preview.contains(e.target)){
      document.getElementById('addImage').click();
    }
  };

  const handleAllowFeatured = () => {
    handleChange('is_featured', false)
  }

  return (
    <>
      <Box
      className="scrollbar-hide rewards-section"
      width={"calc(100% - 259px)"}
      sx={{ padding: "40px", height: "calc(99vh - 80px)", overflowY: "auto", boxSizing: "border-box" }}
    >
      <Box className="rewards-section" sx={{ width: "800px", maxWidth: "800px", minHeight: '645px', margin: "64px auto", borderRadius: "6px", border: "1px solid #000000", paddingTop: "50px", display: "flex", flexDirection: "column", justifyContent: "space-between" }} >
        <Typography variant="h1" textAlign="center" sx={{ mb: 1 }} className="title">
          { (Object.keys(postItem).length > 0) ? postItem?.status === 1 ? "Edit post" : "Edit draft post" : "Create a new post" } 
        </Typography>

        {
          !(Object.keys(postItem).length > 0) ?
          <Typography variant="body1" textAlign="center" sx={{}} className="subtitle font-gs-md">
            Create post for the category “
            <Link to={`/category/${c_name}`} variant="span" style={{ color: "#ED1F23", textDecoration: "none" }}>{user.data.categories.filter((item) => item.slug == c_name)[0]?.name}</Link>
            ”
          </Typography>
          :
          <Typography variant="body1" textAlign="center" sx={{}} className="subtitle">
        </Typography>
        }

        <Box sx={{ p: "50px" }}>
          <Box>
            <TextField sx={{ width: "100%", mb: "20px", "&::placeholder": { color: "#223354b3", }, }} required id="outlined-required" label="Title" onChange={(e)=>handleChange("title", e.target.value)} value={formData.title.value} InputLabelProps={{shrink: true}} placeholder='Enter a title' error={formData.title.error !== ""} helperText={formData.title.error == '' ? '' : formData.title.error} FormHelperTextProps={{ style: {color: (formData.title.error !== "") ? 'red' : 'rgba(34, 51, 84, 0.7)' } }} inputProps={{ maxLength: 255 }} />
          </Box>
          <Box sx={{ marginBottom: "10px" }}>
            <FormControl sx={{ minWidth: "100%" }}>
              <Select value={formData.type.value} onChange={(e)=>handleChange("type", e.target.value)}>
                <MenuItem value="image">Featured Image</MenuItem>
                <MenuItem value="video">Featured Video</MenuItem>
              </Select>
            </FormControl>
          </Box>

          {
            formData.type.value === 'image' ? 
          
            <TextField sx={{mt: 0.5, mb:2}} fullWidth className='Add-image' variant="outlined" disabled placeholder={'Add an image'} onClick={triggerImage} InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button variant="outlined" component="label" id="addImage" className="font-weight-600">
                    Browse... 
                    <input type="file" id="file_upload" accept="image/png, image/jpeg, image/jpg, image/gif, image/svg+xml" onChange={(e) =>{
                      if (checkImageFileExt(e)){
                        onSelectFile(e)
                      } else {
                        props.setToast(true,{type:"end_error", message: 'Please choose a correct file type'});
                      }
                    }} hidden  />
                  </Button>
                </InputAdornment>
              ),
              startAdornment:(
                <InputAdornment position="start">
                  <Box sx={{display:"flex"}}>
                    <Box sx={{position: 'relative'}} id="image_preview">
                      {formData.image.value ?
                      <>
                      <img src={isValidHttpUrl(formData.image.value) ? formData.image.value : (formData.image.value)} height="20" alt="" />
                      <Button id="removeImage" sx={{position: 'absolute', top: '-5px',right:'-5px', minWidth:'0',display:'flex',justifyContent:'center',alignItems:'center', backgroundColor:'rgba(0,0,0)',padding:0,color: '#fff',':hover': {backgroundColor: '#000'}}} onClick={removeImage}><CloseIcon sx={{fontSize: '1rem !important'}}/></Button>
                      </>
                      :
                      <>
                      <img src="/assets/images/empty-img.svg" height="20" alt=""/>
                      </>
                    }
                    </Box>
                    <Typography variant="body1" ml={1}>{formData.image.value?.name}</Typography>
                  </Box>
                </InputAdornment>
              )
            }} />
          :
          <Box>
            <TextField sx={{ width: "100%", mt: 1, mb: "20px", "&::placeholder": { color: "#223354b3", }, }} required id="outlined-required" label="Video URL" onChange={(e)=>handleChange("video", e.target.value)} value={formData.video.value} InputLabelProps={{shrink: true}} placeholder='Enter a URL' error={formData.video.error !== ""} helperText={formData.video.error == '' ? '' : formData.video.error} FormHelperTextProps={{ style: {color: (formData.video.error !== "") ? 'red' : 'rgba(34, 51, 84, 0.7)' } }} />
          </Box>
          }
          <Box className="textareArea" sx={{border: formContentDetail.error ? '1px solid red' : '0px solid'}}>
            <CKEditor
              editor={ ClassicEditor }
              config={{
                placeholder: "Post content",
                heading: {
                  options: [
                    { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                    { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                    { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                    { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                  ]
                },
                removePlugins: ["EasyImage","ImageUpload","MediaEmbed","Table","ImageInsert","AutoImage","Base64UploadAdapter"]
              }}
              data={formContentDetail.value}
              onReady={(editor) => {
                editor.editing.view.document.on( 'drop', ( evt, data ) => {
                // Stop executing next callbacks.
                evt.stop();
        
                // Prevent the default event action.
                data.preventDefault();
              }, { priority: 'high' } );
          
              editor.editing.view.document.on( 'dragover', ( evt, data ) => {
                evt.stop();
                data.preventDefault();
              }, { priority: 'high' } );

                editor.editing.view.document.on('paste', (event, data) => {
                  if (data.dataTransfer._files.length > 0) {
                    event.stop();
                    return;
                  }
                });
                      
              }}          
              onChange={ ( event,editor ) => {
                handleContentChange(editor.getData())
              } }
            />
          </Box>
          {
            formContentDetail.error &&
            <Typography variant='h4' className='label-text' mt={0.5} color="red" fontSize="13px">{formContentDetail.error}</Typography>
          }
          
          {
            allowedToAdd &&
            <Box>
              <Typography variant="body2" sx={{color: "#000", fontFamily: "General Sans Semibold",  fontSize: "16px", mt:"15px"}}>Feature Post</Typography>
              <FormControlLabel control={<Switch disableRipple data-testid="is_featured_page_switch" checked={formData.is_featured.value} onChange={(e) => {handleChange('is_featured', e.target.checked); if (e.target.checked) {setConfirmFeature(true);}}} />} label={
                <>
                <Typography variant="body1" sx={{color: '#8D8B8B', fontSize: '16px'}}>Feature this post</Typography>
                </>
              } />
            </Box>
          }
        </Box>

        <Box sx={{ background: "#f9f9f9", p: "30px 40px", borderRadius: '0 0 10px 10px', display: "flex", alignItems: "center", justifyContent: "space-between", }}>
          <Box>
            <Button to={`/category/${c_name}`} component={NavLink} color="primary" sx={{ fontFamily: "Roboto, sans-serif", fontWeight: "700", fontSize: "15px", }}>Cancel</Button>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", }}>
            <Button variant="outlined" color="primary" sx={{ fontFamily: "Roboto, sans-serif", fontWeight: "700", fontSize: "15px", marginRight: "20px",}} onClick={() => {
              setOpenPreview(true)
            }}>Preview</Button>
            {
              (JSON.stringify(postItem)==='{}' || postItem?.status === 0) &&
              <Button variant="outlined" color="primary" sx={{ fontFamily: "Roboto, sans-serif", fontWeight: "700", fontSize: "15px", marginRight: "20px", }} onClick={() => {submitPost(0)}} disabled={isSubmit}>{isSubmit ? 'Working..' : `Save Draft`}</Button>
            }
            <Button variant="contained" color="error" sx={{ fontFamily: "Roboto, sans-serif", fontWeight: "700", fontSize: "15px", }} onClick={() => {submitPost(1)}} disabled={isSubmit}>{isSubmit ? 'Working..' : postItem?.status === 1 ? `Save` : `Publish Now`}</Button>
          </Box>
        </Box>
      </Box>

      {openPreview &&
        <PostDetailModal type={user.data.categories.filter((item) => item.slug == c_name)[0]?.name} createdAt={location.state?.postItem?.createdAt} contentDetail={formContentDetail} data={formData} open={openPreview} handleClose={() => setOpenPreview(false)}/>
      }

      {confirmFeature &&
          <ConfirmFeaturedPostModal open={confirmFeature} handleClose={() => setConfirmFeature(false)} handleAllowFeatured={handleAllowFeatured} />
        }
        </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  categories: state.categories,
  categoryForm: state.categoryForm
})

const mapDispatchToProps = {
  updateContentPost,
  createNewContentPost,
  setToast,
}

export default connect(mapStateToProps, mapDispatchToProps)(CreatePost)