import React,{useEffect, useState} from 'react'
import { Box, Typography, FormControlLabel, Tooltip, Checkbox, Avatar, TextField, styled, Button, FormControl, InputLabel, InputAdornment, Switch, paginationClasses } from '@mui/material';
import axios from "axios";
import { connect } from 'react-redux';
import { DateAndTimePicker, RewardDateAndTimePicker } from '../DateAndTimePicker';
import { formatDateToDbSup, formatMomentObjectToDate, formatMomentObjectToTime, isValidHttpUrl } from 'src/utils';
import dayjs from 'dayjs';
import moment from 'moment-timezone';

const CustomTextField=styled(TextField)(
  ({theme})=> `
  fieldset{
    border-radius: 4px;
    }
  `
)

const AdvancedSettings = (props) => {
  const { nextTabErrors, setNextTabErrors, user_data, user } = props;
  
  const [allowRedeemPoints, setAllowRedeemPoints] = useState(props.dropData?.redeem_points !== null);
  const [allowWebhook, setAllowWebhook] = useState(props.dropData?.webhook_details !== null);
  const [urlHelperTxt, setUrlHelperTxt] = useState(nextTabErrors.webhook_detail_url.error);
  const { userUpdate } = props
  const [qtyDisabled, setQtyDisabled] = useState((!props.dropData.is_limited_quantity || props.dropData.is_limited_quantity === 'true'));
  const [startDateValue, setStartDateValue] = useState(formatMomentObjectToDate(props?.dropData?.startAt));
  const [startTimeValue, setStartTimeValue] = useState(formatMomentObjectToTime(props?.dropData?.startAt));
  const [endDateValue, setEndDateValue] = useState(formatMomentObjectToDate(props?.dropData?.endsIn));
  const [endTimeValue, setEndTimeValue] = useState(formatMomentObjectToTime(props?.dropData?.endsIn));

  const [scheduleLaterDisabled, setScheduleLaterDisabled] = useState((props.dropData.schedule_now || props.dropData.schedule_now === 'true'));
  const [value, setValue] = useState(formatDateToDbSup(props?.dropData?.endsIn));
  
  const [pageInfoData, setPageInfoData] = useState({
    ...user_data?.other_data,
    advanced_settings_data: user_data?.other_data?.advanced_settings_data ? JSON.stringify(user_data?.other_data?.advanced_settings_data) : "",
    reward_ids: user_data?.other_data?.reward_ids,
    login_page_reward_id: user_data?.other_data?.login_page_reward_id,
    is_home_page: user_data?.other_data?.is_home_page ? user_data?.other_data?.is_home_page : false
  });

  const handleAddnValueData = (type, val) => {
    if (type === 'is_limited_quantity' && val === false) {
      setQtyDisabled(true)
      setNextTabErrors({...nextTabErrors,['quantity']:{value: null, error: ""}});
    } else {
      if (type === 'is_limited_quantity'){
        setQtyDisabled(false)
      }
      setNextTabErrors({...nextTabErrors,[type]:{value: typeof val=="string" ?  val.trim() : val,error:""}});
    }
    props.handleChildData(type, val);
  }

  const handleChange = (newValue) => {
    props.handleChildData('endsIn', newValue)
    setValue(newValue);
    setNextTabErrors({...nextTabErrors,['end_date']:{value: null, error: ""}});
  };

  const handleSchedule = (type, val) => {
    if (type === 'allow_reward_end_date' && val === false) {
      props.handleMultipleChilddata({
        'endsIn': null,
        'allow_reward_end_date': val
      });
      setEndDateValue(dayjs(null));
      setEndTimeValue(moment().startOf('day'));
    } else {

      if (type === 'schedule_now' && val === true) {
        setScheduleLaterDisabled(true);
        setStartDateValue(dayjs(null));
        setStartTimeValue(moment().startOf('day'));
        props.handleChildData('startAt', null);
      } else  if(type === 'schedule_now'){
        setScheduleLaterDisabled(false)
      }
      props.handleChildData(type, val);
    }
  }

  const handleWebhookInputs = (type, val) => {
    const webhook_detail_val =  props.dropData?.webhook_details ? {...props.dropData?.webhook_details} : {};
    webhook_detail_val[type] = val;
    props.handleChildData('webhook_details', webhook_detail_val);
    if (type === 'url') {
      setNextTabErrors({...nextTabErrors,'webhook_detail_url':{value: null, error: ""}});
    }

    setUrlHelperTxt(nextTabErrors.webhook_detail_url.error)
  }

  const testWebhookUrl = async () => {
    const url = props.dropData?.webhook_details?.url;
    console.log(`url ->`,url)
    if (url === '' || url === undefined || url === null) {
      setNextTabErrors({...nextTabErrors,'webhook_detail_url':{value: url, error: 'Not a valid URL'}});
    } else {
      if (!isValidHttpUrl(url)){
        setNextTabErrors({...nextTabErrors,'webhook_detail_url':{value: url, error: 'Not a valid URL'}});
      } else {
        try {
          await axios.post(url);
          setUrlHelperTxt(`Webhook triggered`)
        } catch (e) {
          setNextTabErrors({...nextTabErrors,'webhook_detail_url':{value: url, error: 'Not a valid URL'}});
        }
      }
    }
  }

  return (
    <>
      <Typography variant="body1" textAlign="center" className='subtitle' lineHeight="normal" mb="20px">CREATE REWARD</Typography>
      <Typography variant="h1" textAlign="center" sx={{mb: 1}} className='title'>Advanced settings</Typography>
      <Typography variant="body1" textAlign="center" sx={{mb: 3}} className='subtitle'>Optional settings for your reward</Typography>

      <Box px={8.5}>
        <Typography variant="body2" sx={{color: "#000", fontFamily: "General Sans Semibold",  fontSize: "16px", m: '20px 0 5px 10px'}}>Maximum Claims</Typography>

        <FormControlLabel
          sx={{ m: '0 !important',mt: 1, alignItems: 'start' }}
          control={
            <Checkbox
              disableRipple
              icon={<img alt="Uncheck" src="/assets/img/Uncheck.svg" width="16" height="16" />}
              checkedIcon={<img alt="Check" src="/assets/img/Checkbox.svg" width="16" height="16" />}
              defaultChecked={props.dropData.is_limited_quantity}
              onChange={(e) => {
                handleAddnValueData('is_limited_quantity', e.target.checked)
              }}
              sx={{pt:'0.2rem'}}
            />
          }
          label={
            <Box>
              <Typography variant="body2" sx={{ fontSize: "16px", fontWeight: 500, color: "#8D8B8B", display: 'flex', alignItems: 'center' }}>
                <Box component="span">Limit the number of times this reward can be used in total</Box>
                <Tooltip title={<Box component="span">This limits the total supply for this reward. After the limit is reached, the reward is completed and cannot be claimed.</Box>} arrow><Avatar src="/assets/img/info-icon.svg" alt="Info icon" style={{height:'16px',width:'16px', marginLeft: '5px'}}/></Tooltip>
              </Typography>
              {
                !qtyDisabled &&
                <Box width="32.75%">
                  <CustomTextField fullWidth margin="normal" type="number" value={(props.dropData.category =='raffle' && props.dropData.quantity == 0 ) ? "" :props.dropData.quantity} placeholder="Enter a number" sx={{"& .MuiOutlinedInput-input":{fontSize: '16px'}}} label={<Box component="span">Amount&nbsp;<span style={{color:'red'}}>*</span></Box>} name="quantity" variant="outlined" InputLabelProps={{shrink: true}} error={nextTabErrors.quantity.error !== ""} helperText={nextTabErrors.quantity.error} onWheel={(e) => e.target.blur()} onChange={(e) => { handleAddnValueData('quantity', e.target.value) }}/>
                </Box>
              }
            </Box>
          }
        />
        <FormControlLabel
          disabled={['allow_list', 'raffle', 'poll', 'event_pass'].includes(props.dropData.category)}
          sx={{ m: '0 !important',mt: 1, alignItems: 'start', '&.Mui-disabled':{opacity: 0.5} }}
          control={
            <Checkbox
              disableRipple
              icon={<img alt="Uncheck" src="/assets/img/Uncheck.svg" width="16" height="16" />}
              checkedIcon={<img alt="Check" src="/assets/img/Checkbox.svg" width="16" height="16" />}
              defaultChecked={props.dropData.is_one_time_use}
              onChange={(e) => {
                props.handleChildData('is_one_time_use', e.target.checked)
              }}
              sx={{pt:'0.2rem'}}
            />
          }
          label={
            <Box>
              <Typography variant="body2" sx={{ fontSize: "16px", fontWeight: 500, color: "#8D8B8B", display: 'flex', alignItems: 'center' }}>
                <Box component="span">Unlimited uses per person</Box>
                <Tooltip title="This limits the reward to single use per person." arrow><Avatar src="/assets/img/info-icon.svg" alt="Info icon" style={{height:'16px',width:'16px', marginLeft: '5px'}}/></Tooltip>
              </Typography>
            </Box>
          }
        />

        <Box>
          {/* schedule area */}
          <Typography variant="body2" sx={{color: "#000", fontFamily: "General Sans Semibold", fontSize: "16px", m: '20px 0 5px 10px'}}>Start & Stop Dates</Typography>

          <FormControlLabel
            sx={{ m: '0 !important', alignItems: 'start', width: '100%', '& .MuiTypography-body1': {width: '100%'} }}
            
            control={
              <Checkbox
                disableRipple
                icon={<img alt="Uncheck" src="/assets/img/Uncheck.svg" width="16" height="16" />}
                checkedIcon={<img alt="Check" src="/assets/img/Checkbox.svg" width="16" height="16" />}
                checked={!props.dropData.schedule_now}
                onChange={(e) => {
                  handleSchedule('schedule_now', !e.target.checked)
                }}
                sx={{pt:'0.2rem'}}
              />
            }
            label={
              
              <Box>
                <Typography variant="body2" sx={{ fontSize: "16px", fontWeight: 500, color: "#8D8B8B", display: 'flex', alignItems: 'center' }}>
                  <Box component="span">Set a date for the reward to end</Box>
                </Typography>
                {
                  !props.dropData.schedule_now &&
                  <Box sx={{mt: '10px'}}>
                    <Box>
                      <DateAndTimePicker formtype="new" dropData={props.dropData} nextTabErrors={nextTabErrors} setNextTabErrors={setNextTabErrors} picker_type="startAt" handleChildData={props.handleChildData} startDateValue={startDateValue} setStartDateValue={setStartDateValue} startTimeValue={startTimeValue} setStartTimeValue={setStartTimeValue} timeWidth="7rem" dateWidth="49.25rem" is_scheduled={props.dropData.schedule_now} />
                    </Box>
                    <FormControlLabel  sx={{"& .MuiFormControlLabel-label": {opacity: props.dropData.is_coming_soon ? '1' : '0.5'}, "& .MuiCheckbox-root": {pl:'0'}, ml: "0", mt: "5px"}} onChange={(e) => {handleAddnValueData('is_coming_soon', e.target.checked)}} checked={props.dropData.is_coming_soon} control={<Checkbox defaultChecked disableRipple icon={<img src="/assets/img/Uncheck.svg" width="18" alt="" />} checkedIcon={<img src="/assets/img/Checkbox.svg" width="18" alt="" />}  />} label={`Display as "coming soon"`} />
                  </Box>
                }
              </Box>
            }
          />

          <FormControlLabel
            sx={{ m: '0 !important', alignItems: 'start', width: '100%', '& .MuiTypography-body1': {width: '100%'} }}
            control={
              <Checkbox
                disableRipple
                icon={<img alt="Uncheck" src="/assets/img/Uncheck.svg" width="16" height="16" />}
                checkedIcon={<img alt="Check" src="/assets/img/Checkbox.svg" width="16" height="16" />}
                checked={props.dropData.allow_reward_end_date}
                onChange={(e) => {
                  handleSchedule('allow_reward_end_date', e.target.checked)
                  // handleChange("")
                }}
                sx={{pt:'0.2rem'}}
              />
            }
            label={
              <Box>
                <Typography variant="body2" sx={{ fontSize: "16px", fontWeight: 500, color: "#8D8B8B", display: 'flex', alignItems: 'center' }}>
                  <Box component="span">Schedule for a future publish date</Box>
                </Typography>
              </Box>
            }
          />

          {
            props.dropData.allow_reward_end_date &&
            <Box sx={{mt: '5px', ml: '10px'}}>
              <RewardDateAndTimePicker requiredf={true} formtype="new" dropData={props.dropData} nextTabErrors={nextTabErrors} setNextTabErrors={setNextTabErrors} picker_type={["end_date", "end_time"]} suffix_name="End" handleChildData={handleChange} startDateValue={endDateValue} setStartDateValue={setEndDateValue} startTimeValue={endTimeValue} setStartTimeValue={setEndTimeValue} timeWidth="16.25rem" dateWidth="43rem" />
            </Box>
          }
        </Box>

        {
          props.dropData.community_type === "membership" &&
          <Box>
            <Typography variant="body2" sx={{color: "#000", fontFamily: "General Sans Semibold",  fontSize: "16px", m: '20px 0 5px 10px'}}>Welcome Reward</Typography>
            <FormControlLabel sx={{m: '0 !important'}} control={<Switch disableRipple data-testid="allow_reward_page_switch" defaultChecked={props.dropData.allow_gift} onChange={(e) => {handleAddnValueData('allow_gift', e.target.checked);}} />} label={
              <>
              <Typography variant="body1" sx={{color: '#8D8B8B', fontSize: '16px'}}>Feature this reward to new members (for 30 days after they join.)</Typography>
              </>
            } />
          </Box>
        }

        {
          props.dropData.community_type === "membership" && user.data.engagement_enabled &&
          <Box>
            <Typography variant="body2" sx={{color: "#000", fontFamily: "General Sans Semibold",  fontSize: "16px", m: '20px 0 5px 10px'}}>Require Points to Redeem</Typography>
            <FormControlLabel
              sx={{ m: '0 !important',mt: 1, alignItems: 'start' }}
              control={
                <Checkbox
                  disableRipple
                  icon={<img alt="Uncheck" src="/assets/img/Uncheck.svg" width="16" height="16" />}
                  checkedIcon={<img alt="Check" src="/assets/img/Checkbox.svg" width="16" height="16" />}
                  defaultChecked={allowRedeemPoints}
                  onChange={(e) => {
                    setAllowRedeemPoints(e.target.checked)
                  }}
                  sx={{pt:'0.2rem'}}
                />
              }
              label={
                <Box>
                  <Typography variant="body2" sx={{ fontSize: "16px", fontWeight: 500, color: "#8D8B8B", display: 'flex', alignItems: 'center' }}>
                    <CustomTextField disabled={!allowRedeemPoints} fullWidth margin="normal" type="number" value={props.dropData?.redeem_points} placeholder="Enter amount to deduct" sx={{mt: '10px', "& .MuiOutlinedInput-input":{fontSize: '16px'}}} label={<Box component="span">Points&nbsp;<span style={{color:'red'}}>*</span></Box>} name="re" variant="outlined" InputLabelProps={{shrink: true}} error={nextTabErrors.redeem_points.error !== ""} helperText={nextTabErrors.redeem_points.error} onWheel={(e) => e.target.blur()} onChange={(e) => { handleAddnValueData('redeem_points', e.target.value) }}/>
                  </Typography>
                </Box>
              }
            />
          </Box>
        }

        {
          props.dropData.community_type === "membership" &&
          <Box>
            <Typography variant="body2" sx={{color: "#000", fontFamily: "General Sans Semibold",  fontSize: "16px", m: '20px 0 5px 10px'}}>Add a Webhook</Typography>
            <FormControlLabel
              sx={{ m: '0 !important', width: '100%',mt: 1, alignItems: 'start', '& .MuiFormControlLabel-label': {width: '100%', '& .MuiFormControlLabel-label': {}} }}
              control={
                <Checkbox
                  disableRipple
                  icon={<img alt="Uncheck" src="/assets/img/Uncheck.svg" width="16" height="16" />}
                  checkedIcon={<img alt="Check" src="/assets/img/Checkbox.svg" width="16" height="16" />}
                  defaultChecked={allowWebhook}
                  onChange={(e) => {
                    setAllowWebhook(e.target.checked)
                  }}
                  sx={{pt:'0.2rem'}}
                />
              }
              label={
                <Box sx={{width: '100%'}}>
                  <Typography variant="body2" sx={{ fontSize: "16px", fontWeight: 500, color: "#8D8B8B", display: 'flex', alignItems: 'center' }}>
                    <Box component="span">Trigger an event when a reward is claimed</Box>
                  </Typography>
                  {
                    allowWebhook &&
                    <Box mt={1}>
                      <Typography variant="body2" sx={{ fontSize: "16px", width: '100%', fontWeight: 500, color: "#8D8B8B", display: 'flex', alignItems: 'center' }}>
                        <CustomTextField fullWidth margin="normal"  value={props.dropData?.webhook_details?.name} placeholder="Enter a name" sx={{mt: '10px', "& .MuiOutlinedInput-input":{fontSize: '16px'}}} label={<Box component="span">Friendly Name</Box>} name="re" variant="outlined" InputLabelProps={{shrink: true}} onChange={(e) => { handleWebhookInputs('name', e.target.value) }}/>
                      </Typography>

                      <Typography variant="body2" sx={{ fontSize: "16px", width: '100%', fontWeight: 500, color: "#8D8B8B", display: 'flex', alignItems: 'center' }}>
                        <CustomTextField fullWidth margin="normal"  value={props.dropData?.webhook_details?.url} placeholder="Enter a Webhook URL" sx={{mt: '10px', "& .MuiOutlinedInput-input":{fontSize: '16px'}}} label={<Box component="span">POST URL&nbsp;<span style={{color:'red'}}>*</span></Box>} name="re" variant="outlined" InputLabelProps={{shrink: true}} error={nextTabErrors.webhook_detail_url.error !== ""} helperText={urlHelperTxt} onChange={(e) => { handleWebhookInputs('url', e.target.value) }} InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Button variant="outlined" size="small" component="label" onClick={testWebhookUrl} className="font-weight-600" sx={{borderRadius: '6px'}}>Test</Button>
                            </InputAdornment>
                          )
                        }} FormHelperTextProps={{ style: { color: (nextTabErrors.webhook_detail_url.error !== "") ? 'red' : '#0C8BF4' } }} />
                      </Typography>
                    </Box>
                    }
                </Box>
              }
            />
          </Box>
        }
      </Box>
    </>
  )
}

const mapStateToProps = (state) => ({
  user: state.user
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(AdvancedSettings)