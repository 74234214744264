import React, {useState} from "react";
import { Box, Autocomplete, Typography, Button, Modal, Card, TextField, FormControl } from '@mui/material'
import countries from "../../services/constants/countries.json"
import { RewardTypePill } from "../RewardsData/reward_type_pill";

const MerchandisePreview = (props) => {
  const { reward, info } = props;

  const sizeData = ['XS',"S","M","L","XL","XXL","XXXL"]
    
  return(
    <Box sx={{height:"75vh", overflow:"auto", px:3}} className="preview-modal">
      <Box textAlign="center">
        <RewardTypePill reward={reward} />
      </Box>
      <Typography variant="h6" className="modal-title">Please complete this form to claim reward</Typography>
      {
        info?.details &&
        <Typography variant="h5" className="description">{info?.details}</Typography>
      }
      {(info?.support_contact !== "" && info?.support_contact !== null ) &&
      <Typography variant="h5" align='center' sx={{color:'#343957',fontFamily: "Inter,sans-serif", fontWeight: "400",fontSize: "1rem"}}>If you need help, contact: {info?.support_contact}</Typography>
      }
      <Card className="merchandise-form" sx={{bgcolor:'#F6F8FB', marginTop:'19px', px:2,border: '1px solid #CCCEDD',boxShadow: '0px 4px 21px rgba(24, 69, 136, 0.2)',borderRadius: "6px",py:2 }}>
        {
          (info?.allow_size) &&
          <>
            <FormControl className='merch-form' sx={{my:0.9}} fullWidth>
              <Autocomplete
                disableClearable={true}
                disablePortal
                size='small'
                id="combo-box-demo"
                options={sizeData}
                sx={{".MuiAutocomplete-input": {color: "#8c96a8"}}}
                renderInput={(params) => <TextField required {...params} placeholder="Choose your size" label="Size" InputLabelProps={{shrink: true}}/>}
              />
            </FormControl>

            <Box sx={{width: "140px", borderTop: "1px solid rgba(34, 51, 84, 0.2)", mt:1,mx:"auto"}}></Box>
          </>
        }


        <TextField size="small" sx={{mb:0.7}} fullWidth margin="normal" name="full_name" label="Full Name" variant="outlined" InputLabelProps={{shrink: true}} placeholder='Your First and Last Name' required inputProps={{ maxLength: 130 }} />
        <TextField size="small" sx={{mb:0.7}} fullWidth margin="normal" name="address_line_1" label="Address Line 1" variant="outlined" InputLabelProps={{shrink: true}} placeholder='Street address, P.O. box, company name, c/o' required inputProps={{ maxLength: 130 }} />
        <TextField size="small" sx={{mb:0.7}} fullWidth margin="normal" name="address_line_2" label="Address Line 2" variant="outlined" InputLabelProps={{shrink: true}} placeholder='Apartment, suite, unit, floor, etc.' inputProps={{ maxLength: 130 }} />

        <TextField size="small" sx={{mb:0.7}} fullWidth margin="normal" name="city" label="City" variant="outlined" InputLabelProps={{shrink: true}} required inputProps={{ maxLength: 130 }} />
        <TextField size="small" sx={{mb:0.7}} fullWidth margin="normal" name="state" label="State/Province/Region" variant="outlined" InputLabelProps={{shrink: true}} required inputProps={{ maxLength: 130 }} />
        <TextField size="small" sx={{mb:0.7}} fullWidth margin="normal" name="zip" label="ZIP/ Postal Code" variant="outlined" InputLabelProps={{shrink: true}} required inputProps={{ maxLength: 130 }} />

        <FormControl className='merch-form' sx={{my:0.9}} fullWidth>
          <Autocomplete disableClearable={true} disablePortal size='small' id="combo-box-demo" options={countries} sx={{".MuiAutocomplete-input": {color: "#8c96a8"}}} renderInput={(params) => <TextField required {...params} placeholder="Choose a country" label="Country" InputLabelProps={{shrink: true}}/>} />
        </FormControl>

        <Box sx={{width: "140px", borderTop: "1px solid rgba(34, 51, 84, 0.2)", mt: 1.3, mb:0.5, mx:"auto"}}></Box>

        <TextField size="small" sx={{mb:0.7}} fullWidth margin="normal" name="email" label="Email" variant="outlined" InputLabelProps={{shrink: true}} placeholder="Enter your email address" required inputProps={{ maxLength: 130 }} />
        <TextField size="small" sx={{mb:0.7}} fullWidth margin="normal" name="phone" label="Phone" variant="outlined" InputLabelProps={{shrink: true}} placeholder="Enter your phone number (including country code)" required inputProps={{ maxLength: 130 }} />

        <TextField size="small" sx={{mb:0.7}} multiline rows={3} fullWidth margin="normal" name="phone" variant="outlined" placeholder="Note or Comment" required inputProps={{ maxLength: 130 }} />
        <Box display="flex" justifyContent="center">
          <Button variant="contained" className="claim-blue-btn" sx={{width:'auto !important'}}>Submit</Button>
        </Box>
      </Card>
    </Box>
  )
}
export default MerchandisePreview;