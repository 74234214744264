import React from 'react'
import { Box, Typography} from "@mui/material";

function DefaultLayout(props) {
  return (
    <Box sx={{mt:3, background: "linear-gradient(0deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), url('/assets/img/confetti.svg')", backgroundColor: "white",backgroundSize: "cover", backgroundRepeat: "no-repeat" ,borderRadius: "15px", border: "1px solid #1A1616", p:3}}>
        <Box display="flex" justifyContent="center" alignItems="center" textAlign="center">
          <Box sx={{background: "rgba(255, 255, 255, 0.70)", borderRadius: "15px", p: 2}}>
            <Typography variant='h3' sx={{color: "#252A30", fontFamily: "Poppins, sans-serif", fontSize: "24px", fontWeight: "700"}}>Your Reward</Typography>
            {
              props.details &&
              <Typography variant='body1'sx={{color: "#343957", fontFamily: "Inter", fontSize: "16px", fontWeight: "400"}}>{props.details}</Typography>
            }
          </Box>
        </Box>

        <Box sx={{mt:3,borderRadius: "16px", border: "1px solid #CCCEDD", boxShadow: "0px 4px 21px 0px rgba(24, 69, 136, 0.20)", p:3, background: "#F6F8FB"}}>
          {props.children}
        </Box>
        <Box textAlign="center">
          <Typography variant='body2' display="flex" alignItems="center" justifyContent="center" mt="24px" sx={{fontFamily:"General Sans", fontWeight:400,fontSize:"13px"}}><img src='/assets/img/share_button.svg' alt='Share Button' style={{marginRight:"5px"}}/><span style={{textDecoration:"underline"}}>Want to tell someone about this reward?</span></Typography>
        </Box>
    </Box>
  )
}

export default DefaultLayout