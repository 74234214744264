import { Box, Typography, Alert, Avatar,Container,useTheme} from '@mui/material';

import React,{useEffect,useState} from 'react'
import { connect } from 'react-redux';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import  { useParams } from 'react-router-dom';

import { newEmailConfirmApi} from '../../services/actions/api';

const ConfirmNewEmail = (props) => {
  const {token} = useParams();
  const [emailConfirm, setEmailConfirm] = useState(false);
  const [confirmMessage, setEmailConfirmMessage] = useState({type:"",message:""});
  const { user } = props;
  
  const theme = useTheme()

  const emailApi = async () => {
    let data = await newEmailConfirmApi(token);
    setEmailConfirmMessage(data);
  }
  
  useEffect(() => {
    if(token!="" && typeof token != "undefined"){
      setEmailConfirm(true);
      emailApi();
    }
  },[]);
  

  return (
    <>
      {
        (confirmMessage.type =='success' || confirmMessage.type =='error')  && 
        <Box display="flex" flexDirection="column" justifyContent="center" sx={{height: `calc(100vh - ${theme.header.height})`}}>
          <>
            {
              confirmMessage.type =='success' ?
              <Box sx={{mt:4}} display="flex" justifyContent="center">
                <Avatar src="/assets/images/circle-success.svg" alt="success" sx={{height: '100px', width: '100px'}}/>
              </Box>
              :
              <Box sx={{mt:4}} display="flex" justifyContent="center">
                <Avatar src="/assets/images/circle-error.svg" alt="oops" sx={{height: '100px', width: '100px'}}/>
              </Box>
            }
            <Typography variant="h1" sx={{fontFamily: 'roboto, sans-serif',fontSize: '2.5rem', pt:3,color: '#223354'}} align="center">
              {
              confirmMessage.message.includes('Cannot read properties of null') ? 'Email verification link has expired' : confirmMessage.message
              }</Typography>
            <Typography  variant="h4" sx={{my:2,fontFamily: 'roboto, sans-serif',fontSize: '1.563rem',fontWeight:"300", pt:1,color: '#223354'}} align="center">{confirmMessage.email ? ((confirmMessage.type == "success" ? 'Your email ': "") + confirmMessage.email + (confirmMessage.type == 'success'?  ' has been verified' : '')) : ""}</Typography>
            <br/> <hr style={{width:"15%"}}/> <br/>

            <Box sx={{pt:3}} display="flex" justifyContent="center">
              <Typography variant="h2" sx={{color:"#223354",fontFamily:"Roboto, sans-serif",fontWeight:"700",fontSize:"1.563rem"}} align="center">
                {
                  confirmMessage.message.includes('Cannot read properties of null') ? 
                    <Box component="span">You can close this tab and return to the<br/>main application window to resend an email.</Box> :

                    <Box component="span">You can close this tab and return to the<br/>main application window.</Box>
                    
                }
                </Typography>
            </Box>
          </>
        
      

          {user.message!="" &&  
            <Container maxWidth="xs" sx={{mt:4}} >
              <Alert severity="error"
                action={
                  <IconButton aria-label="close" color="inherit" size="small" onClick={() => { props.userReset(); }}>
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
                sx={{ mb: 2 }}
              >
                {user.message}
              </Alert>
            </Container>
          }
        </Box>
      }
    </>
  )
}



const mapStateToProps = (state) => ({
  user: state.user,
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmNewEmail)