import React, { useState} from 'react';
import { Modal, Box, Typography, TextField, Grid, Button, Snackbar, Alert} from '@mui/material';
import { connect } from 'react-redux';
import { notifyRaffleRewardWinner, setToast } from 'src/services/actions';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '0px solid #000',
  boxShadow: 24,
  borderRadius: '8px',
  pt:5
};

const NotifyRaffleWinner = (props) => {
  const [isSubmit, setIsSubmit] = useState(false);
  const [subjectError, setSubjectError] = useState(false);
  const [messageError, setMessageError] = useState(false);
  const { open, handleClose, notifyRaffleObj, dropsData, setShowSentAlert } = props;
  const [notifyFormData, setNotifyFormData] = useState({
    message: dropsData.drop_type_data[0]?.winner_message,
    subject: "Congratulations, you've won the raffle!"
  })
  // console.log('notifyRaffleObj =>',notifyRaffleObj)
  const handleChange = (type, val) => {
    const formvals = {...notifyFormData};
    if (type == 'subject') {
      setSubjectError(false);
    } else {
      setMessageError(false)
    }
    formvals[type] = val
    setNotifyFormData(formvals)
  }

  const sendNotificationToWinners = async() => {
    
    const formdata = {...notifyFormData};
    if (formdata.subject.trim() == "") {
      setSubjectError(true)
      return false;
    }

    if (formdata.message.trim() == "") {
      setMessageError(true)
      return false;
    }
    setIsSubmit(true);
    formdata['claim_data'] = notifyRaffleObj;
    notifyRaffleRewardWinner(dropsData.id, formdata, (resp) => {
      if (resp.type == 'success') {
        props.setToast(true,{type:"success",message:"Your email(s) have been sent."});
        handleClose(true)
        setShowSentAlert(true);
        setTimeout(() => {
          setShowSentAlert(false);
        },5000);
      }

      setIsSubmit(false)
    })
  }
  return (
    <Modal open={open} aria-labelledby="Notify Winners" className='modals'>
      <Box sx={style} className='modal-card'>
        <Typography variant="body2" sx={{fontFamily: "Roboto, sans-serif", fontWeight: "600", fontSize: "1.875rem", mb: 0}} align="center">Notify {(notifyRaffleObj.constructor === Array && notifyRaffleObj.length >1) ? "Winners" : "Winner"}</Typography>
        
        <Typography variant="body1" align="center" sx={{mt:0.75, fontFamily: "Roboto,sans-serif", fontWeight: "600", fontSize: "1rem"}}>Customize the email to your {(notifyRaffleObj.constructor === Array && notifyRaffleObj.length >1) ? "winners" : "winner"}</Typography>
        
        
        <Box display='flex' justifyContent="center" sx={{mt:"15px"}}>
          <Grid container justifyContent="center">
            <Grid item lg={8}>
              <Box display="flex" justifyContent="center">
                <Typography variant="body1" className="draw-raffle-note-msg" sx={{color: "#1A1616 !important"}} mb="10px">
                  {
                    notifyRaffleObj != null &&
                    (notifyRaffleObj.constructor !== Array) ? 
                    <Box component="span">
                      {notifyRaffleObj.claim_info.email}
                    </Box>
                    :
                    (notifyRaffleObj.length > 3) ?
                    <Box component="span">{notifyRaffleObj.map((c,indx) =>  indx <= 2 && c.claim_info.email).filter(val => Boolean(val)).join(", ")}, +{parseInt(notifyRaffleObj.length - 3)}</Box>
                    :
                    <Box component="span">{notifyRaffleObj.map((c) => c.claim_info.email).join(", ")}</Box>
                  }
                </Typography>
              </Box>

              <TextField fullWidth rows={3} margin="normal" name="subject" label={<span>Subject Line&nbsp;<span style={{color:'red'}}>*</span></span>} variant="outlined" InputLabelProps={{shrink: true}} placeholder='Please write a subject.' defaultValue={notifyFormData.subject} inputProps={{ maxLength: 255 }} onChange={(e)=>handleChange("subject", e.target.value)} error={subjectError} helperText={subjectError ? "Subject can't be blank" : ""} />
              <Box className='textareArea'>
              <TextField fullWidth multiline rows={3} margin="normal" name="short_description" defaultValue={notifyFormData.message} label={<span>Message&nbsp;<span style={{color:'red'}}>*</span></span>} variant="outlined" InputLabelProps={{shrink: true}} placeholder='Please write a message.' inputProps={{ maxLength: 255 }} onChange={(e)=>handleChange("message", e.target.value)} error={messageError} helperText={messageError ? "Message can't be blank" : ""} />
              </Box>

              

              <Box display="flex" justifyContent="start">
                <Typography variant="body1" className="draw-raffle-note-msg" mt="17px">Note: This notification will not be sent to previously-notified winners</Typography>
              </Box>
              <Box display="flex" justifyContent="start">
                <Typography variant="body1" className="draw-raffle-email-label" mt="17px"><strong>Email displayed to the winners:</strong> {dropsData.drop_type_data[0].contact_email}</Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box display="flex" justifyContent="center">
          
        </Box>
        <Box sx={{mt:4,py:3,px:5,background:"rgba(34, 51, 84,0.02)"}}>
          <Box display="flex" justifyContent="space-between">
            <Button variant="outlined" color="primary" sx={{fontFamily: "Roboto, sans-serif", fontWeight: "700" ,fontSize: "15px"}} onClick={handleClose}>Cancel</Button>
            <Button variant="contained" color="primary" sx={{fontFamily: "Roboto, sans-serif", fontWeight: "700" ,fontSize: "15px"}} onClick={sendNotificationToWinners} disabled={isSubmit}>{isSubmit ? 'Working' : `Email the Winners`}</Button>
          </Box>
        </Box>
      </Box>


      
    </Modal>
  )
}


const mapStateToProps = (state) => ({
})

const mapDispatchToProps = {
  notifyRaffleRewardWinner,
  setToast
}

export default connect(mapStateToProps, mapDispatchToProps)(NotifyRaffleWinner)