import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";

// Material UI
import { Box, Typography } from "@mui/material";

import { userLogin, userReset, userWalletAuth, userCustomLogin } from "../../services/actions/ethereum";
import { setPageThought } from "../../services/actions/api";
import CustodialWallet from "src/components/wallet";
import MobileViewModal from "src/components/Modals/mobile_view";

const Home = (props) => {
  const { user, page } = props;
  const [mobileMode, setMobileMode] = useState(false);
  const [responseMsg, setResponseMsg] = useState("");
  const currentTime = new Date().getTime();
  useEffect(() => {
    props.setPageThought({
      heading: "Did you know?",
      txt: "Rewards are a great way to add value and engagement to NFTs. And Step3 makes it easy to create unique rewards for any NFT community.",
    });
  }, []);

  useEffect(() => {
    if (page.width <= 430) {
      setMobileMode(true);
    } else {
      setMobileMode(false);
    }
  }, [page]);

  const login = (data, cb) => {
    console.log("login-data", data);
    console.log("data.event", data.event);
    if (user.type !== "request") {
      props.userReset();
      const finalUserData = { ...data };
      finalUserData['currentTime'] = currentTime;
      if (finalUserData.wallet_type === "mobile") {
        finalUserData["phone"] = data.phone;
        finalUserData["email"] = null;
      }

      if (finalUserData.wallet_type === "email") {
        finalUserData["phone"] = null;
        finalUserData["email"] = data.email;
      }
      if (data.event) {
        props.userCustomLogin(data.eth_address, data.signature, currentTime, data.profile_img, data.email, "mint", data, (response) => {
          setResponseMsg(response.response_msg);
          cb(response)
        });
      } else {
        props.userWalletAuth(finalUserData, (res) => {
          console.log("AAAA =>",res)
          cb(res)
        });
      }
    }
  };

  return (
    <>
      {props.allowedCountry.data.type != undefined && props.allowedCountry.data.type != "error" && (
        <Box display="flex" flexDirection="column" className="homepage" justifyContent="center" height="calc(100vh - 80px)">
          <Box display="flex" alignItems="center" justifyContent={"center"} flexDirection="column">
            <Typography variant="h3" sx={{ fontSize: "2.438rem", fontFamily: "Codec Pro Heavy", color: "#1A1616", lineHeight: "normal", textAlign: "center", mb: 5 }}>
              Welcome to Step3
            </Typography>

            <CustodialWallet
              signMessage={`Welcome to Step3!\n\nSigning this message will not trigger a blockchain transaction, cost fees, or allow us to transfer contents from your wallet.\n\nSignature Specific ID: ${currentTime}`}
              handleAuth={login}
              title="Sign in or register"
              wallets={["Phone", "Email", "Google", "Apple", "Web3 wallets"]}
              colors={{ bg: "#ffffff", pm: "#ED1F23", pm_text: "#ffffff", ac: "#ffffff" }}
            />
          </Box>
        </Box>
      )}

      {user.type == "email_empty" && <Navigate to="/register" />}
      {user.type == "success" && <Navigate to="/rewards/community" state={{ msg: responseMsg }} />}

      {mobileMode && <MobileViewModal open={mobileMode} handleClose={() => setMobileMode(false)} />}
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  page: state.page,
  allowedCountry: state.allowedCountry,
});

const mapDispatchToProps = {
  userLogin,
  userWalletAuth,
  userCustomLogin,
  userReset,
  setPageThought,
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
