import React,{useEffect, useState} from 'react'
import { Box, Typography, TextField, Button, FormControl, InputLabel, InputAdornment, Switch, paginationClasses } from '@mui/material';
import { updateAdminUserAdvancedSettings, resetUpdateAdminUser } from '../../../services/actions'
import { connect } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import NetworkSelectionOptions from 'src/components/NetworkSelectionOptns';
import { isValidHttpUrl } from 'src/utils';


const AdvancedSettings = (props) => {
  const { handleClose, user_data } = props;
  
  const [isSubmit, setIsSubmit] = useState(false);
  const { userUpdate } = props
  

  useEffect(() => {
    props.resetUpdateAdminUser()
  },[])
  
  const [pageInfoData, setPageInfoData] = useState({
    ...user_data?.other_data,
    advanced_settings_data: user_data?.other_data?.advanced_settings_data ? JSON.stringify(user_data?.other_data?.advanced_settings_data) : "",
    reward_ids: user_data?.other_data?.reward_ids,
    login_page_reward_id: user_data?.other_data?.login_page_reward_id,
    is_home_page: user_data?.other_data?.is_home_page ? user_data?.other_data?.is_home_page : false
  });

  const handlePageSettings =(type, val) => {
    let existing_data = {...pageInfoData};
    existing_data[type] = val
    
    setPageInfoData(existing_data)
  }

  const updateRewardPageSettings = () => {
    let infoData = {...pageInfoData};
    

    setIsSubmit(true)
    let membership_page_settings = {};
    infoData['advanced_settings_data'] = infoData.advanced_settings_data ? JSON.parse(infoData.advanced_settings_data) : {}
    membership_page_settings['other_data'] = infoData
    setIsSubmit(false)
    props.updateAdminUserAdvancedSettings(membership_page_settings, user_data.id, (resp) => {
      setIsSubmit(false)
      props.handleClose(true)
    })
  }

  const label = { inputProps: { 'aria-label': `Switch Home Page` } };

  return (
    <Box className="textareArea">
      <Box minHeight="360px">
        <TextField fullWidth multiline rows={5} margin="normal" label='Advanced Settings' name="advanced_settings_data" variant="outlined" InputLabelProps={{shrink: true}} onChange={(e) => handlePageSettings('advanced_settings_data', e.target.value)} value={pageInfoData.advanced_settings_data} helperText={''}  />
      </Box>

      <Box display={'flex'} justifyContent={'space-between'} mt={3}>
        <Button variant="outlined" color="primary" onClick={handleClose}>Cancel</Button>
        <Button variant="contained" color="primary" sx={{cursor: userUpdate.type == "request" ? "not-allowed":"pointer", opacity:userUpdate.type == "request" ? '0.5':"1"}} onClick={userUpdate.type !== "request" ? updateRewardPageSettings : undefined}>{userUpdate.type == "request" ? "Updating..." : "Update"}</Button>
      </Box>
    </Box>
  )
}

const mapStateToProps = (state) => ({
  userUpdate: state.userUpdate,
  adminUsers: state.adminUsers
})

const mapDispatchToProps = {
  updateAdminUserAdvancedSettings,
  resetUpdateAdminUser
}

export default connect(mapStateToProps, mapDispatchToProps)(AdvancedSettings)