import React, { useEffect, useState } from 'react'
import WalletArea from './walletArea'
import { Box } from '@mui/material'
import VerifyWithCode from './verifyWithCode'
import ChooseWallet from './verifyWithCode/chooseWallet'
import axios from 'axios'
import { gtag } from 'src/utils'

export async function walletCreateOtp(wallet_type, data, callback) {
  try {
    let verification = await axios.post(`${process.env.REACT_APP_BASE_APPLE_URL}/api/otp/create/${wallet_type}`, data);
    callback(verification.data);
  } catch (e) {
    callback({type: 'error'})
  }
}

export async function walletVerifyOtp(wallet_type, data, callback) {
  try {
    let verification = await axios.post(
      `${process.env.REACT_APP_BASE_APPLE_URL}/api/otp/verify/${wallet_type}`, {...data}
    );
    callback(verification.data);
  } catch (e) {
    callback({type: 'error'})
  }
}

function CustodialWallet(props) {
  const [openedLoginT, setOpenedLoginT] = useState("Phone");
  const [otpData, setOtpData] = useState({});
  const [walletClicked, setWalletClicked] = useState("");
  const [inputVal, setInputVal] = useState("");
  const [walletResponse, setWalletResponse] = useState({type: '', data: {}, message: ''});
  const [step, setStep] = useState("1");
  const { title, signMessage } = props;

  const {bg, pm, ac, pm_text} = props.colors

  const theme = {
    data: {
      base_bg_color: bg,
      base_pm_color: pm,
      base_pm_text_color: pm_text,
      base_ac_color: ac
    }
  }

  return (
    <Box sx={{height: "auto", display: "flex", alignItems: "center", justifyContent: "center"}}>
      {step == "1" &&
        <WalletArea openedLoginT={openedLoginT} setOpenedLoginT={setOpenedLoginT} setOtpData={(res) => setOtpData(res)} walletClicked={walletClicked} setWalletClicked={setWalletClicked} inputVal={inputVal} setInputVal={setInputVal} setStep={setStep} signMessage={signMessage} theme={theme} title={(title ? title : 'Title (optional)')} handleAuth={props.handleAuth} wallets={props.wallets} walletResponse={walletResponse} setWalletResponse={setWalletResponse} />
      }
      {step == "2" &&
        <VerifyWithCode setOpenedLoginT={setOpenedLoginT} setOtpData={setOtpData} setStep={setStep} otpData={otpData} walletClicked={walletClicked} setWalletClicked={setWalletClicked} inputVal={inputVal} setInputVal={setInputVal} signMessage={signMessage} theme={theme} walletResponse={walletResponse} setWalletResponse={setWalletResponse} handleAuth={props.handleAuth} />
      }
      {step == "3" &&
        <ChooseWallet signMessage={signMessage} setStep={setStep} theme={theme} handleAuth={props.handleAuth} walletResponse={walletResponse} setWalletResponse={setWalletResponse} data={{ name: "Google", icon: "google", step: "2", allowClick: false}} />
      }
    </Box>
  )
}

export default CustodialWallet