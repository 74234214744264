import React, { useEffect, useState } from "react";
import { Modal, Box, Typography, TextField, Button, Snackbar, Alert, FormControl, InputLabel, InputAdornment } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { updateAdminUsers, resetUpdateAdminUser } from "../../../services/actions";
import { connect } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import NetworkSelectionOptions from "src/components/NetworkSelectionOptns";
import { isValidHttpUrl } from "src/utils";

const MembershipNftDetails = (props) => {
  const { handleClose, user_data } = props;

  const [isSubmit, setIsSubmit] = useState(false);
  const { userUpdate } = props;

  const handleFileSelect = (type, fileobj) => {
    let existing_data = { ...pageInfoData };
    existing_data[type] = fileobj;
    setPageInfoData(existing_data);
  };

  useEffect(() => {
    props.resetUpdateAdminUser();
  }, []);

  const [pageInfoData, setPageInfoData] = useState({
    chain_id: user_data?.membership_nft_details?.chain_id,
    collection_image: user_data?.membership_nft_details?.collection_image,
    nft_image: user_data?.membership_nft_details?.nft_image,
    contract_name: user_data?.membership_nft_details?.contract_name,
    contract_address: user_data?.membership_nft_details?.contract_address,
    nft_metadata: user_data?.membership_nft_details?.nft_metadata ? JSON.stringify(user_data?.membership_nft_details?.nft_metadata) : "",
    transaction_private_key: "",
  });

  // console.log("pageInfoData?.chain_id =>", user_data.membership_nft_details)

  const handlePageSettings = (type, val) => {
    let existing_data = { ...pageInfoData };
    existing_data[type] = val;

    setPageInfoData(existing_data);
  };

  const updateRewardPageSettings = () => {
    let infoData = { ...pageInfoData };

    setIsSubmit(true);
    let membership_page_settings = {};
    membership_page_settings["nft_details"] = infoData;
    membership_page_settings["nft_details"]["nft_metadata"] = infoData.nft_metadata ? JSON.parse(infoData.nft_metadata) : {};
    setIsSubmit(false);
    props.updateAdminUsers(membership_page_settings, user_data.id, (resp) => {
      setIsSubmit(false);
      props.handleClose(true);
    });
  };

  const handleNetworkChange = (elem) => {
    handlePageSettings("chain_id", elem.target.value);
  };

  const triggerImage = (e) => {
    let preview = document.getElementById("image_preview");
    if (e.target.id != "addImage" && e.target.id != "file_upload" && !preview.contains(e.target)) {
      document.getElementById("addImage").click();
    }
  };
  const triggerNftImage = (e) => {
    let nftpreview = document.getElementById("image_nft_preview");

    if (e.target.id != "addNftImage" && e.target.id != "file_nft_upload" && !nftpreview.contains(e.target)) {
      document.getElementById("addNftImage").click();
    }
  };

  const onSelectFile = (img_type, e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        handleFileSelect(img_type, reader.result.toString());
      });

      reader.readAsDataURL(e.target.files[0]);
    }
  };
  const removeImage = (img_type, e) => {
    let existing_data = { ...pageInfoData };
    existing_data[img_type] = "";
    setPageInfoData(existing_data);
  };

  return (
    <Box className="textareArea">
      <Box>
        <FormControl className="merch-form" sx={{ marginTop: "1rem" }} fullWidth>
          <InputLabel id="demo-simple-select-label" sx={{ mt: pageInfoData?.chain_id == "" ? "-7px" : "" }}>
            Chain ID
          </InputLabel>
          <NetworkSelectionOptions handleChange={handleNetworkChange} errorStatus={false} network={pageInfoData?.chain_id} />
        </FormControl>
        <TextField
          sx={{ mt: 2 }}
          fullWidth
          className="Add-image"
          variant="outlined"
          disabled
          placeholder={"Collection Image"}
          onClick={triggerImage}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Button variant="outlined" component="label" id="addImage" className="font-weight-600">
                  Browse...
                  <input type="file" id="file_upload" accept="image/png, image/jpeg, image/jpg, image/gif, image/svg+xml" onChange={(e) => onSelectFile("collection_image", e)} hidden />
                </Button>
              </InputAdornment>
            ),
            startAdornment: (
              <InputAdornment position="start">
                <Box sx={{ display: "flex" }}>
                  <Box sx={{ position: "relative" }} id="image_preview">
                    {pageInfoData.collection_image ? (
                      <>
                        <img src={isValidHttpUrl(pageInfoData.collection_image) ? pageInfoData.collection_image : pageInfoData.collection_image} height="20" alt="" />
                        <Button id="removeImage" sx={{ position: "absolute", top: "-5px", right: "-5px", padding: "3px", minWidth: "0", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "rgba(0,0,0)", padding: 0, color: "#fff", ":hover": { backgroundColor: "#000" } }} onClick={() => removeImage("collection_image")}>
                          <CloseIcon sx={{ fontSize: "1rem !important" }} />
                        </Button>
                      </>
                    ) : (
                      <>
                        <img src="/assets/images/empty-img.svg" height="20" alt="" />
                      </>
                    )}
                  </Box>
                  <Typography variant="body1" ml={1}>
                    {pageInfoData.collection_image?.name}
                  </Typography>
                </Box>
              </InputAdornment>
            ),
          }}
        />

        <TextField
          sx={{ mt: 2 }}
          fullWidth
          className="Add-image"
          variant="outlined"
          disabled
          placeholder={"NFT Image"}
          onClick={triggerNftImage}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Button variant="outlined" component="label" id="addNftImage" className="font-weight-600">
                  Browse...
                  <input type="file" id="file_nft_upload" accept="image/png, image/jpeg, image/jpg, image/gif, image/svg+xml" onChange={(e) => onSelectFile("nft_image", e)} hidden />
                </Button>
              </InputAdornment>
            ),
            startAdornment: (
              <InputAdornment position="start">
                <Box sx={{ display: "flex" }}>
                  <Box sx={{ position: "relative" }} id="image_nft_preview">
                    {pageInfoData.nft_image ? (
                      <>
                        <img src={isValidHttpUrl(pageInfoData.nft_image) ? pageInfoData.nft_image : pageInfoData.nft_image} height="20" alt="" />
                        <Button id="removeNftImage" sx={{ position: "absolute", top: "-5px", right: "-5px", padding: "3px", minWidth: "0", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "rgba(0,0,0)", padding: 0, color: "#fff", ":hover": { backgroundColor: "#000" } }} onClick={() => removeImage("nft_image")}>
                          <CloseIcon sx={{ fontSize: "1rem !important" }} />
                        </Button>
                      </>
                    ) : (
                      <>
                        <img src="/assets/images/empty-img.svg" height="20" alt="" />
                      </>
                    )}
                  </Box>
                  <Typography variant="body1" ml={1}>
                    {pageInfoData.nft_image?.name}
                  </Typography>
                </Box>
              </InputAdornment>
            ),
          }}
        />

        <TextField fullWidth margin="normal" label="Contract Name" required={true} name="contract_name" variant="outlined" InputLabelProps={{ shrink: true }} value={pageInfoData.contract_name} error={false} onChange={(e) => handlePageSettings("contract_name", e.target.value)} />
        <TextField fullWidth margin="normal" label="Contract Address" required={true} name="contract_address" variant="outlined" InputLabelProps={{ shrink: true }} value={pageInfoData.contract_address} error={false} onChange={(e) => handlePageSettings("contract_address", e.target.value)} />

        <TextField fullWidth multiline rows={5} margin="normal" label="NFT Metadata" name="nft_metadata" variant="outlined" InputLabelProps={{ shrink: true }} onChange={(e) => handlePageSettings("nft_metadata", e.target.value)} value={pageInfoData.nft_metadata} helperText={<>
          <div>Token URI: {process.env.REACT_APP_BASE_API_URL}/api/nft_info/{user_data.id}?id=</div>
          <div>Metadata: {JSON.stringify({ "name": "", "description": "", "image": "", "animation_url": "", "attributes": [{"trait_type":"","value":""}]})}</div>
          </>} />

        <TextField fullWidth margin="normal" label="Transaction Private Key" required={true} name="transaction_private_key" variant="outlined" InputLabelProps={{ shrink: true }} error={false} onChange={(e) => handlePageSettings("transaction_private_key", e.target.value)} />
      </Box>

      <Box display={"flex"} justifyContent={"space-between"} mt={3}>
        <Button variant="outlined" color="primary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" sx={{ cursor: userUpdate.type == "request" ? "not-allowed" : "pointer", opacity: userUpdate.type == "request" ? "0.5" : "1" }} onClick={userUpdate.type !== "request" ? updateRewardPageSettings : undefined}>
          {userUpdate.type == "request" ? "Updating..." : "Update"}
        </Button>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  userUpdate: state.userUpdate,
  adminUsers: state.adminUsers,
});

const mapDispatchToProps = {
  updateAdminUsers,
  resetUpdateAdminUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(MembershipNftDetails);
