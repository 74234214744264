import React from 'react'
import SortIcon from '@mui/icons-material/Sort';
import { Box, FormControl, MenuItem, Select, SvgIcon, Typography } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const PostsSorting = (props) => {
  const {sortOrder, handleSelectChange} = props;

  const sortOptions = [
    {
      label: "Active",
      value: 1
    },
    {
      label: "Drafts",
      value: 0
    }
  ];

  return (
    <Box display="flex" justifyContent="end" alignItems="center">
      <Box width="20%" textAlign="right">
        <FormControl sx={{ my: 1, minWidth: 115, "& .MuiOutlinedInput-root": {"&:hover": {borderColor: '#504D4D'}}, "& .MuiOutlinedInput-notchedOutline": {borderColor: '#504D4D', "&:hover": {borderColor: '#504D4D'}} }}>
          <Select value={sortOrder} displayEmpty autoWidth onChange={handleSelectChange} sx={{"& .MuiSelect-icon": {color: "#ED1F23"}}} renderValue={(value) => {
              return (
                <Box sx={{ display: "flex", gap: 1, alignItems: 'center', color: "#1A1616", fontFamily: "General Sans Semibold", fontWeight: "600" }}>
                  {sortOptions.find((s) => s.value === value).label}
                </Box>
              );
            }}
            IconComponent={(props) => (<KeyboardArrowDownIcon {...props} sx={{mt: '0.15rem'}} />)}
          >
            {sortOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Box>
  )
}

export default PostsSorting