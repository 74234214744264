import React, { useState } from 'react';
import { Dialog,DialogTitle,DialogContent,DialogActions, Button,Typography,Box, Modal, Fade, Backdrop } from '@mui/material';

import { connect } from 'react-redux';

const DeleteReward = (props) => {
  const { open, handleClose, handleAllowFeatured } = props;

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "1px solid #000",
    boxShadow: 24,
    borderRadius: "6px",
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={style} paddingTop="5px">
          <Box sx={{ margin: "20px 40px",}}>
            <Typography component="p" className="font-gs-md" sx={{fontSize:"25px"}}>There is already a post marked “featured.” Only one post can be featured in a category. Would you like to overwrite the existing one?</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              background: "#f9f9f9",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "400px",
                margin: "30px auto",
                background: "#f9f9f9",
                height: "41.81px",
              }}
            >
              <Button onClick={handleClose} variant="contained" sx={{borderRadius:"6px", py:1,px:3}} className='btn-text'>Yes</Button>
              <Button onClick={() => {handleClose(); handleAllowFeatured();}} variant="text"
                color="error"
                sx={{
                  fontFamily: "Roboto, sans-serif",
                  fontWeight: "700",
                  fontSize: "15px",
                }}>No</Button>
            </Box>
          </Box>
        </Box>
      </Fade>
    </Modal>
  )
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteReward)