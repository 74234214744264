import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import {
    Typography,
    Box, TextField, Link
  } from '@mui/material';
import { formatDateToDbSup } from 'src/utils';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { connect } from 'react-redux';

const RaffleForm = (props) => {
  const {handleChildData, dropData, nextTabErrors, setNextTabErrors,user} = props;
  const [eventPassData, setEventPassData] = useState(props?.dropData?.raffle);
  const [dtPickerOpen, setDtPickerOpen] = useState(false);

  const [focused, setFocused] = useState({
    registrant_message: false,
    winner_message: false,
    non_winner_message: false
  });

  const handlfocus = (key, is_focus) => {
    const cFocused = {...focused};
    cFocused[key] = is_focus;
    setFocused(cFocused)
  }

  useEffect(() => {
    let existing_data = {...eventPassData}
    if(props?.dropData?.raffle?.contact_email == "" || props?.dropData?.raffle?.contact_email == null){
      existing_data['contact_email'] = user.data.email
    }
    // console.log(props?.dropData?.raffle?.contact_email)

    // console.log(existing_data)
    setEventPassData(existing_data)
  }, [])
  
  const handleData = (type, val) => {
    let existing_data = {...eventPassData};
    existing_data[type] = val
    setEventPassData(existing_data)
  }
  useEffect(() => {
    handleChildData('raffle', eventPassData)
  } ,[eventPassData]);
  
  const handleRaffleValueData = (type, val) => {
    handleData(type, val);
    if (type == 'contact_email') {
      setNextTabErrors({...nextTabErrors,['raffle_contact_email']:{value: typeof val=="string" ?  String(val).trim() : val,error:""}});
    } else {
      setNextTabErrors({...nextTabErrors,[type]:{value: typeof val=="string" ?  String(val).trim() : val,error:""}});
    }
  }

  return (
    <>
      <Box className="event-pass-form">
        {dropData.status !== 1 &&
        <>
          <Typography variant="body1" textAlign="center" className='subtitle' lineHeight="normal" mb="20px">CREATE REWARD</Typography>
          <Typography variant="h1" textAlign="center" sx={{mb: 1}} className="title">Add details about your raffle</Typography>
          <Typography variant="body2" textAlign="center" className="detail-box">After the raffle’s registration period has closed, you’ll be able to pick winners from your dashboard. Once winners are picked, they will see a message when viewing their reward. Learn more about raffle rewards <Link href="https://help.step3.io/creating--managing-rewards/ty25EcCpyGNQWuwxhGEyp6/raffle-rewards/w892bfNZmBv6EjWpnC2tBK" target="_blank">here</Link>.</Typography>
        </>
        }
        
        <Box pt={dropData.status == 1 ? 0 : 4}>
          <Box>
            <TextField type="number" className='max_number_input' InputProps={{ inputProps: {step:"any"} }} onKeyDown={ (evt) =>{ (evt.key === 'e' || evt.key==='.' || evt.key===',' || evt.key === 190 || evt.code === 'Minus'|| evt.code === "Comma") && evt.preventDefault() } } fullWidth margin="normal" label={<span>MAX number of Winners&nbsp;<span style={{color:'red'}}>*</span></span>} name="max_winners" variant="outlined" InputLabelProps={{shrink: true}} placeholder="Enter a number" onChange={(e) => handleRaffleValueData('max_winners', e.target.value)} defaultValue={dropData?.raffle?.max_winners} helperText={nextTabErrors.max_winners.error == "" ? '' : nextTabErrors.max_winners.error} error={nextTabErrors.max_winners.error !== ""} onWheel={(e) => e.target.blur()} />
            <Box className="textareArea">
              <TextField fullWidth multiline rows="3" inputProps={{maxLength: 150}} margin="normal" label='Message for Registrants' name="text" variant="outlined" InputLabelProps={{shrink: true}} placeholder='Is there anything else all registrants should know?' onChange={(e) => handleData('registrant_message', e.target.value)} onFocus={() => handlfocus("registrant_message", true)} onBlur={() => handlfocus("registrant_message", false)} defaultValue={dropData?.raffle?.registrant_message} helperText={
                <Box component="span" className='charTxt'>
                  <Box component="span" display={focused.registrant_message ? 'block' : 'none'}>
                  {eventPassData?.registrant_message?.length > 0 ? `${eventPassData?.registrant_message?.length} / 150` : false}
                  </Box>
                </Box>
              } FormHelperTextProps={{ style: { textAlign: 'right',color: (eventPassData?.registrant_message?.length == 150) ? 'red' : 'rgba(34, 51, 84, 0.7)' } }} />

              <TextField fullWidth multiline rows="3" inputProps={{maxLength: 150}} margin="normal" label='Message for Winners' name="text" variant="outlined" InputLabelProps={{shrink: true}} placeholder='Is there anything else the winners should know?' onChange={(e) => handleData('winner_message', e.target.value)} onFocus={() => handlfocus("winner_message", true)} onBlur={() => handlfocus("winner_message", false)} defaultValue={dropData?.raffle?.winner_message} helperText={
                <Box component="span" className='charTxt'>
                  <Box component="span" display={focused.winner_message ? 'block' : 'none'}>
                  {eventPassData?.winner_message?.length > 0 ? `${eventPassData?.winner_message?.length} / 150` : false}
                  </Box>
                </Box>
              } FormHelperTextProps={{ style: { textAlign: 'right',color: (eventPassData?.winner_message?.length == 150) ? 'red' : 'rgba(34, 51, 84, 0.7)' } }} />
            
              <TextField fullWidth multiline rows="3" inputProps={{maxLength: 150}} margin="normal" label='Message for non-winners' name="text" variant="outlined" InputLabelProps={{shrink: true}} placeholder='Is there anything else non-winners should know?' onChange={(e) => handleData('non_winner_message', e.target.value)} onFocus={() => handlfocus("non_winner_message", true)} onBlur={() => handlfocus("non_winner_message", false)} defaultValue={dropData?.raffle?.non_winner_message} helperText={
                <Box component="span" className='charTxt'>
                  <Box component="span" display={focused.non_winner_message ? 'block' : 'none'}>
                  {eventPassData?.non_winner_message?.length > 0 ? `${eventPassData?.non_winner_message?.length} / 150` : false}
                  </Box>
                </Box>
              } FormHelperTextProps={{ style: { textAlign: 'right',color: (eventPassData?.non_winner_message?.length == 150) ? 'red' : 'rgba(34, 51, 84, 0.7)' } }} />
            </Box>

            <Typography variant="body2" sx={{color: "#000", fontFamily: "Roboto, sans-serif", fontWeight: "600", fontSize: "16px", mt:1}}>Email displayed to the winners</Typography>
            <TextField fullWidth margin="normal" label={<span>Your Email&nbsp;<span style={{color:'red'}}>*</span></span>} placeholder="Admin Email Address" name="contact_email" variant="outlined" InputLabelProps={{shrink: true, style:{}}} onChange={(e) => handleRaffleValueData('contact_email', e.target.value)} value={eventPassData?.contact_email} error={nextTabErrors.raffle_contact_email.error !== ""} helperText={nextTabErrors.raffle_contact_email.error} />
          </Box>
        </Box>
      </Box>
    </>
  )
}

const mapStateToProps = (state) => ({
  user: state.user
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(RaffleForm)