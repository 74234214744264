import React from "react";
import { styled, Select, MenuItem } from "@mui/material";
const SelectBox = styled(Select)({
  "& .MuiSelect-select.MuiSelect-outlined": {
    display: "flex",
  },
});

const NetworkSelectionOptions = (props) => {
  return (
    <>
      {process.env.REACT_APP_NODE_ENV == "production" ? (
        <SelectBox fullWidth labelId="demo-simple-select-label" id="demo-simple-select" label="Network" value={props.network} onChange={props.handleChange} sx={{ display: "flex !important" }} error={props.errorStatus}>
          <MenuItem value="43114">
            <img src="/assets/images/avalanche.svg" alt="avalanche" height="20" style={{ marginRight: "5px" }} /> Avalanche Mainnet
          </MenuItem>
          <MenuItem value="1">
            <img src="/assets/images/eth.png" alt="eth" height="20" style={{ marginRight: "5px" }} /> Ethereum Mainnet
          </MenuItem>
          <MenuItem value="137">
            <img src="/assets/images/polygon-token.webp" alt="eth" height="20" style={{ marginRight: "5px" }} /> Polygon Mainnet
          </MenuItem>
          <MenuItem value="8453">
            <img src="/assets/images/base-network.png" alt="eth" height="20" style={{ marginRight: "5px" }} /> Base Network
          </MenuItem>
        </SelectBox>
      ) : (
        <SelectBox fullWidth labelId="demo-simple-select-label" id="demo-simple-select" label="Network" value={props.network} onChange={props.handleChange} sx={{ display: "flex !important" }} error={props.errorStatus}>
          <MenuItem value="43113">
            <img src="/assets/images/avalanche.svg" alt="avalanche" height="20" style={{ marginRight: "5px" }} /> Avalanche Fuji
          </MenuItem>
          <MenuItem value="43114">
            <img src="/assets/images/avalanche.svg" alt="avalanche" height="20" style={{ marginRight: "5px" }} /> Avalanche Mainnet
          </MenuItem>
          <MenuItem value="1">
            <img src="/assets/images/eth.png" alt="eth" height="20" style={{ marginRight: "5px" }} /> Ethereum Mainnet
          </MenuItem>
          <MenuItem value="11155111">
            <img src="/assets/images/eth.png" alt="eth" height="20" style={{ marginRight: "5px" }} /> Ethereum Sepolia Testnet
          </MenuItem>
          <MenuItem value="137">
            <img src="/assets/images/polygon-token.webp" alt="eth" height="20" style={{ marginRight: "5px" }} /> Polygon Mainnet
          </MenuItem>
          <MenuItem value="80001">
            <img src="/assets/images/polygon-token.webp" alt="eth" height="20" style={{ marginRight: "5px" }} /> Polygon Mumbai
          </MenuItem>
          <MenuItem value="8453">
            <img src="/assets/images/base-network.png" alt="eth" height="20" style={{ marginRight: "5px" }} /> Base Network
          </MenuItem>
          <MenuItem value="84531">
            <img src="/assets/images/base-network.png" alt="eth" height="20" style={{ marginRight: "5px" }} /> Base Goerli Testnet
          </MenuItem>
          <MenuItem value="84532">
            <img src="/assets/images/base-network.png" alt="eth" height="20" style={{ marginRight: "5px" }} /> Base Sepolia Testnet
          </MenuItem>
        </SelectBox>
      )}
    </>
  );
};

export default NetworkSelectionOptions;
