import React from "react";
import { Box, Autocomplete, Typography, Button, Modal, Card, TextField, FormControl } from '@mui/material'
import countries from "../../services/constants/countries.json"
import { networkSym, viewDate, viewUTCTime } from "src/utils";
import { RewardTypePill } from "../RewardsData/reward_type_pill";

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'white',
    boxShadow: 24,
    py: 4,
    px: 8,
    borderRadius:'6px',
    // height:"90vh",
    // overflow:"auto"
  };  
  const AllowListPreview = (props) => {
    const {open, handleClose, reward, info} = props;

    const sizeData = ['XS',"S","M","L","XL","XXL","XXXL"]
    
    return(
        <Box className="preview-modal">
            <Box sx={{ px:3}}>
                <Box textAlign="center">
                    <RewardTypePill reward={reward} />
                </Box>
              <Card className="allow-list-card" sx={{px:2}}>

                <Card className="inner-al-card" sx={{p:1, my:1}}>
                    <Card className="detail-card" sx={{p:1, my:0.5}}>
                        <Box display="flex">
                            <Box>
                                <img src="/assets/img/check-icon2.svg" alt="check icon"/>
                            </Box>
                            <Box ml={1}>
                                <Typography variant="h6" className="helper-title">You’re On The List!</Typography>
                                <Typography variant="body2" className="helper-description">0xA2d377d11CB89F654DdD437123bA168b97b044d0</Typography>
                            </Box>
                        </Box>
                    </Card>
                    {
                        (info.project_name != "" || info?.project_website != null) &&
                        <Card className="detail-card" sx={{p:1, my:0.5}}>
                            <Box>
                                <Box display="flex">
                                    <img src="/assets/img/link-icon2.svg" alt="link icon"/>
                                    <Box ml={1}>
                                        <Typography variant="h6" className="helper-title">{ info.project_name != "" ? info.project_name : "Project"}</Typography>
                                        <Typography variant="body2" className="helper-description">Learn more</Typography>
                                    </Box>
                                </Box>
                            </Box>
                            {
                                info.project_website != "" &&
                                <Button color="primary" variant="outlined" target="_blank" LinkComponent="a" href={info.project_website} className="btn">Visit</Button>
                            }
                        </Card>
                    }
                    {
                        info.price != "" &&
                        <Card className="detail-card" sx={{p:1, my:0.5}}>
                            <Box>
                            <Box display="flex">
                                <img src="/assets/img/dollar-icon2.svg" alt="link icon"/>
                                <Box ml={1}>
                                    <Typography variant="h6" className="helper-title">Mint Price</Typography>
                                    <Typography variant="body2" className="helper-description">{info.price} {info.network && networkSym(info.network)}</Typography>
                                </Box>
                            </Box>
                            </Box>
                        </Card>
                    }
                    {
                        info.discord_url != "" &&
                        <Card className="detail-card" sx={{p:1, my:0.5}}>
                            <Box>
                                <Box display="flex">
                                    <img src="/assets/img/discord-icon2.svg" width={40} alt="link icon"/>
                                    <Box ml={1}>
                                        <Typography variant="h6" className="helper-title">Discord</Typography>
                                        <Typography variant="body2" className="helper-description">Check out the community</Typography>
                                    </Box>
                                </Box>
                            </Box>
                            <Button color="primary" variant="outlined" target="_blank" LinkComponent="a" href={info.discord_url} className="btn">Visit</Button>
                        </Card>
                    }
                    {
                        info.mint_date != null &&
                        <Card className="detail-card" sx={{p:1, my:0.5}}>
                            <Box>
                                <Box display="flex">
                                    <img src="/assets/img/calendar-icon2.svg" alt="link icon"/>
                                    <Box ml={1}>
                                        <Typography variant="h6" className="helper-title">Mint Date</Typography>
                                        <Typography variant="body2" className="helper-description">{viewDate(info.mint_date)} @ {viewUTCTime(info.mint_date)}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Card>
                    }
                </Card>
              </Card>
            </Box>
        </Box>
    )
}
export default AllowListPreview;