import React, { useEffect, useState } from 'react';
import { Modal, Button, Typography, Box, Card } from '@mui/material';

import { connect } from 'react-redux';
import { setToast, createOtp, verifyOtp } from 'src/services/actions'
import { ValidateEmail, ahex_to_rba } from 'src/utils';
import { MuiOtpInput } from 'mui-one-time-password-input';
import CloseIcon from '@mui/icons-material/Close';

export const matchIsNumeric = (text) => {
  const isNumber = typeof text === 'number'
  const isString = typeof text === 'string'
  return (isNumber || (isString && text !== '')) && !isNaN(Number(text))
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};
    
const OTPVerification = (props) => {
  const { open, handleClose, user, emailAddress, otpData, setOtpData } = props;
  const [emailVerify, setEmailVerify] = useState(2);
  const [timerValue, setTimerValue] = useState(0);
  const [otp, setOtp] = useState('')
  const [error, setError] = useState(false)
  const [isloading, setIsloading] = useState(false);
  const [verified, setVerified] = useState(false)

  const theme = {data: { base_bg_color: "#ffffff", base_pm_color: "#ED1F23", base_pm_text_color: "#ffffff", base_ac_color: "#ffffff" }}

  const validateChar = (value, index) => {
    return matchIsNumeric(value)
  }

  let reSendEmailLink = () => {
    setEmailVerify(3);
    setError(false)
    if (ValidateEmail(emailAddress)) {
      props.createOtp('email', {user_type: 'AdminUser', user_id: user.data.id, email: emailAddress, themeInfo: { name: `step3`, logo: `${process.env.REACT_APP_BASE_API_URL}/assets/images/combomark.png`, bg_color: "#ED1F23", btn_color: '#ED1F23', btn_text_color: '#ffffff', txt_color: "#000000"}}, (resp) => {
        setEmailVerify(4);
        if (resp.type === 'success') {
          setOtpData(resp)

          clearTimeout(window.resendTimeout);
          clearInterval(window.timerInterval);
          setTimerValue(60);

          window.resendTimeout = setTimeout(() => {
            clearInterval(window.timerInterval);
            setTimerValue(0);
            setEmailVerify(2);
          }, 60000);

          window.timerInterval = setInterval(() => {
            setTimerValue((prevState) => {
              return prevState - 1;
            });
          }, 1000);
        } else {
          setError(true)
        }
      })
    } else {
      setError(true)
    }
  };

  const handleChange = (newValue) => {
    setError(false)
    setOtp(newValue)
  }

  const verifyOtpValue = () => {
    setError(false)
    setIsloading(true)
    const data = {code: otp, opt_verification_id: otpData.opt_verification_id, user_type: 'AdminUser'};
    props.verifyOtp('email', data, (res) => {
      if (res.type === 'success') {
        setVerified(true)
        props.updateSettingsInfo()
        handleClose();
      } else {
        setIsloading(false)
        setError(true)
      }
    })
  }

  const handleNoChange = () => {
    handleClose();
  }

  return (
    <Modal open={open} aria-labelledby="Notify Winners" className='modals' sx={{bgcolor: 'transparent', border: '0px solid'}}>
      <Box sx={style} >
        <Card sx={{borderRadius: "15px", border: "1px solid #000", background: "#fff", boxShadow: "0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08)",  width: "462px",maxWidth: "90vw", boxSizing: "border-box"}}>
        <Box sx={{p: {md: "20px 40px", xs: "20px"}, borderBottom: "1px solid rgba(0, 0, 0, 0.10)"}}>
          <Box display="flex" alignItems="center">
            <Typography variant='h4' align='center' sx={{pl: '20px',width: "100%", color: "#000",fontSize: "1rem", lineHeight: "normal"}} className='font-gs-sb'>Verify your Email</Typography>
            <CloseIcon sx={{color: "#000", cursor: "pointer"}} onClick={handleNoChange}/>
          </Box>
        </Box>
          <Box sx={{p: {md: "40px", xs: "50px 20px"}}}>
            <Box>
              <Box display="flex" justifyContent="center">
                <img src='/icons/email-icon.svg' alt='Email icon' width={30} />
              </Box>
              <Typography variant='h4'align='center' my={"10px"} sx={{color: "#000", fontSize: "1.25rem", fontFamily: "General Sans bold"}}>Enter your code</Typography>
              <Typography variant='body1'align='center' my={"10px"} sx={{color: "#000", fontSize: "13px"}} className='font-gs-md'>Please check <span className='font-gs-sb'>{emailAddress}</span> for an email to find your verification code.</Typography>

              <Box mt={"40px"} display="flex" justifyContent="center">
                <MuiOtpInput TextFieldsProps={{autoComplete: false}} gap={2.35} sx={{"& .MuiOutlinedInput-input": {fontSize:"1.563rem", textAlign: "center", p:'0px !important', height: '50px', width: '35px'}, "& .MuiInputBase-root": {background: "#ffffff","& .MuiOutlinedInput-notchedOutline": {borderColor: error ? "#ED1F23" : "#000"}, "&:hover": {background: `rgba(${ahex_to_rba(theme.data.base_pm_color)}, 0.10)`, "& .MuiOutlinedInput-notchedOutline": {borderColor: "#000000"}}, "&:focus-within": {background: "#ffffff", "& .MuiOutlinedInput-notchedOutline": {borderWidth: "3px", borderColor: `${theme.data.base_pm_color} !important`}},"&:active": {background: `rgba(${ahex_to_rba(theme.data.base_pm_color)}, 0.10)`, "& .MuiOutlinedInput-notchedOutline": {borderWidth: "3px", borderColor: theme.data.base_pm_color}}}}} value={otp} onChange={handleChange} length={6} validateChar={validateChar} />

              </Box>
              {error &&
                <Typography variant='body1' sx={{mt: "10px",color:"#ED1F23", fontSize: "13px"}} align='center' className='font-gs-md'>Invalid or expired code</Typography>
              }
              {verified &&
                <Typography variant='body1' sx={{mt: "10px",color:"#0BB681", fontSize: "1rem"}} align='center'  className='font-gs-sb'>Your Verified!</Typography>
              }
              <Box display={"flex"} justifyContent="center" my="10px">
                <Button variant="contained" disabled={isloading} onClick={() => verifyOtpValue()} sx={{fontSize: "1rem", fontFamily: "General Sans Semibold", background: theme.data.base_pm_color, color: theme.data.base_pm_text_color, "&.Mui-disabled": {color: "#fff", background: `linear-gradient(0deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.15) 100%), ${theme.data.base_pm_color}`}, "&:hover": {background: `linear-gradient(0deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.15) 100%), var(--fills-primary-primary-fill-100, ${theme.data.base_pm_color})`}}} className='font-gs-sb'>Submit {isloading && <div className='spinner'/> }</Button>
              </Box>
              
              <Box display="flex" flexDirection="row" className='font-gs-md' alignItems="center" justifyContent="center">
                <Typography variant='body1' onClick={() => {emailVerify === 2 && reSendEmailLink();}} sx={{textDecoration: "underline", cursor: emailVerify !== 2 ? 'not-allowed' : "pointer", color: emailVerify !== 2 ?"#818181" : "#000"}} className='font-gs-md' align='center' >
                  Resend
                </Typography>
                {
                  emailVerify == 4 &&
                  <Box component="span" className='font-gs-md' sx={{fontSize: '14px', ml: '2px'}}>
                  ({timerValue} Sec.)
                  </Box>
                }
              </Box>
              
            </Box>
          </Box>
        </Card>
      </Box>
    </Modal>
  )
}

const mapStateToProps = (state) => ({
  user: state.user,
  showToast: state.showToast
})

const mapDispatchToProps = {
  setToast,
  createOtp,
  verifyOtp
}

export default connect(mapStateToProps, mapDispatchToProps)(OTPVerification)