import React from "react";
import {Modal,Box,Button, Alert, Typography} from '@mui/material';
import ClaimCard from "../ClaimCard";
import { connect } from "react-redux";


const ClaimCardModal = (props) => {
  const {open, handleClose} = props;
  const {dropData} = props

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'white',
    boxShadow: 24,
    borderRadius:'8px',
    maxHeight: "90vh",
    overflowY: "auto"
  };

  return(
    <>
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" className="claim-modal">
        <Box sx={style} className='modal-card'>
          <Box sx={{width: 700, px:7,pt:2}}>
            <ClaimCard dropData={dropData} title={props.title} description={props.description} publishedDt={dropData.published_date} />

            {
              dropData.category == 'event_pass' &&
              <Alert severity="info" sx={{mt: 4, mx: 1}}>
                <Typography variant="body1">The link for the event “Check In” site can be found in the detailed view of your reward card after it's been published.</Typography>
              </Alert>
            }
          </Box>

          <Box sx={{ p:4,background: "rgba(34, 51, 84, 0.02)", display:'flex', justifyContent:'center'}}>
            <Button variant="contained"  onClick={handleClose}  sx={{ px:4, fontFamily:"General sans semibold", fontSize: "1rem"}}>Close</Button>
          </Box>
        </Box>
      </Modal>
    </>
  )
}

const mapStateToProps = (state) => ({
  rewardList: state.rewardList
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(ClaimCardModal)