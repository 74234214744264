import React from 'react';
import { Dialog,DialogTitle,DialogContent,DialogActions, Button, Typography, Box } from '@mui/material';

import { connect } from 'react-redux';

  
  const ResetMeasurementId = (props) => {
    const { open, handleClose } = props;

    const resetGAId = () => {
      props.handleData('measurement_id', '')
      handleClose(true)
    }
    
    return (
      <Dialog open={open} onClose={()=>{ handleClose() }} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className='modals'>
          <Box className='modal-card'>
            <DialogTitle id="alert-dialog-title" display="flex" justifyContent="center" sx={{mt:4,mb:4}}>
              <img src="/assets/img/delete-icon.svg" alt="delete" />
            </DialogTitle>
            <DialogContent sx={{pl:4, pr:4}}>
              <Typography variant="h1" className='heading'>Are you sure you want to reset?</Typography>
            </DialogContent>
            <DialogActions sx={{ mb: 4,display: 'flex', justifyContent: 'center'}}>
              <Button onClick={handleClose} variant="contained" className='btn-text'>Cancel</Button>
              <Button autoFocus onClick={resetGAId} className='btn-text'>
                Yes
              </Button>
            </DialogActions>
          </Box>
      </Dialog>

  )
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetMeasurementId)