import { Box, Dialog, DialogContent, Link, Typography } from '@mui/material';
import React from 'react'

const MobileViewModal = (props) => {
  const { open, handleClose } = props;

  const handleModalClose = (event, reason) => {
    if (reason && reason === "backdropClick") 
        return;
    handleClose();
}
  
  return (
    <Dialog open={open} onClose={handleModalClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className='modals'>
        <Box className='modal-card'>
          <DialogContent sx={{p: 3, textAlign: 'center'}}>
            <Typography variant="h4">This site is optimized for desktop view.</Typography>

            <Typography variant='h4' mt={1}><Link sx={{cursor: 'pointer'}} onClick={() => handleClose(false)}>Continue</Link></Typography>
          </DialogContent>
        </Box>
      </Dialog>
  )
}

export default MobileViewModal