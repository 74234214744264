export function userReducer(state = { type: "", login: false, data: {}, message: "", account_type: "existing" }, action) {
  let obj;
  let tmp = [];
  obj = { ...state };
  // eslint-disable-next-line default-case
  switch (action.type) {
    case "RESET_USER":
      return { type: "", login: false, data: {}, message: "" };
    case "LOGIN_REQUEST":
      return { ...state, type: "request" };
    case "LOGIN_RESET":
      return { type: "", login: false, data: {}, message: "" };
    case "UPDATE_EMAIL_REQUEST":
      return { ...obj, type: "email_empty", forceEmail: true };
    case "LOGIN_SUCCESS":
      if (action.data.email_verified) {
        if (action.data.terms) {
          return { type: "success", login: true, data: action.data, message: "", account_type: action.response_msg };
        } else {
          return { type: "email_empty", login: false, data: action.data, message: "" };
        }
      } else {
        if (action.data.email == null || action.data.email == "") {
          return { type: "email_empty", login: false, data: action.data, message: "" };
        } else {
          // return {type:"email_pending",login:false,data:action.data,message:""}
          return { type: "success", login: true, data: action.data, message: "" };
        }
      }
    case "UPDATE_USER_REQUEST":
      return { ...obj, type: "update_request" };
    case "UPDATE_USER_SUCCESS":
      return { ...obj, type: "success", data: action.data, message: "" };
    case "UPDATE_USER_ERROR":
      return { ...obj, type: "update_error", data: action.data, message: action.message };
    case "LOGIN_ERROR":
      return { type: "error", login: false, data: {}, message: action.message };

    case "UPDATE_PAGE_SETTINGS_REQUEST":
      return { ...obj, type: "update_request" };
    case "UPDATE_PAGE_SETTINGS_SUCCESS":
      return { ...obj, type: "success", data: action.data, message: "" };
    case "UPDATE_PAGE_SETTINGS_ERROR":
      return { ...obj, type: "update_error", message: action.message };
    case "ADD_CATEGORIES_SUCCESS":
      return { ...obj, type: "category_success", data: {...obj.data, categories: [...obj.data.categories, action.data]} };
    case "DELETE_CATEGORIES_SUCCESS":
      let newCat = obj.data.categories.filter((item) => item.id != action.data)
      return { ...obj, type: "category_success", data: {...obj.data, categories: newCat} };
    case "UPDATE_CATEGORIES_SUCCESS":
      let updatedCateData = JSON.parse(JSON.stringify([...obj.data.categories]))
      for (let i = 0; i < updatedCateData.length; i++) {
        if(updatedCateData[i].id == action.data.id){
          updatedCateData[i] = action.data
        }
      }
      // let newCat = obj.data.categories.filter((item) => item.id != action.data)
        return { ...obj, type: "category_success", data: {...obj.data, categories: updatedCateData} };
  }
  return state;
}
