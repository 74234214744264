import React,{useEffect} from 'react'
import { connect } from 'react-redux';

// Material UI
import { Box,  Typography, useTheme,Avatar} from '@mui/material';

import { setPageThought} from '../../services/actions/api';

const Home = (props) => {
  const theme = useTheme()
  useEffect(() => {
    props.setPageThought({heading: "Did you know?", txt: 'Rewards are a great way to add value and engagement to NFTs. And Step3 makes it easy to create unique rewards for any NFT community.'});
  },[])


  return (
    <Box display="flex" flexDirection="column" justifyContent="center" sx={{height: `calc(100vh - ${theme.header.height})`}}>
      <Box display="flex" justifyContent="center" sx={{mb:3}}>
        <Avatar src="/assets/images/circle-error.svg" alt="oops" sx={{height: '100px', width: '100px'}}/>
      </Box>

      <Box px={10} textAlign="center">
        <Typography variant="h2" sx={{mt:2,color: '#223354', fontSize:'2.5rem', fontFamily:'Roboto, sans-serif'}} align="center">You are trying to visit our site from a country we are unable to do business with at this time.</Typography>
        <Typography variant="h2" sx={{mb:5,mt:3,fontSize: '2rem', fontFamily:'Roboto, sans-serif', color: '#223354'}} align="center">Please check back later.</Typography>
      </Box>
    </Box>
  )
}



const mapStateToProps = (state) => ({
})

const mapDispatchToProps = {
  setPageThought
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)