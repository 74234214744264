import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { Box, TextField, styled, Grid } from '@mui/material';
import moment from 'moment-timezone';
import { formatMomentObjectToDate, formatMomentObjectToTime } from 'src/utils';

const CustomTextField=styled(TextField)(
  ({theme})=> `
  fieldset{
    border-radius: 4px;
    }
  `
)

export const DateAndTimePicker = (props) => {
  const {nextTabErrors, startDateValue, setStartDateValue, startTimeValue, setStartTimeValue, setNextTabErrors, picker_type, formtype, timeWidth, dateWidth} = props;
  // console.log("nextTabErrors =>",nextTabErrors.start_date, nextTabErrors.start_time)
  const handleStartDtChange = (newValue) => {
    
    props.handleChildData(picker_type, newValue)
    setStartDateValue(newValue);
    
    if (startTimeValue == undefined || startTimeValue?._isValid == false || startTimeValue.diff(moment().startOf('day')) == 0) {
      setNextTabErrors({...nextTabErrors,['start_time']:{val: moment().startOf('day'), error: ""}});
      setStartTimeValue(moment().startOf('day'));
    }
    // console.log("moment valid =>",moment(startDateValue, "YYYY-MM-DD hh:mm:ss A Z", true).isValid())
  };

  useEffect(() => {
    setNextTabErrors({...nextTabErrors,['start_date']:{val: startDateValue, error: ""}});
  },[startDateValue])

  const handleTimeChange = (newValue) => {
    setNextTabErrors({...nextTabErrors,['start_time']:{val: newValue, error: ""}});
    setStartTimeValue(newValue);
    if (newValue !== null){
      const dateVal = startDateValue.format("YYYY-MM-DD")
      const timeVal = moment(newValue['$d']).format("hh:mm A");
      const dtVal = moment(dateVal+timeVal, 'YYYY-MM-DDLT').format("YYYY-MM-DD hh:mm:ss A Z")
      props.handleChildData(picker_type, dtVal)
    }
  }
  useEffect(() => {
    // console.log("startTimeValue =>",startTimeValue)
    if (!startTimeValue._isValid) {
      setNextTabErrors({...nextTabErrors,['start_time']:{val: moment().startOf('day'), error: ""}});
      setStartTimeValue(moment().startOf('day'));
    }
  },[])
  useEffect(() => {
    if (startTimeValue != undefined && startTimeValue?._isValid){
      const dateVal = startDateValue.format("YYYY-MM-DD")
      const timeVal = moment(startTimeValue._d).format("hh:mm A");
      // console.log("set val", startTimeValue._d)
      const dtVal = moment(dateVal+timeVal, 'YYYY-MM-DDLT').format("YYYY-MM-DD hh:mm:ss A Z")
      props.handleChildData(picker_type, dtVal)
    }
  },[startTimeValue])
  // console.log("nextTabErrors.start_time.value =>",nextTabErrors.start_time.value)
  return (
    <Grid container spacing="10px">
      <Grid item xs={formtype == 'edit' ? 9 : 9.9}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={['DatePicker']}>
            <DatePicker
              minDate={dayjs()}
              label={<Box component="span">Start Date ({moment.tz.guess()})&nbsp;<span style={{color:'red'}}>*</span></Box>}
              value={dayjs(startDateValue)}
              onChange={handleStartDtChange}
              disabled={props.dropData.schedule_now}
              sx={{width: '100%'}}
              slotProps={{textField: {error: nextTabErrors.start_date.error !== "", helperText: nextTabErrors.start_date.error, InputLabelProps: {shrink: true}, inputProps: {placeholder: "Start Date"}}}}
            />
          </DemoContainer>
        </LocalizationProvider>
      </Grid>
      <Grid item xs={formtype == 'edit' ? 3 : 2.1}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={['TimePicker']}>
            <TimePicker
              minDate={dayjs()}
              label={`Start Time`}
              value={dayjs(startTimeValue)}
              
              disabled={props.dropData.schedule_now}
              onChange={(newValue) => {
                handleTimeChange(newValue);
              }}
              slotProps={{textField: {error: nextTabErrors.start_time.error !== "", helperText: nextTabErrors.start_time.error, InputLabelProps: {shrink: true}, inputProps: {placeholder: "Start Time"}, sx:{width: '100%', minWidth: "auto !important"}}}}
            />
          </DemoContainer>
        </LocalizationProvider>
      </Grid>
    </Grid>
  )
}

export const RewardDateAndTimePicker = (props) => {
  const {nextTabErrors, startDateValue, setStartDateValue, startTimeValue, setStartTimeValue, setNextTabErrors, picker_type, timeWidth, dateWidth, suffix_name, formtype, requiredf} = props;
  
  const handleStartDtChange = (newValue) => {
    const dtVal = moment(newValue['$d'], 'YYYY-MM-DDLT').format("YYYY-MM-DD hh:mm:ss A Z")
    props.handleChildData(dtVal)
    setStartDateValue(newValue);
    setNextTabErrors({...nextTabErrors,[picker_type[0]]:{value: newValue, error: ""}});
    if (startTimeValue == undefined || startTimeValue?._isValid == false) {
      setNextTabErrors({...nextTabErrors,[picker_type[1]]:{value: moment().startOf('day'), error: ""}});
      setStartTimeValue(moment().startOf('day'));
    }
  };
  const handleTimeChange = (newValue) => {
    setNextTabErrors({...nextTabErrors,[picker_type[1]]:{value: newValue, error: ""}});
    setStartTimeValue(newValue);
    const dateVal = startDateValue.format("YYYY-MM-DD")
    const timeVal = moment(newValue['$d']).format("hh:mm A")
    const dtVal = moment(dateVal+timeVal, 'YYYY-MM-DDLT').format("YYYY-MM-DD hh:mm:ss A Z")
    props.handleChildData(dtVal)
  }

  const error_picker = (pickertype) => {
    if (picker_type[0] == 'event_date') {
      return pickertype == 'date' ? nextTabErrors.event_date : nextTabErrors.event_time
    } else if (picker_type[0] == 'end_date') {
      return pickertype == 'date' ? nextTabErrors.end_date : nextTabErrors.end_time
    } else if (picker_type[0] == 'mint_date') {
      return pickertype == 'date' ? nextTabErrors.mint_date : nextTabErrors.mint_time
    }
  }

  useEffect(() => {
    // console.log("startTimeValue =>",startTimeValue)
    if (!startTimeValue._isValid) {
      setNextTabErrors({...nextTabErrors,[picker_type[1]]:{value: moment().startOf('day'), error: ""}});
      setStartTimeValue(moment().startOf('day'));
    }
  },[])

  useEffect(() => {
    if (startTimeValue != undefined && startTimeValue?._isValid){
      const dateVal = startDateValue.format("YYYY-MM-DD")
      const timeVal = moment(startTimeValue._d).format("hh:mm A")
      const dtVal = moment(dateVal+timeVal, 'YYYY-MM-DDLT').format("YYYY-MM-DD hh:mm:ss A Z")
      props.handleChildData(dtVal)
    }
  },[startTimeValue])
  return (
    <Grid container spacing="10px">
      <Grid item xs={formtype == 'edit' ? 9 : 10}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={['DatePicker']}>
            <DatePicker
              minDate={dayjs()}
              label={requiredf ? <Box component="span">{suffix_name} Date ({moment.tz.guess()}) <span style={{color:'red'}}>*</span></Box> : `${suffix_name} Date (${moment.tz.guess()})`}
              value={dayjs(startDateValue)}
              onChange={handleStartDtChange}
              sx={{width: '100%'}}
              slotProps={{textField: {error: error_picker('date')?.error !== "", helperText: error_picker('date')?.error, InputLabelProps: {shrink: true}, inputProps: {placeholder: "Start Date"}}}}
            />
          </DemoContainer>
        </LocalizationProvider>
      </Grid>
      <Grid item xs={formtype == 'edit' ? 3 : 2}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={['TimePicker']}>
            <TimePicker
              minDate={dayjs()}
              label={`${suffix_name} Time`}
              value={dayjs(startTimeValue)}
              onChange={(newValue) => {
                handleTimeChange(newValue);
              }}
              slotProps={{textField: {error: error_picker('time').error !== "", helperText: error_picker('time').error, InputLabelProps: {shrink: true}, inputProps: {placeholder: "Start Time"}, sx:{width: '100%', minWidth: "auto !important"}}}}
            />                                                                                                                                
          </DemoContainer>
        </LocalizationProvider>
      </Grid>
    </Grid>
  )
}

