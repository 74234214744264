import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment-timezone';

import { Typography, Box, TextField, Link } from '@mui/material';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


const CustomRewardForm = (props) => {
  const {handleChildData, dropData, nextTabErrors, setNextTabErrors} = props;
  const [customRewardData, setCustomRewardData] = useState(props?.dropData?.custom);
  const [focused, setFocused] = useState(false);
  const handleData = (type, val) => {
    let existing_data = {...customRewardData};
    existing_data[type] = val
    setCustomRewardData(existing_data)
    setNextTabErrors({ ...nextTabErrors, ["custom_details"]: { value: dropData.custom.details, error: "" } });
  }
  useEffect(() => {
    handleChildData('custom', customRewardData)
  } ,[customRewardData]);
  
  return (
    <>
      <Box className="event-pass-form">
        {dropData.status !== 1 &&
        <>
          <Typography variant="body1" textAlign="center" className='subtitle' lineHeight="normal" mb="20px">CREATE REWARD</Typography>
          <Typography variant="h1" textAlign="center" sx={{mb: 1}} className="title">Add claim details</Typography>
          <Typography variant="body2" textAlign="center" className="detail-box">Add details about how someone can receive the reward.</Typography>
        </>
        }
        
        <Box p={dropData.status == 1 ? 0 : 4}>
          <Box>
            <Typography variant='h4' className='label-text' mb={0.5} fontSize="13px">Text <span style={{color:'red'}}>*</span></Typography>
            <Box className="textareArea" sx={{border: nextTabErrors?.custom_details?.error ? '1px solid red' : '0px solid'}}>
              <CKEditor
                editor={ ClassicEditor }
                config={{
                  placeholder: "How can someone receive this reward?",
                  heading: {
                    options: [
                      { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                      { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                      { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                      { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                    ]
                  },
                  removePlugins: ["EasyImage","ImageUpload","MediaEmbed","Table","ImageInsert","AutoImage","Base64UploadAdapter"]
                }}
                data={dropData?.custom?.details}
                onReady={(editor) => {
                  editor.editing.view.document.on( 'drop', ( evt, data ) => {
                  // Stop executing next callbacks.
                  evt.stop();
          
                  // Prevent the default event action.
                  data.preventDefault();
                }, { priority: 'high' } );
            
                editor.editing.view.document.on( 'dragover', ( evt, data ) => {
                  evt.stop();
                  data.preventDefault();
                }, { priority: 'high' } );

                  editor.editing.view.document.on('paste', (event, data) => {
                    if (data.dataTransfer._files.length > 0) {
                      event.stop();
                      return;
                    }
                  });
                        
                }}          
                onChange={ ( event,editor ) => {
                  handleData('details',editor.getData())
                } }
              />
            </Box>
            {
              nextTabErrors?.custom_details?.error &&
              <Typography variant='h4' className='label-text' mt={0.5} color="red" fontSize="13px">{nextTabErrors?.custom_details?.error}</Typography>
            }
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default CustomRewardForm