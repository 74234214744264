import React from "react";
import { Box, Typography,Button, Card, Link } from '@mui/material'
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import { RewardTypePill } from "../RewardsData/reward_type_pill";

function GatedContentPreview(props){
  const { reward, info } = props;
    return(
      <Box>
        <Box textAlign="center"><RewardTypePill reward={reward} /></Box>
        <Box sx={{background: 'rgba(34, 51, 84, 0.02)', py: 0.7, px: 1.2, m: 1, borderRadius: '0.5rem'}}>
          {
            info.map((url_data,index) => {
              return(
                <Card sx={{bgcolor:'white', boxShadow:'none', my:1, borderRadius: '0px'}} key={index}>
                  <Card sx={{display:'flex', justifyContent:'space-between' , px:1, py:1, bgcolor:'white',boxShadow:'none'}}>
                    <Box sx={{display:'flex' , alignItems:'center'}}>
                      <Box>
                        <Card sx={{borderRadius:'100%', p:1, bgcolor:'rgba(34, 51, 84, 0.2)', display: 'flex', justifyContent: 'center', width: '25px' }}>
                          <InsertLinkIcon sx={{ color: "#323232" }} />
                        </Card>
                      </Box>
                      <Typography variant="h5" className="content-name" ml={1}>{url_data.button_text}</Typography>
                    </Box>
                    <Box>
                    <Link href={url_data.content_url} target="_BLANK" underline="none"><Button variant="contained" className="claim-blue-btn">View </Button></Link>
                    </Box>
                  </Card>
                </Card>
              )
            })
          }
        </Box>
      </Box>
    )
}
export default GatedContentPreview;