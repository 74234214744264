import collections from "src/pages/collections";

const initalState = {
    editType: '',
    type: '',
    collections : [],
    message: '',
    new_collection_status: '',
    delete_collection_status: ''
}


export function adminCollectionReducer(state = initalState, action){
  let obj = state;
    switch (action.type) {
      case "GET_ADMIN_COLLECTION_SUCCESS":
        return{type: 'success', collections: action.data};
      case "UPDATE_ADMIN_COLLECTION_REQUEST":
        return{...obj, editType: 'request'};
      case "UPDATE_ADMIN_COLLECTION_SUCCESS":
        for (let i = 0; i < obj.collections.length; i++) {
          if(obj.collections[i].id == action.data.id){
            obj.collections[i] = action.data
          }
        }
        return {...obj, editType:"success"};
      case "DELETE_ADMIN_COLLECTION_SUCCESS":
        return{delete_collection_status: 'success', collections: state.collections.filter(collection => collection.id !== action.id)};
      case "DELETE_ADMIN_COLLECTION_REQUEST":
        return{...state, delete_collection_status: 'request'};
      case "GET_ADMIN_COLLECTION_ERROR":
        return{type: 'error', message: action.message};
      case "REQUEST_ADMIN_COLLECTION":
        return{ type: 'request',collections: []};
      case "RESET_ADMIN_COLLECTION_CD_STATE":
        return{ ...state, new_collection_status: '',message: "",delete_collection_status: ''};
      case "NEW_ADMIN_COLLECTION_ERROR":
        return{ ...state, new_collection_status: 'error',message: action.message};
      case "NEW_ADMIN_COLLECTION_REQUEST":
        return{ ...state, new_collection_status: 'request'};
      case "NEW_ADMIN_COLLECTION_SUCCESS":
        return{...state, message: '', new_collection_status:'success',collections: [...state.collections, action.newCollection]};
      default:
        return state;
    }
    return state;
  }