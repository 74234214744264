import React from 'react'
import { Box, styled, Typography, Link } from '@mui/material';

function NoActiveCollection(props) {
  return (
    <>
        <Box className='no-collections'>
            <img variant="square" src={"/assets/img/no-collections.png"} alt="No Collections" width="95"/>
            <Box pt={3}>
                <Typography variant="body1" className='helper-text'>{props.description}</Typography>
                {props.type !== "admin" && 
                <Link sx={{cursor: 'pointer'}} onClick={props.onClick}>Add a new collection</Link>
                }
            </Box>
        </Box>
    </>
    )
}

export default NoActiveCollection