import { Avatar, Box, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { dropTypesData } from 'src/utils'

export const RewardTypePill = ({ reward }) => {
  return (
    <Box className="drop-category" display="flex" alignItems="center" mb={1.6}>
      <img src={dropTypesData.find((d) => d.short_name === reward.drop_type).icon} alt={reward.drop_type} />
      <Typography variant="body1" pl={0.5}>
        {dropTypesData.find((d) => d.short_name === reward.drop_type).title}
      </Typography>
    </Box>
  )
}

export const AllMembersPill = ({dropsData}) => {
  return (
    <Box>
      {
        dropsData.community_type === 'membership' ? 
        <Box border="1px solid" p="7px" borderRadius="1000px">
          <Box display="flex" alignItems="center">
            <Avatar src={dropsData.sponsor_collections[0]?.logo_image ?dropsData.sponsor_collections[0]?.logo_image: '/assets/images/default_collection.svg'} alt={dropsData.sponsor_collections[0]?.title} style={{height: '25px', width: '25px', borderRadius: '50%'}}/>
            <Typography variant="body2" className='sponsor-name'>All Members</Typography>
          </Box>
        </Box>
        :
        dropsData.sponsor_collections.length !== 0 &&
        <>
          <Box display="flex" flexDirection="row">
            {(dropsData.sponsor_collections.length > 1) ?
              <>
                <Tooltip componentsProps={{
                  tooltip:{
                    sx:{
                      background:"#1A1616",
                      width:"200px"
                    }
                  }
                  }} title={
                    <Box id="basic-menu" sx={{padding: '0px !important'}}>
                      {dropsData.sponsor_collections.map((item,index) => {
                        return(
                          <Box sx={{py: '0.5rem'}} key={index}>
                            <Box display="flex" alignItems="center">
                              <Avatar src={item.logo_image ?item.logo_image: '/assets/images/default_collection.svg'} alt={item.title} className='sponsorcol_image'/>
                              <Typography variant="body1" className='sponsor_title'>{item.title}</Typography>
                            </Box>
                          </Box>
                        )
                      })}
                    </Box>
                  } arrow>
                  <Box display="flex">
                  
                    {dropsData.sponsor_collections.map((item,index) => {
                      return(
                        index <= 2 &&
                          <Avatar src={item.logo_image ?item.logo_image: '/assets/images/default_collection.svg' } key={index} alt={item.title} className='sponsor-border' sx={{marginLeft: index == 0 ? '0px' : '-1rem', borderRadius: '50%',zIndex:index,cursor:"pointer", height:"35px", width:"35px"}}/>
                      )
                    })}
                    
                    {dropsData.sponsor_collections.length > 3 &&
                      <Box className='more-sponsors-box' display="flex" justifyContent="center" alignItems="center" aria-controls={'basic-menu'} aria-haspopup="true" aria-expanded={'true'} >+{parseInt(dropsData.sponsor_collections.length - 3)}</Box>
                    }
                  </Box>
                </Tooltip>
              </>
              :
              <Box display="flex" alignItems="center">
                <Avatar src={dropsData.sponsor_collections[0].logo_image ?dropsData.sponsor_collections[0].logo_image: '/assets/images/default_collection.svg'} alt={dropsData.sponsor_collections[0].title} height="40" width="40" style={{borderRadius: '50%'}}/>
                <Typography variant="body2" className='sponsor-name'>{dropsData.sponsor_collections[0].title}</Typography>
              </Box>
            }
          </Box>
        </>
      }
    </Box>
  )
}