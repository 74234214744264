import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Box } from "@mui/material";
import DeleteCategoryModal from "src/components/Modals/delete_category_modal";

export default function BasicMenu(props) {
  const {id, content_count, name, slug, menuClicked} = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteOpen = () => {
    setDeleteOpen(true)
  }

  return (
    <Box sx={{ position: "relative", width:"45px" }}>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{
          position: "relative",
          background: "transparent",
          // p: "0px",
          // width: "6px",
          "&:hover": {
            backgroundColor: "transparent",
          },
        }}
      >
        <img src="/assets/images/dots.svg" alt="" />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button" ,
        }}
        className="navbar_dropdown navbar_dropdown_list"
        sx={{ padding: "0px" }}
      >
        <Box sx={{ padding: "0px" }}>
          <MenuItem onClick={() => {
            menuClicked(id);
          }} className="cate_delete_modal" sx={{"&:hover":{
            background:"#f3f3f3"
          }}} style={{height:"16px",}}>
            Edit
          </MenuItem>
          <MenuItem style={{margin:"10px 0px"}} sx={{borderBottom:"1px solid #dddddd", padding:"0px",}}></MenuItem>
          <MenuItem
            className="cate_delete_modal"
            onClick={handleDeleteOpen}
            sx={{pb: 0}}
          >
              Delete
            {/* <DeleteCategoryModal id={id}/> */}
          </MenuItem>

          {/* <MenuItem onClick={handleClose}>Logout</MenuItem> */}
        </Box>
      </Menu>
      {deleteOpen &&
        <DeleteCategoryModal slug={slug} id={id} name={name} content_count={content_count} open={deleteOpen} handleClose={(df = false) => {
          setDeleteOpen(false)
          if(df){
            handleClose()
          }
        }}/>
      }
    </Box>
  );
}
